export const ERROR_MESSAGE = {
  //COMMON
  ERR_0000: {
    code: 'ERR_0000',
    title: '',
    message: '',
  },
  ERR_0001: {
    code: 'ERR_0001',
    title: 'Lỗi',
    message: 'Hệ thống không phản hồi, hãy thử lại lần nữa.',
  },
  ERR_0002: {
    code: 'ERR_0002',
    title: 'CSV',
    message: 'Hãy tải lên tệp csv',
  },
  ERR_0003: {
    code: 'ERR_0003',
    title: 'Đăng nhập',
    message: 'Bạn chưa đăng nhập',
  },
  ERR_0004: {
    code: 'ERR_0004',
    title: 'Lỗi',
    message: 'Hệ thống không phản hồi, hãy thử lại lần nữa.',
  },
  ERR_0005: {
    code: 'ERR_0005',
    title: 'Tài khoản',
    message: 'Tài khoản của bạn không có quyền truy câp vào dữ liệu này',
  },
  ERR_0006: {
    code: 'ERR_0006',
    title: 'Tài khoản',
    message: 'Tài khoản không có quyền truy cập',
  },
  ERR_0007: {
    code: 'ERR_0007',
    title: 'Dữ liệu',
    message: 'Không tìm thấy dữ liệu',
  },
  ERR_0008: {
    code: 'ERR_0008',
    title: 'Dữ liệu',
    message: 'Lỗi dữ liệu',
  },
  ERR_0009: {
    code: 'ERR_0009',
    title: 'Dữ liệu',
    message: 'Không thể thay đổi dữ liệu',
  },
  ERR_0010: {
    code: 'ERR_0010',
    title: 'Dữ liệu',
    message: 'Không thể xoá dữ liệu',
  },
  //USER ERR_U***
  ERR_U000: {
    code: 'ERR_U000',
    title: 'Tài khoản',
    message: 'Không tìm thấy tài khoản',
  },
  ERR_U001: {
    code: 'ERR_U001',
    title: 'Tài khoản',
    message: 'Sai tài khoản đăng nhập hoặc mật khẩu, vui lòng thử lại một lần nữa',
  },
  ERR_U101: {
    code: 'ERR_U101',
    title: 'Tài khoản',
    message: 'Không tìm thấy tài khoản',
  },
  ERR_U102: {
    code: 'ERR_1102',
    title: 'Tài khoản',
    message: 'Tài khoản đã bị khoá',
  },
  ERR_U103: {
    code: 'ERR_U103',
    title: 'Mật khẩu',
    message: 'Sai mật khẩu. Hãy kiểm tra lại và đăng nhập.',
  },
  ERR_U104: {
    code: 'ERR_U104',
    title: 'Tài khoản',
    message: 'Sai mật khẩu hoặc email. Hãy kiểm tra lại và đăng nhập.',
  },
  ERR_U105: {
    code: 'ERR_U105',
    title: 'Tài khoản',
    message: 'Tài khoản đã tồn tại.',
  },
  ERR_U106: {
    code: 'ERR_U106',
    title: 'Tài khoản',
    message: 'Tài khoản chưa được xác thực. Hãy vào hòm thư để xác thực tài khoản',
  },
  ERR_U201: {
    code: 'ERR_U201',
    title: 'Phiên',
    message: 'Lỗi phiên',
  },
  ERR_U202: {
    code: 'ERR_U202',
    title: 'Phiên',
    message: 'Lỗi phiên',
  },
  ERR_U300: {
    code: 'ERR_U300',
    title: 'Tài khoản',
    message: 'Không thể tạo tài khoản',
  },
  ERR_U301: {
    code: 'ERR_U301',
    title: 'Tài khoản',
    message: 'Đã tồn tại tài khoản đăng nhập',
  },
  ERR_U302: {
    code: 'ERR_U302',
    title: 'Tài khoản',
    message: 'Đã tồn tại email',
  },
  ERR_U303: {
    code: 'ERR_U302',
    title: 'Dữ liệu',
    message: 'Không thể cập nhật dữ liệu',
  },
  ERR_U304: {
    code: 'ERR_U304',
    title: 'Mật khẩu',
    message: 'Mật khẩu không chính xác. Hãy thử điền bằng mật khẩu khác',
  },
  ERR_U305: {
    code: 'ERR_U305',
    title: 'Mật khẩu',
    message: 'Không thể khôi phục mật khẩu',
  },
  ERR_U306: {
    code: 'ERR_U306',
    title: 'Tài khoản',
    message: 'Không thể xoá tài khoản',
  },
  ERR_U401: {
    code: 'ERR_U401',
    title: 'Tài khoản',
    message: 'Không thể đăng xuất tài khoản',
  },
  ERR_O501: {
    code: 'ERR_O501',
    title: 'Giỏ hàng',
    message: '',
  },
  ERR_O502: {
    code: 'ERR_O501',
    title: 'Giỏ hàng',
    message: 'Không thể đặt hàng',
  },
  ERR_C600: {
    code: 'ERR_C600',
    title: 'Liên hệ',
    message: '',
  },
  ERR_C601: {
    code: 'ERR_C601',
    title: 'Liên hệ',
    message: 'Không gửi được liên hệ, vui lòng thử lại một lần nữa',
  },
  ERR_C602: {
    code: 'ERR_C602',
    title: 'Liên hệ',
    message: 'Không xoá được liên hệ, vui lòng thử lại một lần nữa',
  },
  ERR_C603: {
    code: 'ERR_C603',
    title: '連絡先',
    message: 'Thời gian đặt lịch đã tồn tại, vui lòng chọn thời gian khác',
  },
  ERR_CO700: {
    code: 'ERR_CO700',
    title: 'Tạo khoá học',
    message: 'Tạo khoá học không thành công',
  },
  ERR_CO701: {
    code: 'ERR_CO701',
    title: 'Cập nhật khoá học',
    message: 'Cập nhật khoá học không thành công',
  },
  ERR_CO702: {
    code: 'ERR_CO702',
    title: 'Xoá khoá học',
    message: 'Xoá khoá học không thành công',
  },
  ERR_CO703: {
    code: 'ERR_CO703',
    title: 'Khoá học',
    message:
      'Thông tin khoá học không hợp lệ , vui lòng kiểm tra lại các thông tin của khoá học. Lưu ý Các trường dữ liệu (*) là bắt buộc phải nhập.',
  },
  ERR_CO704: {
    code: 'ERR_CO704',
    title: 'Mã tiền đã được sử dụng',
    message: 'Lỗi mã đã được sử dụng, vui lòng hãy chọn một mã giá khác',
  },
  ERR_B00: {
    code: 'ERR_B00',
    title: 'Giáo trình',
    message: 'Không tìm thấy giáo trình',
  },
  ERR_B01: {
    code: 'ERR_B01',
    title: 'Giáo trình',
    message: 'Không tìm thấy giáo trình',
  },
  ERR_B02: {
    code: 'ERR_B02',
    title: 'Giáo trình',
    message:
      'Thông tin giáo trình không hợp lệ , vui lòng kiểm tra lại các thông tin của giáo trình và khoá học. Lưu ý Các dữ liệu không được để trống. Lưu ý Các trường dữ liệu (*) là bắt buộc phải nhập.',
  },
  ERR_E02: {
    code: 'ERR_E02',
    title: 'Bài test',
    message:
      'Thông tin bài test không hợp lệ , vui lòng kiểm tra lại các thông tin của các bài test và khoá học. Lưu ý Các dữ liệu không được để trống. Lưu ý Các trường dữ liệu (*) là bắt buộc phải nhập.',
  },
  ERR_P001: {
    code: 'ERR_P001',
    title: 'Post',
    message: '',
  },
  ERR_E001: {
    code: 'ERR_E001',
    title: 'Update',
    message: 'Update error',
  },
  ERR_Q000: {
    code: 'ERR_Q000',
    title: 'Xoá câu hỏi',
    message: 'Không thể xoá câu hỏi. Vui lòng thử lại.',
  },
  ERR_Q001: {
    code: 'ERR_Q001',
    title: 'Xoá câu hỏi',
    message: 'Không thể xoá câu hỏi. Vì câu hỏi đang được sử dụng trong bài test',
  },
  ERR_SCHEDULE_001: {
    code: 'ERR_SCHEDULE_001',
    title: 'スケジュールの作成',
    message: 'スケジュールは作成できません。もう一度やり直してください。',
  },
  ERR_SCHEDULE_002: {
    code: 'ERR_SCHEDULE_002',
    title: 'スケジュールの削除',
    message: 'スケジュールは削除できません。もう一度やり直してください。',
  },
  ERR_BOOKING_001: {
    code: 'ERR_BOOKING_001',
    title: '予約の作成',
    message: '予約は作成できません。もう一度やり直してください。',
  },
  ERR_BOOKING_002: {
    code: 'ERR_BOOKING_002',
    title: '予約の変更',
    message: '予約は変更できません。もう一度やり直してください。',
  },
  ERR_BOOKING_003: {
    code: 'ERR_BOOKING_003',
    title: '予約のキャンセル',
    message: '予約はキャンセルできません。もう一度やり直してください。',
  },
  ERR_BOOKING_004: {
    code: 'ERR_BOOKING_004',
    title: '予約の終了',
    message: '予約は終了できません。もう一度やり直してください。',
  },
};
