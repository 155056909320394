import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { History } from 'history';
import * as Pages from './Loadables';
import Header from 'components/common/components/Header/Header';
import Footer from 'components/common/components/PageFooter/Footer';
const MyBookingPage = React.lazy(() => import('containers/student-interface/MyBooking/index'));
const BookingSearchPage = React.lazy(() => import('containers/user-interface/booking/BookingSearchPage'));

type StudentLayoutProps = {
  history: History;
};

export const StudentLayout = (props: StudentLayoutProps) => {
  const location = useLocation();
  return (
    <div>
      <Header isCMS={false} history={props.history} />
      <Switch>
        <Route path="/user/lecture/:bookId/:lessonId" component={Pages.Lecture} />
        <Route path="/user/lecture/:bookId" component={Pages.Lecture} />
        <Route path="/user/exam/:examId/:userExamId" component={() => <Pages.Exam history={props.history} />} />
        <Route path="/user/exam/:examId" component={() => <Pages.Exam history={props.history} />} />
        <Switch>
          <Pages.User history={props.history}>
            <Route exact path="/user/my-profile" history={props.history} component={() => <Pages.UserInformation />} />
            <Route
              exact
              path="/user/orders"
              history={props.history}
              component={() => <Pages.OrderContainer history={props.history} />}
            />

            <Route
              exact
              path="/user/courses/:courseId/exams"
              history={props.history}
              component={() => <Pages.MyExam history={props.history} />}
            />
            <Route
              exact
              path="/user/courses"
              history={props.history}
              component={() => <Pages.MyCourse history={props.history} />}
            />
            <Route
              exact
              path="/user/my-exam"
              history={props.history}
              component={() => <Pages.MyExam history={props.history} />}
            />
            <Route exact path="/user/booking" history={props.history} component={BookingSearchPage} />
            <Route exact path="/user/my-booking" history={props.history} component={MyBookingPage} />
            <Route exact path="/user/change-password" history={props.history} component={() => <Pages.ChangePass />} />
            {/* NOTIFICATION */}
            <Route exact path="/user/notification" history={props.history} component={Pages.Notification} />
          </Pages.User>
        </Switch>

        <Route component={Pages.NotFoundPage} />
      </Switch>
      {!location.pathname.includes('/user') && <Footer />}
    </div>
  );
};
