export const typeList = {
  languages: [
    {
      label: 'Tiếng Nhật',
      value: 'ja',
    },
    // {
    //   label: 'Tiếng Anh',
    //   value: 'en',
    // },
    {
      label: 'Tiếng Việt',
      value: 'vi',
    },
  ],
  questionTypes: [
    {
      label: 'Nhóm',
      value: 'GROUP',
    },
    {
      label: 'Đơn',
      value: 'SINGLE',
    },
  ],
  articleCategories: [
    {
      label: 'Tin tức',
      value: 'news',
    },
    {
      label: 'Blog',
      value: 'blog',
    },
    {
      label: 'Sự kiện',
      value: 'event',
    },
    {
      label: 'Kinh doanh',
      value: 'business',
    },
    {
      label: 'Giáo dục',
      value: 'education',
    },
    {
      label: 'Bài thi',
      value: 'exam',
    },
    {
      label: 'Khoá học',
      value: 'course',
    },
  ],

  questionCategories: [
    {
      label: 'Đọc',
      value: 'READING',
    },
    {
      label: 'Nghe',
      value: 'LISTENING',
    },
  ],
  userProfileMenu: [
    {
      prefixIcon: 'user',
      label: 'Thông tin tài khoản',
      url: '/user/my-profile',
    },
    {
      prefixIcon: 'message',
      label: 'Thông báo',
      url: '/user/notification',
    },
    {
      prefixIcon: 'clock',
      label: 'Đặt Lịch',
      url: '/user/booking',
    },
    {
      prefixIcon: 'clock',
      label: 'Đặt Lịch của tôi',
      url: '/user/my-booking',
    },
    {
      prefixIcon: 'cart',
      label: 'Lịch sử đặt hàng',
      url: '/user/orders',
    },
    {
      prefixIcon: 'archive',
      label: 'Khoá học của tôi',
      url: '/user/courses',
    },
    // {
    //   prefixIcon: 'check-circle',
    //   label: 'Bài thi',
    //   url: '/user/my-exam',
    // },
    // {
    //   prefixIcon: 'book',
    //   label: 'Sách',
    //   url: '/user/my-book',
    // },
    {
      prefixIcon: 'key',
      label: 'Đổi mật khẩu',
      url: '/user/change-password',
    },
    {
      prefixIcon: 'logout',
      label: 'Đăng xuất',
      url: '/user/logout',
    },
  ],
  authorMenu: [
    {
      prefixIcon: 'statistic',
      label: 'Thống kê',
      url: '/author/statistics',
    },
    {
      prefixIcon: 'clock',
      label: 'Lịch giảng',
      url: '/author/schedule',
    },
    {
      prefixIcon: 'user',
      label: 'Thông tin cá nhân',
      url: '/author/profile',
    },
    {
      prefixIcon: 'message',
      label: 'Thông báo',
      url: '/author/notification',
    },
    {
      prefixIcon: 'case',
      label: 'Quản lý khoá học',
      url: '/author/courses',
    },
    {
      prefixIcon: 'question',
      label: 'Q.L Câu hỏi',
      url: '/author/questions',
    },
    {
      prefixIcon: 'key',
      label: 'Đổi mật khẩu',
      url: '/author/change-password',
    },
    {
      prefixIcon: 'media',
      label: 'Đa phương tiện',
      url: '/author/medias',
    },

    {
      prefixIcon: 'logout',
      label: 'Thoát',
      url: '/author/logout',
    },
  ],
  courseDetailsMenu: [
    {
      prefixIcon: 'book',
      label: 'Giáo trình',
      url: 'syllabus',
    },
    {
      prefixIcon: 'check-circle',
      label: 'Q.L Bài test',
      url: 'exam',
    },
    {
      prefixIcon: 'case',
      label: 'Tổng quan',
      url: 'course-landing',
    },
    {
      prefixIcon: 'dollar',
      label: 'Cài đặt giá',
      url: 'price',
    },
  ],
  footerMenu: [
    [
      {
        type: 'header',
        title: 'Vietcademy',
      },
      {
        type: 'text',
        title:
          'QHコンサルティング（QHC）は豊かな経験・業界s知識を駆使して、 お客様の直面する変asd化や課題を解決しています。 デジタル化時代における、 IT活用はお客様の経営戦略を支援します。',
      },
      {
        type: 'phone',
        title: '03-6873-1076',
      },
      {
        type: 'email',
        title: 'contact@qh-consulting.com',
      },
    ],
    [
      {
        type: 'header',
        title: 'Công ty',
      },
      // {
      //   type: 'link',
      //   title: 'Thông tin công ty',
      //   url: 'https://qh-consulting.com/about',
      // },
      {
        type: 'link',
        title: 'Điều khoản',
        url: '/terms-of-service',
      },
      {
        type: 'link',
        title: 'C.Sách bảo mật',
        url: '/privacy',
      },
      {
        type: 'link',
        title: 'Luật thương mại',
        url: '/specified-commercial-law',
      },
      {
        type: 'link',
        title: 'Liên hệ',
        url: '/contact',
      },
    ],
    [
      {
        type: 'header',
        title: 'Trình đơn',
      },
      {
        type: 'link',
        title: 'Khoá học',
        url: '/courses',
      },
      {
        type: 'link',
        title: 'Dịch vụ',
        url: '/services',
      },
      {
        type: 'link',
        title: 'Tin tức',
        url: '/articles',
      },
      {
        type: 'link',
        title: 'Trợ giúp',
        url: '/help-center',
      },
    ],
    [
      {
        type: 'header',
        title: 'Ứng dụng',
      },
      {
        type: 'android',
        title: 'android',
      },

      {
        type: 'ios',
        title: 'ios',
      },
    ],
  ],
  genders: [
    {
      label: 'Nữ',
      value: 'FEMALE',
    },
    {
      label: 'Nam',
      value: 'MALE',
    },
  ],
  userDetailsTabs: [
    {
      label: 'Thông tin',
      value: 'UserInformation',
    },
    {
      label: 'Khoá học',
      value: 'UserCourse',
    },
    {
      label: 'Kết quả Test',
      value: 'UserExam',
    },
  ],
  adminDetailsTabs: [
    {
      label: 'Thông tin',
      value: 'UserInformation',
    },
    {
      label: 'Khoá học',
      value: 'UserCourse',
    },
    {
      label: 'Kết quả Test',
      value: 'UserExam',
    },
  ],
  yesNoTypes: [
    {
      label: 'Không',
      value: false,
    },
    {
      label: 'Có',
      value: true,
    },
  ],
  examSegmentItems: [
    {
      label: 'Đơn',
      value: 'single',
    },
    {
      label: 'Tất cả',
      value: 'all',
    },
  ],
  cmsSidebarMenus: [
    {
      label: 'Quản lý tài khoản',
      prefixIcon: 'user',
      url: '/cms/users',
      subMenus: [
        {
          label: 'Tìm kiếm T.Khoản',
          url: '/cms/users',
        },
        {
          label: 'Tìm kiếm B.Thi',
          url: '/cms/user-exams',
        },
      ],
    },
    {
      prefixIcon: 'clock',
      label: 'Quản lý Schedule',
      url: '/cms/schedules',
      subMenus: [
        {
          label: 'Quản lý Schedule',
          url: '/cms/schedules',
        },
      ],
    },
    {
      prefixIcon: 'clock',
      label: 'Quản lý booking',
      url: '/cms/bookings',
      subMenus: [
        {
          label: 'Quản lý booking',
          url: '/cms/bookings',
        },
      ],
    },
    // {
    //   label: 'Quản lý đơn hàng',
    //   prefixIcon: 'cart',
    //   url: '/cms/orders',
    //   subMenus: [
    //     {
    //       label: 'Tìm Đ.Hàng',
    //       url: '/cms/orders',
    //     },
    //   ],
    // },
    // {
    //   prefixIcon: 'book',
    //   label: 'Quản lý sách',
    //   url: '/cms/books',
    //   subMenus: [
    //     {
    //       label: 'Tìm sách',
    //       url: '/cms/books',
    //     },
    //     {
    //       prefixIcon: 'check',
    //       label: 'Tải lên tệp',
    //       url: '/cms/book/upload',
    //     },
    //   ],
    // },
    {
      prefixIcon: 'case',
      label: 'Quản lý K.Học',
      url: '/cms/courses',
      subMenus: [
        {
          label: 'Tìm K.Học',
          url: '/cms/courses',
        },
        {
          prefixIcon: 'check',
          label: 'Tải lên tệp',
          url: '/cms/courses/upload',
        },
      ],
    },
    // {
    //   prefixIcon: 'question',
    //   label: 'Quản lý câu hỏi',
    //   url: '/cms/questions',
    //   subMenus: [
    //     {
    //       label: 'Tìm C.Hỏi',
    //       url: '/cms/questions',
    //     },
    //     {
    //       prefixIcon: 'check',
    //       label: 'Tải lên tệp',
    //       url: '/cms/questions/upload',
    //     },
    //   ],
    // },
    // {
    //   prefixIcon: 'clock',
    //   label: 'Quản lý bài thi',
    //   url: '/cms/exam/list',
    //   subMenus: [
    //     {
    //       label: 'Tìm B.Thi',
    //       url: '/cms/exam/list',
    //     },
    //     {
    //       prefixIcon: 'check',
    //       label: 'Kết quả thi',
    //       url: '/cms/exam-result/list',
    //     },
    //   ],
    // },

    {
      prefixIcon: 'file',
      label: 'Bài đăng',
      url: '/cms/articles',
      subMenus: [
        {
          label: 'DS Bài đăng',
          url: '/cms/articles',
        },
      ],
    },
    {
      prefixIcon: 'case',
      label: 'Dịch vụ',
      url: '/cms/services',
      subMenus: [
        {
          label: 'Tìm D.Vụ',
          url: '/cms/services',
        },
      ],
    },
    {
      prefixIcon: 'question',
      label: 'Trợ giúp',
      url: '/cms/helps',
      subMenus: [
        {
          label: 'Quản lý trợ giúp',
          url: '/cms/helps',
        },
      ],
    },
    {
      prefixIcon: 'media',
      label: 'Quản lý media',
      url: '/cms/medias',
      subMenus: [
        {
          label: 'Quản lý media',
          url: '/cms/medias',
        },
      ],
    },
    {
      prefixIcon: 'email',
      label: 'Liên hệ',
      url: '/cms/contacts',
      subMenus: [
        {
          label: 'Quản lý liên hệ',
          url: '/cms/contacts',
        },
      ],
    },
    {
      prefixIcon: 'list',
      label: 'Báo cáo',
      url: '/cms/reports',
      subMenus: [
        {
          label: 'Quản lý báo cáo',
          url: '/cms/reports',
        },
      ],
    },
    {
      prefixIcon: 'gift',
      label: 'Sự kiện',
      url: '/cms/events',
      subMenus: [
        {
          label: 'DS sự kiện',
          url: '/cms/events',
        },
        {
          label: 'Quảng cáo',
          url: '/cms/marketing',
        },
      ],
    },
    {
      prefixIcon: 'case',
      label: 'ACCESS LOG',
      url: '/cms/access-log',
      subMenus: [
        {
          label: 'ACCESS LOG',
          url: '/cms/access-log',
        },
      ],
    },
    {
      prefixIcon: 'setting',
      label: 'Cài đặt',
      url: '/cms/setting',
      subMenus: [
        {
          label: 'Trang chủ',
          url: '/cms/settings/home',
        },
        {
          label: 'Chính sách bảo mật',
          url: '/cms/settings/privacy',
        },
        {
          label: 'Điều khoản xử dụng D.V',
          url: '/cms/settings/terms-of-service',
        },
        {
          label: 'Thông tin công ty',
          url: '/cms/settings/company-profile',
        },
        {
          label: 'Luật Thương mại',
          url: '/cms/settings/specified-commercial-law',
        },
        {
          label: 'Bản tin',
          url: '/cms/settings/articles',
        },
        {
          label: 'Khoá học',
          url: '/cms/settings/course',
        },
        {
          label: 'Cuối trang',
          url: '/cms/settings/footer',
        },
        {
          label: 'Bản dịch ngôn ngữ',
          url: '/cms/settings/locale-translation',
        },
      ],
    },
    {
      prefixIcon: 'logout',
      label: 'Đăng xuất',
      url: '/logout',
      subMenus: [],
    },
  ],
  orderStatus: [
    {
      label: 'Đang duyệt',
      value: 'PENDING',
    },
    {
      label: 'Đã duyệt',
      value: 'APPROVED',
    },
  ],
  orderSegment: [
    {
      label: 'Giỏ hàng',
      value: 'cart',
    },
    {
      label: 'Đơn hàng',
      value: 'order',
    },
  ],
  examTypes: [
    {
      label: 'Bài thi nhỏ',
      value: 'MiniTest',
    },
    {
      label: 'Bài thi lớn',
      value: 'FullTest',
    },
  ],
  examTabs: [
    {
      label: 'Tất cả',
      value: 'All',
    },
    {
      label: 'Bài thi nhỏ',
      value: 'MiniTest',
    },
    {
      label: 'Bài thi lớn',
      value: 'FullTest',
    },
  ],
  userRoles: [
    {
      label: 'Nhà quản lý',
      value: 'ADMIN',
    },
    {
      label: 'Thành viên',
      value: 'USER',
    },
    {
      label: 'Giáo viên',
      value: 'AUTHOR',
    },
  ],
  segmentPublic: [
    {
      label: 'Công khai',
      value: true,
    },
    {
      label: 'Không công khai',
      value: false,
    },
  ],
  examSteps: [
    '(Yêu cầu: Khoá học đã được tạo và đã công khai).',
    '',
    '1. Liên kết đề thi với khoá học. ( Để biết đề thi dành cho khoá học nào ).',
    '2. Thêm các mục.',
    '3. Thêm câu hỏi cho các mục.',
    '',
    'Để chỉnh sửa câu hỏi nhấp vào nút chỉnh sửa.',
  ],
  courseSteps: [
    'Yêu cầu: Giáo trình đã được tạo và đã công khai.',
    'Mỗi khoá học có thể có nhiều giáo trình khác nhau.',
    '',
    '1. Thêm giáo trình.',
    'Tên, mô tả và các tiêu đề của bài học được lấy tự động.',
    '',
    '2. Chỉnh sửa nội dung tiêu đề - mô tả - bài học nếu cần.',
    '',
    '3. Thêm Các kỹ năng sẽ học được trong mỗi giáo trình.',
  ],
  questionSteps: [
    'Yêu cầu: Giáo trình đã được tạo và đã công khai.',
    'Mỗi Giáo trình đều có nhiều bài học khác nhau. Vì thế mỗi câu hỏi sẽ tạo ra từ nội dung bài học',
    '',
    '1. Chọn bài học cần tạo câu hỏi [Tên bài học]. nếu không có bài học vui lòng thêm bài học vào giáo trình trước',
    '',
    '2. Chọn loại câu hỏi đơn hay nhóm.',
    '',
    '3. Chọn loại câu hỏi về nghe hay đọc hiểu.',
    '',
    '4. Nhập nội dung câu hỏi.',
  ],
  helpCategories: [
    {
      label: 'Tài khoản',
      value: 'account',
    },
    {
      label: 'Giáo trình',
      value: 'book',
    },
    {
      label: 'Khoá học',
      value: 'course',
    },
    {
      label: 'Câu hỏi',
      value: 'question',
    },
    {
      label: 'Đề thi',
      value: 'exam',
    },
    {
      label: 'Thanh toán',
      value: 'payment',
    },
    {
      label: 'Chính sách & điều khoản',
      value: 'policy',
    },
    {
      label: 'Khác',
      value: 'other',
    },
  ],
  daysOfWeek: [
    {
      label: 'T.2',
      value: 'monday',
    },
    {
      label: 'T.3',
      value: 'tuesday',
    },
    {
      label: 'T.4',
      value: 'wednesday',
    },
    {
      label: 'T.5',
      value: 'thursday',
    },
    {
      label: 'T.6',
      value: 'friday',
    },
    {
      label: 'T.7',
      value: 'saturday',
    },
    {
      label: 'C.N',
      value: 'sunday',
    },
  ],
  targetGroup: [
    {
      label: 'Thành viên mới',
      value: 'NEW_MEMBER',
    },
    {
      label: 'Học viên',
      value: 'MEMBER',
    },
    {
      label: 'Học viên đã từng thanh toán',
      value: 'PAID_MEMBER',
    },
    {
      label: 'Giáo viên',
      value: 'AUTHOR',
    },
  ],
  eventType: [
    {
      label: 'Tặng khoá học',
      value: 'BONUS_COURSE',
    },
    {
      label: 'Tặng vé',
      value: 'BONUS_TICKET',
    },
  ],
  priceGroup: [
    {
      label: 'Có phí',
      value: 'FEE',
    },
    {
      label: 'Miễn phí',
      value: 'FREE',
    },
  ],
  mediaGroup: [
    {
      label: 'Hình ảnh',
      value: 'image',
    },
    {
      label: 'Video',
      value: 'video',
    },
    {
      label: 'Âm thanh',
      value: 'audio',
    },
  ],
  limitOptions: [
    {
      label: '10 kiện',
      value: 10,
    },
    {
      label: '30 kiện',
      value: 30,
    },
    {
      label: '50 kiện',
      value: 50,
    },
    {
      label: '100 kiện',
      value: 100,
    },
    {
      label: '500 kiện',
      value: 500,
    },
  ],
  objectReport: [
    {
      label: 'Giáo viên',
      value: 'author',
    },
    {
      label: 'Đặt lịch',
      value: 'booking',
    },
    {
      label: 'Hoàn vé',
      value: 'refund-ticket',
    },
    {
      label: 'Chương trình',
      value: 'course',
    },
  ],
  lessonLevels: [
    {
      label: 'Sơ cấp',
      value: 'lesson-level-beginner',
    },
    {
      label: 'Trung cấp',
      value: 'lesson-level-intermediate',
    },
    {
      label: 'Cấp độ công việc',
      value: 'lesson-level-business',
    },
    {
      label: 'Chưa rõ cấp độ',
      value: 'lesson-level-unkown',
    },
    {
      label: 'Có thể đọc viết nhưng giao tiếp chưa tốt',
      value: 'lesson-level-only-red-and-write',
    },
  ],
  lessonTopics: [
    {
      label: 'Giao tiếp hàng ngày',
      value: 'lesson-topic-daily-communication',
    },
    {
      label: 'Hội thoại công việc',
      value: 'lesson-topic-business-communication',
    },
    {
      label: 'Bài học cho người mới',
      value: 'lesson-topic-beginners',
    },
    {
      label: 'Nói chuyện tự do',
      value: 'lesson-topic-free-talk',
    },
    {
      label: 'Phim ảnh và âm nhạc',
      value: 'lesson-topic-movies-music-etc',
    },
    {
      label: 'Giúp đỡ làm bài tập',
      value: 'lesson-topic-homework',
    },
    {
      label: 'Bài học cho trẻ em',
      value: 'lesson-topic-kids',
    },
    {
      label: 'Luyện thi Tiếng Việt',
      value: 'lesson-topic-vietnamese-test',
    },
    {
      label: 'Khác (Dựa theo nguyện vọng của học viên)',
      value: 'other',
    },
  ],
  lessonFlows: [
    {
      label: 'Rút ngắn phần giới thiệu bản thân (Chỉ giới thiệu tên)',
      value: 'self-introduce-shorten',
    },
    {
      label: 'Nói chậm',
      value: 'talk-slowly',
    },
    {
      label: 'Học sinh được nói nhiều hơn',
      value: 'student-talk-more',
    },
  ],
  lessonStrictness: [
    {
      label: 'Tuỳ thuộc vào giáo viên',
      value: 'teacher-depend',
    },
    {
      label: 'Chỉ ra một cách vui vẻ ',
      value: 'point-out',
    },
    {
      label: 'Vì muốn nói chuyện vui vẻ, nên không chỉ ra cũng được',
      value: 'not-point-out',
    },
  ],
  marketingTypes: [
    {
      label: 'Nhà hàng',
      value: 'restaurant',
    },
    {
      label: 'Cửa hàng',
      value: 'shop',
    },
    {
      label: 'Khoá học',
      value: 'course',
    },
    {
      label: 'Sự kiện',
      value: 'event',
    },
  ],
};
