import { useMutation } from '@apollo/client';
import { DocumentNode, GraphQLError } from 'graphql';
import { useTranslationText } from 'locale';
import { IMessage } from 'types/Models';

type UseBaseMutation = {
  mutation: DocumentNode;
};

export function useBaseMutation<TMutation = any, TData = any>(props: UseBaseMutation) {
  const { getMessage } = useTranslationText();
  const [mutation, { loading }] = useMutation(props.mutation);

  async function mutationData(input?: TMutation): Promise<{ error?: IMessage; data?: TData }> {
    try {
      const { data, errors } = await mutation(input ? { variables: { input } } : undefined);
      if (errors?.length) {
        const errorCode = errors[0].message;
        if (!errorCode) {
          return { error: getMessage(errorCode, 'error') };
        }
        return { error: getMessage('ERR_0001', 'error') };
      }

      if (!data) {
        return { error: getMessage('ERR_0001', 'error') };
      }

      return { data };
    } catch (errors) {
      const { graphQLErrors } = errors as { graphQLErrors: GraphQLError[] };
      const error = graphQLErrors?.length
        ? { error: getMessage(graphQLErrors[0].message, 'error') }
        : { error: getMessage('ERR_0001', 'error') };

      return error;
    }
  }

  return {
    /**
     * Loading api
     */
    loading,
    /**
     * Fn Update or Delete data
     */
    mutation: mutationData,
  };
}
