export const global = {
  header: {
    login: 'Đăng nhập',
  },
  courseType: {
    course: 'Khoá học',
    exam: 'Bài tập kiểm tra',
  },
  newRegister: 'Đăng ký',
  cropMedia: 'Cắt ảnh',
  export: 'Xuất ra tệp',
  type: 'Loại',
  status: 'Trạng thái',
  languageTranslation: 'Bản dịch các ngôn ngữ',
  translationReplaceTo: 'Bản dịch thay thế',
  errorsTranslation: 'Bản dịch T.B lỗi',
  typeTranslation: 'Bản dịch chọn dữ liệu',
  labelTranslation: 'Bản dịch nhãn mác',
  selfStudy: 'Chương trình cho người tự học',
  revenue: {
    title: 'Thống kê thu nhập',
    total: 'Tổng thu nhập',
    bookingList: 'Danh sách lịch hẹn',
    date: 'Thời gian',
    studentName: 'Tên học sinh',
    price: 'Số tiền',
  },
  statusCollection: {
    new: 'Mới',
    all: 'Tất cả',
    empty: 'Trống',
    finish: 'Đã hoàn thành',
    completed: 'Đã hoàn thành',
    pending: 'Đang xét duyệt',
    approved: 'Đã công khai',
    public: 'Công khai',
    unpublic: 'Chưa công khai',
    reject: 'Bị từ chối',
  },
  actions: {
    reSubmitForReview: 'Gửi lại xét duyệt',
    submitForReview: 'Gửi xét duyệt',
    submitForApproval: 'Xét duyệt',
    rejection: 'Từ chối',
  },
  accountStatus: {
    active: 'Đang hoạt động',
    locked: 'Bị khoá',
    inactive: 'Chưa xác thực',
    pendingAuthor: 'Chưa xác nhận',
    approveAuthor: 'Đã xác nhận',
    notverifyauthor: 'Chưa xác nhận T.K G.Viên',
  },
  vietcademyService: 'Dịch vụ của Vietcademy',
  selectALanguage: 'Chọn ngôn ngữ mà bạn muốn hiển thị',
  ja: 'Tiếng Nhật',
  vi: 'Tiếng Việt',
  viTranslation: 'B.Dịch Tiếng Việt',
  jaTranslation: 'B.Dịch Tiếng Nhật',
  enTranslation: 'B.Dịch Tiếng Anh',
  role: 'Vai trò',
  admin: 'Quản lý',
  user: 'Học sinh',
  author: 'Giảng viên',
  emptyValue: 'Trống',
  myAccount: 'Tài khoản',
  publish: 'Xuất bản',
  edit: 'Chỉnh sửa',
  delete: 'Xoá',
  new: 'Thêm mới',
  details: 'Xem chi tiết',
  created: 'Ngày tạo:',
  updated: 'Ngày cập nhật:',
  noname: 'Chưa đặt tên',
  gotoSyllabus: 'Đến trang giáo trình',
  nosyllabus: 'Bạn chưa tạo giáo trình, hãy tạo giáo trình trước khi tạo câu hỏi.',
  description: 'Mô tả',
  message1: 'Lưu ý: Hãy lưu dữ liệu trước khi gửi dể xét duyệt',
  pendingMessage: 'Chú ý: Đang trong thời gian xem xét nên bạn không thể chỉnh sửa được nội dung.',
  rejectMessage:
    'Xin lỗi! chúng tôi không thể duyệt nội dung của bạn. Hãy xem lý do tại sao và kiểm tra lại toàn bộ nội dung của bạn',
  reason: 'Lý do: ',
  remainTime: 'Còn lại',
  day: 'ngày',
  hour: 'giờ',
  minute: 'phút',
  free: 'Miễn phí',
  avgRate: 'Điểm đánh giá',
  rating: 'Đánh giá',
  totalReview: 'Lượt đánh giá',
  review: 'Các bài đánh giá',
  writeReview: 'Viết bài đánh giá',
  reviewText: 'Nội dung',
  reviewRate: 'Xếp hạng',
  editReview: 'Chỉnh sửa',
  submitReview: 'Gửi bài đánh giá',
  writeAReview: 'Viết đánh giá khoá học',
  reviewer: 'Người đánh giá',
  studentRate: 'Đánh giá của học viên',
  studentReview: 'Nhận xét của học viên',
  you: 'Bạn',
  noReviewData: 'Không có bài nhận xét nào',
  updatedTime: 'Cập nhật',
  display: 'Hiển thị',
  unpublish: 'Huỷ xuất bản',
  select: 'Chọn',
  search: 'Tìm kiếm',
  searchExamFree: 'Tìm bài test miễn phí',
  selectCourse: 'Chọn khoá học',
  sharePost: 'Hãy chia sẻ bài viết cho bạn bè',
  seeMore: 'Xem thêm',
  pagination: 'Trang',
  nodata: 'Không có dữ liệu',
  report: 'Báo cáo',
  mobileSideBar: {
    home: 'Trang chủ',
    course: 'Khoá học',
    news: 'Bản tin',
    service: 'Dịch vụ',
    login: 'Đăng nhập',
    logout: 'Đăng xuất',
    register: 'Đăng ký',
    cart: 'Giỏ hàng',
    help: 'Trợ giúp',
    forStudent: 'Học sinh',
    forAuthor: 'Giảng viên',
    language: 'Ngôn ngữ',
  },
  userLayout: {
    exam: {
      examFinish: 'Bài thi đã kết thúc',
      messageForGuest: 'Đăng ký tài khoản để xem kết quả bài thị.',
      register: 'Đăng ký tài khoản',
      guest: 'Khách',
      person: 'Thí sinh',
      exam: 'Bài thi',
      course: 'Khoá học',
      finish: 'Đã hoàn thành',
      time: 'Thời gian',
      hour: 'Giờ',
      second: 'Giây',
      minute: 'Phút',
      submit: 'Nộp bài',
      question: 'Câu hỏi',
      subQuestion: 'Câu hỏi phụ',
      startButton: 'Bắt đầu K.Tra',
      unitTime: 'Phút',
      totalPass: 'Đáp án đúng',
      totalQuestion: 'Tất cả câu hỏi',
      cancelButton: 'Huỷ',
      resultTitle: 'Kết quả bài K.Tra',
      gotoMyExam: 'Tới DS Bài K.Tra',
      showResult: 'Hiển thị đáp án',
      noAccess: 'Không thể xem nội dung',
      notForYou:
        'Xin lỗi, tài khoản của bạn không có quyền xem nội dung này. Vui lòng dăng nhập bằng tài khoản học viên.',
      login: 'Đăng nhập',
      redirection: 'Chuyển hướng',
      waitingRorRedirection: 'Đang chuyển hướng, vui lòng chờ trong giây lát...',
    },
    lecture: {
      title: 'Bài giảng',
    },
    userTabs: {
      information: {
        account: 'Tài khoản',
        myInformation: 'Thông tin tài khoản',
        header: 'Thông tin',
        avatar: 'Hình đại diện',
        title: 'Thông tin',
        email: 'email',
        name: 'Tên',
        gender: 'Giới tính',
        birthday: 'Ngày sinh',
        address: 'Địa chỉ',
        description: 'Mô tả ngắn',
        image: 'Hình ảnh',
        promoVideo: 'Video giới thiệu',
        certificate: 'Bằng cấp, chứng chỉ',
        certificateImage: 'Hình ảnh (Bằng cấp, chứng chỉ)',
        certificateName: 'Tên (bằng cấp, chứng chỉ)',
        update: 'Cập nhật',
        lessonLevel: 'Cấp độ hướng tới',
        lessonTopic: 'Nội dung sở trường',
      },
      order: {
        history: 'Lịch sử mua hàng',
        cart: 'Giỏ hàng',
        noItem: 'Không có',
        title: 'Lịch sử mua hàng',
        time: 'Giờ',
        unitPrice: '円',
        day: 'Ngày',
        purchaseDate: 'Ngày giao dịch',
        totalItems: 'S.L',
        totalPrice: 'Thành tiền',
        payment: 'Thanh toán',
        cancel: 'Huỷ',
        username: 'Tên',
        status: 'Trạng thái',
        createdTime: 'Thời gian',
        price: 'Giá',
        approved: 'Đã duyệt',
        pending: 'Đang duyệt',
        step: 'Bước',
        step1: 'Đơn hàng',
        step2: 'Thanh toán',
        step3: 'Vào học',
        back: 'Quay lại',
        next: 'Tiếp theo',
        learn: 'Vào học',
        paymentMethod: 'Phương thức thanh toán',
        paymentOrder: 'Thanh toán đơn hàng',
        paymentInfor: 'Thông tin thanh toán',
        changePaymentCard: 'Thay đổi',
        noPaymentMethod: 'Bạn không có thẻ thanh toán nào, hãy thêm thẻ thanh toán mới để có thể hoàn tất đơn hàng.',
        paymentCard: 'Thẻ thanh toán',
        paymentCardList: 'Danh sách thẻ thanh toán',
        addPaymentCard: 'Thẻ thanh toán mới',
        errorPaymentMethod: 'Sai thông tin thẻ thanh toán, vui lòng kiểm tra lại.',
        add: 'Thêm',
        noItemInCart: 'Không có khoá học nào',
        noItemInCartDescription:
          'Hiện tại bạn chưa thêm khoá học nào vào giỏ hàng của mình. Hãy đi đến danh sách khoá học để tìm khoá học phù hợp cho mình',
        gotoCourses: 'Đi đến danh sách khoá học',
        paymentCompleted: 'Thanh toán thành công',
        paymentCompletedDescription: 'Cảm ơn bạn đã mua khoá học của chúng tôi. ',
        gotoLearning: 'Đến đi đến khoá học của tôi',
        processing: 'Đang xử lý',
        messageWarning:
          'Hệ thống đang hoàn tất quá trình thanh toán. Vui lòng không tắt trình duyệt cho đến khi hoàn tất.',
        pleaseLoginTocontinue: 'Vui lòng đăng nhập tài khoản Vietcademy trước khi thanh toán',
        login: 'Đăng nhập',
        alertCancel: {
          message: 'Bạn có muốn huỷ đơn hàng này không',
          close: 'Không',
          delete: 'Xoá',
        },
        alertConfirm: {
          title: 'Bạn có muốn xác nhận đơn hàng này không',
          close: 'Không',
          confirm: 'Có',
        },
      },
      myCourse: {
        allCourses: 'Tất cả các khoá học',
        title: 'Khoá học',
        addCourse: 'Thêm khoá học',
        exam: 'Bài test',
        book: 'Sách',
        learn: 'Vào học',
        details: 'Xem chi tiết',
        goToShop: 'Tìm khoá học',
        noData: 'Không có khoá học',
        noCourse:
          'Hiện tại bạn chưa sở hữu khoá học nào, hãy đi đến trang danh sách khoá học để tìm cho mình một khoá học thích hợp',
      },
      myBook: {
        title: 'Giáo trình',
        chapter: 'Chương',
      },
      myExam: {
        course: 'Khoá học',
        addExam: 'Thêm bài test',
        title: 'Bài thi',
        time: 'Thời gian',
        numberDay: 'Số ngày',
        day: 'ngày',
        unitTime: 'Phút',
        times: 'Lần',
        answer: 'Câu trả lời',
        dateTime: 'Thời gian',
        pointAvg: 'Điểm T.Bình',
        pointCount: 'Số điểm',
        answerCount: 'Số câu T.Lời',
        numberOfTesting: 'Số lần tham gia',
        startTesting: 'Kiểm tra',
        showDetails: 'Chi tiết',
        noExam: 'Không có bài test nào cho khoá học này',
        new: 'Thêm bài test',
        miniTest: 'Bài luyện tập nhỏ',
        fullTest: 'Bài luyện tập tổng hợp',
        examName: 'Tên',
      },
      myQuestion: {
        header: 'Quản lý câu hỏi',
        noQuestion: 'Không có dữ liệu về câu hỏi',
        new: 'Thêm câu hỏi',
        addQuestion: 'Tạo câu hỏi',
      },
      changePassword: {
        account: 'Tài khoản',
        title: 'Đổi mật khẩu',
        currentPassword: 'Mật khẩu hiện tại',
        newPassword: 'Mật khẩu mới',
        confirmNewPassword: 'Xác nhận mật khẩu',
        save: 'Lưu thay đổi',
      },
    },
  },
  ordersList: {
    information: 'Thông tin',
    username: 'Người đặt',
    status: 'Trạng thái',
    createdTime: 'Thời gian',
    price: 'Giá',
    unitPrice: '円',
    approved: 'Đã duyệt',
    pending: 'Đang duyệt',
    searchbar: {
      status: 'Trạng thái',
      title: 'Tìm kiếm',
      keyword: 'Từ khoá',
    },
    alertDelete: {
      message: 'Bạn có chắc muốn xoá nội dung này không',
      close: 'Không',
      delete: 'Xoá',
    },
  },
  orderDetails: {
    username: 'Tên',
    email: 'Mail',
    title: 'Chi tiết Đ.Hàng',
    time: 'Giờ',
    status: 'T.Thái',
    createdTime: 'T.Gian',
    unitPrice: '円',
    totalItems: 'Số lượng',
    totalPrice: 'Đơn giá',
    approve: 'Duyệt',
    reject: '',
    approved: 'Đã duyệt',
    pending: 'Đang duyệt',
    alertConfirm: {
      message: 'Bạn có chắc muốn duyệt đơn hàng này không',
      close: 'Không',
      delete: 'Duyệt',
    },
  },
  userDetails: {
    account: 'T.Khoản',
    title: 'Thông tin',
    email: 'Mail',
    name: 'Tên',
    gender: ' Giới tính',
    birthday: 'Ngày sinh',
    address: 'Địa chỉ',
    isLocked: 'Khoá T.Khoản',
    isVerified: 'Xác thực T.Khoản',
    isAuthorVerified: 'Xác Thực T.Khoản T.Giả',
    role: 'Vai trò',
    changePass: 'Đổi mật khẩu',
    save: 'Lưu',
    details: 'Chi tiết',
    addCourse: 'Thêm khoá học',
    deleteCourseMsg: 'Bạn có chắc muốn xoá nội dung này không',
    identity: {
      read: 'Đọc',
      write: 'Ghi',
    },
    addIdentity: 'Thêm vai trò',
  },
  usersList: {
    information: 'Thông tin',
    email: 'Mail',
    name: 'Tên',
    isLocked: 'Vô hiệu',
    isVerified: 'Xác minh',
    role: 'Vai trò',
    alertDelete: {
      message: 'Bạn có chắc muốn xoá nội dung này không',
      close: 'Không',
      delete: 'Xoá',
    },
  },
  public: {
    home: {
      feature: {
        header: 'Chúng tôi là',
      },
      course: {
        header: 'Khoá học nổi bật',
        seeMore: 'Xem thêm',
      },
      registration: {
        header: 'Các bước đăng ký tài khoản',
        register: 'Đăng ký ngay',
        step1: 'Nhập thông tin',
        step2: 'Gửi thông tin đăng ký',
        step3: 'Xác nhận tài khoản',
        step4: 'Hoàn thành đăng ký',
      },
      application: {
        header: 'Ứng dụng của chúng tôi',
        message: 'Thuận tiện học hành hơn khi sử dụng ứng dụng',
      },
      examFree: {
        header: 'Bài luyện tập miễn phí',
        seeMore: 'Xem thêm',
      },
      news: {
        header: 'Các bản tin',
        seeMore: 'Xem thêm',
      },
      blog: {
        header: 'Góc chia sẻ',
        seeMore: 'Xem thêm',
      },
    },
    login: {
      title: 'Đăng nhập',
      email: 'Mail',
      password: 'Mật khẩu',
      resetPassword: 'Lấy lại mật khẩu',
      register: 'Đăng ký tài khoản mới',
      registerMember: 'Đăng ký thành viên mới',
      registerAuthor: 'Đăng ký tài khoản giảng viên',
      button: 'tại đây',
      loginButton: 'Đăng nhập',
    },
    verifyAccount: {
      title: 'Xác nhận tài khoản',
      message: 'Xác nhận tài khoản thành công',
      login: 'đăng nhập',
      error: 'Xác nhận tài khoản thất bại',
    },
    resetPasswordConfirm: {
      title: 'Đổi mật khẩu',
      password: 'Mật khẩu mới',
      confirmPassword: 'Nhập lại Mật khẩu mới',
      change: 'Đổi mật khẩu',
      success: 'Bạn đã đổi mật khẩu thành công',
      login: 'đăng nhập ngay',
    },
    resetPassword: {
      title: 'Lấy lại mật khẩu',
      completed: 'Bạn đã khôi phục mật khẩu thành công, vui lòng kiểm tra thông tin trong hộp thư cảu bạn.',
      email: 'Mail',
      button: 'tại đây',
      reset: 'Khôi phục',
      home: 'Trang chủ',
      hasAccount: 'Bạn đã có tài khoản đăng nhâp ',
      noAccount: 'Banj chưa có tài khoản đăng ký ',
    },
    register: {
      title: 'Đăng ký tài khoản',
      authorTitle: 'Đăng ký giảng viên',
      verify: 'Xác thực tài khoản',
      completed:
        'Chúng tôi đã gửi URL để hoàn tất đăng ký thành viên đến địa chỉ e-mail đã đăng ký. Xin lỗi vì sự bất tiện của bạn, nhưng vui lòng kiểm tra email của bạn.',
      email: 'Mail',
      password: 'Mật khẩu',
      confirmPassword: 'Xác nhận mật khẩu',
      button: 'tại đây',
      register: 'Đăng ký',
      backToLogin: 'Trang đăng nhập',
      gohome: 'Về trang chủ',
      success: 'Đăng ký tài khoản thành công',
      step1: 'Thông tin',
      step2: 'Xác thực',
    },
    courseList: {
      addCourse: 'Thêm khoá học',
      header: 'DS khoá học',
      featureCourses: 'Khoá học nổi bật',
      otherCourses: 'Các khoá học khác',
      description: 'Bạn có thể tìm thêm thông tin trên trang chi tiết khóa học',
      card: {
        price: 'Giá',
        details: 'Chi tiết',
      },
      searchbar: {
        title: 'Tìm kiếm khoá học / bài test',
        keyword: 'Từ khoá',
      },
    },
    courseDetails: {
      price: 'Giá',
      time: 'Ngày học',
      thumbnail: 'Hình ảnh thu nhỏ',
      addToCartSuccess: 'Đã thêm khoá học vào giỏ hàng',
      duration: 'Thời lượng',
      syllabus: 'Giáo trình',
      owner: 'Sở hữu giáo trình trọn đời',
      unitTime: 'ngày học',
      lesson: 'bài học',
      share: 'Chia sẻ đường dẫn khoá học cho mọi người',
      course: 'Khoá học',
      exam: 'Bài tập kiểm tra',
      author: 'Giảng viên',
      student: 'Học sinh',
      avgRate: 'Xếp hạng',
      totalReview: 'Đánh giá',
      card: {
        refund: 'Hoàn tiền trong 14 ngày nếu không hài lòng',
        free: 'Miễn phí',
        freeMessage: 'Khoá học này đang được chia sẻ miễn phí',
        addToMyCourse: 'Học ngay',
        price: 'Giá',
        time: 'Ngày',
        title: 'The deadline to enroll in the next cohort is January 11, 2021.',
        addToCard: 'Thêm vào giỏ hàng',
        booking: 'Đặt lịch học',
        buyNow: 'Thanh toán',
        duration: 'Thời lượng',
        syllabus: 'Giáo trình',
        owner: 'Sở hữu giáo trình trọn đời',
        unitTime: 'ngày học',
        lesson: 'bài học',
        share: 'Chia sẻ đường dẫn khoá học cho mọi người',
        alertConfirm: {
          title: 'Bạn có muốn thêm vào giỏ hàng không',
          close: 'Không',
          confirm: 'Có',
        },
      },
    },
    newsList: {
      header: 'Tin tức',
      searchTitle: 'Tìm bài viết',
      description: 'Cập nhật các tin tức về khoá học, công nghệ, ...',
    },
    articleDetails: {
      header: 'Chi tiết bài viết',
      description: '',
    },
    articleSearch: {
      header: 'Tìm kiếm bài viết',
      description: '',
    },
    serviceList: {
      header: 'Dịch vụ',
      description: 'Để hiểu rõ hơn về dịch vụ của chúng tôi bạn hãy ấn vào để xem chi tiết',
    },
    about: {
      header: 'Thông tin công ty',
    },

    privacy: {
      header: 'Chính sách bảo mật',
      title: 'Chính sách bảo mật',
    },
    terms: {
      header: 'Điều khoản của dịch vụ',
      title: 'Điều khoản của dịch vụ',
    },
    commercialLaw: {
      header: 'Luật thương mại',
    },
    studyWithTeacher: {
      title: 'Khoá học trực tiếp với giáo viên',
      recommend: 'Khuyến khích',
      videoRegisterGuide: 'Video hướng dẫn đăng ký',
      vietcademyTeachers: 'Các giáo viên của Vietcademy',
      findTeacher: 'Tìm kiếm giáo viên',
    },
  },
  bookList: {
    header: 'Giáo trình',
    modalHeader: 'Giáo trình (Đã công khai)',
    name: 'Tên giáo trình',
    title: 'Tiêu đề',
    public: 'Công khai',
    approved: 'Đã duyệt',
    searchbar: {
      title: 'Tìm giáo trình',
      keyword: 'Từ khoá',
      condition: 'Điều kiện',
      buttonSearch: 'Tìm kiếm',
    },
    alertDelete: {
      message: 'Bạn có chắc muốn xoá nội dung này không?',
      close: 'Không',
      delete: 'Xoá',
    },
  },
  bookDetails: {
    editChapter: 'Chỉnh sửa nội dung chương',
    thumbnail: 'Hình ảnh thu nhỏ',
    noSyllabus: 'Không có giáo trình. Bạn hãy thêm giáo trình mới cho khoá học này.',
    new: 'Thêm giáo trình',
    notitle: 'Không có tiêu đề',
    preview: 'Xem trước',
    header: 'Chi tiết giáo trình',
    title: 'Tiêu đề',
    public: 'Công khai',
    approved: 'Đã duyệt',
    description: 'Mô tả',
    chapter: 'Chương',
    lesson: 'Bài học',
    content: 'Nội dung',
    addChapter: 'Thêm Chương',
    edit: 'Chỉnh sửa',
    save: 'Lưu thay đổi',
    quickView: 'Xem nhanh',
    alertDeleteLesson: {
      message: 'Bạn có chắc muốn xoá nội dung này không？',
      close: 'Không',
      delete: 'Xoá',
    },
    alertDeleteChapter: {
      message: 'Bạn có chắc muốn xoá nội dung này không？',
      close: 'Không',
      delete: 'Xoá',
    },
  },
  courseList: {
    addCourse: 'Thêm khoá học',
    featureCourses: 'Các khoá học nổi bật',
    otherCourses: 'Các khoá học khác',
    header: 'Quản lý khoá học',
    description: 'Danh sách các khoá học',
    name: 'Tên',
    title: 'Tiêu đề',
    public: 'Công khai',
    price: 'Giá',
    owner: 'Người đăng',
    alertDelete: {
      message: 'Bạn có chắc muốn xoá nội dung này không？',
      close: 'Không',
      delete: 'Xoá',
    },
    searchbar: {
      title: 'Tìm kiếm khoá học / bài test',
      keyword: 'Từ khoá',
    },
  },
  article: {
    header: 'Tìm kiếm bài viết',
    description: '',
  },
  courseDetails: {
    step: 'Cách tạo khoá học',
    header: 'Chi tiết khoá học',
    course: 'Khoá học',
    exam: 'Bài tập kiểm tra',
    priceId: 'Mã giá tiền',
    notitle: 'Không có tiêu đề',
    title: 'Tiêu đề',
    price: 'Giá',
    thumbnail: 'thumbnail',
    unitPrice: '円',
    duration: 'Thời gian học',
    unitTime: 'Ngày',
    public: 'Công khai',
    description: 'Mô tả',
    syllabus: 'Giáo trình',
    content: 'Nội dung',
    edit: 'Chỉnh sửa',
    save: 'Lưu thay đổi',
    quickView: 'Xem nhanh',
    priceIdDescription:
      'Mỗi khoá học đều phải tạo một mã giá tiền. Mã được lấy từ hệ thống quản lý thanh toán. Liên hệ người quản lý hệ thống thanh toán để tạo mã giá tiền cho khoá học này',
    lesson: {
      lesson: 'Bài học',
      title: 'Tiêu đề',
      content: 'Nội dung',
    },
    addSkill: 'Thêm kỹ năng',
    skill: {
      skill: 'Kỹ năng',
      title: 'Tiêu đề',
      content: 'Nội dung',
    },
    alertDeleteSyllabus: {
      message: 'Bạn có chắc muốn xoá nội dung này không？',
      close: 'Không',
      delete: 'Xoá',
    },
  },
  questionDetails: {
    step: 'Cách tạo câu hỏi',
    header: 'Chi tiết câu hỏi',
    title: 'Nội dung',
    type: 'Loại (Nhóm hay đơn)',
    subQuestion: 'Câu hỏi phụ',
    category: 'Loại (Đọc hiểu hay nghe hiểu)',
    audio: 'mp3',
    public: 'Công khai',
    addAnswer: 'Thêm câu trả lời',
    select: 'Chọn câu hỏi',
    book: {
      title: 'Tên giáo trình',
      lesson: 'Tên bài học',
    },
    answer: {
      title: 'Đáp án',
      explain: 'Mô tả thêm',
    },
    alertDeleteQues: {
      message: 'Bạn có chắc muốn xoá nội dung này không？',
      close: 'Không',
      delete: 'Xoá',
    },
    alertDeleteAnswer: {
      message: 'Bạn có chắc muốn xoá nội dung này không？',
      close: 'Không',
      delete: 'Xoá',
    },
  },
  examList: {
    title: 'Tiêu đề',
    public: 'Công khai',
    searchbar: {
      title: 'Tìm kiếm',
      keyword: 'Từ khoá',
      condition: 'Điều kiện',
      buttonSearch: 'Tìm kiếm',
    },
    alertDelete: {
      message: 'Bạn có chắc muốn xoá nội dung này không？',
      close: 'Không',
      delete: 'Xoá',
    },
  },
  examDetails: {
    step: 'Cách tạo bài test',
    header: 'Chi tiết bài test',
    title: 'Tiêu đề',
    course: 'Liên kết K.Học',
    type: 'Loại',
    time: 'Thời gian',
    unitTime: 'Phút',
    category: 'Loại',
    question: 'Câu hỏi',
    audio: 'mp3',
    public: 'Công khai',
    details: 'Chi tiết',
    testDate: 'Ngày thi',
    lesson: 'Bài học',
    edit: 'Chỉnh sửa',
    save: 'Lưu thay đổi',
    quickView: 'Xem nhanh',
    sectionTitle: 'Các phần',
    section: {
      header: 'Phần',
      title: 'Tiêu đề',
      addSection: 'Thêm phần TEST mới',
      deleteSection: 'Xoá phần TEST',
      addQuestion: 'Thêm câu hỏi mới',
      deleteQuestion: 'Xoá câu hỏi',
    },
    book: {
      title: 'Tên giáo trình',
      lesson: 'Tên bài học',
    },
    answer: {
      title: 'Đáp án',
      explain: 'Mô tả',
    },
    alertDeleteQues: {
      message: 'Bạn có chắc muốn xoá nội dung này không？',
      close: 'Không',
      delete: 'Xoá',
    },
    alertDeleteExam: {
      message: 'Bạn có chắc muốn xoá nội dung này không？',
      close: 'Không',
      delete: 'Xoá',
    },
  },
  news: {
    category: 'Loại',
    news: 'Tin tức',
    title: 'Tiêu đề',
    description: 'Mô tả',
    public: 'Công khai',
    content: 'Nội dung',
    preview: 'Xem trước',
    searchbar: {
      title: 'Tìm kiếm',
      keyword: 'Từ khoá',
      condition: 'Điều kiện',
      buttonSearch: 'Tìm kiếm',
    },
    alertDelete: {
      message: 'Bạn có chắc muốn xoá nội dung này không？',
      close: 'Không',
      delete: 'Xoá',
    },
  },
  post: {
    category: 'Loại',
    header: 'Bài đăng',
    title: 'Tiêu đề',
    description: 'Mô tả',
    public: 'Công khai',
    content: 'Nội dung',
    preview: 'Xem trước',
    searchbar: {
      title: 'Tìm kiếm',
      keyword: 'Từ khoá',
      condition: 'Điều kiện',
      buttonSearch: 'Tìm kiếm',
    },
    alertDelete: {
      message: 'Bạn có chắc muốn xoá nội dung này không？',
      close: 'Không',
      delete: 'Xoá',
    },
  },
  service: {
    category: 'Loại',
    service: 'Dịch vụ',
    title: 'Tiêu đề',
    description: 'Mô tả',
    public: 'Công khai',
    content: 'Nội dung',
    preview: 'Xem trước',
    status: 'Trạng thái',
    searchbar: {
      title: 'Tìm kiếm',
      keyword: 'Từ khoá',
      condition: 'Điều kiện',
      buttonSearch: 'Tìm kiếm',
    },
    alertDelete: {
      message: 'Bạn có chắc muốn xoá nội dung này không？',
      close: 'Không',
      delete: 'Xoá',
    },
  },
  questionList: {
    header: 'Danh sách câu hỏi',
    title: 'Câu hỏi',
    public: 'Công khai',
    book: 'Giáo trình',
    lesson: 'Bài học',
    question: 'Câu hỏi',
    questionSelected: 'Chọn câu hỏi',
    questionList: 'Danh sách câu hỏi',
    update: 'Cập nhật',
    addQuestion: 'Thêm câu hỏi mới',
    selectQuestion: 'Chọn câu hỏi',
    searchbar: {
      title: 'Tìm kiếm',
      keyword: 'Từ khoá',
      condition: 'Điều kiện',
      buttonSearch: 'Tìm kiếm',
    },
    alertDelete: {
      message: 'Bạn có chắc muốn xoá nội dung này không？',
      close: 'Không',
      delete: 'Xoá',
    },
    alertInvalid: {
      message: 'Nội dung câu hỏi không hợp lệ. Vui lòng cập nhật đầy đủ dữ liệu câu hỏi',
      close: 'Đóng',
      edit: 'Chỉnh sửa',
    },
  },
  home: {
    title: 'Trang chủ',
    public: 'Công khai',
    limit: 'Giới hạn',
    save: 'Lưu lại',
    postUrl: 'Đường dẫn bài viết',
    postTitle: 'Tiêu đề',
    postDescription: 'Mô tả',
    postBanner: 'Ảnh bìa',
    bannerBlur: 'Độ mờ ( 0 - 50)',
    topBanner: {
      title: 'Tiêu đề',
      description: 'Mô tả',
      details: 'Chi tiết',
    },
    promotion: {
      header: 'Khuyến mại',
    },
    category: {
      header: 'Nổi bật',
      icon: 'Biểu tượng',
      title: 'Tiêu đề',
      description: 'Mô tả',
      create: 'Tạo',
      edit: 'Lưu',
    },
    examFree: {
      header: 'Dề thi miễn phí',
      title: 'Tiêu đề',
      description: 'Mô tả',
      limit: '',
    },
    course: {
      header: 'Khoá học',
      title: 'Tiêu đề',
      description: 'Mô tả',
    },
    news: {
      header: 'Tin tức',
      title: 'Tiêu đề',
      description: 'Mô tả',
      limit: '',
    },
    blog: {
      header: 'Blog',
      title: 'Tiêu đề',
      description: 'Mô tả',
      limit: '',
    },
  },
  companyProfile: {
    header: 'Thông tin công ty',
  },
  media: {
    title: 'Đa phương tiện',
    message: 'Chọn hoặc kéo thả hình ảnh vào khung',
    deleteAlert: {
      message: 'Bạn có chắc muốn xoá nội dung này không？',
      close: 'Không',
      delete: 'Xoá',
    },
  },

  footer: {
    message: '「Viet Academy」でベトナム語を学びましょう。',
    buttonStart: '始まる',
    vietcademy: {
      title: 'Trở thành giảng viên tiếng Việt tại Vietcademy',
      description:
        'Bạn có muốn kiếm thêm thu nhập bằng sự sáng tạo của bạn. Hãy tham gia với chúng tôi. Chúng tôi sẽ giúp bạn.',
    },
  },

  common: {
    nodata: 'Không có dữ liệu',
    nocourse: 'Không có dữ liệu khoá học',
    nobook: 'Không có dữ liệu giáo trình',
    course: 'Khoá học',
    book: 'Giáo trình',
    exam: 'Đề thi',
    new: 'Mới',
    public: 'Công khai',
    exportCsv: 'Xuất CSV',
    buttonBack: 'Quay lại',
    buttonSaveChange: 'Lưu',
    buttonSave: 'Lưu',
    buttonCreate: 'Tạo',
    yes: 'Có',
    no: 'Không',
    statistic: 'Thống kê',
    statisticData: 'Thống kê thu nhập của bạn',
    topMenu: {
      home: 'Trang chủ',
      news: 'Tin tức',
      course: 'Khoá học',
      service: 'Dịch vụ',
      about: 'Thông tin công ty',
      help: 'Trợ giúp',
      booking: 'Đặt lịch',
    },
  },
  mediaCenter: {
    title: 'Đa phương tiện',
    mediaList: 'Danh sách da phương tiện',
  },
  uploadBook: {
    title: 'Tải lên giáo trình',
  },
  uploadCourse: {
    title: 'Tải lên khoá học',
  },
  uploadQuestion: {
    title: 'Tải lên câu hỏi',
  },
  uploadExam: {
    title: 'Tải lên bài test',
  },
  about: {
    header: 'Thông tin Công ty',
  },

  privacy: {
    header: 'Chính sách bảo mật',
  },
  terms: {
    header: 'Điều khoản sử dụng',
  },
  userList: {
    addNew: 'Thêm thành viên',
    header: 'Quản lý thành viên',
    description: 'Danh sách các thành viên',
    searchbar: {
      title: 'Tìm kiếm tài khoản',
      keyword: 'Từ khoá',
    },
    alertDelete: {
      message: 'Bạn có chắc muốn xoá nội dung này không？',
      close: 'Không',
      delete: 'Xoá',
    },
  },
  setting: {
    title: 'Cài đặt',
    law: 'Luật thương mại',
    news: 'Cài đặt bản tin',
    newsFeaturePost: 'Bản tin nổi bật',
    course: 'Cài đặt khoá học',
    courseFeaturePost: 'Khoá học nổi bật',
    save: 'Lưu lại',
    copyright: 'Copyright',
    footer: {
      footerSetting: 'Cài đặt cuối trang',
      iosUrl: 'Đường dẫn ứng dụng ios',
      androidUrl: 'Đường dẫn ứng dụng android',
      profile: 'Thông tin công ty',
      companyName: 'Tên công ty',
      description: 'Mô tả ngắn',
      phone: 'Số điện thoại',
      email: 'Thư điện tử',
      copyright: 'Copyright',
    },
  },
  createUser: {
    title: 'Tạo mới',
    email: 'Mail',
    password: 'Mật khẩu',
    confirmPassword: 'Xác nhận mật khẩu',
    create: 'Tạo',
    role: 'Vai trò',
  },
  page404: {
    title: '',
    goHome: 'Quay lại trang chủ',
    description: 'Lỗi! Không tìm thấy trang!',
  },
  validator: {
    passwordRequired: 'Mật khẩu là bắt buộc',
    passwordConfirmRequired: 'Nhập lại mật khẩu là bắt buộc',
    passwordMin: 'Mật khẩu phải có 8 ký tự trở lên',
    passwordMax: 'Mật khẩu tối đa có 20 ký tự。',
    passwordInvalid: 'Mật khẩu phải bao gồm chữ và số (Ít nhất 1 chữ cái viết hoa)',
    passwordNotMatch: 'Mật khẩu không khớp',
    requiredEmail: 'Mail không được để trống',
    invalidEmail: 'Mail không hợp lệ.',
    requiredForRole: 'Trợ giúp cho là trường bắt buộc',
    requiredSeoName: 'seoname không được để trống',
    requiredUrl: 'Url không được để trống',
    requiredCategory: 'Danh mục là trường bắt buộc',
    requiredThumbnail: 'Bạn phải tải lên ảnh thu nhỏ.',
    requiredTitle: 'Tiêu đề không được để trống',
    requiredContent: 'Nội dung không được để trống',
    nameMinMax: 'Tên phải lớn hơn 2 và nhỏ hơn 16 ký tự',
    requiredName: 'Tên là bắt buộc',
    requiredImage: 'Hình ảnh là bắt buộc',
    requiredAddress: 'Địa chỉ là bắt buộc',
    requiredGender: 'Giới tính là bắt buộc',
    requiredDescription: 'Mô tả là bắt buộc',
    requiredSubject: 'Chủ đề không được để trống',
    requiredPhone: 'SDT không được để trống',
    requiredAgree: 'Vui lòng chọn xác nhận gửi thông tin trước khi gửi',
    requiredDatetime: 'Bạn phải chọn một thời gian cụ thể.',
    requiredType: 'Loại không được để trống',
    requiredTarget: 'Mục đối tượng là bắt buộc',
    requiredDuration: 'Bạn phải chọn một khoảng thời gian cụ thể.',
    requiredBanner: 'Bạn phải tải lên ảnh.',
    requiredPrice: 'Bạn phải nhập giá',
    requiredPriceId: 'Bạn phải nhập id giá cả',
    requiredCourseDuration: 'Bạn phải nhập số ngày học',
    requiredCourseDurationMin: 'Thời gian học tối thiểu là 1 ngày',
    requiredLesson: 'Bạn phải phải tạo bài học',
    requiredSkill: 'Bạn phải phải tạo kỹ năng',
    requiredSyllabus: 'Bạn phải thêm giáo trình cho khoá học',
    requiredChapter: 'Bạn phải thêm các chương cho giáo trình',
    requiredAnswer: 'Câu trả lời không được để trống',
    requiredQuestion: 'Câu hỏi không được để trống',
    requiredAudio: 'Audio không được để trống',
    requiredCheckedAnswer: 'Hãy chọn đáp án đúng cho câu hỏi bằng cách tích vào nút vuông',
    requiredExamTime: 'Vui lòng nhập thời gian của bài thi',
    requiredCourse: 'Hãy chọn khoá học',
    requiredSection: 'Mục của bài thị không đươc để trống',
    requiredExamLesson: 'Vui lòng chọn bài học (Khóa học phải được chọn trước)',
  },
  modals: {
    submitExamConfirmModal: {
      title: 'Gửi bài thi',
      answer: 'Số câu trả lời',
      message: 'Bạn có chắc muốn gửi bài thi khôg',
      submit: 'Gửi',
    },
    createQuestion: {
      title: 'Tạo câu hỏi',
      type: 'Loại',
      book: 'Giáo trình',
      create: 'Tạo',
    },
    updateQuestion: {
      title: 'Cập nhật câu hỏi',
    },
    cropImageModal: {
      selectRatio: 'Chọn và tải lên',
    },
    questionList: {
      book: 'Giáo trình',
      lesson: 'Bài học',
      question: 'Câu hỏi',
      questionSelected: 'Câu hỏi đã chọn',
      questionList: 'D.S Câu hỏi',
      update: 'Cập nhật',
      searchbar: {
        title: 'Tìm kiếm',
        keyword: 'Từ khoá',
        condition: 'Điều kiện',
        buttonSearch: 'Tìm kiếm',
      },
    },
    confirmLogout: {
      title: 'Bạn có chắc muốn thoát tài khoản không？',
      close: 'Không',
      confirm: 'Thoát',
    },
    confirmSubmitCourse: {
      title: 'Bạn có chắc muốn công khai khoá học này không',
      close: 'Không',
      confirm: 'Có',
    },
    confirmDelete: {
      message: 'Bạn có chắc muốn xoá nội dung này không？',
      close: 'Không',
      delete: 'Xoá',
    },
    createCourse: {
      title: '',
      titleCourse: 'Khoá học',
      titleExam: 'Bài tập kiểm tra',
      message: 'Đầu tiên, hãy nghĩ về loại khóa học bạn muốn.',
      messageCourse: 'Tạo nội dung học tập với các bài giảng video, câu đố hay bài tập, ... ',
      messageExam: 'Giúp học sinh chuẩn bị cho các kỳ thi lấy chứng chỉ bằng cách cung cấp các câu hỏi thực hành.',
      create: 'Tạo',
    },
    approveCourse: {
      title: 'Bạn có chắc muốn phê duyệt nội dung này không',
      close: 'Huỷ',
      confirm: 'Phê duyệt',
    },
    rejectCourse: {
      title: 'Bạn có chắc muốn từ chối nội dung này không?. Hãy nhập lý do tại sao lại từ chối nội dung này.',
      close: 'Huỷ',
      confirm: 'Từ chối',
    },
    invalidCourse: {
      title: 'Tại sao tôi không thể gửi để xem xét?',
      message: 'Để gửi được nội dung bạn cần kiểm tra lần lượt các mục dưới đây:',
      requireContents: [
        {
          title: 'Giáo trình',
          values: [
            'Có thông tin về giáo trình như tiêu đề và mô tả',
            'Có ít nhất 1 chương học',
            'Có ít nhất 5 bài giảng',
            'Có nội dung cho tất cả các bài giảng',
          ],
        },
        {
          title: 'Bài test(có thể có hoặc không), nếu có cần thoả mãn các điều kiện sau',
          values: [
            'Có thông tin bài test: tiêu đề - loại bài test - thời gian test',
            'Có ít nhất 1 phần test(Đọc hiểu hoặc nghe hiểu)',
            'Mỗi phần có ít nhất 10 câu hỏi',
          ],
        },
        {
          title: 'Tổng quan',
          values: [
            'Có đầy đủ thông tin: ảnh khoá học - seoname(giống tiêu đề và viết không dấu, nếu là tiếng nhật phải dùng romaji) - tiêu đề -  tổng số ngày cần hoàn thành - Mô tả',
            'Kỹ năng Có ít nhất 2 mục, (kỹ năng là sau khi học xong khoá học này thì sẽ có được những kỹ năng hay kiến thức gì.)',
            'Nôi dung mô tả về khoá học có ít 200 từ.',
          ],
        },
        {
          title: 'Cài đặt giá',
          values: [
            'Phải lựa chọn khoá học là miễn phí hay không miễn phí',
            'Giá khoá học được tính theo giá trị YÊN của Nhật Bản, Kiểm tra tỉ giá trước khi nhập',
            'Giá khoá học phải lớn hơn 100 YÊN',
          ],
        },
      ],
      close: 'Đóng',
    },
    invalidCourseExam: {
      title: 'Tại sao tôi không thể gửi để xem xét?',
      message: 'Để gửi được nội dung bạn cần kiểm tra lần lượt các mục dưới đây:',
      requireContents: [
        {
          title: 'Bài test',
          values: [
            'Có thông tin bài test: tiêu đề - loại bài test - thời gian test',
            'Có ít nhất 1 phần test(Đọc hiểu hoặc nghe hiểu)',
            'Mỗi phần có ít nhất 10 câu hỏi',
          ],
        },
        {
          title: 'Tổng quan',
          values: [
            'Có đầy đủ thông tin: ảnh khoá học - seoname(giống tiêu đề và viết không dấu, nếu là tiếng nhật phải dùng romaji) - tiêu đề -  tổng số ngày cần hoàn thành - Mô tả',
            'Kỹ năng Có ít nhất 2 mục, (kỹ năng là sau khi học xong khoá học này thì sẽ có được những kỹ năng hay kiến thức gì.)',
            'Nôi dung mô tả về khoá học có ít 200 từ.',
          ],
        },
        {
          title: 'Cài đặt giá',
          values: [
            'Phải lựa chọn khoá học là miễn phí hay không miễn phí',
            'Giá khoá học được tính theo  giá trị YÊN của Nhật Bản, Kiểm tra tỉ giá trước khi nhập',
            'Giá khoá học phải lớn hơn 100 YÊN',
          ],
        },
      ],
      close: 'Đóng',
    },
  },
  statistic: {
    header: 'Thống kê',
  },
  //Alert
  alertDelete: {
    message: 'Bạn có chắc muốn xoá nội dung này không',
    close: 'Không',
    delete: 'Xoá',
  },
  invalidDataAlert: {
    message: 'Dữ liệu không hợp lệ,vui lòng kiểm tra lại các trường đã nhập',
    close: 'Đóng',
  },
  message: {
    create: 'Tạo dữ liệu',
    update: 'Thay đổi dữ liệu',
    delete: 'Xoá dữ liệu',
    error: 'Không thành công',
    success: 'Thành công',
    publishSuccess: 'Xuất bản thành công',
    approvedSuccess: 'Xét duyệt thành công',
    submitForReviewSuccess: 'Đã gửi nội dung để xem xét thành công',
    rejectSuccess: 'Đã từ chối nội dung',
    unpublishSuccess: 'Đã huỷ xuất bản',
    publishError: '',
    paymentError: 'Đã xảy ra lỗi trong quá trình thanh toán. vui lòng kiểm tra lại thông tin thanh toán của bạn',
    errorMessage1: 'Đã có lỗi xảy ra vui lòng thử lại',
  },
  teacher: {
    intro: 'Giới thiệu',
    recent: '',
    ownerCourse: 'Khoá học của giảng viên',
    studyWith: 'Học cùng với ',
    unitDay: 'ngày',
    lesson: 'Bài giảng',
    detailsCourse: 'Xem chi tiết',
    lessonLevels: 'Đối tượng học ',
    lessonTopics: 'Nội dung giảng dạy sở trường',
  },
  searchbar: {
    keyword: 'Từ khoá',
    keywordPlaceholder: 'Nhập từ khoá',
  },
  helpCenter: 'Trung tâm trợ giúp',
  helpTitle: 'Trợ giúp',
  helpEdit: 'Chỉnh sửa trợ giúp',
  title: 'Tiêu đề',
  content: 'Nội dung',
  preview: 'Xem trước',
  publicTitle: 'Công khai',
  category: 'Danh mục',
  keyword: 'Nhập từ khoá',
  helpMessage: 'Chúng tôi có thể giúp gì cho bạn?',
  information: 'Thông tin',
  searchResult: '検索ヒット数',
  resultCount: '件',
  language: 'Ngôn ngữ',
  must: 'Bắt buộc',
  helpFor: 'Trợ giúp cho',
  save: 'Lưu',
  close: 'Đóng',
  blogTitle: 'Các bài blog',
  examTitle: 'Kỳ thi',
  businessTitle: 'Kinh doanh',
  newsTitle: 'Tin tức',
  postTitle: 'Bài đăng',
  recentNews: 'Bài viết gần đây',
  skillsYouWillGain: 'Bạn sẽ có được kỹ năng gì',
  whatYouWillLearn: 'Bạn sẽ học được những gì',
  homeTitle: 'Trang chủ',
  courseDetailsTitle: 'Chi tiết khoá học',
  copied: 'Đã sao chép',
  userDetailsTitle: 'Thông tin T.khoản',
  lectureTitle: 'Bài giảng',
  //Contact
  contactDetails: 'Chi tiết liên hệ',
  contactHeader: 'Liên hệ',
  contactList: 'Quản lý liên hệ',
  contactName: 'Tên gọi',
  contactTime: 'T.Gian hẹn',
  contactEmail: 'Mail',
  contactPhone: 'Số điện thoại',
  contactSubject: 'Chủ đề',
  contactContent: 'Nội dung',
  contactDirect: 'Đặt lịch hẹn',
  sendContact: 'Gửi',
  sendContactSuccess: 'Đã liên hệ thành công',
  sendContactError: 'Đã có lỗi xảy ra, vui lòng thử lại lần nữa.',
  contactMessage: 'Cảm ơn đã liên hệ với chúng tôi. Chúng tôi sẽ liên lạc với bạn theo ngày và giờ bạn đã đăng ký.',
  contactMessage1:
    '※ Nếu「×」được hiển thị trên tất cả các ô, vui lòng tải lại trang hoặc chọn sang tuần kế tiếp nếu được.',
  contactMessage2: '※ Nếu bạn không xử lý được vui lòng liên hệ với chúng tôi qua hòm thư「contact@vietcademy.jp」.',
  contactMessage3: 'Từ thời gian mà bạn chọn bên dưới thì thời gian tối đã cho 1 cuộc hẹn là 25 phút.',
  contactMessage4:
    '※ Sau khi bạn gửi chúng tôi sẽ liện hệ với bạn trong thời gian sớm nhất có thể, thường là sau hai ngày làm việc',
  contactMessage5: 'Nếu bạn có bất kỳ câu hỏi nào, vui lòng liên hệ với trung tâm hỗ trợ của chúng tôi.',
  contactPolicyMessage: 'Bạn có đồng ý gửi những thông tin bên trên cho chúng tôi không?',
  contactInvalidContent: 'Vui lòng kiểm tra lại thông tin nhập của bạn',
  back: 'Quay lại',

  privacyPolicy: 'Chính sách bảo mật',

  no: 'Không có',

  newsKeyword: 'Nhập từ khoá hoặc ID để tìm kiếm',

  authorVerifyMessage:
    'Chào bạn\n\nCảm ơn bạn đã đăng ký tài khoản giáo viên trên Vietcademy. Bạn vui lòng cập nhật thông tin và tải ảnh đại diện, đồng thời gửi thông tin để Vietcademy kiểm tra và xác nhận tài khoản cho bạn về email: contact@vietcademy.jp\n1. Thông tin hợp đồng\n\tHọ và tên\n\tĐịa chỉ\n\tSố điện thoại\n\tSố tài khoản ngân hàng để nhận thù lao\n2. Ảnh chứng chỉ tiếng Nhật JLPT bạn đang có',
  eventList: {
    header: 'Sự kiện / khuyến mãi',
    keyword: 'Từ khoá',
    title: 'Tiêu đề',
    description: 'Mô tả',
    content: 'Nội dung',
    from: 'Từ ngày',
    to: 'Đến ngày',
    public: 'Công khai',
    duration: 'Thời hạn',
    information: 'Thông tin',
    alertDelete: {
      message: 'Bạn có chắc muốn xoá nội dung này không',
      close: 'Không',
      delete: 'Xoá',
    },
  },
  eventDetails: {
    header: 'Chi tiết sự kiện',
    keyword: 'Từ khoá',
    title: 'Tiêu đề',
    description: 'Mô tả',
    ticket: 'Số vé',
    content: 'Nội dung',
    from: 'Từ ngày',
    to: 'Đến ngày',
    type: 'Loại',
    target: 'Đối tượng',
    duration: 'Thời hạn',
    public: 'Công khai',
    courseInEvent: 'Các khoá học cho sự kiện này',
    enterCourseId: 'Hãy nhập khoá học ID',
    addNewCourseId: 'Thêm mới',
    save: 'Lưu lại',
    update: 'Cập nhật',
  },
  pageTitle: {
    // course details
    courseLanding: 'Tổng quan',
    courseSkill: 'Kỹ năng',
    courseSyllabus: 'Giáo trình',
    courseExam: 'Q.L bài test',
    coursePrice: 'Cài đặt giá',
    courseEditExam: 'Chi tiết bài TEST',
    courseQuestion: 'Q.L câu hỏi',
  },
  userExamResults: {
    title: 'Kết quả Test',
  },
  calendar: {
    event: 'Sự kiện',
    edit: 'Chỉnh sửa',
    meeting: 'Họp',
    teaching: 'Lịch G.Dạy',
    noEventTitle: 'Không có lịch dạy',
    noEventMessage: 'Không có lịch dạy trong khoảng thời gian đã chọn',
    with: 'Học viên',
    addEvent: 'Thêm lịch giảng dạy',
    registered: 'Đã đăng ký',
    noMemer: 'Không có học viên nào',
    streamUrl: 'Link học trực tuyến',
    password: 'Mật khẩu',
    finish: 'Kết thúc cuộc hẹn',
    unset: 'Chưa đặt',
    delete: 'Xoá',
    details: 'Xem chi tiết',
    author: 'Giảng viên',
    addEventModal: {
      header: 'Tạo sự kiện',
      cancel: 'Huỷ',
      create: 'Tạo',
    },
    confirmFinish: {
      message: 'Bạn có chắc muốn hoàn thành lịch giảng dạy này không?',
      finish: 'Hoàn thành',
      cancel: 'Huỷ',
    },
    confirmDelete: {
      message: 'Bạn có chắc muốn xoá lịch giảng dạy này không?',
      delete: 'Xoá',
      cancel: 'Huỷ',
    },
  },
  all: 'Tất cả',
  item: 'Kiện',
  point: {
    header: 'Thêm vé',
    point: 'vé',
    addPoint: 'Thêm vé',
    cancel: 'Huỷ',
    next: 'Tiếp theo',
    payment: 'Thanh toán',
    confirm: {
      message: 'Bạn có chắc muốn mua thêm {point} vé không？',
      confirm: 'Mua',
      cancel: 'Huỷ',
    },
    error: {
      message:
        'Đã xảy ra lỗi trong quá trình thanh toán。\nHãy thử lại hoặc liên hệ với chúng tôi qua hòm thư contact@vietcademy.jp để biết thêm chi tiết. \nCám ơn!',
    },
  },
  userExamsSearch: {
    no: 'STT',
    userId: 'Mã người dự thi',
    userName: 'Tên người dự thị',
    examName: 'Tên bài thi',
    courseId: 'Mã khoá học',
    courseName: 'Tên khoá học',
    searchbar: {
      title: 'Tìm kiếm thành viên',
      courseId: 'Mã khoá học',
      userId: 'Mã thành viên',
      keyword: 'Từ khoá',
      keywordPlaceHolder: 'Nhập tên thành viên, khoá học hoặc tên bài thi để tìm kiếm',
    },
  },
  authorSchedule: {
    createModal: {
      header: 'Tạo lịch dạy',
      datetime: 'Thời gian',
      date: 'Ngày',
      time: 'Thời gian',
      course: 'Khoá học',
      cancel: 'Huỷ',
      create: 'Tạo',
    },
    editModal: {
      header: 'Cập nhật lịch dạy',
      bookedPerson: '予約名',
      datetime: 'Thời gian',
      date: 'Ngày',
      time: 'Thời gian',
      course: 'Khoá học',
      cancel: 'Huỷ',
      update: 'Cập nhật',
      chat: 'Nhắnn tin',
      url: 'Zoom URL',
      password: 'Mật khẩu',
      close: 'Đóng',
    },
  },
  booking: {
    bookingFilter: {
      schedule: 'Lịch dạy của {author}',
    },
    schedulesList: {
      booked: 'Đã đặt',
      cancel: 'Huỷ',
      booking: 'Đặt lịch',
      noScheduleTitle: 'Không có lịch dạy học',
      noScheduleMessage:
        'Không có lịch dạy học của giảng viên trong khoảng thời gian đã chọn. Vui lòng chọn một khoảng thời gian khác',
    },
    bookingModal: {
      fee: 'Phí',
      point: 'Vé',
      header: 'Đặt lịch',
      datetime: 'Thời gian',
      course: 'Khoá học',
      author: 'Giảng viên',
      cancel: 'Huỷ',
      create: 'Đặt lịch',
      messageError: 'Đặt lịch không thành công, vui lòng thử lại',
      messageSuccess: 'Đã đặt lịch thành công',
      notEnoughPoints: 'Bạn không đủ điểm để đặt lịch. hãy mua thêm điểm để có thể sử dụng chức năng này.',
    },
  },
  bookingSearch: {
    header: 'Đặt lịch học',
    filterBar: {
      header: 'Đặt lịch học',
      level: 'Cấp độ',
      lessonContent: 'Nội dung bài học',
      fromTime: 'Từ',
      toTime: 'đến',
      schedule: 'Lịch dạy của {author}',
      search: 'Tìm kiếm',
    },
    yearOld: ' tuổi',
    teacher: 'Giáo viên ',
    content: 'Nội dung đặt lịch ',
    experience: 'Kinh nghiệm',
    lessonFlow: 'Cách tiến hành ',
    lessonStrictness: 'Khi có lỗi sai về ngữ pháp hoặc phát âm',
    lessonOther: 'Yêu cầu khác',
    bookingModal: {
      teacher: 'Giáo viên',
      student: 'Học sinh',
      content: 'Nội dung',
      experience: 'Kinh nghiệm',
      header: 'Đặt lịch',
      point: ' vé',
      datetime: 'Thời gian',
      course: 'Khoá học',
      author: 'Tác giả',
      cancel: 'Huỷ',
      create: 'Đặt',
      fee: 'Chi phí',
      level: 'Cấp độ ',
      lessonContent: 'Nội dung bài học',
      messageError: 'Đặt lịch thất bại ',
      messageSuccess: 'Đặt lịch thành công',
      lessonFlow: 'Cách tiến hành ',
      lessonStrictness: 'Khi có lỗi sai về ngữ pháp hoặc phát âm',
      lessonOther: 'Yêu cầu khác',
      notEnoughPoints: 'Bạn không có đủ vé để đặt lịch. Xin vui lòng nạp thêm vé để sử dụng tính năng này.',
    },
    bookedModal: {
      message: 'Đã đặt lịch thành công',
      cancel: 'Trở lại',
      goMyBooking: 'Quay trở lại lịch của tôi',
    },
  },
  myBooking: {
    header: 'Đặt lịch của tôi',
    course: 'Khoá học',
    author: 'Giảng viên',
    datetime: 'Thời gian',
    noBookingTitle: 'Không có lịch dặt',
    noBookingMessage: 'Không có lịch hẹn của bạn trong khoảng thời gian đã chọn.',
    completed: 'Đã hoàn thành',
    finish: 'Đã kết thúc',
    cancel: 'Huỷ',
    details: 'Xem chi tiết',
    confirmCancel: {
      message: 'Bạn có chắc muốn huỷ lịch đặt này không?',
      messageSuccess: 'Bạn đã huỷ thành công',
      close: 'Huỷ',
      cancel: 'Có',
    },
  },
  reportsPage: {
    name: 'Tên',
    type: 'Loại',
    title: 'Tiêu đề',
    header: 'Quản lý báo cáo',
    detail: 'Chi tiết báo cáo',
    reason: 'Lý do',
    author: 'Giáo viên',
    course: 'Chương trình',
    booking: 'Đặt lịch',
    ticket: 'Vé',
    status: 'Tình trạng ',
    createdTime: 'Thời gian',
    refunded: 'Đã hoàn trả',
    refundWaiting: 'Chưa hoàn trả',
    refund: 'Hoàn vé',
  },
  ticketModal: {
    title: 'Hoàn trả vé',
    time: 'Thời gian',
    quantity: 'Số lượng',
    messageSuccess: 'Đã hoàn trả',
    add: 'Mua',
    sub: 'Sử dụng',
    refunded: 'Đã hoàn trả',
    noTicket: 'Không có vé',
  },
  notificationPage: {
    header: 'Thông báo',
    title: 'Thông báo mới',
    dateTime: 'Thời gian',
    content: 'Nội dung',
  },
  getNotification: {
    messageRefunded: 'Bạn đã được hoàn lại {ticket} vé',
    booked: 'Bạn có lịch hẹn với {bookedName} vào lúc {date}',
    scheduleComplete: 'Bạn đã hoàn thành lịch học lúc {date}',
    bookingCanceled: 'Lịch hẹn vào lúc {date} với {bookedName} đã bị huỷ.',
  },
  emailModal: {
    title: 'Gửi email',
    btnSend: 'Gửi email',
    btnSendAll: 'Gửi email cho tất cả',
    sendTo: 'Người nhận',
    all: 'Mọi người',
    subject: 'Tiêu đề',
    content: 'Nội dung',
    sent: 'Đã gửi email thành công',
    sendFailed: 'Có email chưa gửi thành công!',
    sendFailedMessage: 'Bạn có muốn gửi lại không?',
    btnSendAgain: 'Gửi lại',
  },
  marketing: {
    title: 'Marketing',
    type: 'Loại',
    name: 'Tên',
    address: 'Địa chỉ',
    phone: 'Số điện thoại',
    email: 'Email',
    url: 'マーケティングURL',
    edit: 'Chỉnh sửa thông tin marketing',
  },
  access: {
    title: 'Danh sách truy cập',
    dataSource: 'Nguồn dữ liệu',
    userAgent: 'Thiết bị',
    userId: 'ID người dùng',
    userName: 'Tên người dùng',
  },
};
