export const ERROR_MESSAGE = {
  //COMMON
  ERR_0000: {
    code: 'ERR_0000',
    tite: '',
    message: '',
  },
  ERR_0001: {
    code: 'ERR_0001',
    tite: 'サーバーエラー。',
    message: 'サーバーエラー。もう一回確認してください。',
  },
  ERR_0002: {
    code: 'ERR_0002',
    tite: 'CSVファイル。',
    message: 'CSVファイルをアップロードしてください。',
  },
  ERR_0003: {
    code: 'ERR_0003',
    tite: 'ログイン',
    message: 'ログインしてください。',
  },
  ERR_0004: {
    code: 'ERR_0004',
    tite: 'サーバーエラー。',
    message: 'サーバーエラー。もう一回確認してください。',
  },
  ERR_0005: {
    code: 'ERR_0005',
    tite: '管理者アカウント。',
    message: '管理者ではないです。管理者アカウントをログインしてください。',
  },
  ERR_0006: {
    code: 'ERR_0006',
    tite: '権限がないです。',
    message: '権限がないです。',
  },
  //USER ERR_U***
  ERR_U000: {
    code: 'ERR_U000',
    tite: 'ユーザー。',
    message: 'ユーザーが見つかりませんでした。',
  },
  ERR_U001: {
    code: 'ERR_U001',
    tite: 'ユーザー。',
    message: '会社IDまたはユーザーIDまたはパスワードが無効です. もう一度やり直してください',
  },
  ERR_U101: {
    code: 'ERR_U101',
    tite: 'ログイン',
    message: 'ユーザーが存在しません。',
  },
  ERR_U102: {
    code: 'ERR_1102',
    tite: 'ログイン',
    message: 'アカウントはロックされています。',
  },
  ERR_U103: {
    code: 'ERR_U103',
    tite: 'ログイン',
    message: 'アカウントはロックされています。',
  },
  ERR_U104: {
    code: 'ERR_U104',
    tite: 'ログイン',
    message: '会社IDまたはユーザーIDまたはパスワードが違います. もう一度やり直してください',
  },
  ERR_U201: {
    code: 'ERR_U201',
    tite: 'TOKEN',
    message: '',
  },
  ERR_U202: {
    code: 'ERR_U202',
    tite: 'TOKEN',
    message: '',
  },
  ERR_U300: {
    code: 'ERR_U300',
    tite: 'ユーザーの作成に失敗しました。',
    message: 'ユーザーの作成に失敗しました。',
  },
  ERR_U301: {
    code: 'ERR_U301',
    tite: '社員IDが存在しています',
    message: '社員IDが存在しています。もう一度やり直してください。',
  },
  ERR_U302: {
    code: 'ERR_U302',
    tite: 'メールが存在しています。',
    message: 'メールが存在しています。もう一度やり直してください。',
  },
  ERR_U303: {
    code: 'ERR_U302',
    tite: 'ユーザーの更新。',
    message: 'ユーザーの更新に失敗しました。',
  },
  ERR_U304: {
    code: 'ERR_U304',
    tite: 'リセットパスワード',
    message: '現在のパスワードが正しくありません. もう一度やり直してください',
  },
  ERR_U305: {
    code: 'ERR_U305',
    tite: 'パスワードのリセット',
    message: 'パスワードのリセットに失敗しました。',
  },
  ERR_U306: {
    code: 'ERR_U306',
    tite: 'ユーザーの削除',
    message: 'ユーザーの削除に失敗しました。',
  },
  ERR_U401: {
    code: 'ERR_U401',
    tite: 'ユーザーのログアウト',
    message: 'ユーザーのログアウトに失敗しました。',
  },

  //SHINSEI ERR_S***
  ERR_S001: {
    code: 'ERR_S001',
    tite: '',
    message: '',
  },
  //TRANSIT ERR_T***
  ERR_T001: {
    code: 'ERR_T001',
    tite: '',
    message: '',
  },
  //ROUTE ERR_T***
  ERR_R001: {
    code: 'ERR_R001',
    tite: '権限。',
    message: '権限がないです。',
  },
  ERR_R002: {
    code: 'ERR_R002',
    tite: '作成。',
    message: '作成エラーです。',
  },
  ERR_R003: {
    code: 'ERR_R003',
    tite: '更新。',
    message: '更新エラーです。',
  },
  //COMPANY ERR_C***
  ERR_C001: {
    code: 'ERR_C001',
    tite: '',
    message: '',
  },
  ERR_C002: {
    code: 'ERR_C002',
    tite: '作成。',
    message: 'IDが存在しました。もう一度やり直してください',
  },
  ERR_C003: {
    code: 'ERR_C003',
    tite: '作成。',
    message: 'メールが存在しました。もう一度やり直してください',
  },
  ERR_C004: {
    code: 'ERR_C004',
    tite: 'サーバーエラー。',
    message: 'サーバーエラー。もう一回確認してください。',
  },
  //MEDIA ERR_M***
  ERR_M001: {
    code: 'ERR_M001',
    tite: '',
    message: '',
  },
};
