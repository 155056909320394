export const ERROR_MESSAGE = {
  //COMMON
  ERR_0000: {
    code: 'ERR_0000',
    title: '',
    message: '',
  },
  ERR_0001: {
    code: 'ERR_0001',
    title: 'サーバーエラー。',
    message: 'サーバーエラー。もう一回確認してください。',
  },
  ERR_0002: {
    code: 'ERR_0002',
    title: 'CSVファイル。',
    message: 'CSVファイルをアップロードしてください。',
  },
  ERR_0003: {
    code: 'ERR_0003',
    title: 'ログイン',
    message: 'ログインしてください。',
  },
  ERR_0004: {
    code: 'ERR_0004',
    title: 'サーバーエラー。',
    message: 'サーバーエラー。もう一回確認してください。',
  },
  ERR_0005: {
    code: 'ERR_0005',
    title: '管理者アカウント。',
    message: '管理者ではないです。管理者アカウントをログインしてください。',
  },
  ERR_0006: {
    code: 'ERR_0006',
    title: '権限がないです。',
    message: '権限がないです。',
  },
  ERR_0007: {
    code: 'ERR_0007',
    title: 'データ見つかりません。',
    message: 'データ見つかりません。',
  },
  ERR_0008: {
    code: 'ERR_0008',
    title: 'データエラー',
    message: 'データエラー',
  },
  ERR_0009: {
    code: 'ERR_0009',
    title: 'データ更新エラー',
    message: 'データ更新エラー',
  },
  ERR_0010: {
    code: 'ERR_0010',
    title: 'データ削除エラー',
    message: 'データ削除エラー',
  },
  //USER ERR_U***
  ERR_U000: {
    code: 'ERR_U000',
    title: 'ユーザー。',
    message: 'ユーザーが見つかりませんでした。',
  },
  ERR_U001: {
    code: 'ERR_U001',
    title: 'ユーザー。',
    message: 'ユーザーIDまたはパスワードが無効です. もう一度やり直してください',
  },
  ERR_U101: {
    code: 'ERR_U101',
    title: 'ログイン',
    message: 'ユーザーが存在しません。',
  },
  ERR_U102: {
    code: 'ERR_1102',
    title: 'ログイン',
    message: 'アカウントはロックされています。',
  },
  ERR_U103: {
    code: 'ERR_U103',
    title: 'ログイン',
    message: 'パスワードが違います. もう一度やり直してください。',
  },
  ERR_U104: {
    code: 'ERR_U104',
    title: 'ログイン',
    message: 'IDまたはパスワードが違います. もう一度やり直してください',
  },
  ERR_U105: {
    code: 'ERR_U105',
    title: 'エラー',
    message: 'アカウントはすでに存在しています',
  },
  ERR_U106: {
    code: 'ERR_U106',
    title: 'エラー',
    message: '認証されていないアカウント。 メールでアカウントを確認してください。',
  },
  ERR_U201: {
    code: 'ERR_U201',
    title: 'TOKEN',
    message: '',
  },
  ERR_U202: {
    code: 'ERR_U202',
    title: 'TOKEN',
    message: '',
  },
  ERR_U300: {
    code: 'ERR_U300',
    title: 'ユーザーの作成に失敗しました。',
    message: 'ユーザーの作成に失敗しました。',
  },
  ERR_U301: {
    code: 'ERR_U301',
    title: 'ユーザーが存在しています',
    message: 'ユーザーが存在しています。もう一度やり直してください。',
  },
  ERR_U302: {
    code: 'ERR_U302',
    title: 'メールが存在しています。',
    message: 'メールが存在しています。もう一度やり直してください。',
  },
  ERR_U303: {
    code: 'ERR_U302',
    title: 'ユーザーの更新。',
    message: 'ユーザーの更新に失敗しました。',
  },
  ERR_U304: {
    code: 'ERR_U304',
    title: 'リセットパスワード',
    message: '現在のパスワードが正しくありません. もう一度やり直してください',
  },
  ERR_U305: {
    code: 'ERR_U305',
    title: 'パスワードのリセット',
    message: 'パスワードのリセットに失敗しました。',
  },
  ERR_U306: {
    code: 'ERR_U306',
    title: 'ユーザーの削除',
    message: 'ユーザーの削除に失敗しました。',
  },
  ERR_U401: {
    code: 'ERR_U401',
    title: 'ユーザーのログアウト',
    message: 'ユーザーのログアウトに失敗しました。',
  },
  ERR_O501: {
    code: 'ERR_O501',
    title: '注文',
    message: '',
  },
  ERR_O502: {
    code: 'ERR_O501',
    title: '注文',
    message: '注文の作成に失敗しました。',
  },
  ERR_C600: {
    code: 'ERR_C600',
    title: '連絡先',
    message: '',
  },
  ERR_C601: {
    code: 'ERR_C601',
    title: '連絡先',
    message: '連絡先の作成に失敗しました。',
  },
  ERR_C602: {
    code: 'ERR_C602',
    title: '連絡先',
    message: '連絡先の削除に失敗しました。',
  },
  ERR_C603: {
    code: 'ERR_C603',
    title: '連絡先',
    message: '予約時間はすでに存在します',
  },
  ERR_CO700: {
    code: 'ERR_CO700',
    title: 'コースの作成',
    message: 'コースの作成に失敗しました。',
  },
  ERR_CO701: {
    code: 'ERR_CO701',
    title: 'コースの変更',
    message: 'コースの変更に失敗しました。',
  },
  ERR_CO702: {
    code: 'ERR_CO702',
    title: 'コースの削除',
    message: 'コースの削除に失敗しました。',
  },
  ERR_CO703: {
    code: 'ERR_CO703',
    title: 'コース',
    message: 'コース情報が無効です。もう一度確認してください. 注データフィールド（*）は完全に入力する必要があります',
  },
  ERR_CO704: {
    code: 'ERR_CO704',
    title: '使用されています',
    message: 'エラーコードはすでに使用されています。別の価格コードを選択してください',
  },
  ERR_B00: {
    code: 'ERR_B00',
    title: '教材',
    message: 'データ見つかりません。',
  },
  ERR_B01: {
    code: 'ERR_B01',
    title: '教材',
    message: 'データ見つかりません。',
  },
  ERR_B02: {
    code: 'ERR_B02',
    title: '教材',
    message: '教材情報が無効です。もう一度確認してください. 注データフィールド（*）は完全に入力する必要があります',
  },
  ERR_E02: {
    code: 'ERR_E02',
    title: '試験',
    message: '試験情報が無効です。もう一度確認してください. 注データフィールド（*）は完全に入力する必要があります',
  },
  ERR_P001: {
    code: 'ERR_P001',
    title: 'Post',
    message: '',
  },
  ERR_E001: {
    code: 'ERR_E001',
    title: 'Update',
    message: 'Update error',
  },
  ERR_Q000: {
    code: 'ERR_Q000',
    title: '質問の削除',
    message: '質問の削除に失敗しました。',
  },
  ERR_Q001: {
    code: 'ERR_Q001',
    title: '質問の削除',
    message: '質問は削除できません。質問がテストで使用されている。',
  },
  ERR_SCHEDULE_001: {
    code: 'ERR_SCHEDULE_001',
    title: 'スケジュールの作成',
    message: 'スケジュールは作成できません。もう一度やり直してください。',
  },
  ERR_SCHEDULE_002: {
    code: 'ERR_SCHEDULE_002',
    title: 'スケジュールの削除',
    message: 'スケジュールは削除できません。もう一度やり直してください。',
  },
  ERR_BOOKING_001: {
    code: 'ERR_BOOKING_001',
    title: '予約の作成',
    message: '予約は作成できません。もう一度やり直してください。',
  },
  ERR_BOOKING_002: {
    code: 'ERR_BOOKING_002',
    title: '予約の変更',
    message: '予約は変更できません。もう一度やり直してください。',
  },
  ERR_BOOKING_003: {
    code: 'ERR_BOOKING_003',
    title: '予約のキャンセル',
    message: '予約はキャンセルできません。もう一度やり直してください。',
  },
  ERR_BOOKING_004: {
    code: 'ERR_BOOKING_004',
    title: '予約の終了',
    message: '予約は終了できません。もう一度やり直してください。',
  },
};
