import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Loading } from 'vendor-deprecated/Loading';
import CommonLayout from './CommonLayout';
import { SessionProvider } from '../session/SessionProvider';
import * as Pages from './Loadables';

export const Routes = () => {
  return (
    <SessionProvider>
      <React.Suspense fallback={<Loading loading color={'gray'} />}>
        <Switch>
          <Route path="/article-mobile/:articleId" component={Pages.MobileArticleDetails} />
          <Route path="/" component={CommonLayout} />
        </Switch>
      </React.Suspense>
    </SessionProvider>
  );
};
