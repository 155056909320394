export const typeList = {
  languages: [
    {
      label: '日本語',
      value: 'ja',
    },
    {
      label: '英語',
      value: 'en',
    },
    {
      label: 'ベトナム語',
      value: 'vi',
    },
  ],
  test: {
    name: 'asd',
  },
  questionTypes: [
    {
      label: 'グループ',
      value: 'GROUP',
    },
    {
      label: '単一',
      value: 'SINGLE',
    },
  ],
  articleCategories: [
    {
      label: 'ニュース',
      value: 'news',
    },
    {
      label: 'ブログ',
      value: 'blog',
    },
    {
      label: 'Business',
      value: 'business',
    },
    {
      label: '教育',
      value: 'education',
    },
    {
      label: '試験',
      value: 'exam',
    },
    {
      label: 'コース',
      value: 'course',
    },
  ],
  serviceTypes: [
    {
      label: 'ニュース',
      value: 'NEWS',
    },
    {
      label: 'サービス',
      value: 'SERVICE',
    },
  ],
  questionCategories: [
    {
      label: '読解',
      value: 'READING',
    },
    {
      label: '聴解',
      value: 'LISTENING',
    },
  ],
  userProfileMenu: [
    {
      prefixIcon: 'home',
      label: 'トップページ',
      url: '/',
    },
    {
      prefixIcon: 'user',
      label: '個人情報',
      url: '/user/my-profile',
    },
    {
      prefixIcon: 'cart',
      label: 'カート',
      url: '/user/cart',
    },
    {
      prefixIcon: 'cart',
      label: 'オーダー',
      url: '/user/orders',
    },
    {
      prefixIcon: 'archive',
      label: 'コース',
      url: '/user/courses',
    },
    {
      prefixIcon: 'check-circle',
      label: '試験',
      url: '/user/my-exam',
    },
    {
      prefixIcon: 'book',
      label: '教科書',
      url: '/user/my-book',
    },
    {
      prefixIcon: 'key',
      label: 'パスワード変更',
      url: '/user/change-password',
    },
    {
      prefixIcon: 'logout',
      label: 'ログアウト',
      url: '/user/logout',
    },
  ],
  authorMenu: [
    {
      prefixIcon: 'statistic',
      label: '統計',
      url: '/author/statistics',
    },
    {
      prefixIcon: 'user',
      label: '個人情報',
      url: '/author/profile',
    },
    {
      prefixIcon: 'case',
      label: 'コース管理',
      url: '/author/courses',
    },
    {
      prefixIcon: 'key',
      label: 'パスワード変更',
      url: '/author/change-password',
    },
    {
      prefixIcon: 'media',
      label: 'メディアセンター',
      url: '/author/medias',
    },
    {
      prefixIcon: 'logout',
      label: 'ログアウト',
      url: '/author/logout',
    },
  ],
  footerMenu: [
    [
      {
        type: 'header',
        title: 'VietAcademy',
      },
      {
        type: 'link',
        title: 'ミッション',
        url: '/services',
      },
      {
        type: 'link',
        title: 'コース',
        url: '/courses',
      },
      {
        type: 'link',
        title: '運営会社',
        url: '/about',
      },
    ],
    [
      {
        type: 'header',
        title: '会社情報',
      },

      {
        type: 'link',
        title: '個人情報保護方針',
        url: '/terms-of-service',
      },
      {
        type: 'link',
        title: '使用条件',
        url: '/privacy',
      },
      {
        type: 'link',
        title: 'お問い合わせ',
        url: '/contact',
      },
    ],
    [
      {
        type: 'header',
        title: 'アプリ',
      },
      {
        type: 'text',
        title: 'vietacademy (Android)',
      },
      {
        type: 'text',
        title: 'vietacademy (ios)',
      },
    ],
    [
      {
        type: 'header',
        title: '事業内容',
      },
      {
        type: 'text',
        title: '教育',
      },
    ],
    [
      {
        type: 'header',
        title: 'お問い合わせ',
      },
      {
        type: 'phone',
        title: '03-6873-1076',
      },
      {
        type: 'email',
        title: 'contact@qh-consulting.com',
      },
    ],
  ],
  genders: [
    {
      label: '女性',
      value: 'FEMALE',
    },
    {
      label: '男性',
      value: 'MALE',
    },
  ],
  userDetailsTabs: [
    {
      label: '情報',
      value: 'UserInformation',
    },
    {
      label: 'コース',
      value: 'UserCourse',
    },
    {
      label: '教科書',
      value: 'UserBook',
    },
    {
      label: '試験',
      value: 'UserExam',
    },
  ],
  adminDetailsTabs: [
    {
      label: '情報',
      value: 'UserInformation',
    },
    {
      label: 'コース',
      value: 'UserCourse',
    },
    {
      label: '教科書',
      value: 'UserBook',
    },
    {
      label: '試験',
      value: 'UserExam',
    },
    {
      label: '権限データ',
      value: 'UserIdentity',
    },
  ],
  yesNoTypes: [
    {
      label: 'いいえ',
      value: false,
    },
    {
      label: 'はい',
      value: true,
    },
  ],
  examSegmentItems: [
    {
      label: 'シングル',
      value: 'single',
    },
    {
      label: 'すべて',
      value: 'all',
    },
  ],
  cmsSidebarMenus: [
    {
      prefixIcon: 'home',
      label: '会社概要',
      url: '/cms/about',
      subMenus: [
        {
          label: 'トップページ',
          url: '/cms/settings/home',
        },
        {
          label: '使用条件',
          url: '/cms/privacy',
        },
        {
          label: '利用規約',
          url: '/cms/terms-of-service',
        },
        {
          label: '運営会社',
          url: '/cms/company-profile',
        },
      ],
    },
    {
      label: 'ユーザー管理',
      prefixIcon: 'user',
      url: '/cms/users',
      subMenus: [
        {
          label: 'ユーザー一覧',
          url: '/cms/users',
        },
      ],
    },
    {
      label: '注文管理',
      prefixIcon: 'cart',
      url: '/cms/orders',
      subMenus: [
        {
          label: '注文一覧',
          url: '/cms/orders',
        },
      ],
    },
    {
      prefixIcon: 'book',
      label: '教科書管理',
      url: '/cms/books',
      subMenus: [
        {
          label: '教科書一覧',
          url: '/cms/books',
        },
        {
          prefixIcon: 'check',
          label: '教科書アップロード',
          url: '/cms/book/upload',
        },
      ],
    },
    {
      prefixIcon: 'case',
      label: 'コース管理',
      url: '/cms/courses',
      subMenus: [
        {
          label: 'コース一覧',
          url: '/cms/courses',
        },
        {
          prefixIcon: 'check',
          label: 'コースアップロード',
          url: '/cms/courses/upload',
        },
      ],
    },
    {
      prefixIcon: 'question',
      label: '質問管理',
      url: '/cms/questions',
      subMenus: [
        {
          label: '質問一覧',
          url: '/cms/questions',
        },
        {
          prefixIcon: 'check',
          label: '質問アップロード',
          url: '/cms/questions/upload',
        },
      ],
    },
    {
      prefixIcon: 'clock',
      label: '試験管理',
      url: '/cms/exam/list',
      subMenus: [
        {
          label: '試験一覧',
          url: '/cms/exam/list',
        },
        {
          prefixIcon: 'check',
          label: '試験結果',
          url: '/cms/exam-result/list',
        },
      ],
    },

    {
      prefixIcon: 'file',
      label: 'ニュース',
      url: '/cms/articles',
      subMenus: [
        {
          label: 'ニュース一覧',
          url: '/cms/articles',
        },
      ],
    },
    {
      prefixIcon: 'case',
      label: 'サービス',
      url: '/cms/services',
      subMenus: [
        {
          label: 'サービス一覧',
          url: '/cms/services',
        },
      ],
    },
    {
      prefixIcon: 'media',
      label: 'メディアセンター',
      url: '/cms/medias',
      subMenus: [
        {
          label: 'メディア一覧',
          url: '/cms/medias',
        },
      ],
    },
    {
      prefixIcon: 'setting',
      label: '設定',
      url: '/cms/setting',
      subMenus: [
        {
          label: 'copyright',
          url: '/cms/setting',
        },
      ],
    },
    {
      prefixIcon: 'question',
      label: 'ヘルプ',
      url: '/cms/helps',
      subMenus: [
        {
          label: 'ヘルプマネージャー',
          url: '/cms/helps',
        },
      ],
    },
    {
      prefixIcon: 'logout',
      label: 'ログアウト',
      url: 'logout',
      subMenus: [],
    },
  ],
  orderStatus: [
    {
      label: '承認待ち',
      value: 'PENDING',
    },
    {
      label: '承認済み',
      value: 'APPROVED',
    },
  ],
  orderSegment: [
    {
      label: 'カート',
      value: 'cart',
    },
    {
      label: '注文',
      value: 'order',
    },
  ],
  examTypes: [
    {
      label: 'ミニテスト',
      value: 'MiniTest',
    },
    {
      label: 'フルテスト',
      value: 'FullTest',
    },
  ],
  examTabs: [
    {
      label: 'すべて',
      value: 'All',
    },
    {
      label: 'ミニテスト',
      value: 'MiniTest',
    },
    {
      label: 'フルテスト',
      value: 'FullTest',
    },
  ],
  userRoles: [
    {
      label: '管理者',
      value: 'ADMIN',
    },
    {
      label: 'メンバー',
      value: 'USER',
    },
    {
      label: '著者',
      value: 'AUTHOR',
    },
  ],
  segmentPublic: [
    {
      label: '公開',
      value: true,
    },
    {
      label: '非公開',
      value: false,
    },
  ],
  examSteps: [
    '(Yêu cầu: Khoá học đã được tạo và đã công khai).',
    '',
    '1. Liên kết đề thi với khoá học. ( Để biết đề thi dành cho khoá học nào ).',
    '2. Thêm các mục.',
    '3. Thêm câu hỏi cho các mục.',
    '',
    'Để chỉnh sửa câu hỏi nhấp vào nút chỉnh sửa.',
  ],
  courseSteps: [
    'Yêu cầu: Giáo trình đã được tạo và đã công khai.',
    'Mỗi khoá học có thể có nhiều giáo trình khác nhau.',
    '',
    '1. Thêm giáo trình.',
    'Tên, mô tả và các tiêu đề của bài học được lấy tự động.',
    '',
    '2. Chỉnh sửa nội dung tiêu đề - mô tả - bài học nếu cần.',
    '',
    '3. Thêm Các kỹ năng sẽ học được trong mỗi giáo trình.',
  ],
  questionSteps: [
    'Yêu cầu: Giáo trình đã được tạo và đã công khai.',
    'Mỗi Giáo trình đều có nhiều bài học khác nhau. Vì thế mỗi câu hỏi sẽ tạo ra từ nội dung bài học',
    '',
    '1. Chọn bài học cần tạo câu hỏi [Tên bài học]. nếu không có bài học vui lòng thêm bài học vào giáo trình trước',
    '',
    '2. Chọn loại câu hỏi đơn hay nhóm.',
    '',
    '3. Chọn loại câu hỏi về nghe hay đọc hiểu.',
    '',
    '4. Nhập nội dung câu hỏi.',
  ],
  helpCategories: [
    {
      label: 'アカウント',
      value: 'account',
    },
    {
      label: '本',
      value: 'book',
    },
    {
      label: 'コース',
      value: 'course',
    },
    {
      label: '質問',
      value: 'question',
    },
    {
      label: '試験',
      value: 'exam',
    },
    {
      label: '支払い',
      value: 'payment',
    },
    {
      label: 'ポリシー',
      value: 'policy',
    },
    {
      label: 'その他',
      value: 'other',
    },
  ],
};
