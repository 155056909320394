import React from 'react';
import styled from 'styled-components';

const ImageWrapper = styled.img<ImageProps>`
  overflow: ${(props) => props.overflow || 'hidden'};
  object-fit: ${(props) => props.objectFit};
  border-radius: ${(props) => props.borderRadius || '0px'};
  width: ${(props) => props.width || 'auto'};
  height: ${(props) => props.height || 'auto'};
  ${(props) => (props.margin ? `margin:${props.margin}` : null)};
  ${(props) => (props.marginTop ? `margin-top:${props.marginTop}` : null)};
  ${(props) => (props.marginLeft ? `margin-left:${props.marginLeft}` : null)};
  ${(props) => (props.marginRight ? `margin-right:${props.marginRight}` : null)};
  ${(props) => (props.marginBottom ? `margin-bottom:${props.marginBottom}` : null)};
  ${(props) => (props.padding ? `padding:${props.padding}` : null)};
  ${(props) => (props.paddingTop ? `padding-top:${props.paddingTop}` : null)};
  ${(props) => (props.paddingLeft ? `padding-left:${props.paddingLeft}` : null)};
  ${(props) => (props.paddingRight ? `padding-right:${props.paddingRight}` : null)};
  ${(props) => (props.paddingBottom ? `padding-bottom:${props.paddingBottom}` : null)};
`;

export type ImageProps = {
  src?: string;
  width?: string;
  height?: string;
  borderRadius?: string;
  margin?: string;
  marginTop?: string;
  marginLeft?: string;
  marginRight?: string;
  marginBottom?: string;
  padding?: string;
  paddingLeft?: string;
  paddingRight?: string;
  paddingTop?: string;
  paddingBottom?: string;
  alt?: string;
  overflow?: React.CSSProperties['overflow'];
  objectFit?: React.CSSProperties['objectFit'];
  children?: React.ReactNode;
};

export const Image = React.memo((props: ImageProps) => {
  if (!props.src) {
    return null;
  }
  return (
    <ImageWrapper alt={props.alt ? props.alt : props.src ? props.src.split('/').slice(-1).pop() : ''} {...props}>
      {props.children}
    </ImageWrapper>
  );
});
