export const global = {
  header: {
    login: 'ログイン',
  },
  myAccount: 'アカウント',
  mobileSideBar: {
    home: 'トップページ',
    course: 'コース',
    news: 'ニュース',
    service: 'サービス',
    login: 'ログイン',
    logout: 'ログアウト',
    register: '登録',
    cart: 'カート',
    help: 'ヘルプ',
  },
  userLayout: {
    exam: {
      finish: '終了しました',
      time: '時間',
      hour: '時',
      second: '秒',
      minute: '分',
      submit: '提出する',
      question: '質問',
      subQuestion: 'サブ質問',
      startButton: 'テストを開始します',
      unitTime: '分',
      totalPass: '正しい',
      totalQuestion: '合計質問',
      cancelButton: 'キャンセル',
      resultTitle: '結果テスト',
      gotoMyExam: '試験リストに行く',
    },
    lecture: {
      title: '講義',
    },
    userTabs: {
      information: {
        title: '情報',
        email: 'メール',
        name: '名前',
        gender: '性別',
        birthday: 'お誕生日',
        address: '住所',
      },
      order: {
        noItem: '注文なし',
        title: '注文リスト',
        time: '時',
        unitPrice: '円',
        day: '日',
        totalItems: 'コース数',
        totalPrice: '合計金額',
        payment: '支払い',
        cancel: 'キャンセル',
        username: '名前',
        status: 'ステータス',
        createdTime: '時間',
        price: '価格',
        approved: '承認済み',
        pending: '承認待ち',
        alertCancel: {
          message: '注文のキャンセルを実施してもよろしですか？',
          close: 'いいえ',
          delete: 'はい',
        },
        alertConfirm: {
          title: 'このお支払いを完了してもよろしいですか？',
          close: 'いいえ',
          confirm: 'はい',
        },
      },
      myCourse: {
        title: 'コースリスト',
      },
      myBook: {
        title: '教科書リスト',
        chapter: '章 ',
      },
      myExam: {
        title: '試験リスト',
        time: '時間',
        numberDay: '日数',
        day: '日',
        unitTime: '分',
        times: '回',
        answer: '回答',
        dateTime: '時間',
        pointAvg: '平均点',
        pointCount: '点数',
        answerCount: '回答数',
        startTesting: 'テスト',
      },
      changePassword: {
        title: 'パスワードの変更',
        currentPassword: '現在のパスワード',
        newPassword: '新しいパスワード',
        confirmNewPassword: '確認パスワード',
        save: '変更する',
      },
    },
  },
  ordersList: {
    information: '情報',
    username: '名前',
    status: 'ステータス',
    createdTime: '時間',
    price: '価格',
    unitPrice: '円',
    approved: '承認済み',
    pending: '承認待ち',
    searchbar: {
      status: 'ステータス',
      title: '注文検索',
      keyword: 'キーワード',
    },
    alertDelete: {
      message: '注文の削除を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
  },
  orderDetails: {
    username: '名前',
    email: 'メール',
    title: '注文詳細',
    time: '時',
    status: 'ステータス',
    createdTime: '時間',
    unitPrice: '円',
    totalItems: 'コース数',
    totalPrice: '合計金額',
    approve: '承認する',
    reject: '',
    approved: '承認済み',
    pending: '承認待ち',
    alertConfirm: {
      message: '注文の承認を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
  },
  userDetails: {
    account: 'アカウント',
    title: 'アカウント情報',
    email: 'メール',
    name: '名前',
    gender: '性別',
    birthday: 'お誕生日',
    address: '住所',
    isLocked: '無効',
    isVerified: '検証',
    role: '権限',
    changePass: 'パスワードの変更',
    save: '変更する',
    details: '詳細',
    addCourse: 'コースを追加する',
    deleteCourseMsg: 'コースの削除を実施してもよろしですか？',
    identity: {
      read: '読み込み',
      write: '書き込み',
    },
    addIdentity: '権限を追加する',
  },
  usersList: {
    information: '情報',
    email: 'メール',
    name: '名前',
    isLocked: '無効',
    isVerified: '検証',
    role: '権限',
    alertDelete: {
      message: 'アカウントの削除を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
  },
  public: {
    login: {
      title: 'ログイン',
      email: 'メールアドレス',
      password: 'パスワード',
      resetPassword: 'パスワードをお忘れの方はこちら',
      register: 'アカウントをお持ちでない方は',
      registerMember: '新しいメンバーアカウントを作成する',
      registerAuthor: '新しい著者アカウントを作成する',
      button: 'こちら',
      loginButton: 'ログインする',
    },
    verifyAccount: {
      title: 'アカウントの確認',
      message: 'アカウントの確認に成功しました',
      login: 'ログイン',
      error: 'アカウントの確認に失敗しました',
    },
    resetPasswordConfirm: {
      title: 'パスワードの変更',
      password: '新しいパスワード',
      confirmPassword: '新しいパスワードを再入力してください',
      change: '変更',
      success: 'パスワードを変更しました',
      login: 'ログイン',
    },
    resetPassword: {
      title: 'パスワードのリセット',
      completed: 'パスワードのリセットが完了しました。 メールでチェックしてください',
      email: 'メールアドレス',
      button: 'こちら',
      reset: 'リセットする',
      home: 'トップページ',
      hasAccount: 'アカウントをお持ち方は',
      noAccount: 'アカウントをお持ちでない方は',
    },
    register: {
      title: '新規会員登録',
      authorTitle: '新規著者登録',
      completed: '登録が完了しました. メールでチェックしてください',
      email: 'メールアドレス',
      password: 'パスワード',
      confirmPassword: '確認パスワード',
      button: 'こちら',
      register: '登録する',
      backToLogin: 'ログインに戻る',
    },
    courseList: {
      header: 'コースリスト',
      description: 'コースの詳細ページで、詳細情報を見つけることができます',
      card: {
        price: '価格',
        details: '詳細',
      },
      searchbar: {
        title: 'コース検索',
      },
    },
    courseDetails: {
      price: '価格',
      time: '日',
      card: {
        price: '価格',
        time: '時',
        title: 'The deadline to enroll in the next cohort is January 11, 2021.',
        addToCard: '購入する',
        alertConfirm: {
          title: '購入を実施してもよろしですか？',
          close: 'いいえ',
          confirm: 'はい',
        },
      },
    },
    newsList: {
      header: 'ニュース',
    },
    serviceList: {
      header: 'サービス',
    },
    about: {
      header: '会社概要',
    },

    privacy: {
      header: '使用条件',
      title: '使用条件',
    },
    terms: {
      header: '利用規約',
      title: '利用規約',
    },
  },
  bookList: {
    header: '教科書',
    modalHeader: '教科書 (公開)',
    name: '教科書名',
    title: 'タイトル',
    public: '公開',
    approved: '承認済み',
    searchbar: {
      title: '教科書検索',
      keyword: 'キーワード',
      condition: '検索条件クリア',
      buttonSearch: '検索',
    },
    alertDelete: {
      message: '本の削除を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
  },
  bookDetails: {
    notitle: 'タイトルなし',
    preview: 'プレビュー',
    header: '詳細',
    title: 'タイトル',
    public: '公開',
    approved: '承認済み',
    description: '説明',
    chapter: '章',
    lesson: 'レッスン',
    content: '内容',
    addChapter: '章',
    alertDeleteLesson: {
      message: 'レッスンの削除を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
    alertDeleteChapter: {
      message: '章の削除を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
  },
  courseList: {
    header: 'コース管理',
    description: 'コース一覧',
    name: 'コース名',
    title: 'タイトル',
    public: '公開',
    price: '価格',
    alertDelete: {
      message: 'コースの削除を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
    searchbar: {
      title: 'コース検索',
    },
  },
  courseDetails: {
    step: 'コースの作成方法',
    header: 'コース詳細',
    notitle: 'タイトルなし',
    title: 'タイトル',
    price: '価格',
    thumbnail: 'thumbnail',
    unitPrice: '円',
    duration: '日',
    unitTime: '日',
    public: '公開',
    description: '説明',
    syllabus: '教材',
    content: '内容',
    lesson: {
      lesson: 'レッスン',
      title: 'タイトル',
      content: '内容',
    },
    skill: {
      skill: 'スキル',
      title: 'タイトル',
      content: '内容',
    },
    alertDeleteSyllabus: {
      message: '教材の削除を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
  },
  questionDetails: {
    step: '質問の作成方法',
    header: '質問詳細',
    title: '質問内容',
    type: '質問タイプ',
    subQuestion: 'サブ質問',
    category: '質問カテゴリー',
    audio: 'mp3',
    public: '公開',
    book: {
      title: '教科書名',
      lesson: 'レッスン名',
    },
    answer: {
      title: '回答内容',
      explain: '内容説明',
    },
    alertDeleteQues: {
      message: '質問の削除を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
    alertDeleteAnswer: {
      message: '回答の削除を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
  },
  examList: {
    title: 'タイトル',
    public: '公開',
    searchbar: {
      title: '試験検索',
      keyword: 'キーワード',
      condition: '検索条件クリア',
      buttonSearch: '検索',
    },
    alertDelete: {
      message: '試験の削除を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
  },
  examDetails: {
    step: '試験の作成方法',
    header: '試験詳細',
    title: 'タイトル',
    course: 'コース',
    type: 'タイプ',
    time: '時間',
    unitTime: '分',
    category: '質問カテゴリー',
    question: '質問',
    audio: 'mp3',
    public: '公開',
    details: '詳細',
    testDate: '試験日',
    lesson: 'レッスン',
    section: {
      header: 'セクション',
      title: 'タイトル',
    },
    book: {
      title: '教科書名',
      lesson: 'レッスン名',
    },
    answer: {
      title: '回答内容',
      explain: '内容説明',
    },
    alertDeleteQues: {
      message: '質問の削除を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
    alertDeleteExam: {
      message: '試験の削除を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
  },
  news: {
    category: 'ニュース',
    news: 'ニュース',
    title: 'タイトル',
    description: '説明',
    public: '公開',
    content: '内容',
    preview: 'プレビュー',
    searchbar: {
      title: 'News検索',
      keyword: 'キーワード',
      condition: '検索条件クリア',
      buttonSearch: '検索',
    },
    alertDelete: {
      message: 'ニュースの削除を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
  },
  service: {
    category: 'サービス',
    service: 'サービス',
    title: 'タイトル',
    description: '説明',
    public: '公開',
    content: '内容',
    preview: 'プレビュー',
    status: 'ステータス',
    searchbar: {
      title: 'サービス検索',
      keyword: 'キーワード',
      condition: '検索条件クリア',
      buttonSearch: '検索',
    },
    alertDelete: {
      message: 'サービスの削除を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
  },
  questionList: {
    header: '質問リスト',
    title: '質問',
    public: '公開',
    book: '教科書',
    lesson: 'レッスン',
    question: '質問',
    questionSelected: '選択した質問',
    questionList: '質問リスト',
    update: '更新',
    searchbar: {
      title: '質問検索',
      keyword: 'キーワード',
      condition: '検索条件クリア',
      buttonSearch: '検索',
    },
    alertDelete: {
      message: '質問の削除を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
  },
  home: {
    title: 'トップページ',
    public: '公開',
    limit: '制限',
    topBanner: {
      title: 'タイトル',
      description: '説明',
      details: '詳細',
    },
    category: {
      header: 'カテゴリー',
      icon: 'アイコン',
      title: 'タイトル',
      description: '説明',
      create: '作成する',
      edit: '保存する',
    },
    course: {
      header: 'コース',
      title: 'タイトル',
      description: '説明',
    },
    news: {
      header: 'ニュース',
      title: 'タイトル',
      description: '説明',
      limit: '',
    },
    blog: {
      header: 'ブログ',
      title: 'タイトル',
      description: '説明',
      limit: '',
    },
  },
  companyProfile: {
    header: '会社概要',
  },
  media: {
    title: 'メディア',
    message: '選択する',
    deleteAlert: {
      message: 'メディアの削除を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
  },

  footer: {
    message: '「Viet Academy」でベトナム語を学びましょう。',
    buttonStart: '始まる',
  },

  common: {
    nodata: 'データなし',
    nocourse: 'コースなし',
    nobook: '教科書なし',
    course: 'コース',
    book: '教科書',
    exam: '試験',
    new: '新規',
    public: '公開',
    exportCsv: 'CSVエクスポート',
    buttonBack: '戻る',
    buttonSaveChange: '変更する',
    buttonSave: '保存する',
    buttonCreate: '作成する',
    yes: 'はい',
    no: 'いいえ',
    topMenu: {
      home: 'ホーム',
      news: 'ニュース',
      course: 'コース',
      service: 'サービス',
      about: '会社概要',
      help: 'ヘルプ',
    },
  },
  mediaCenter: {
    title: 'メディアセンター',
  },
  uploadBook: {
    title: '教科書アップロード',
  },
  uploadCourse: {
    title: 'コースアップロード',
  },
  uploadQuestion: {
    title: '質問アップロード',
  },
  uploadExam: {
    title: '試験アップロード',
  },
  about: {
    header: '会社概要',
  },

  privacy: {
    header: '使用条件',
  },
  terms: {
    header: '利用規約',
  },
  userList: {
    searchbar: {
      title: 'ユーザー検索',
      keyword: 'キーワード',
    },
    alertDelete: {
      message: 'ユーザーの削除を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
  },
  setting: {
    title: '設定',
    copyright: 'Copyright',
  },
  createUser: {
    title: '新規アカウント',
    email: 'メールアドレス',
    password: 'パスワード',
    confirmPassword: '確認パスワード',
    create: '作成する',
    role: '権限',
  },
  page404: {
    title: '',
    goHome: 'ホームへ戻る',
    description: '申し訳ありません。お探しのページが見つかりません。',
  },
  validator: {
    passwordRequired: 'パスワードは必須項目です。',
    passwordConfirmRequired: '確認パスワードは必須項目です。',
    passwordMin: 'パスワードは8文字以上で入力してください。',
    passwordMax: 'パスワードは20文字以内で入力してください。',
    passwordInvalid: 'パスワードは半角英数記号(英数混合必須)で入力してください。',
    passwordNotMatch: '２つのパスワードが一致していません。',
    requiredEmail: 'メールアドレスは必須項目です。',
    requiredForRole: '役割は必須項目です。',
    requiredSeoName: 'seonameは必須項目です。',
    requiredCategory: 'カテゴリーは必須項目です。',
    requiredTitle: 'タイトルは必須項目です。',
    requiredContent: '内容は必須項目です。',
  },
  modals: {
    submitExamConfirmModal: {
      title: 'テストの提出',
      answer: '回答数',
      message: '本当にこのテストを提出してもよろしいですか？',
      submit: '提出する',
    },
    createQuestion: {
      title: '質問の作成',
      type: 'タイプ',
      book: '教科書',
      create: '作成',
    },
    cropImageModal: {
      selectRatio: '選択してアップロード',
    },
    questionList: {
      book: '教科書',
      lesson: 'レッスン',
      question: '質問',
      questionSelected: '選択した質問',
      questionList: '質問リスト',
      update: '更新',
      searchbar: {
        title: '質問検索',
        keyword: 'キーワード',
        condition: '検索条件クリア',
        buttonSearch: '検索',
      },
    },
    confirmLogout: {
      title: 'アカウントからサインアウトしますか？',
      close: 'いいえ',
      confirm: 'サインアウト',
    },
  },
  statistic: {
    header: '統計',
  },
  //Alert
  alertDelete: {
    message: '注文の削除を実施してもよろしですか？',
    close: 'いいえ',
    delete: 'はい',
  },
  message: {
    create: 'データ作成',
    update: 'データ更新',
    delete: 'データ削除',
    error: '失敗しました',
    success: '成功しました',
  },
  helpCenter: 'ヘルプセンター',
  helpTitle: 'ヘルプ',
  helpEdit: 'ヘルプの編集',
  title: 'タイトル',
  content: 'コンテンツ',
  preview: 'プレビュー',
  publicTitle: '公衆',
  category: 'カテゴリー',
  keyword: 'キーワードを入力します',
  helpMessage: 'お手伝いしましょうか？',
  information: '情報',
  searchResult: '検索結果',
  language: '言語',
  must: '必要',
  helpFor: 'ヘルプのために',
  save: '保存',
  close: '閉じる',
  newsTitle: 'ニュース',
  blogTitle: 'ブログ投稿',
  recentNews: '最近のニュース',
  skillsYouWillGain: 'スキル　-　ゲイン',
  whatYouWillLearn: '何を学びますか？',
  homeTitle: 'トップページ',
  courseDetailsTitle: 'コース詳細',
  copied: 'コピーされました',
  userDetailsTitle: 'アカウント情報',
  contactEmail: 'メール',
  contactPhone: '電話番号',
  contactSubject: 'タイトル',
  contactContent: '内容',
  sendContact: '送る',
  authorVerifyMessage:
    'Chào bạn\n\nCảm ơn bạn đã đăng ký tài khoản giáo viên trên Vietcademy. Bạn vui lòng cập nhật thông tin và tải ảnh đại diện, đồng thời gửi thông tin để Vietcademy kiểm tra và xác nhận tài khoản cho bạn về email: contact@vietcademy.jp\n1. Thông tin hợp đồng\n\tHọ và tên\n\tĐịa chỉ\n\tSố điện thoại\n\tSố tài khoản ngân hàng để nhận thù lao\n2. Ảnh chứng chỉ tiếng Nhật JLPT bạn đang có',
};
