import { useLazyQuery, QueryTuple, QueryLazyOptions, WatchQueryFetchPolicy, DocumentNode } from '@apollo/client';
import { TableType, useCache } from 'api/cache/useCache';

export type QueryProps = {
  table: TableType;
  action: 'list' | 'get';
  tData: string;
  query: DocumentNode;
  fetchPolicy?: WatchQueryFetchPolicy;
};
export function useQueryData<TData = any, TVariables = Record<string, any>>(
  props: QueryProps,
): QueryTuple<TData, TVariables> {
  const { updateCacheKeys } = useCache();

  const [getQuery, queryResult] = useLazyQuery(props.query, {
    ...(props.fetchPolicy ? { fetchPolicy: props.fetchPolicy } : {}),
  });

  function queryData(options?: QueryLazyOptions<Record<string, any>> | undefined) {
    getQuery(options);
    if (options?.variables?.input) {
      updateCacheKeys({
        table: props.table,
        type: props.action,
        tData: props.tData,
        input: options?.variables?.input,
        query: props.query,
      });
    }
  }

  return [queryData, queryResult as any];
}
