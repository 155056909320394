import React from 'react';
import styled from 'styled-components';
import { Modal } from 'vendor-deprecated/Modal';
import { VerticalView } from 'vendor-deprecated/Wrapper';
import { AppColors, AppConst } from 'helpers';
import { useWindowDimensions } from 'vendor-deprecated/Hooks/useWindow/useWindowDimensions';
import { Button } from 'vendor-deprecated/Button';
import { Hr } from 'vendor-deprecated/CommonParts';
import { TextDisplay } from 'vendor-deprecated/Text';
import { useTranslationText } from 'locale';

export const ModalWrapper = styled.div``;
export const AlertModalWrapper = styled.div``;
export const ContentWrapper = styled.div``;
export const AddUserWrapper = styled.div``;

const Content = styled.div<{ width: string; height: string }>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background: ${AppColors.TRANSPARENT};
`;

interface Props {
  open: boolean;
  onClose: () => void;
}

export const ChangeLanguageModal = (props: Props) => {
  const { isMobile } = useWindowDimensions();
  const { translation } = useTranslationText();
  return (
    <Modal
      transparent
      zIndex={AppConst.zIndex.header + 3000}
      backdropColor={'rgb(1,1,1,0.3)'}
      open={props.open}
      onClose={() => {
        props.onClose();
      }}
      backdrop
      closeOnBackDropClick
    >
      <Content width={'100vw'} height={'auto'}>
        <VerticalView fullWidthHeight alignContent={'center'}>
          <VerticalView
            marginBottom={'20px'}
            width={isMobile ? '300px' : '400px'}
            background={AppColors.WHITE}
            borderRadius={'10px'}
            alignContent={'center'}
            overflow={'hidden'}
          >
            <TextDisplay id={'selectALanguage'} marginTop={'20px'} marginBottom={'20px'} />
            <Hr />
            <Button
              width={'100%'}
              size={'15px'}
              height={'60px'}
              text={translation('ja')}
              onClick={() => {
                localStorage.setItem('QHC_LANG', 'ja');
                window.location.reload();
                props.onClose();
              }}
            />
            <Hr />
            <Button
              width={'100%'}
              size={'15px'}
              height={'60px'}
              text={translation('vi')}
              onClick={() => {
                localStorage.setItem('QHC_LANG', 'vi');
                window.location.reload();
                props.onClose();
              }}
            />
            <VerticalView fullWidth>
              <Hr />

              <Button
                text={translation('close')}
                marginTop={'5px'}
                marginBottom={'5px'}
                width={'100%'}
                height={'50px'}
                borderRadius={'5px'}
                color={AppColors.RED}
                background={AppColors.WHITE}
                onClick={props.onClose}
              />
            </VerticalView>
          </VerticalView>
        </VerticalView>
      </Content>
    </Modal>
  );
};
