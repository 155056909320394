import EventEmitter from 'events';
export const appEvent = new EventEmitter();

type EventProps = {
  type: 'update-cart';
};

export const sendEvent = (props: EventProps) => {
  appEvent.emit(props.type);
};
