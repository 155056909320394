import { MutationTuple, QueryLazyOptions, DocumentNode, useMutation } from '@apollo/client';
import { useCache, TableType } from 'api/cache/useCache';
import { useApolloCache, MutationDataType, UpdateCacheQuery } from 'hooks/apolloCache';

export type MutationProps = {
  table: TableType;
  tData?: string;
  action: 'update' | 'delete' | 'add' | 'login' | 'logout';
  mutation: DocumentNode;
  updateCacheQuery?: UpdateCacheQuery;
};

export function useMutationData<TData = any, TVariables = Record<string, any>>(
  props: MutationProps,
): MutationTuple<TData, TVariables> {
  const { syncDataCache } = useCache();
  const { updateApolloCache } = useApolloCache();

  const [mutationAction, result] = useMutation(props.mutation);

  async function mutationData(options?: QueryLazyOptions<Record<string, any>> | undefined) {
    const responseData = await mutationAction(options);
    if (props.updateCacheQuery && props.tData && responseData?.data && !responseData.errors) {
      switch (props.action) {
        case 'add':
        case 'delete':
          updateApolloCache(props.updateCacheQuery, {
            type: props.tData as MutationDataType,
            input: options?.variables?.input,
            response: responseData.data[props.tData],
          });
          break;
      }
    } else if (options?.variables?.input && props.table && props.action && !responseData.errors) {
      const value = { ...options?.variables?.input };
      if (props.action === 'add' && props.tData) {
        const obj = responseData.data[props.tData];
        value.id = obj.id;
      }
      syncDataCache({ action: props.action, table: props.table, value: value });
    }
    return responseData;
  }

  return [mutationData, result];
}
