import * as React from 'react';
import styled from 'styled-components';
import { useTranslationText } from 'locale';

const Wrapper = styled.div``;

const Font = {
  vi: "'Open Sans', sans-serif",
  jp: "'Montserrat', sans-serif",
  en: "'Poppins', sans-serif",
};

export interface BaseTextProps {
  id?: string;
  default?: string;
  width?: string;
  height?: string;
  lineHeight?: string;
  color?: string;
  size?: string;
  fontFamily?: 'jpn' | 'eng' | 'vie';
  fontWeight?:
    | number
    | 'normal'
    | 'bold'
    | '-moz-initial'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'unset'
    | (number & {})
    | 'bolder'
    | 'lighter'
    | undefined;
  textAlign?: 'center' | 'left' | 'right';
  background?: string;
  padding?: string;
  borderRadius?: string;
  overflow?: React.CSSProperties['overflow'];
  margin?: string;
  marginTop?: string;
  marginLeft?: string;
  marginRight?: string;
  marginBottom?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

function getStyle(props: BaseTextProps) {
  const baseStyle: React.CSSProperties = {};
  props.borderRadius && (baseStyle.borderRadius = props.borderRadius);
  props.overflow && (baseStyle.overflow = props.overflow);
  props.width && (baseStyle.width = props.width);
  props.height && (baseStyle.height = props.height);
  props.color && (baseStyle.color = props.color);
  props.size && (baseStyle.fontSize = props.size);
  props.textAlign && (baseStyle.textAlign = props.textAlign);
  props.fontWeight && (baseStyle.fontWeight = props.fontWeight);
  props.background && (baseStyle.backgroundColor = props.background);
  props.padding && (baseStyle.padding = props.padding);
  props.margin && (baseStyle.margin = props.margin);
  props.marginTop && (baseStyle.marginTop = props.marginTop);
  props.marginLeft && (baseStyle.marginLeft = props.marginLeft);
  props.marginRight && (baseStyle.marginRight = props.marginRight);
  props.marginBottom && (baseStyle.marginBottom = props.marginBottom);
  props.lineHeight && (baseStyle.lineHeight = props.lineHeight);
  const style = { ...baseStyle, ...(props.style ? props.style : {}) };
  return style;
}

export const TextDisplay = (props: BaseTextProps) => {
  const { translation } = useTranslationText();
  const style = getStyle(props);

  const fontCode = localStorage.getItem('QHC_LANG') || 'ja';

  switch (fontCode) {
    case 'en':
      style.fontFamily = Font.en;
      break;
    case 'vi':
      style.fontFamily = Font.vi;
      break;
    default:
      style.fontFamily = Font.jp;
      break;
  }

  if (props.id) {
    return (
      <Wrapper {...props} style={style}>
        {translation(props.id)}
      </Wrapper>
    );
  } else {
    return (
      <Wrapper {...props} style={style}>
        {props.children ? props.children : ''}
      </Wrapper>
    );
  }
};

export const TextDisplayWithWarningEmpty = (props: BaseTextProps) => {
  const { translation } = useTranslationText();
  if (props.children && typeof props.children === 'string') {
    const value = props.children as string;
    if (value.trim().length > 0) {
      return <TextDisplay {...props} />;
    }
  }
  if (props.children && typeof props.children === 'number') {
    const value = props.children as number;
    if (value > 0) {
      return <TextDisplay {...props} />;
    }
  }
  return (
    <TextDisplay
      borderRadius={'4px'}
      textAlign={'center'}
      width={'80px'}
      size={'14px'}
      padding={'5px 10px'}
      color={'red'}
      background={'rgb(255, 0, 0,0.05)'}
      overflow={'hidden'}
    >
      {translation('emptyValue')}
    </TextDisplay>
  );
};
