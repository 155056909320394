import { History } from 'history';
import Header from 'components/common/components/Header/Header';
import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Loading } from 'vendor-deprecated/Loading';
import * as Pages from './Loadables';
import { IUser } from 'types/types';
export const UserExamSearchPage = React.lazy(
  () => import('containers/cms-interface/UserExam/containers/UserExamSearchPage'),
);

const BookingPage = React.lazy(() => import('containers/student-interface/MyBooking/index'));

type AdminLayoutProps = {
  history: History;
};

export const AdminLayout = (props: AdminLayoutProps) => {
  return (
    <>
      <Header isCMS={true} history={props.history} />

      <Switch>
        <Route path="/cms/courses/:courseId/:tabId" history={props.history} component={Pages.AuthorCourseDetails} />
        <Pages.CMSIndex history={props.history}>
          <Suspense fallback={<Loading />}>
            <Switch>
              <Route path="/cms/:courseId/syllabus-preview" component={Pages.BookPreview} />
              <Route path="/cms/exam/:examId/preview" component={Pages.CMSExamPreview} />

              {/* Setting */}
              <Route exact path="/cms/settings/home" history={props.history} component={Pages.CMSHomeSetting} />
              <Route exact path="/cms/settings/privacy" history={props.history} component={Pages.CMSPrivacy} />
              <Route exact path="/cms/settings/terms-of-service" history={props.history} component={Pages.CMSTerms} />
              <Route
                exact
                path="/cms/settings/company-profile"
                history={props.history}
                component={Pages.CMSCompanyProfile}
              />
              <Route
                exact
                path="/cms/settings/locale-translation"
                history={props.history}
                component={Pages.LocaleTranslation}
              />
              <Route
                exact
                path="/cms/settings/specified-commercial-law"
                history={props.history}
                component={Pages.CMSLawSetting}
              />
              <Route exact path="/cms/settings/articles" history={props.history} component={Pages.CMSNewsSetting} />
              <Route exact path="/cms/settings/course" history={props.history} component={Pages.CMSCourseSetting} />
              <Route exact path="/cms/settings/footer" history={props.history} component={Pages.CMSFooterSetting} />

              {/* User */}
              <Route exact path="/cms/users" history={props.history} component={Pages.UserList} />
              <Route exact path="/cms/user-exams" history={props.history} component={UserExamSearchPage} />
              <Route exact path="/cms/users/:userId" history={props.history} component={Pages.UserDetails} />
              <Route
                exact
                path="/cms/users/:userId/statistics"
                component={() => <Pages.AuthorStatistic auth={{} as IUser} />}
              />
              <Route
                exact
                path="/cms/users/:userId/:courseId/exams"
                history={props.history}
                component={Pages.UserExamResults}
              />

              <Route
                exact
                path="/cms/user-exams/:userId/:courseId"
                history={props.history}
                component={Pages.UserExamResults}
              />

              {/* Course */}
              <Route exact path="/cms/courses" history={props.history} component={Pages.CourseList} />
              <Route exact path="/cms/courses/upload" history={props.history} component={Pages.UploadCourse} />

              {/* Book */}
              <Route
                exact
                path="/cms/books"
                history={props.history}
                component={() => <Pages.BookList history={props.history} />}
              />
              <Route exact path="/cms/book/upload" history={props.history} component={Pages.UploadBook} />
              <Route
                exact
                path="/cms/books/:bookId"
                history={props.history}
                component={() => <Pages.CreateBook history={props.history} />}
              />

              {/* Question */}
              <Route exact path="/cms/questions/upload" history={props.history} component={Pages.UploadQuestion} />
              <Route
                exact
                path="/cms/questions/:questionId"
                history={props.history}
                component={() => <Pages.QuestionDetails />}
              />
              <Route
                exact
                path="/cms/questions"
                history={props.history}
                component={() => <Pages.QuestionList history={props.history} />}
              />

              {/* Help */}
              <Route
                exact
                path="/cms/helps"
                history={props.history}
                component={() => <Pages.CMSHelpsList history={props.history} />}
              />
              <Route
                path="/cms/helps/:helpId"
                history={props.history}
                component={() => <Pages.CMSHelpDetails history={props.history} />}
              />

              {/* articles */}
              <Route
                exact
                path="/cms/articles"
                history={props.history}
                component={() => <Pages.CMSNewsList history={props.history} />}
              />
              <Route exact path="/cms/articles/:postId" history={props.history} component={Pages.CMSNewsDetails} />

              {/* Service */}
              <Route
                exact
                path="/cms/services/:serviceId"
                history={props.history}
                component={Pages.CMSServiceDetails}
              />
              <Route exact path="/cms/services" history={props.history} component={Pages.CMSServiceList} />
              <Route exact path="/cms/medias" history={props.history} component={Pages.MediaList} />

              {/* Exam */}
              <Route exact path="/cms/exam/list" history={props.history} component={Pages.CMSExamList} />
              <Route path="/cms/exam/:examId/preview" component={Pages.CMSExamPreview} />
              <Route
                exact
                path="/cms/exam/:examId"
                history={props.history}
                component={() => <Pages.CMSExamDetails history={props.history} />}
              />

              {/* Order */}
              <Route exact path="/cms/orders" history={props.history} component={Pages.CMSOrdersList} />
              <Route exact path="/cms/orders/:orderId" history={props.history} component={Pages.CMSOrderDetails} />

              {/* CONTACT */}
              <Route
                exact
                path="/cms/contacts/:contactId"
                history={props.history}
                component={Pages.CMSContactDetails}
              />
              <Route exact path="/cms/contacts" history={props.history} component={Pages.CMSContactList} />

              {/* REPORT */}
              <Route exact path="/cms/reports/:reportId" history={props.history} component={Pages.CMSReportDetail} />
              <Route exact path="/cms/reports" history={props.history} component={Pages.CMSReportList} />

              {/* PROMOTION */}
              <Route exact path="/cms/events/:eventId" history={props.history} component={Pages.CMSEventDetails} />
              <Route exact path="/cms/events" history={props.history} component={Pages.CMSEventsList} />
              <Route exact path="/cms/marketing" history={props.history} component={Pages.CMSMarketing} />
              <Route exact path="/cms/marketing/:mkId" history={props.history} component={Pages.CMSMarketingDetail} />

              <Route exact path="/cms/access/:mkId" component={Pages.CMSAccessList} />
              <Route exact path="/cms/access-log" component={Pages.CMSAccessLog} />

              {/* <Route exact path="/cms/schedule" history={props.history} component={() => <Pages.CMSSchedule />} /> */}

              <Route exact path="/cms/schedules" history={props.history} component={() => <Pages.CMSSchedule />} />
              <Route exact path="/cms/bookings" history={props.history} component={BookingPage} />
              <Route exact path="/cms" history={props.history} component={Pages.EmptyPage} />

              <Route component={Pages.NotFoundPage} />
            </Switch>
          </Suspense>
        </Pages.CMSIndex>
      </Switch>
    </>
  );
};

export default AdminLayout;
