import { useBaseGetQuery } from 'api/base/useBaseGetQuery';
import { notificationQueries } from 'api/notification';

export const useGetUnreadNotificationCount = () => {
  const { getQuery, item } = useBaseGetQuery<undefined, number>({
    fetchPolicy: 'cache-and-network',
    query: notificationQueries.GET_UNREAD,
  });

  return {
    getUnreadNotificationCount: getQuery,
    unreadCount: item,
  };
};
