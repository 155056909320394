import { Div } from 'components/common/vendors/Wrapper';
import { Text } from 'components/common/vendors/Text';
import { useGetUnreadNotificationCount } from 'hooks/api/notification/useGetUnreadNotificationCount';
import React from 'react';
import { useAppSetting } from 'helpers/useAppSetting';

type PropsType = {
  width?: string;
  height?: string;
};

export const NotificationCount = (props: PropsType) => {
  const { appSize } = useAppSetting();
  const { getUnreadNotificationCount, unreadCount } = useGetUnreadNotificationCount();

  React.useEffect(() => {
    getUnreadNotificationCount();
  }, []);

  return (
    <Div
      display={unreadCount > 0 ? 'flex' : 'none'}
      width={props.width}
      height={props.height}
      minWidth="20px"
      minHeight="20px"
      background={'red'}
      alignContent="center"
      borderRadius="50%"
    >
      <Text fontSize={appSize.h5} color={'white'} fontWeight={'bold'}>{`${unreadCount}`}</Text>
    </Div>
  );
};
