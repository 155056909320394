import * as React from 'react';
import { HorizontalView, VerticalView } from '.';

export const Body = (props: { maxWidth?: string; padding?: string; children: React.ReactNode }) => {
  return (
    <HorizontalView fullWidthHeight alignContent={'topCenter'}>
      <VerticalView fullWidth maxWidth={props.maxWidth || '1000px'} padding={props.padding || '0px'}>
        <VerticalView fullWidthHeight alignContent="center">
          {props.children}
        </VerticalView>
      </VerticalView>
    </HorizontalView>
  );
};
