import React from 'react';
import styled from 'styled-components';
import { History } from 'history';
import { HorizontalView } from 'vendor-deprecated/Wrapper';
import { AppColors, AppConst } from 'helpers';
import { Button } from 'vendor-deprecated/Button';
import { IUser } from 'types/types';
import { useTranslationText } from 'locale';
import { ConfirmModal } from '../Modals/ConfirmModal';
import { useUserApi } from 'api/user/useUserApi';
import { AnimationView } from 'vendor-deprecated/Animation';
import { NaviDropdown, DropdownProps, ItemProps } from 'vendor-deprecated/NaviDropdown';
import { useWindowDimensions } from 'vendor-deprecated/Hooks/useWindow/useWindowDimensions';
import { useLocation } from 'react-router';
import { CartButton } from '../Cart/CartButton';
import { useAppSetting } from 'helpers/useAppSetting';
import { isManagerCourseDetailsPage } from 'helpers/commonUtils';
import { ChangeLanguage } from '../ChangeLanguage';
import { useSession } from 'session/useSession';
import { Div } from 'components/common/vendors/Wrapper';
import { NotificationCount } from '../NotificationCount';

const MenuWrapper = styled.div<{ isCMS: boolean }>`
  z-index: 20;
  padding-left: 10px;
  padding-right: 10px;
  display: ${(props) => (props.isCMS ? 'none' : 'block')};
`;

type HeaderSearchBarProps = {
  isSessionLoaded?: boolean;
  auth?: IUser;
  isCMS: boolean;
  isMobile: boolean;
  path: string;
  history: History;
};

export function getLang(getTypes: (path: string) => ItemProps[]) {
  const lang = localStorage['QHC_LANG'] ? localStorage['QHC_LANG'] : 'ja';
  const menus = getTypes('languages');
  const languagesMenu: DropdownProps = {
    prefixIcon: `/images/lang_${lang}.png`,
    subMenus: menus,
  };
  return languagesMenu;
}

function getLeftMenus(translation: (path: string) => string) {
  const userMenu = [
    {
      prefixIcon: 'book',
      label: translation('common.topMenu.course'),
      url: '/courses',
    },
    {
      prefixIcon: 'file',
      label: translation('common.topMenu.news'),
      url: '/articles',
    },
    {
      prefixIcon: 'service',
      label: translation('common.topMenu.service'),
      url: '/services',
    },
    {
      prefixIcon: 'clock',
      label: translation('common.topMenu.booking'),
      url: '/booking',
    },
  ];

  return userMenu as ItemProps[];
}

const RightMenu = (props: HeaderSearchBarProps) => {
  const { translation } = useTranslationText();
  const { logout } = useUserApi();
  const { appTheme } = useAppSetting();
  const [confirmLogout, setConfirmLogout] = React.useState(false);

  let isCms = false;
  const textColor = appTheme.header.text;

  if (
    location.pathname.includes('/author/') ||
    location.pathname.includes('/cms') ||
    location.pathname.includes('/user')
  ) {
    isCms = true;
    // textColor = AppColors.DARK;
  }

  return (
    <HorizontalView alignContent={'centerRight'} flexWrap={'nowrap'}>
      <HorizontalView display={!props.isSessionLoaded || props.auth?.id ? 'none' : 'flex'}>
        <Button
          fontWeight={500}
          fontSize={'20px'}
          text={translation('mobileSideBar.forAuthor')}
          color={textColor}
          background={AppColors.TRANSPARENT}
          onClick={() => props.history.push('/teach-apply')}
        />
      </HorizontalView>
      <HorizontalView display={!props.isSessionLoaded || props.auth?.id ? 'none' : 'flex'}>
        <Button
          fontWeight={500}
          fontSize={'20px'}
          text={translation('newRegister')}
          color={textColor}
          background={AppColors.TRANSPARENT}
          onClick={() => props.history.push('/register')}
        />
      </HorizontalView>
      <HorizontalView display={!props.isSessionLoaded || props.auth?.id ? 'none' : 'flex'}>
        <Button
          fontWeight={500}
          fontSize={'20px'}
          text={translation('mobileSideBar.login')}
          color={textColor}
          background={AppColors.TRANSPARENT}
          onClick={() => props.history.push('/login')}
        />
      </HorizontalView>
      <HorizontalView display={!props.isSessionLoaded || !props.auth?.id ? 'none' : 'flex'}>
        <Button
          icon={'user'}
          fontWeight={500}
          size={'25px'}
          color={location.pathname === '/author/profile' ? 'white' : textColor}
          background={AppColors.TRANSPARENT}
          onClick={() => {
            if (props.auth?.role === 'ADMIN') {
              props.history.push(`/cms/users/${props.auth.id}`);
            } else if (props.auth?.role === 'AUTHOR') {
              props.history.push('/author/profile');
            } else {
              props.history.push('/user/my-profile');
            }
          }}
        />
      </HorizontalView>
      <HorizontalView display={!props.isSessionLoaded || !props.auth?.id ? 'none' : 'flex'}>
        <Div
          onClick={() => {
            if (props.auth?.role === 'AUTHOR') {
              props.history.push('/author/notification');
            } else if (props.auth?.role === 'USER') {
              props.history.push('/user/notification');
            }
          }}
        >
          <NotificationCount width="25px" height="25px" />
        </Div>
      </HorizontalView>

      {props.auth?.role === 'USER' && (
        <CartButton size={isCms ? '15px' : '25px'} color={location.pathname === '/user' ? 'white' : textColor} />
      )}

      {props.auth?.id && (
        <HorizontalView>
          <HorizontalView height={'30px'} width={'1px'} background={textColor} marginLeft={'5px'} marginRight={'5px'} />
          <Button
            icon={'log-out'}
            size={isCms ? '15px' : '25px'}
            color={location.pathname === '/author/profile' ? 'white' : textColor}
            background={AppColors.TRANSPARENT}
            onClick={() => {
              setConfirmLogout(true);
            }}
          />
        </HorizontalView>
      )}
      <HorizontalView height={'30px'} width={'1px'} background={textColor} marginLeft={'5px'} marginRight={'15px'} />

      <ChangeLanguage showLabel={false} color={location.pathname === '/author/profile' ? 'white' : textColor} />
      {/* <NaviDropdown
        {...getLang(getTypes)}
        direction={'left'}
        size={'13px'}
        prefixIconSize={isCms ? '15px' : '20px'}
        onClick={(menu) => {
          if (menu.value) {
            localStorage['QHC_LANG'] = menu.value;
            window.location.reload();
          }
        }}
      /> */}
      <ConfirmModal
        open={confirmLogout}
        title={translation('modals.confirmLogout.title')}
        cancelTitle={translation('modals.confirmLogout.close')}
        confirmTitle={translation('modals.confirmLogout.confirm')}
        onClose={() => setConfirmLogout(false)}
        onConfirm={async () => {
          setConfirmLogout(false);
          await logout.logoutUser();
          props.history.push('/');
        }}
      />
    </HorizontalView>
  );
};

export const WebHeaderView = (props: HeaderSearchBarProps) => {
  const { translation, getLanguage } = useTranslationText();
  const location = useLocation();
  const { session } = useSession();
  const { windowDimensions } = useWindowDimensions();
  const { appTheme, appLayout } = useAppSetting();
  const publicMenus = getLeftMenus(translation);

  if (props.isMobile) {
    return <HorizontalView display={'none'} />;
  }

  let isCms = false;

  if (
    location.pathname.includes('/author/') ||
    location.pathname.includes('/cms') ||
    location.pathname.includes('/user')
  ) {
    if (!location.pathname.includes('/user/exam')) {
      isCms = true;
    }
  }

  const isShowTopMenu = () => {
    return (
      location.pathname.includes('/user/') &&
      !location.pathname.includes('author') &&
      !location.pathname.includes('cms') &&
      !location.pathname.includes('/user/exam/') &&
      !location.pathname.includes('/user/lecture/')
    );
  };

  const isShowBackButton = () => {
    return (
      isManagerCourseDetailsPage(location.pathname) ||
      location.pathname.includes('/user/lecture/') ||
      (location.pathname.includes('/user/courses/') && location.pathname.includes('/exams'))
    );
  };

  return (
    <HorizontalView
      fullWidthHeight
      alignContent={'centerBetween'}
      zIndex={AppConst.zIndex.header + 500}
      overflow={'hidden'}
      paddingLeft={'20px'}
      paddingRight={'20px'}
      flexWrap={'nowrap'}
    >
      <HorizontalView alignContent={'centerBetween'}>
        {!isCms && (
          <Button
            marginBottom={'3px'}
            fontWeight={500}
            fontSize={'30px'}
            color={appTheme.header.text}
            text={'Vietcademy'}
            background={AppColors.TRANSPARENT}
            onClick={() => props.history.push('/')}
          />
        )}

        {isShowBackButton() && (
          <Button
            icon={'arrow-left'}
            size={'20px'}
            fontWeight={500}
            fontSize={'15px'}
            text={translation('back')}
            color={appTheme.header.text}
            background={AppColors.TRANSPARENT}
            onClick={() => {
              if (location.pathname.includes('/user/')) {
                props.history.push('/user/courses');
              } else if (location.pathname.includes('/cms')) {
                props.history.push('/cms/courses');
              } else {
                props.history.push('/author/courses');
              }
            }}
          />
        )}

        <HorizontalView>
          {isShowTopMenu() && (
            <HorizontalView
              paddingLeft={location.pathname.includes('/user/lecture') ? '0px' : appLayout.sidebar.minWidth}
            >
              {publicMenus.map((menu, i) => {
                return (
                  <HorizontalView margin={'5px'} key={i} width={windowDimensions.width <= 1100 ? '50px' : 'auto'}>
                    <NaviDropdown
                      {...menu}
                      label={windowDimensions.width <= 1100 ? '' : menu.label}
                      color={'dark'}
                      size={'13px'}
                      isHover={true}
                      onClick={(menu) => {
                        props.history.push(menu.url!);
                      }}
                    />
                  </HorizontalView>
                );
              })}
            </HorizontalView>
          )}
          <MenuWrapper isCMS={props.isCMS || location.pathname.includes('/user')}>
            <AnimationView animation={'fadeIn'}>
              <HorizontalView width={'100%'} alignContent={'center'}>
                <HorizontalView width="auto" alignContent={'centerAround'} flexWrap={'wrap'}>
                  {!isCms &&
                    publicMenus.map((menu, i) => {
                      const isSelect = menu.url ? location.pathname.includes(menu.url) : false;
                      const hideLabel = () => {
                        if (getLanguage() === 'ja') {
                          return windowDimensions.width <= 1100;
                        } else {
                          if (session.isAuthenticated) {
                            return windowDimensions.width <= 1100;
                          }
                          return windowDimensions.width <= 1250;
                        }
                      };

                      return (
                        <HorizontalView margin={'5px'} key={i} width={hideLabel() ? '50px' : 'auto'}>
                          <NaviDropdown
                            {...menu}
                            label={hideLabel() ? '' : menu.label}
                            color={appTheme.header.text}
                            isHover={true}
                            isBolder={isSelect}
                            onClick={(menu) => {
                              props.history.push(menu.url!);
                            }}
                          />
                        </HorizontalView>
                      );
                    })}
                </HorizontalView>
              </HorizontalView>
            </AnimationView>
          </MenuWrapper>
        </HorizontalView>
      </HorizontalView>
      <RightMenu {...props} />
    </HorizontalView>
  );
};
