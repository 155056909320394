import React from 'react';
import { useLazyQuery, QueryLazyOptions, useMutation } from '@apollo/client';
import { userMutations, userQueries } from './index';
import { useQueryData } from '../apollo/query';
import { useMutationData } from '../apollo/mutation';
import {
  IDeleteUserInput,
  ICreateUserInput,
  IRegisterUserInput,
  IMutationRegisterArgs,
  IUpdateUserInput,
  IMutationUpdateUserArgs,
  IUser,
  IListUserInput,
  ILoginUserInput,
  IGetUserInput,
  IQueryGetUserArgs,
  IMutationCreateUserArgs,
  IMutationDeleteUserArgs,
  IQueryListUsersArgs,
  IGetUserCourseInput,
  IQueryGetUserCourseArgs,
  IUserCourse,
  IChangePasswordUserInput,
  IMutationChangePasswordArgs,
  IResetPasswordInput,
  IMutationResetPasswordArgs,
  IResetConfirmInput,
  IMutationResetConfirmArgs,
  IConfirmInput,
  IMutationConfirmArgs,
  IGetAuthorProfileInput,
  IListUserCourseData,
  IQueryListUserCoursesArgs,
  IListUserCoursesInput,
  IOperationSearchUsersInput,
  ISortDirection,
} from 'types/types';

import { IError, ISuccess } from 'types/Models';
import { useTranslationText } from 'locale';
import client from 'config/apollo';
import { useSession } from 'session/useSession';
import { useBaseSearchQuery } from 'api/base/useBaseSearchQuery';

interface Callback {
  checkAuth: (options?: QueryLazyOptions<Record<string, any>> | undefined) => void;
  logout: {
    loading: boolean;
    logoutUser: () => Promise<{ error?: IError; success?: ISuccess }>;
  };
  login: {
    loading: boolean;
    loginUser: (input: ILoginUserInput) => Promise<{ user?: IUser; error?: IError; success?: ISuccess }>;
  };
  create: {
    loading: boolean;
    createUser: (input: ICreateUserInput) => Promise<{ id?: string; error?: IError; success?: ISuccess }>;
  };
  register: {
    loading: boolean;
    registerUser: (input: IRegisterUserInput) => Promise<{ error?: IError; success?: ISuccess }>;
  };
  update: {
    loading: boolean;
    updateUser: (input: IUpdateUserInput) => Promise<{ updated?: boolean; error?: IError; success?: ISuccess }>;
  };
  delete: {
    loading: boolean;
    deleteUser: (input: IDeleteUserInput) => Promise<{ deleted?: boolean; error?: IError; success?: ISuccess }>;
  };
  verifyAccount: {
    loading: boolean;
    confirm: (input: IConfirmInput) => Promise<{ error?: IError; success?: ISuccess }>;
  };
  resetPassword: {
    loading: boolean;
    resetPassword: (input: IResetPasswordInput) => Promise<{ error?: IError; success?: ISuccess }>;
  };
  resetConfirm: {
    loading: boolean;
    resetConfirm: (input: IResetConfirmInput) => Promise<{ error?: IError; success?: ISuccess }>;
  };
  changePassword: {
    loading: boolean;
    changePassword: (input: IChangePasswordUserInput) => Promise<{ error?: IError; success?: ISuccess }>;
  };
  get: {
    loading: boolean;
    error?: any;
    user?: IUser;
    getUser: (input: IGetUserInput) => void;
  };
  listUserCourses: {
    loading: boolean;
    userCourses: IUserCourse[];
    listUserCourses: (input: IListUserCoursesInput) => void;
  };
  getUserCourse: {
    loading: boolean;
    userCourse?: IUserCourse;
    getUserCourse: (input: IGetUserCourseInput) => void;
    asyncGetUserCourse: (input: IGetUserCourseInput) => Promise<IUserCourse | null>;
  };
  getProfile: {
    loading: boolean;
    error?: any;
    user?: IUser;
    getUserProfile: (input: IGetUserInput) => void;
  };
  getAuthorProfile: {
    loading: boolean;
    error?: any;
    user?: IUser;
    getAuthorProfile: (input: IGetAuthorProfileInput) => void;
  };
  list: {
    loading: boolean | false;
    users: IUser[];
    listUser: (input: IListUserInput) => void;
  };
}

export const useUserApi = (): Callback => {
  const { getMessage } = useTranslationText();
  const { dispatch } = useSession();

  //MARK: GET QURERY
  const [checkAuth] = useLazyQuery<{ checkAuth: IUser }>(userQueries.CHECK_AUTH, { fetchPolicy: 'network-only' });

  //MARK: GET QURERY
  const [getUser, { data: dataGet, loading: getLoading }] = useQueryData<{ getUser: IUser }, IQueryGetUserArgs>({
    table: 'user',
    action: 'get',
    tData: 'getUser',
    fetchPolicy: 'cache-and-network',
    query: userQueries.GET,
  });

  const [listUserCourses, { data: dataListUserCourses, loading: listUserCourseLoading }] = useQueryData<
    { listUserCourses: IListUserCourseData },
    IQueryListUserCoursesArgs
  >({
    table: 'user',
    action: 'list',
    tData: 'listUserCourses',
    fetchPolicy: 'cache-and-network',
    query: userQueries.LIST_USER_COURSE,
  });

  const [getUserCourse, { data: dataGetUserCourse, loading: getUserCourseLoading }] = useQueryData<
    { getUserCourse: IUserCourse },
    IQueryGetUserCourseArgs
  >({
    table: 'user',
    action: 'get',
    tData: 'getUserCourse',
    fetchPolicy: 'cache-and-network',
    query: userQueries.GET_USER_COURSE,
  });

  const [getUserProfile, { data: dataGetProfile, loading: getLoadingProfile }] = useQueryData<
    { getUserProfile: IUser },
    IQueryGetUserArgs
  >({
    table: 'user',
    action: 'get',
    tData: 'getUserProfile',
    fetchPolicy: 'cache-first',
    query: userQueries.GET_PROFILE,
  });

  const [getAuthorProfile, { data: dataGetAuthorProfile, loading: getAuthorProfileLoading }] = useQueryData<
    { getAuthorProfile: IUser },
    IQueryGetUserArgs
  >({
    table: 'user',
    action: 'get',
    tData: 'getAuthorProfile',
    fetchPolicy: 'cache-and-network',
    query: userQueries.GET_AUTHOR_PROFILE,
  });

  //MARK: GET LIST
  const [listUsers, { data: dataList, loading: loadingList }] = useQueryData<
    { listUsers: IUser[] },
    IQueryListUsersArgs
  >({
    table: 'user',
    action: 'list',
    tData: 'listUsers',
    fetchPolicy: 'cache-and-network',
    query: userQueries.LIST,
  });

  //MARK LOGIN MUTATION
  const [login, { loading: loginLoading }] = useMutationData({
    table: 'user',
    action: 'login',
    tData: 'login',
    mutation: userMutations.LOG_IN,
  });

  //MARK LOGOUT MUTATION
  const [logout, { loading: logoutLoading }] = useMutationData<{ login: IUser }>({
    table: 'user',
    action: 'logout',
    tData: 'logout',
    mutation: userMutations.LOG_OUT,
  });

  //MARK: CREATE USER MUTATION
  const [createUser, { loading: createLoading }] = useMutationData<{ createUser: string }, IMutationCreateUserArgs>({
    mutation: userMutations.CREATE,
    table: 'user',
    action: 'add',
    tData: 'createUser',
  });

  const [registerUser, { loading: registerLoading }] = useMutation<{ registerUser: string }, IMutationRegisterArgs>(
    userMutations.REGISTER,
  );

  //MARK: UPDATE USER MUTATION
  const [updateUser, { loading: updateLoading }] = useMutationData<{ updateUser: boolean }, IMutationUpdateUserArgs>({
    mutation: userMutations.UPDATE,
    table: 'user',
    action: 'update',
  });

  //MARK: DELETE USER MUTATION
  const [deleteUser] = useMutationData<{ deleteUser: boolean }, IMutationDeleteUserArgs>({
    mutation: userMutations.DELETE,
    table: 'user',
    action: 'delete',
  });

  //MARK: CHANGE PASS USER MUTATION
  const [changePassword, { loading: changePassLoading }] = useMutationData<
    { changePassword: boolean },
    IMutationChangePasswordArgs
  >({
    mutation: userMutations.CHANGE_PASS,
    table: 'user',
    action: 'update',
  });

  //MARK: RESET PASS USER MUTATION
  const [resetPassword, { loading: resetPassLoading }] = useMutationData<
    { resetPassword: boolean },
    IMutationResetPasswordArgs
  >({
    mutation: userMutations.RESET_PASS,
    table: 'user',
    action: 'update',
  });

  //MARK: RESET PASS CONFIRM MUTATION
  const [resetConfirm, { loading: resetConfirmLoading }] = useMutationData<
    { resetConfirm: boolean },
    IMutationResetConfirmArgs
  >({
    mutation: userMutations.RESET_PASS_CONFIRM,
    table: 'user',
    action: 'update',
  });

  //MARK: Verify account
  const [confirm, { loading: confirmLoading }] = useMutationData<{ confirm: boolean }, IMutationConfirmArgs>({
    mutation: userMutations.CONFIRM,
    table: 'user',
    action: 'update',
  });

  /********************** CUSTOM FUNCTION ************************* */

  //MARK:CREATE FUNCTION
  async function mutationLoginUser(
    input: ILoginUserInput,
  ): Promise<{ user?: IUser; error?: IError; success?: ISuccess }> {
    try {
      const loginData = await login({ variables: { input } });
      return { user: loginData.data?.login };
    } catch (error) {
      return { error: getMessage(error.message, 'error') };
    }
  }

  //MARK:CREATE FUNCTION
  async function mutationLogoutUser(): Promise<{ success?: ISuccess; error?: IError }> {
    try {
      await logout();
      client.writeQuery({ query: userQueries.CHECK_AUTH, data: null });
      localStorage.setItem('QHC_LOGIN_TOKEN', '');
      dispatch({ type: 'logout' });
      await client.clearStore();
      return { success: { code: '0' } as ISuccess };
    } catch (error) {
      return { error: getMessage(error.message, 'error') };
    }
  }

  //MARK:CREATE FUNCTION
  async function mutationCreateUser(
    user: ICreateUserInput,
  ): Promise<{ id?: string; error?: IError; success?: ISuccess }> {
    try {
      const create = await createUser({ variables: { input: user } });
      return { id: create.data?.createUser, success: getMessage('0', 'success') };
    } catch (error) {
      return { error: getMessage(error.message, 'error') };
    }
  }

  async function mutationRegisterUser(
    user: IRegisterUserInput,
  ): Promise<{ id?: string; error?: IError; success?: ISuccess }> {
    try {
      await registerUser({ variables: { input: user } });
      return { success: getMessage('0', 'success') };
    } catch (error) {
      return { error: getMessage(error.message, 'error') };
    }
  }

  //MARK:UPDATE FUNCTION
  async function mutationUpdateUser(
    user: IUpdateUserInput,
  ): Promise<{ updated?: boolean; error?: IError; success?: ISuccess }> {
    try {
      await updateUser({ variables: { input: user } });
      return { updated: true, success: getMessage('0', 'success') };
    } catch (error) {
      return { error: getMessage(error.message, 'error') };
    }
  }

  //MARK:CREATE FUNCTION
  async function mutationDeleteUser(
    deleteInput: IDeleteUserInput,
  ): Promise<{ deleted?: boolean; error?: IError; success?: ISuccess }> {
    try {
      await deleteUser({ variables: { input: deleteInput } });
      return { deleted: true, success: getMessage('0', 'success') };
    } catch (error) {
      return { error: getMessage(error.message, 'error') };
    }
  }

  //MARK:Change pass FUNCTION
  async function mutationChangePassword(
    input: IChangePasswordUserInput,
  ): Promise<{ error?: IError; success?: ISuccess }> {
    try {
      await changePassword({ variables: { input: input } });
      return { success: getMessage('0', 'success') };
    } catch (error) {
      return { error: getMessage(error.message, 'error') };
    }
  }

  //MARK:Reset pass FUNCTION
  async function mutationResetConfirmPassword(
    input: IResetConfirmInput,
  ): Promise<{ error?: IError; success?: ISuccess }> {
    try {
      await resetConfirm({ variables: { input: input } });
      return { success: getMessage('0', 'success') };
    } catch (error) {
      return { error: getMessage(error.message, 'error') };
    }
  }

  //MARK:Reset pass FUNCTION
  async function mutationResetPassword(
    input: IResetPasswordInput,
  ): Promise<{ deleted?: boolean; error?: IError; success?: ISuccess }> {
    try {
      await resetPassword({ variables: { input: input } });
      return { success: getMessage('0', 'success') };
    } catch (error) {
      return { error: getMessage(error.message, 'error') };
    }
  }

  //MARK:Verify account
  async function mutationConfirm(
    input: IConfirmInput,
  ): Promise<{ deleted?: boolean; error?: IError; success?: ISuccess }> {
    try {
      await confirm({ variables: { input: input } });
      return { success: getMessage('0', 'success') };
    } catch (error) {
      return { error: getMessage(error.message, 'error') };
    }
  }

  async function queryGetUser(getInput: IGetUserInput) {
    getUser({ variables: { input: getInput } });
  }

  async function queryGetUserCourse(getInput: IGetUserCourseInput) {
    getUserCourse({ variables: { input: getInput } });
  }

  async function asyncGetUserCourse(input: IGetUserCourseInput) {
    try {
      const { data } = await client.query<{ getUserCourse: IUserCourse }>({
        query: userQueries.GET_USER_COURSE,
        variables: { input: input },
      });
      return data?.getUserCourse ? data.getUserCourse : null;
    } catch (error) {
      return null;
    }
  }

  async function queryGetUserProfile(getInput: IGetUserInput) {
    getUserProfile({ variables: { input: getInput } });
  }

  async function queryGetAuthorProfile(getInput: IGetAuthorProfileInput) {
    getAuthorProfile({ variables: { input: getInput } });
  }

  //MARK:GET FUNCTION
  function queryListUser(listIput: IListUserInput) {
    listUsers({ variables: { input: listIput } });
  }

  function queryListUserCourses(getInput: IListUserCoursesInput) {
    listUserCourses({ variables: { input: getInput } });
  }

  return {
    checkAuth: checkAuth,
    logout: {
      loading: logoutLoading,
      logoutUser: mutationLogoutUser,
    },
    login: {
      loading: loginLoading,
      loginUser: mutationLoginUser,
    },
    update: {
      loading: updateLoading,
      updateUser: mutationUpdateUser,
    },
    create: {
      loading: createLoading,
      createUser: mutationCreateUser,
    },
    register: {
      loading: registerLoading,
      registerUser: mutationRegisterUser,
    },
    delete: {
      loading: createLoading,
      deleteUser: mutationDeleteUser,
    },
    changePassword: {
      loading: changePassLoading,
      changePassword: mutationChangePassword,
    },
    resetPassword: {
      loading: resetPassLoading,
      resetPassword: mutationResetPassword,
    },
    resetConfirm: {
      loading: resetConfirmLoading,
      resetConfirm: mutationResetConfirmPassword,
    },
    verifyAccount: {
      loading: confirmLoading,
      confirm: mutationConfirm,
    },
    get: {
      user: dataGet?.getUser,
      loading: dataGet?.getUser ? false : getLoading,
      getUser: queryGetUser,
    },
    listUserCourses: {
      userCourses: (dataListUserCourses?.listUserCourses?.results || []) as IUserCourse[],
      loading: dataListUserCourses?.listUserCourses ? false : listUserCourseLoading,
      listUserCourses: queryListUserCourses,
    },
    getUserCourse: {
      userCourse: dataGetUserCourse?.getUserCourse,
      loading: dataGetUserCourse?.getUserCourse ? false : getUserCourseLoading,
      getUserCourse: queryGetUserCourse,
      asyncGetUserCourse,
    },
    getProfile: {
      user: dataGetProfile?.getUserProfile,
      loading: dataGetProfile?.getUserProfile ? false : getLoadingProfile,
      getUserProfile: queryGetUserProfile,
    },
    getAuthorProfile: {
      user: dataGetAuthorProfile?.getAuthorProfile,
      loading: dataGetAuthorProfile?.getAuthorProfile ? false : getAuthorProfileLoading,
      getAuthorProfile: queryGetAuthorProfile,
    },
    list: {
      loading: (dataList?.listUsers || []).length > 0 ? false : loadingList,
      users: dataList?.listUsers || [],
      listUser: queryListUser,
    },
  };
};

export const useOperationSearchUsers = () => {
  const query = useBaseSearchQuery<IOperationSearchUsersInput, IUser>({
    defaulInput: {
      page: 1,
      sortDirection: ISortDirection.Asc,
      limit: 10,
    } as IOperationSearchUsersInput,
    fetchPolicy: 'cache-and-network',
    query: userQueries.OPERATION_SEARCH_USER,
  });

  /**
   * Call api
   */
  React.useEffect(() => {
    query.onSearch(query.input);
  }, []);

  return {
    loading: query.loading,
    error: query.error,
    query: query.input,
    page: query.page,
    hasMore: query.hasMore,
    totalItems: query.totalItems,
    totalPages: query.totalPages,
    limit: query.limit,
    users: query.items,
    onChangePage: query.onChangePage,
    onChangeLimit: query.onChangeLimit,
    onSearchUsers: query.onSearch,
    onLoadMoreUsers: query.onLoadMore,
    onDeleteUserInList: query.onDeleteItem,
  };
};
