export default {
  graphql: {
    uri: '/graphql',
  },
  UI_HOST_URL: 'http://localhost:3000',
  HOST_URL: 'http://localhost:3000',
  API_HOST_URL: 'http://localhost:5003',
  STRIPE_PUBLISH:
    'pk_test_51IpWGPHZr2kCozyWrl0VG59yKToKcCBh4djjmu1v50yQ30pV9PEG2i1Hyj1QmlynDvBEAxFq4qUGokplz44Nw2ZD001RLKWGGN',
  FACEBOOK_APP_ID: '1777256802456199',
  tickets: {
    ticket_1: 'prod_KoHVMAwQx4r3W0',
    ticket_3: 'prod_KoHVdQcALXT4jB',
    ticket_5: 'prod_KoHWSwa77adtax',
    ticket_10: 'prod_KoHWyab9Rej35P',
  },
};
