import React from 'react';
import { Actions, SessionContext, SessionState } from './SessionProvider';
import { useApolloClient } from '@apollo/client';
import { userQueries } from 'api/user';
import { IUser } from 'types/types';

type UseSession = () => {
  session: SessionState;
  reauthenticate: () => Promise<boolean>;
  dispatch: React.Dispatch<Actions>;
};

export const useSession: UseSession = () => {
  const client = useApolloClient();

  const context = React.useContext(SessionContext);

  const reauthenticate = async (): Promise<boolean> => {
    const token = localStorage.getItem('QHC_LOGIN_TOKEN');
    const { data } = await client.query<{ checkAuth: IUser }>({
      query: userQueries.CHECK_AUTH,
      fetchPolicy: 'network-only',
    });

    if (data && data.checkAuth) {
      const user = {
        id: data.checkAuth.id,
        role: data.checkAuth.role,
        isAuthorVerified: data.checkAuth.isAuthorVerified,
        customerId: data.checkAuth?.customerId,
      } as IUser;
      context.dispatch({ type: 'update', payload: { user: user, isAuthenticated: true, token: token } });

      return true;
    } else {
      context.dispatch({ type: 'delete-session' });
    }

    return false;
  };
  return { session: context.session, dispatch: context.dispatch, reauthenticate };
};
