import React from 'react';
import { History } from 'history';
import { HorizontalView, VerticalView } from 'vendor-deprecated/Wrapper';
import { Button } from 'vendor-deprecated/Button';
import { MobileSideBar } from './MobileSideBar';
import { MobileCmsSideBar } from './MobileCmsSideBar';
import { AppColors } from 'helpers';
import { TextDisplay } from 'vendor-deprecated/Text';
import { IUser } from 'types/types';
import { ConfirmModal } from '../Modals/ConfirmModal';
import { useUserApi } from 'api/user/useUserApi';
import { useTranslationText } from 'locale';
import { CartButton } from '../Cart/CartButton';
import { isManagerCourseDetailsPage } from 'helpers/commonUtils';
import { useTitle } from 'hooks/useTitle/useTitle';
import { useAppSetting } from 'helpers/useAppSetting';
import { NotificationCount } from '../NotificationCount';
import { Div } from 'components/common/vendors/Wrapper';

type HeaderSearchBarProps = {
  isSessionLoaded?: boolean;
  auth?: IUser;
  isCMS: boolean;
  isMobile: boolean;
  path: string;
  history: History;
};

export const MobileHeaderView = (props: HeaderSearchBarProps) => {
  const { logout } = useUserApi();
  const { appTheme } = useAppSetting();
  const { translation } = useTranslationText();
  const [confirmLogout, setConfirmLogout] = React.useState(false);
  const [openSidebar, setOpenSidebar] = React.useState(false);
  if (!props.isMobile) {
    return <HorizontalView display={'none'} />;
  }

  return (
    <VerticalView fullWidth alignContent={'centerLeft'} zIndex={20} paddingBottom={'10px'} paddingTop={'10px'}>
      <HorizontalView fullWidth alignContent={'centerBetween'}>
        <HorizontalView>
          {isManagerCourseDetailsPage(location.pathname) && (
            <Button
              icon={'arrow-left'}
              size={'20px'}
              fontWeight={600}
              fontSize={'15px'}
              text={translation('back')}
              color={appTheme.header.text}
              background={AppColors.TRANSPARENT}
              onClick={() => {
                if (location.pathname.includes('/cms')) {
                  props.history.push('/cms/courses');
                } else {
                  props.history.push('/author/courses');
                }
              }}
            />
          )}
          {!isManagerCourseDetailsPage(location.pathname) && (
            <HorizontalView>
              <Button
                icon={'menu'}
                ariaLabel={'display sidebar menu'}
                size={'30px'}
                color={appTheme.header.text}
                background={AppColors.TRANSPARENT}
                onClick={() => {
                  setOpenSidebar(true);
                }}
              />

              <HorizontalView>
                <HeaderTitle />
              </HorizontalView>
            </HorizontalView>
          )}
        </HorizontalView>

        <HorizontalView paddingRight={'10px'}>
          {!props.auth?.id && (
            <Button
              icon={'user'}
              ariaLabel={'display user profile'}
              size={'25px'}
              color={appTheme.header.text}
              background={AppColors.TRANSPARENT}
              onClick={() => {
                props.history.push('/login');
              }}
            />
          )}

          {props.auth?.id && (
            <>
              <Button
                icon={'user'}
                ariaLabel={'display user profile'}
                size={'20px'}
                color={appTheme.header.text}
                background={AppColors.TRANSPARENT}
                onClick={() => {
                  if (props.auth?.role === 'ADMIN') {
                    props.history.push(`/cms/users/${props.auth.id}`);
                  } else if (props.auth?.role === 'AUTHOR') {
                    props.history.push('/author/profile');
                  } else {
                    props.history.push('/user/my-profile');
                  }
                }}
              />
              <Div
                onClick={() => {
                  if (props.auth?.role === 'AUTHOR') {
                    props.history.push('/author/notification');
                  } else if (props.auth?.role === 'USER') {
                    props.history.push('/user/notification');
                  }
                }}
              >
                <NotificationCount />
              </Div>
              {props.auth?.role === 'USER' && (
                <CartButton size={'20px'} color={location.pathname.includes('/user') ? 'white' : 'black'} />
              )}
            </>
          )}
        </HorizontalView>
      </HorizontalView>

      <MobileSideBar
        open={openSidebar && !props.isCMS}
        history={props.history}
        auth={props.auth}
        onClose={() => setOpenSidebar(false)}
      />
      <MobileCmsSideBar
        open={openSidebar && props.isCMS}
        history={props.history}
        auth={props.auth}
        onClose={() => setOpenSidebar(false)}
      />
      <ConfirmModal
        open={confirmLogout}
        title={translation('modals.confirmLogout.title')}
        cancelTitle={translation('modals.confirmLogout.close')}
        confirmTitle={translation('modals.confirmLogout.confirm')}
        onClose={() => setConfirmLogout(false)}
        onConfirm={async () => {
          setConfirmLogout(false);
          await logout.logoutUser();
          props.history.push('/');
        }}
      />
    </VerticalView>
  );
};

export const HeaderTitle = () => {
  const { pageTitle } = useTitle();
  const { appTheme } = useAppSetting();
  return (
    <TextDisplay fontWeight={600} size={'15px'} color={appTheme.header.text}>
      {pageTitle}
    </TextDisplay>
  );
};
