export const global = {
  header: {
    login: 'ログイン',
  },
  courseType: {
    course: 'コース',
    exam: '演習テスト',
  },
  newRegister: '新規登録',
  object: '対象',
  cropMedia: 'トリミング',
  export: 'エクスポート',
  type: '種類',
  status: 'スターテス',
  languageTranslation: '言語翻訳',
  translationReplaceTo: '翻訳置換',
  errorsTranslation: 'エラー翻訳',
  typeTranslation: 'タイプ翻訳',
  labelTranslation: 'ラベル翻訳',
  selfStudy: '自分で勉強したい方向けコース',
  revenue: {
    title: '収入統計',
    total: '総収入',
    bookingList: '予約概要リスト',
    date: '日付',
    studentName: '学生名',
    price: '金閣',
  },
  statusCollection: {
    all: 'すべて',
    new: '新しい',
    finish: '終了',
    empty: '空',
    completed: '完了',
    inReview: '',
    pending: '承認待ち',
    approved: '承認済み',
    public: '公開',
    unpublic: '非公開',
    reject: '差し戻し',
  },
  actions: {
    reSubmitForReview: '再審査に提出',
    submitForReview: '審査に提出',
    submitForApproval: '承認',
    rejection: '差し戻し',
  },
  accountStatus: {
    active: '検証済み',
    locked: 'ブロック',
    inactive: '検証なし',
    pendingAuthor: '著者検証なし',
    approveAuthor: '著者検証済み',
    notverifyauthor: '著者検証なし',
  },
  vietcademyService: 'Vietcademyサービス',
  selectALanguage: '表示言語を選択してください',
  ja: '日本語',
  vi: 'ベトナム語',
  viTranslation: 'ベトナム語訳',
  jaTranslation: '日本語訳',
  enTranslation: '英語訳',
  role: '権限',
  admin: '管理者',
  user: 'メンバー',
  author: '著者',
  emptyValue: '空',
  myAccount: 'アカウント',
  publish: '公開する',
  details: '詳細',
  edit: '編集する',
  delete: '削除する',
  created: '作成日',
  updated: '更新日',
  new: '作成する',
  noname: 'タイトルなし',
  description: 'コンテンツ',
  gotoSyllabus: '教材ページに移動',
  nosyllabus: '教材はありません。 質問を作成する前に教材を作成してください。',
  message1: '注：審査に提出する前にデータを保存してください',
  pendingMessage: '注：内容が審査を提出されましたから。内容は編集できません',
  rejectMessage: '内容が差戻しされました。内容をご確認ください。',
  reason: '理由: ',
  remainTime: '残り時間',
  day: '日',
  hour: '時',
  minute: '分',
  free: '無料',
  avgRate: '評価',
  rating: '評価',
  totalReview: 'レビュー数',
  review: 'レビュー',
  writeReview: 'レビューを書く',
  reviewText: 'コンテンツ',
  reviewRate: '評価',
  editReview: '編集する',
  submitReview: 'レビューを送信する',
  writeAReview: 'レビューを書く',
  reviewer: '評価者',
  studentRate: '学者の評価',
  studentReview: '学者のレビュー',
  you: 'あなた',
  noReviewData: 'レビューはまだありません',
  updatedTime: '更新',
  display: '表示する',
  unpublish: '非公開',
  select: '選択する',
  search: '検索する',
  searchExamFree: 'テスト無料検索',
  selectCourse: 'コースの選択',
  sharePost: '投稿を共有してください',
  pagination: 'ページ',
  seeMore: '続きを見る',
  noData: 'データなし',
  mobileSideBar: {
    home: 'トップページ',
    course: 'コース',
    news: '記事',
    service: 'サービス',
    login: 'ログイン',
    logout: 'ログアウト',
    register: '登録',
    cart: 'カート',
    help: 'ヘルプ',
    registerAuthor: '',
    registerMember: '',
    forStudent: '学生向け',
    forAuthor: '著者向け',
    language: '言語',
  },
  userLayout: {
    exam: {
      examFinish: 'テスト終了',
      messageForGuest: 'テスト結果を確認するにはアカウントを登録してください',
      register: 'アカウントを登録する',
      guest: 'ゲスト',
      person: '受験者',
      course: 'コース',
      exam: 'テスト',
      finish: '終了しました',
      time: '時間',
      hour: '時',
      second: '秒',
      minute: '分',
      submit: '提出する',
      question: '質問',
      subQuestion: 'サブ質問',
      startButton: 'テストを開始します',
      unitTime: '分',
      totalPass: '正しい',
      totalQuestion: '合計質問',
      cancelButton: 'キャンセル',
      resultTitle: '結果テスト',
      gotoMyExam: 'テストリストに行く',
      showResult: '答えを表示する',
      noAccess: 'アクセスなし',
      notForYou: '申し訳ありませんが、このコンテンツは表示できません. 学者アカウントにログインしてください',
      login: 'ログイン',
      redirection: 'リダイレクト',
      waitingRorRedirection: 'リダイレクト. しばらくお待ちください',
    },
    lecture: {
      title: '講義',
    },
    userTabs: {
      information: {
        account: 'マイページ',
        header: '情報',
        myInformation: 'マイページ',
        avatar: 'アバター',
        title: '情報',
        email: 'メール',
        name: '名前',
        gender: '性別',
        birthday: 'お誕生日',
        address: '住所',
        description: '説明',
        image: '画像',
        promoVideo: '紹介ビデオ',
        certificate: '証明書',
        certificateName: '証明書名',
        certificateImage: '証明書画像',
        update: '更新する',
        lessonLevel: '対象レベル',
        lessonTopic: '得意なレッスン内容',
      },
      order: {
        history: '支払歴',
        cart: 'カート',
        noItem: '購入履歴なし',
        title: '購入履歴',
        time: '時',
        unitPrice: '円',
        day: '日',
        purchaseDate: '購入日',
        totalItems: '数',
        totalPrice: '合計金額',
        payment: '支払い',
        cancel: 'キャンセル',
        username: '名前',
        status: 'ステータス',
        createdTime: '時間',
        price: '価格',
        approved: '承認済み',
        pending: '承認待ち',
        step: 'ステップ',
        step1: '注文',
        step2: '支払',
        step3: '学ぶ',
        back: '戻る',
        next: '次へ',
        learn: '学ぶ',
        paymentOrder: '注文支払',
        paymentMethod: 'お支払い方法',
        paymentInfor: '支払情報',
        changePaymentCard: 'カード変更',
        paymentCard: '支払いカード',
        paymentCardList: '支払いカードのリスト',
        addPaymentCard: '新しい支払いカード',
        noItemInCart: 'コースはがりません',
        noItemInCartDescription:
          '現在、カートにコースを追加していません。 コースページへ、あなたにぴったりのコースを見つけましょう.',
        gotoCourses: 'コースリストに移動',
        noPaymentMethod: '支払いカードがありません。注文を完了するには、新しい支払いカードを追加してください。',
        errorPaymentMethod: '支払いカード情報が間違っています。もう一度確認してください',
        paymentCompleted: '支払いが完了しました',
        paymentCompletedDescription: 'コースをご購入いただき誠にありがとうございます。',
        gotoLearning: '私のコースに行く',
        processing: '処理中',
        messageWarning: 'お支払いが完了​するまでブラウザを閉じないでください',
        pleaseLoginTocontinue: 'Vietcademyアカウントにログインしてください',
        login: 'ログイン',
        add: '追加する',
        alertCancel: {
          message: '注文のキャンセルを実施してもよろしですか？',
          close: 'いいえ',
          delete: 'はい',
        },
        alertConfirm: {
          title: 'このお支払いを完了してもよろしいですか？',
          close: 'いいえ',
          confirm: 'はい',
        },
      },
      myCourse: {
        allCourses: 'すべてのコース',
        addCourse: 'コースを追加する',
        title: 'コースリスト',
        details: '詳細',
        exam: 'テスト',
        book: '教科書',
        learn: '学ぶ',
        goToShop: 'コースを検索する',
        noData: 'データなし',
        noCourse: 'まだコースを所有していません。自分に最適なコースを探しましょう',
      },
      myBook: {
        title: '教科書リスト',
        chapter: '章 ',
      },
      myExam: {
        course: 'コース',
        addExam: 'テストを追加する',
        title: 'テストリスト',
        time: '時間',
        numberDay: '日数',
        day: '日',
        unitTime: '分',
        times: '回',
        answer: '回答',
        dateTime: '時間',
        pointAvg: '平均点',
        pointCount: '点',
        answerCount: '回答数',
        numberOfTesting: '受験回数',
        startTesting: 'テスト',
        showDetails: '詳細を表示',
        noExam: 'テストはありません。新しいテストを作成してください',
        new: 'テストを追加する',
        miniTest: 'ミニテスト',
        fullTest: '総テスト',
        examName: 'テスト名',
      },
      myQuestion: {
        header: '質問管理',
        noQuestion: '質問はありません。',
        new: '質問を追加する',
        addQuestion: '質問を追加する',
      },
      changePassword: {
        account: 'マイページ',
        title: 'パスワードの変更',
        currentPassword: '現在のパスワード',
        newPassword: '新しいパスワード',
        confirmNewPassword: '確認パスワード',
        save: '変更する',
      },
    },
  },
  ordersList: {
    information: '情報',
    username: '名前',
    status: 'ステータス',
    createdTime: '時間',
    price: '価格',
    unitPrice: '円',
    approved: '承認済み',
    pending: '承認待ち',
    searchbar: {
      status: 'ステータス',
      title: '注文検索',
      keyword: 'キーワード',
    },
    alertDelete: {
      message: '注文の削除を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
  },
  orderDetails: {
    username: '名前',
    email: 'メール',
    title: '注文詳細',
    time: '時',
    status: 'ステータス',
    createdTime: '時間',
    unitPrice: '円',
    totalItems: 'コース数',
    totalPrice: '合計金額',
    approve: '承認する',
    reject: '',
    approved: '承認済み',
    pending: '承認待ち',
    alertConfirm: {
      message: '注文の承認を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
  },
  userDetails: {
    account: 'アカウント',
    title: 'アカウント情報',
    email: 'メール',
    name: '名前',
    gender: '性別',
    birthday: 'お誕生日',
    address: '住所',
    isLocked: '無効',
    isVerified: '検証',
    role: '権限',
    changePass: 'パスワードの変更',
    save: '変更する',
    details: '詳細',
    addCourse: 'コースを追加する',
    isAuthorVerified: '検証著者',
    deleteCourseMsg: 'コースの削除を実施してもよろしですか？',
    identity: {
      read: '読み込み',
      write: '書き込み',
    },
    addIdentity: '権限を追加する',
  },
  usersList: {
    information: '情報',
    email: 'メール',
    name: '名前',
    isLocked: '無効',
    isVerified: '検証',
    role: '権限',
    alertDelete: {
      message: 'アカウントの削除を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
  },
  public: {
    home: {
      feature: {
        header: 'Vietcademyの特長',
        header2: 'Vietcademyの特徴',
      },
      course: {
        header: 'Vietcademyのコース',
        seeMore: 'もっとコースを見る',
      },
      registration: {
        header: '登録までの流れ',
        register: '登録する',
        step1: '入力情報',
        step2: '登録',
        step3: 'アカウントを確認する',
        step4: '登録完了',
      },
      application: {
        header: 'アプリ',
        message: 'アプリを使用するともっと便利',
      },
      examFree: {
        header: '無料テスト',
        seeMore: '無料体験テスト',
      },
      news: {
        header: 'Vietcademy のニュース',
        seeMore: 'もっとニュースを見る',
      },
      blog: {
        header: 'Vietcademy のブログ',
        seeMore: 'もっとブログを見る',
      },
    },
    login: {
      title: 'ログイン',
      email: 'メールアドレス',
      password: 'パスワード',
      resetPassword: 'パスワードをお忘れの方はこちら',
      register: 'アカウントをお持ちでない方は',
      registerMember: '新しいメンバーアカウントを作成する',
      registerAuthor: '新しい著者アカウントを作成する',
      button: 'こちら',
      loginButton: 'ログインする',
    },
    verifyAccount: {
      title: 'アカウントの確認',
      message: 'アカウントの確認に成功しました',
      login: 'ログイン',
      error: 'アカウントの確認に失敗しました',
    },
    resetPasswordConfirm: {
      title: 'パスワードの変更',
      password: '新しいパスワード',
      confirmPassword: '新しいパスワードを再入力してください',
      change: '変更',
      success: 'パスワードを変更しました',
      login: 'ログイン',
    },
    resetPassword: {
      title: 'パスワードのリセット',
      completed: 'パスワードのリセットが完了しました。 メールでチェックしてください',
      email: 'メールアドレス',
      button: 'こちら',
      reset: 'リセットする',
      home: 'トップページ',
      hasAccount: 'アカウントをお持ち方は',
      noAccount: 'アカウントをお持ちでない方は',
    },
    register: {
      title: '新規会員登録',
      authorTitle: '新規著者登録',
      verify: 'アカウントの確認',
      completed:
        'ご登録いただきましたメールアドレスへ会員登録を完了するためのURLをお送りしました。お手数ですが、メールのご確認をお願いいたします。',
      email: 'メールアドレス',
      password: 'パスワード',
      confirmPassword: '確認パスワード',
      button: 'こちら',
      register: '登録する',
      backToLogin: 'ログインに戻る',
      gohome: 'トップページへ移動する',
      success: 'アカウント登録が成功しました',
      step1: '登録情報',
      step2: 'アカウント認証',
    },
    courseList: {
      header: 'コースリスト',
      featureCourses: '特徴コース',
      otherCourses: 'その他のコース',
      description: 'コースの詳細ページで、詳細情報を見つけることができます',
      card: {
        price: '価格',
        details: '詳細',
      },
      searchbar: {
        title: 'コース検索',
        keyword: 'キーワード',
      },
    },
    courseDetails: {
      thumbnail: 'サムネイル',
      price: '価格',
      time: '日',
      addToCartSuccess: 'コースがカートに追加されました',
      duration: '期間',
      syllabus: '教材',
      unitTime: '日',
      lesson: 'レッスン',
      owner: 'コースを永久に所有します',
      share: 'コースリンクを共有します',
      course: 'コース',
      exam: '演習テスト',
      author: '著者',
      student: '学者',
      avgRate: '評価',
      totalReview: 'レビュー',
      card: {
        refund: '満足できない場合は14日以内に返品できます。',
        free: '無料',
        freeMessage: 'このコースは無料で共有されています',
        addToMyCourse: '学ぶ',
        price: '価格',
        time: '時',
        title: 'The deadline to enroll in the next cohort is January 11, 2021.',
        addToCard: '購入する',
        booking: '予約する',
        buyNow: '支払う',
        duration: '期間',
        syllabus: '教材',
        unitTime: '日',
        lesson: 'レッスン',
        owner: 'コースを永久に所有します',
        share: 'コースリンクを共有します',
        alertConfirm: {
          title: '購入を実施してもよろしですか？',
          close: 'いいえ',
          confirm: 'はい',
        },
      },
    },
    newsList: {
      header: '記事',
      searchTitle: '記事検索',
      description: '記事、テクノロジーに関する情報の更新',
    },
    articleDetails: {
      header: '記事詳細',
      description: '',
    },
    articleSearch: {
      header: '検索記事',
      description: '',
    },
    blogList: {
      header: 'ブログ',
      description: '知識と経験を共有する',
    },
    serviceList: {
      header: 'サービスについて',
      description: 'サービスの詳細ページで、詳細情報を見つけることができます',
    },
    about: {
      header: '会社概要',
    },

    privacy: {
      header: '使用条件',
      title: '使用条件',
    },
    terms: {
      header: '利用規約',
      title: '利用規約',
    },
    commercialLaw: {
      header: '特商法に基づく表記',
      title: '特商法に基づく表記',
    },
    studyWithTeacher: {
      title: '先生と直接勉強したい方向けコース',
      recommend: 'おすすめの方',
      videoRegisterGuide: '動画で登録までの流れを見る',
      vietcademyTeachers: 'Vietcademyの先生',
      findTeacher: '先生を検索する',
    },
  },
  bookList: {
    header: '教科書',
    modalHeader: '教科書 (公開)',
    name: '教科書名',
    title: 'タイトル',
    public: '公開',
    approved: '承認済み',
    searchbar: {
      title: '教科書検索',
      keyword: 'キーワード',
      condition: '検索条件クリア',
      buttonSearch: '検索',
    },
    alertDelete: {
      message: '本の削除を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
  },
  article: {
    header: '検索記事',
    description: '',
  },
  bookDetails: {
    editChapter: 'チャプター内容編集',
    thumbnail: 'コース画像',
    noSyllabus: 'このコースの教材を追加してください',
    new: '教材を追加する',
    notitle: 'タイトルなし',
    header: '詳細',
    title: 'タイトル',
    public: '公開',
    approved: '承認済み',
    description: '説明',
    chapter: '章',
    lesson: 'レッスン',
    content: '内容',
    addChapter: '章を追加する',
    edit: '編集する',
    save: '更新する',
    preview: 'プレビュー',
    quickView: 'クイックビュー',
    alertDeleteLesson: {
      message: 'レッスンの削除を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
    alertDeleteChapter: {
      message: '章の削除を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
  },
  courseList: {
    header: 'コース管理',
    description: 'コース一覧',
    name: 'コース名',
    title: 'タイトル',
    public: '公開',
    price: '価格',
    owner: '所有者',
    alertDelete: {
      message: 'コースの削除を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
    searchbar: {
      title: 'コース検索',
      keyword: 'キーワード',
    },
  },
  courseDetails: {
    step: 'コースの作成方法',
    header: 'コース詳細',
    course: 'コース',
    exam: '演習テスト',
    priceId: '価格ID',
    notitle: 'タイトルなし',
    title: 'タイトル',
    price: '価格',
    thumbnail: 'thumbnail',
    unitPrice: '円',
    duration: '日',
    unitTime: '日',
    public: '公開',
    description: '説明',
    syllabus: '教材',
    content: '内容',
    edit: '編集する',
    save: '更新する',
    preview: 'プレビュー',
    quickView: 'クイックビュー',
    priceIdDescription:
      'Mỗi khoá học đều phải tạo một mã giá tiền. Mã được lấy từ hệ thống quản lý thanh toán. Liên hệ người quản lý hệ thống thanh toán để tạo mã giá tiền cho khoá học này',
    lesson: {
      lesson: 'レッスン',
      title: 'タイトル',
      content: '内容',
    },
    addSkill: 'スキルを追加する',
    skill: {
      skill: 'スキル',
      title: 'タイトル',
      content: '内容',
    },
    alertDeleteSyllabus: {
      message: '教材の削除を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
  },
  questionDetails: {
    step: '質問の作成方法',
    header: '質問詳細',
    title: '質問内容',
    type: '質問タイプ',
    subQuestion: 'サブ質問',
    category: '質問カテゴリー',
    audio: 'mp3',
    public: '公開',
    select: '選択する',
    book: {
      title: '教科書名',
      lesson: 'レッスン名',
    },
    addAnswer: '回答を追加する',
    answer: {
      title: '回答内容',
      explain: '内容説明',
    },
    alertDeleteQues: {
      message: '質問の削除を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
    alertDeleteAnswer: {
      message: '回答の削除を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
  },
  examList: {
    title: 'タイトル',
    public: '公開',
    searchbar: {
      title: 'テスト検索',
      keyword: 'キーワード',
      condition: '検索条件クリア',
      buttonSearch: '検索',
    },
    alertDelete: {
      message: 'テストの削除を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
  },
  examDetails: {
    step: 'テストの作成方法',
    header: 'テスト詳細',
    title: 'タイトル',
    course: 'コース',
    type: 'タイプ',
    time: '時間',
    unitTime: '分',
    category: '質問カテゴリー',
    question: '質問',
    audio: 'mp3',
    public: '公開',
    details: '詳細',
    testDate: 'テスト日',
    lesson: 'レッスン',
    edit: '編集する',
    save: '更新する',
    preview: 'プレビュー',
    quickView: 'クイックビュー',
    sectionTitle: '全てパート',
    section: {
      header: 'パート',
      title: 'タイトル',
      addSection: 'パートを追加する',
      deleteSection: 'パートを削除する',
      addQuestion: '質問を追加する',
      deleteQuestion: '質問を削除する',
    },
    book: {
      title: '教科書名',
      lesson: 'レッスン名',
    },
    answer: {
      title: '回答内容',
      explain: '内容説明',
    },
    alertDeleteQues: {
      message: '質問の削除を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
    alertDeleteExam: {
      message: 'テストの削除を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
  },
  news: {
    category: '記事',
    news: '記事',
    title: 'タイトル',
    description: '説明',
    public: '公開',
    content: '内容',
    preview: 'プレビュー',
    searchbar: {
      title: 'News検索',
      keyword: 'キーワード',
      condition: '検索条件クリア',
      buttonSearch: '検索',
    },
    alertDelete: {
      message: '記事の削除を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
  },
  post: {
    category: 'カテゴリー',
    header: '投稿',
    title: 'タイトル',
    description: '説明',
    public: '公開',
    content: '内容',
    preview: 'プレビュー',
    searchbar: {
      title: '投稿検索',
      keyword: 'キーワード',
      condition: '検索条件クリア',
      buttonSearch: '検索',
    },
    alertDelete: {
      message: '投稿の削除を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
  },
  service: {
    category: 'サービス',
    service: 'サービス',
    title: 'タイトル',
    description: '説明',
    public: '公開',
    content: '内容',
    preview: 'プレビュー',
    status: 'ステータス',
    searchbar: {
      title: 'サービス検索',
      keyword: 'キーワード',
      condition: '検索条件クリア',
      buttonSearch: '検索',
    },
    alertDelete: {
      message: 'サービスの削除を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
  },
  questionList: {
    header: '質問リスト',
    title: '質問',
    public: '公開',
    book: '教科書',
    lesson: 'レッスン',
    question: '質問',
    questionSelected: '選択した質問',
    questionList: '質問リスト',
    update: '更新',
    addQuestion: '質問を追加する',
    selectQuestion: '選択する',
    searchbar: {
      title: '質問検索',
      keyword: 'キーワード',
      condition: '検索条件クリア',
      buttonSearch: '検索',
    },
    alertDelete: {
      message: '質問の削除を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
    alertInvalid: {
      message: '無効なデータ! この質問の内容を確認してください',
      close: '閉じる',
      edit: '編集する',
    },
  },
  home: {
    title: 'トップページ',
    public: '公開',
    limit: '制限',
    save: '保存する',
    postUrl: '記事のリンク',
    postTitle: 'タイトル',
    postDescription: '説明',
    postBanner: 'バナー',
    bannerBlur: 'ぼかす ( 0 - 50 )',
    topBanner: {
      title: 'タイトル',
      description: '説明',
      details: '詳細',
    },
    promotion: {
      header: 'イベント',
    },
    feature: {
      header: 'Vietcademyの特長',
      header2: 'Vietcademyの特長',
    },
    category: {
      header: 'カテゴリー',
      icon: 'アイコン',
      title: 'タイトル',
      description: '説明',
      create: '作成する',
      edit: '保存する',
    },
    course: {
      header: 'コース',
      title: 'タイトル',
      description: '説明',
      banner: '',
    },
    news: {
      header: '記事',
      title: 'タイトル',
      description: '説明',
      limit: '',
    },
    examFree: {
      header: 'テスト無料',
      title: 'タイトル',
      description: '説明',
      limit: '',
    },
    blog: {
      header: 'ブログ',
      title: 'タイトル',
      description: '説明',
      limit: '',
    },
  },
  companyProfile: {
    header: '会社概要',
  },
  media: {
    title: 'メディア',
    message: '選択する',
    deleteAlert: {
      message: 'メディアの削除を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
  },

  footer: {
    message: '「Viet Academy」でベトナム語を学びましょう。',
    buttonStart: '始まる',
    vietcademy: {
      title: 'Vietcademy の ベトナム語の教師になる',
      description: 'ネイティブレベルのベトナム語を生かし、 お金を稼ぎましょう。 Vietcademyはあなたを支援します。',
    },
  },

  common: {
    nodata: 'データなし',
    nocourse: 'コースなし',
    nobook: '教科書なし',
    course: 'コース',
    book: '教科書',
    exam: 'テスト',
    new: '新規',
    public: '公開',
    exportCsv: 'CSVエクスポート',
    buttonBack: '戻る',
    buttonSaveChange: '変更する',
    buttonSave: '保存する',
    buttonCreate: '作成する',
    yes: 'はい',
    no: 'いいえ',
    statistic: '統計',
    statisticData: '収入の統計',
    topMenu: {
      home: 'ホーム',
      news: '記事',
      course: 'コース',
      service: 'サービス',
      about: '運営会社',
      booking: '予約',
      help: 'ヘルプ',
    },
  },
  mediaCenter: {
    title: 'メディアセンター',
    mediaList: 'メディアリスト',
  },
  uploadBook: {
    title: '教科書アップロード',
  },
  uploadCourse: {
    title: 'コースアップロード',
  },
  uploadQuestion: {
    title: '質問アップロード',
  },
  uploadExam: {
    title: 'テストアップロード',
  },
  about: {
    header: '運営会社情報',
  },

  privacy: {
    header: '使用条件',
  },
  terms: {
    header: '利用規約',
  },
  userList: {
    addNew: 'ユーザーを追加する',
    header: 'ユーザー管理',
    description: 'ユーザー一覧',
    searchbar: {
      title: 'ユーザー検索',
      keyword: 'キーワード',
    },
    alertDelete: {
      message: 'ユーザーの削除を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
  },
  setting: {
    title: '設定',
    law: '特商法に基づく表記',
    news: '記事設定',
    newsFeaturePost: '注目の記事',
    course: 'コース設定',
    courseFeaturePost: '注目のコース',
    save: '保存する',
    footer: {
      footerSetting: 'フッター設定',
      iosUrl: 'Iosのurl',
      androidUrl: 'Androidのurl',
      profile: 'マイページ',
      companyName: '会社名',
      description: '説明',
      phone: '電話番号',
      email: 'メール',
      copyright: 'Copyright',
    },
    copyright: 'Copyright',
  },
  createUser: {
    title: '新規アカウント',
    email: 'メールアドレス',
    password: 'パスワード',
    confirmPassword: '確認パスワード',
    create: '作成する',
    role: '権限',
  },
  page404: {
    title: '',
    goHome: 'ホームへ戻る',
    description: '申し訳ありません。お探しのページが見つかりません。',
  },
  validator: {
    passwordRequired: 'パスワードは必須項目です。',
    passwordConfirmRequired: '確認パスワードは必須項目です。',
    passwordMin: 'パスワードは8文字以上で入力してください。',
    passwordMax: 'パスワードは20文字以内で入力してください。',
    passwordInvalid: 'パスワードは半角英数記号(英数混合必須)で入力してください。',
    passwordNotMatch: '２つのパスワードが一致していません。',
    requiredEmail: 'メールアドレスは必須項目です。',
    invalidEmail: 'メールアドレスを正しく入力してください。',
    requiredForRole: '役割は必須項目です。',
    requiredSeoName: 'seonameは必須項目です。',
    requiredUrl: 'urlは必須項目です。',
    requiredCategory: 'カテゴリーは必須項目です。',
    requiredThumbnail: 'サムネイルは必須項目です。',
    requiredTitle: 'タイトルは必須項目です。',
    requiredContent: '内容は必須項目です。',
    nameMinMax: '名前は2〜16文字で入力してください',
    requiredName: '名前は必須項目です。',
    requiredImage: '画像は必須項目です。',
    requiredAddress: '住所は必須項目です。',
    requiredGender: '性別は必須項目です。',
    requiredDescription: '説明は必須項目です。',
    requiredSubject: 'タイトルは必須項目です。',
    requiredPhone: '電話番号は必須項目です。',
    requiredAgree: '送信確認のチェックを選択してください。',
    requiredDatetime: '予約時間が必要です。',
    requiredType: 'タイプが必要です。',
    requiredTarget: 'ターゲットが必要です。',
    requiredDuration: '期間は必須項目です。',
    requiredBanner: 'バナーは必須項目です。',
    requiredPrice: '価格は必須項目です。',
    requiredPriceId: '価格IDは必須項目です。',
    requiredCourseDuration: '数日は必須項目です。',
    requiredCourseDurationMin: '数日は1日以上で入力してください',
    requiredLesson: 'レッスンは必須項目です。',
    requiredSkill: 'スキルは必須項目です。',
    requiredSyllabus: '教材は必須項目です。',
    requiredChapter: 'チャプターは必須項目です。',
    requiredAnswer: '回答は必須項目です。',
    requiredQuestion: '質問は必須項目です。',
    requiredAudio: 'Audioは必須項目です。',
    requiredCheckedAnswer: '正しい答えを選択してください',
    requiredExamTime: 'テスト時間は必須項目です。',
    requiredCourse: 'コースは必須項目です。',
    requiredSection: 'セクションは必須項目です。',
    requiredExamLesson: 'レッスンを選択してください (最初にコースを選択する必要があります)',
  },
  modals: {
    submitExamConfirmModal: {
      title: 'テストの提出',
      answer: '回答数',
      message: '本当にこのテストを提出してもよろしいですか？',
      submit: '提出する',
    },
    createQuestion: {
      title: '質問の作成',
      type: 'タイプ',
      book: '教科書',
      create: '作成',
    },
    createCourse: {
      title: '',
      titleCourse: 'コース',
      titleExam: '演習テスト',
      message: '最初に、どういった種類のコースにするかを考えましょう。',
      messageCourse: 'ビデオレクチャー、小テスト、コーディング演習などを組み合わせて、内容の充実を図りましょう。',
      messageExam: '演習テストを設けて、受講生の資格テスト対策をサポートしましょう。',
      create: '作成する',
    },
    updateQuestion: {
      title: '質問の更新',
    },
    cropImageModal: {
      selectRatio: '選択してアップロード',
    },
    questionList: {
      book: '教科書',
      lesson: 'レッスン',
      question: '質問',
      questionSelected: '選択した質問',
      questionList: '質問リスト',
      update: '更新',
      searchbar: {
        title: '質問検索',
        keyword: 'キーワード',
        condition: '検索条件クリア',
        buttonSearch: '検索',
      },
    },
    confirmLogout: {
      title: 'アカウントからサインアウトしますか？',
      close: 'いいえ',
      confirm: 'サインアウト',
    },
    confirmSubmitCourse: {
      title: 'この内容を審査に提出してもよろしいですか？',
      close: 'いいえ',
      confirm: 'はい',
    },
    confirmDelete: {
      message: 'データの削除を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
    approveCourse: {
      title: 'この内容を承認してもよろしいですか？',
      close: 'いいえ',
      confirm: 'はい',
    },
    rejectCourse: {
      title: 'この内容を差し戻ししてもよろしいですか？.この内容が差し戻しされる理由を入力してください',
      close: 'いいえ',
      confirm: 'はい',
    },
    invalidCourse: {
      title: '審査に提出できないのはなぜですか?',
      message: 'Để gửi được nội dung bạn cần kiểm tra lần lượt các mục dưới đây:',
      requireContents: [
        {
          title: '教材',
          values: [
            'Có thông tin về giáo trình như tiêu đề và mô tả',
            'Có ít nhất 1 chương học',
            'Có ít nhất 5 bài giảng',
            'Có nội dung cho tất cả các bài giảng',
          ],
        },
        {
          title: 'テスト(có thể có hoặc không), nếu có cần thoả mãn các điều kiện sau',
          values: [
            'Có thông tin bài test: tiêu đề - loại bài test - thời gian test',
            'Có ít nhất 1 phần test(Đọc hiểu hoặc nghe hiểu)',
            'Mỗi phần có ít nhất 10 câu hỏi',
          ],
        },
        {
          title: '概要',
          values: [
            'Có đầy đủ thông tin: ảnh khoá học - seoname(giống tiêu đề và viết không dấu, nếu là tiếng nhật phải dùng romaji) - tiêu đề -  tổng số ngày cần hoàn thành - Mô tả',
            'Kỹ năng Có ít nhất 2 mục, (kỹ năng là sau khi học xong khoá học này thì sẽ có được những kỹ năng hay kiến thức gì.)',
            'Nôi dung mô tả về khoá học có ít 200 từ.',
          ],
        },
        {
          title: '価格設定',
          values: [
            'Phải lựa chọn khoá học là miễn phí hay không miễn phí',
            'Giá khoá học được tính theo  giá trị YÊN của Nhật Bản, Kiểm tra tỉ giá trước khi nhập',
            'Giá khoá học phải lớn hơn 100 YÊN',
          ],
        },
      ],
      close: 'Đóng',
    },
    invalidCourseExam: {
      title: '審査に提出できないのはなぜですか？',
      message: 'Để gửi được nội dung bạn cần kiểm tra lần lượt các mục dưới đây:',
      requireContents: [
        {
          title: 'テスト',
          values: [
            'Có thông tin bài test: tiêu đề - loại bài test - thời gian test',
            'Có ít nhất 1 phần test(Đọc hiểu hoặc nghe hiểu)',
            'Mỗi phần có ít nhất 10 câu hỏi',
          ],
        },
        {
          title: '概要',
          values: [
            'Có đầy đủ thông tin: ảnh khoá học - seoname(giống tiêu đề và viết không dấu, nếu là tiếng nhật phải dùng romaji) - tiêu đề -  tổng số ngày cần hoàn thành - Mô tả',
            'Kỹ năng Có ít nhất 2 mục, (kỹ năng là sau khi học xong khoá học này thì sẽ có được những kỹ năng hay kiến thức gì.)',
            'Nôi dung mô tả về khoá học có ít 200 từ.',
          ],
        },
        {
          title: '価格設定',
          values: [
            'Phải lựa chọn khoá học là miễn phí hay không miễn phí',
            'Giá khoá học được tính theo  giá trị YÊN của Nhật Bản, Kiểm tra tỉ giá trước khi nhập',
            'Giá khoá học phải lớn hơn 100 YÊN',
          ],
        },
      ],
      close: 'Đóng',
    },
  },
  statistic: {
    header: '統計',
  },
  //Alert
  alertDelete: {
    message: '注文の削除を実施してもよろしですか？',
    close: 'いいえ',
    delete: 'はい',
  },
  invalidDataAlert: {
    message: 'データが無効です。入力したフィールドを確認してください',
    close: '閉じる',
  },
  message: {
    create: 'データ作成',
    update: 'データ更新',
    delete: 'データ削除',
    error: '失敗しました',
    success: '成功しました',
    publishSuccess: '正常に公開されました',
    approvedSuccess: '承認しました。',
    submitForReviewSuccess: '提出しました',
    rejectSuccess: '差戻ししました',
    unpublishSuccess: 'コースのキャンセルが正常に完了しています',
    publishError: '',
    paymentError: 'エラーが発生しました。 お支払い情報をご確認ください',
    errorMessage1: 'エラーが発生しました。もう一度やり直してください',
  },
  teacher: {
    intro: '紹介',
    calendar: 'カレンダー',
    ownerCourse: 'のコース',
    studyWith: 'と一緒に勉強',
    unitDay: '日',
    lesson: 'レッスン',
    detailsCourse: '詳細',
    lessonLevels: '対象レベル',
    lessonTopics: '得意なレッスン内容',
  },
  searchbar: {
    keyword: 'キーワード',
    keywordPlaceholder: 'キーワードを入力します',
  },
  helpCenter: 'ヘルプセンター',
  helpTitle: 'ヘルプ',
  helpEdit: 'ヘルプの編集',
  title: 'タイトル',
  content: 'コンテンツ',
  preview: 'プレビュー',
  publicTitle: '公衆',
  category: 'カテゴリー',
  report: '報告する',
  keyword: 'キーワードを入力します',
  helpMessage: 'お手伝いしましょうか？',
  information: '情報',
  searchResult: '検索ヒット数',
  resultCount: '件',
  language: '言語',
  must: '必要',
  helpFor: 'ヘルプのために',
  save: '保存',
  close: '閉じる',
  newsTitle: '記事',
  postTitle: '役職',
  blogTitle: 'ブログ記事',
  examTitle: 'テスト記事',
  businessTitle: 'ビジネス',
  recentNews: '最近の記事',
  skillsYouWillGain: 'スキル　-　ゲイン',
  whatYouWillLearn: '何を学びますか？',
  homeTitle: 'トップページ',
  courseDetailsTitle: 'コース詳細',
  copied: 'コピーされました',
  userDetailsTitle: 'アカウント情報',
  lectureTitle: '講義',
  //Contact
  contactDetails: '連絡先の詳細',
  contactHeader: 'お問い合わせ',
  contactList: 'コンタクトマネージャー',
  contactName: 'お名前',
  contactEmail: 'メール',
  contactTime: '予約時間',
  contactPhone: '電話番号',
  contactSubject: '件名',
  contactContent: '内容',
  contactDirect: '予約時間',
  sendContact: '送る',
  sendContactSuccess: '送りました。',
  sendContactError: 'エラーが発生しました。もう一度やり直してください。',
  contactMessage: 'お問い合わせいただきありがとうございます. 登録日時までにご連絡いたします。',
  contactMessage1: '※ 全ての時間帯に「×」が表示されている場合は、一度ページを再読み込みしてください',
  contactMessage2: '※ 解決しない場合は「contact@vietcademy.jp」までご連絡ください。',
  contactMessage3: '下記カレンダーから、ご希望の時間帯をクリックしてください。所要時間は25分を予定しております。',
  contactMessage4: '※ 通常、ご連絡頂いてから2営業日以内にご返信します',
  contactMessage5: 'ご不明な点などございましたら、弊社サポートセンターまでお問い合わせください。',
  contactPolicyMessage: '個人情報の取り扱いに同意の上、送信しますか？',
  contactInvalidContent: '入力内容に不備があります。再度ご確認ください。',

  back: '戻る',

  no: 'なし',

  privacyPolicy: '使用条件',

  newsKeyword: 'キーワードまたはIDを入力してください',

  authorVerifyMessage:
    'Chào bạn\n\nCảm ơn bạn đã đăng ký tài khoản giáo viên trên Vietcademy. Bạn vui lòng cập nhật thông tin và tải ảnh đại diện, đồng thời gửi thông tin để Vietcademy kiểm tra và xác nhận tài khoản cho bạn về email: contact@vietcademy.jp\n1. Thông tin hợp đồng\n\tHọ và tên\n\tĐịa chỉ\n\tSố điện thoại\n\tSố tài khoản ngân hàng để nhận thù lao\n2. Ảnh chứng chỉ tiếng Nhật JLPT bạn đang có',

  eventList: {
    header: 'イベント',
    keyword: 'キーワード',
    title: 'タイトル',
    description: '説明',
    content: 'コンテンツ',
    from: '日から',
    to: '日まで',
    public: '公衆',
    duration: '期間',
    information: '情報',
    alertDelete: {
      message: 'イベントの削除を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
  },
  eventDetails: {
    header: 'イベント編集',
    keyword: 'キーワード',
    title: 'タイトル',
    ticket: 'チケット数',
    description: '説明',
    content: 'コンテンツ',
    from: '日から',
    to: '日まで',
    type: 'タイプ',
    target: '対象',
    duration: '期間',
    public: '公衆',
    courseInEvent: 'イベントのコース',
    enterCourseId: 'コースIDを入力してください',
    addNewCourseId: 'コースIDを追加する',
    save: '保存する',
    update: '保存する',
  },
  pageTitle: {
    // course details
    courseLanding: '概要',
    courseSkill: 'スキル',
    courseSyllabus: '教材',
    courseExam: 'テスト',
    coursePrice: '価格設定',
    courseEditExam: 'テストの詳細',
    courseQuestion: '質問管理',
  },
  registerTime: '',
  userExamResults: {
    title: '結果テスト',
  },
  calendar: {
    edit: '編集',
    event: 'イベント',
    meeting: 'ミーティング',
    teaching: '授業',
    noEventAtTime: '',
    noEventTitle: 'スケジュールなし。',
    noEventMessage: '選択した期間のレッスン予約。',
    with: '学生',
    addEvent: '授業スケジュールの追加',
    registered: '授業',
    noMemer: '学生はいません',
    streamUrl: 'オンライン学習リンク',
    password: 'パスワード',
    finish: '予約を完了する',
    unset: '未設定',
    delete: '削除',
    details: '詳細',
    author: '著者',
    addEventModal: {
      header: 'イベントの作成',
      cancel: 'キャンセル',
      create: '作成',
    },
    confirmFinish: {
      message: 'この予約を完了してもよろしいですか？',
      finish: '完了',
      cancel: 'キャンセル',
    },
    confirmDelete: {
      message: 'この予約を削除してもよろしいですか？',
      delete: '削除',
      cancel: 'キャンセル',
    },
  },
  all: '全',
  item: '件',
  point: {
    header: 'チケットの追加',
    point: 'チケット',
    addPoint: 'チケットを追加',
    next: '次へ',
    payment: '支払い',
    cancel: 'キャンセル',
    confirm: {
      message: '{point}チケット購入してもよろしいですか？',
      confirm: 'OK',
      cancel: 'キャンセル',
    },
    error: {
      message:
        'お支払い中にエラーが発生しました。\nもう一度お試しいただくか、詳細はcontact@vietcademy.jpまでお問い合わせください。',
    },
  },
  userExamsSearch: {
    no: 'NO',
    userId: 'ユーザーID',
    userName: 'ユーザー名',
    examName: '試験名',
    courseId: 'コースID',
    courseName: 'コース名',
    searchbar: {
      title: 'ユーザー試験一覧',
      courseId: 'コースID',
      userId: 'ユーザーID',
      keyword: 'キーワード',
      keywordPlaceHolder: 'ユーザー名、または試験名、またはコース名を入力してください。',
    },
  },
  authorSchedule: {
    createModal: {
      header: 'スケジュールの作成',
      datetime: '時間',
      date: '日',
      time: '時間',
      course: 'コース',
      cancel: 'キャンセル',
      create: '作成',
    },
    editModal: {
      bookedPerson: '予約名',
      header: 'スケジュールの編集',
      datetime: '時間',
      date: '日',
      time: '時間',
      chat: 'チャット',
      url: 'Zoom URL',
      password: 'パスワード',
      course: 'コース',
      cancel: 'キャンセル',
      update: '編集',
      close: '閉じる',
    },
  },
  booking: {
    bookingFilter: {
      header: '',
      schedule: '{author}のスケジュール',
    },
    schedulesList: {
      booking: '予約する',
      booked: '予約しました',
      cancel: 'キャンセル',
      noScheduleTitle: '申し訳ございません。条件にマッチする講師が見つかりませんでした。',
      noScheduleMessage:
        'お手数ですが別の条件を指定してください。\n予約に関するヒント\n平日の日中〜20:00までは余裕を持って予約ができるのでおすすめです。\n「さらに詳しい条件を指定する」で過去の条件が保存されていると、表示される講師の数が減ることがあります。',
    },
  },
  bookingSearch: {
    header: 'レッスン予約',
    filterBar: {
      header: 'レッスン予約',
      level: 'レベル',
      lessonContent: 'レッスン内容',
      fromTime: '時間から',
      toTime: '時間まで',
      schedule: '{author}のスケジュール',
      search: '検索する',
    },
    teacher: '教師',
    content: '予約内容',
    yearOld: '歳',
    experience: '教師歴',
    lessonFlow: '進め方',
    lessonStrictness: '文法や発音を間違えたときの指摘',
    lessonOther: 'その他要望',
    bookingModal: {
      teacher: '教師',
      student: '学者',
      content: '予約内容',
      experience: '教師歴',
      header: '予約',
      point: 'チケット',
      datetime: '時間',
      course: 'コース',
      author: '著者',
      cancel: 'キャンセル',
      create: '予約する',
      fee: '費用',
      level: 'レベル',
      lessonContent: 'レッスン内容',
      messageError: '予約に失敗しました',
      messageSuccess: '予約に成功しました',
      lessonFlow: '進め方',
      lessonStrictness: '文法や発音を間違えたときの指摘',
      lessonOther: 'その他要望',
      notEnoughPoints:
        'チケットがありません。予約するため、チケット追加ボタンをクリックして、チケットを購入してください。',
    },
    bookedModal: {
      message:
        'レッスン予約が完了いたしました。\n\n予約のキャンセル、リクエスト内容の変更はレッスン開始60分前までとなっております。',
      cancel: '戻る',
      goMyBooking: 'マイスケジュールへ',
    },
  },
  myBooking: {
    header: 'マイスケジュール',
    course: 'コース',
    author: '著者',
    datetime: '時間',
    noBookingTitle: '予約なし',
    noBookingMessage: '選択した期間、予約は利用できません。',
    completed: '完了',
    finish: '終了',
    cancel: 'キャンセル',
    details: '詳細',
    confirmCancel: {
      message: 'この予約をキャンセルしてもよろしいですか？',
      messageSuccess: 'キャンセルに成功しました',
      close: 'キャンセル',
      cancel: 'OK',
    },
  },
  reportsPage: {
    name: 'お名前',
    type: '種類',
    title: '件名',
    header: 'クレームマネジメント',
    detail: 'クレーム詳細',
    reason: '理由',
    author: '教師',
    course: 'コース',
    booking: '予約',
    ticket: 'チケット',
    status: '状況',
    createdTime: '受取時間',
    refunded: '返金済み',
    refundWaiting: '返金待ち',
    refund: '返金',
  },
  ticketModal: {
    title: 'チケット返金',
    time: '時間',
    quantity: '数量',
    messageSuccess: '返金完了',
    add: '購入',
    sub: '使用',
    refunded: '返金済',
    noTicket: 'チケットなし',
  },
  notificationPage: {
    header: 'お知らせ一覧',
    title: '新着情報',
    dateTime: '時間',
    content: '内容',
  },
  getNotification: {
    messageRefunded: 'チケット{ticket}枚が返却されました。',
    booked: '{date}に、{bookedName}さんと予約があります。',
    scheduleComplete: '{date}のスケジュールが完了しました。',
    bookingCanceled: '{date}の{bookedName}さんとの予約予約はキャンセルされました。',
  },
  emailModal: {
    title: 'メール送り',
    btnSend: '送信',
    btnSendAll: '全員送信',
    sendTo: '宛先',
    all: '全員',
    subject: '件名',
    content: '内容',
    sent: 'メールを送信しました',
    sendFailed: '送信できないメールが発生しました。',
    sendFailedMessage: '再送信しますか？',
    btnSendAgain: '再送信',
  },
  marketing: {
    title: 'マーケティング',
    type: '種類',
    name: '名前',
    address: '住所',
    phone: '電話番号',
    email: 'メール',
    url: 'マーケティングURL',
    edit: 'マーケティング編集',
  },
  access: {
    title: 'アクセス',
    dataSource: 'データ原',
    userAgent: '端末',
    userId: 'ユーザID',
    userName: 'ユーザ名',
  },
};
