import { useQuery } from '@apollo/client';
import { userQueries } from 'api/user';
import * as React from 'react';
import { IUser } from 'types/types';
import EventEmitter from 'events';
export const event = new EventEmitter();

export type IAuth = {
  session: SessionState;
  dispatch: React.Dispatch<Actions>;
};

export const SessionContext = React.createContext<IAuth>({} as IAuth);

export type SessionState = {
  user?: IUser;
  isAdmin?: boolean;
  isAuthor?: boolean;
  isGuest?: boolean;
  isStudent?: boolean;
  token?: string | null;
  isAuthenticated: boolean;
  isLoaded: boolean;
};

const initialState = {
  isLoaded: false,
  token: null,
  isAuthenticated: false,
} as SessionState;

export type Actions =
  | { type: 'update'; payload: { user: IUser; isAuthenticated: boolean; token: string | null } }
  | { type: 'delete-session' }
  | { type: 'logout' };

export function authReducer(state: SessionState, action: Actions): SessionState {
  switch (action.type) {
    case 'update':
      return {
        ...state,
        ...action.payload,
        isLoaded: true,
        isAuthor: action.payload.user?.role === 'AUTHOR',
        isAdmin: action.payload.user?.role === 'ADMIN',
        isStudent: action.payload.user?.role === 'USER',
        isGuest: !action.payload.user.id,
      };
    case 'delete-session':
    case 'logout':
      return { isAuthenticated: false, isLoaded: true, isGuest: true };
    default:
      return state;
  }
}

export const SessionProvider = (props: { children: React.ReactNode }) => {
  const token = localStorage.getItem('QHC_LOGIN_TOKEN');
  const { data, loading } = useQuery(userQueries.CHECK_AUTH, { fetchPolicy: 'cache-and-network' });
  const [session, dispatch] = React.useReducer(authReducer, initialState);

  const checkAuth: IUser = data ? data.checkAuth : undefined;
  const isUnAuthenticated = !checkAuth || !token || (token && token.length === 0);

  React.useEffect(() => {
    if (!loading) {
      dispatch({
        type: 'update',
        payload: { user: checkAuth || {}, isAuthenticated: !isUnAuthenticated, token: token },
      });
    }
    // eslint-disable-next-line
  }, [checkAuth, loading]);

  return <SessionContext.Provider value={{ session, dispatch }}>{props.children}</SessionContext.Provider>;
};
