import { gql } from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Upload: File;
  _FieldSet: any;
};

export type IAbout = INode & {
  __typename?: 'About';
  id: Scalars['ID'];
  type: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  createdBy: Scalars['ID'];
  updatedTime: Maybe<Scalars['DateTime']>;
  createdTime: Maybe<Scalars['DateTime']>;
};

export type IAccess = INode & {
  __typename?: 'Access';
  createdBy: Maybe<Scalars['String']>;
  createdTime: Maybe<Scalars['DateTime']>;
  dataSource: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  ip: Maybe<Scalars['String']>;
  mkid: Maybe<Scalars['String']>;
  permission: Maybe<Scalars['Int']>;
  table: Scalars['String'];
  updatedTime: Maybe<Scalars['DateTime']>;
  url: Maybe<Scalars['String']>;
  user: Maybe<IUser>;
  userAgent: Maybe<Scalars['String']>;
};

export enum IAccessType {
  AccessTable = 'AccessTable',
  AccessProduct = 'AccessProduct',
}

export enum IAllowRule {
  Ownner = 'OWNNER',
  Public = 'PUBLIC',
}

export type IAnswer = {
  __typename?: 'Answer';
  answer: Maybe<Scalars['String']>;
  isCorrect: Maybe<Scalars['Boolean']>;
  explain: Maybe<Scalars['String']>;
};

export type IAnswerInput = {
  answer: Maybe<Scalars['String']>;
  isCorrect: Maybe<Scalars['Boolean']>;
  explain: Maybe<Scalars['String']>;
};

export type IApproveCourseInput = {
  id: Scalars['ID'];
};

export type IAuthorRevenueOuput = {
  __typename?: 'AuthorRevenueOuput';
  totalRevenue: Scalars['Int'];
  bookingSummaries: Maybe<Array<Maybe<IBookingSummary>>>;
};

export type IAuthorSearchScheduleInput = {
  from: Maybe<Scalars['String']>;
  to: Maybe<Scalars['String']>;
};

export type IAverageReview = {
  __typename?: 'AverageReview';
  rate: Maybe<Scalars['Float']>;
  total: Maybe<Scalars['Int']>;
};

export type IAverageReviewInput = {
  productId: Scalars['String'];
};

export type IBook = INode & {
  __typename?: 'Book';
  id: Scalars['ID'];
  courseId: Scalars['ID'];
  title: Maybe<Scalars['String']>;
  isPublic: Maybe<Scalars['Boolean']>;
  thumbnail: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  chapters: Maybe<Array<Maybe<IChapter>>>;
  createdBy: Scalars['ID'];
  updatedBy: Scalars['ID'];
  updatedTime: Maybe<Scalars['DateTime']>;
  createdTime: Maybe<Scalars['DateTime']>;
};

export type IBookCountInput = {
  userId: Maybe<Scalars['ID']>;
};

export type IBooking = {
  __typename?: 'Booking';
  id: Scalars['ID'];
  url: Maybe<Scalars['String']>;
  password: Maybe<Scalars['String']>;
  scheduleId: Scalars['ID'];
  userId: Scalars['ID'];
  userName: Maybe<Scalars['ID']>;
  authorId: Scalars['ID'];
  authorName: Maybe<Scalars['String']>;
  lessonLevel: Maybe<Scalars['String']>;
  lessonTopic: Maybe<Scalars['String']>;
  lessonFlows: Maybe<Array<Maybe<Scalars['String']>>>;
  lessonStrictness: Maybe<Array<Maybe<Scalars['String']>>>;
  lessonOthers: Maybe<Scalars['String']>;
  datetime: Scalars['String'];
  status: Scalars['String'];
  createdBy: Scalars['ID'];
  updatedBy: Scalars['ID'];
  updatedTime: Scalars['DateTime'];
  createdTime: Scalars['DateTime'];
};

export type IBookingOutput = {
  __typename?: 'BookingOutput';
  id: Scalars['ID'];
  url: Maybe<Scalars['String']>;
  password: Maybe<Scalars['String']>;
  scheduleId: Scalars['ID'];
  userId: Scalars['ID'];
  userName: Maybe<Scalars['String']>;
  userProfilePath: Maybe<Scalars['String']>;
  authorId: Scalars['ID'];
  authorName: Maybe<Scalars['String']>;
  authorTeachingExperience: Maybe<Scalars['String']>;
  authorBirthday: Maybe<Scalars['String']>;
  authorAvgRate: Maybe<Scalars['Float']>;
  authorProfilePath: Maybe<Scalars['String']>;
  lessonLevel: Maybe<Scalars['String']>;
  lessonTopic: Maybe<Scalars['String']>;
  lessonFlows: Maybe<Array<Maybe<Scalars['String']>>>;
  lessonStrictness: Maybe<Array<Maybe<Scalars['String']>>>;
  lessonOthers: Maybe<Scalars['String']>;
  datetime: Scalars['String'];
  status: Scalars['String'];
  isCanCancel: Maybe<Scalars['Boolean']>;
  createdBy: Maybe<Scalars['ID']>;
  updatedTime: Maybe<Scalars['DateTime']>;
  createdTime: Maybe<Scalars['DateTime']>;
};

export type IBookingSummary = {
  __typename?: 'BookingSummary';
  bookingId: Scalars['String'];
  datetime: Scalars['String'];
  studentName: Scalars['String'];
  status: Scalars['String'];
  revenue: Scalars['Int'];
};

export type ICsvUploadOutput = {
  __typename?: 'CSVUploadOutput';
  id: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
};

export type ICancelBookingInput = {
  id: Scalars['ID'];
};

export type ICategory = {
  __typename?: 'Category';
  title: Maybe<Scalars['String']>;
  icon: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
};

export type ICategoryInput = {
  title: Maybe<Scalars['String']>;
  icon: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
};

export type ICertificate = {
  __typename?: 'Certificate';
  name: Maybe<Scalars['String']>;
  image: Maybe<Scalars['String']>;
};

export type ICertificateInput = {
  name: Maybe<Scalars['String']>;
  image: Maybe<Scalars['String']>;
};

export type IChangePasswordUserInput = {
  id: Maybe<Scalars['String']>;
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
  confirmNewPassword: Scalars['String'];
};

export type IChapter = {
  __typename?: 'Chapter';
  id: Scalars['ID'];
  bookId: Scalars['ID'];
  title: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  lessons: Maybe<Array<Maybe<ILesson>>>;
};

export type IChapterInput = {
  id: Scalars['ID'];
  bookId: Scalars['ID'];
  title: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  lessons: Maybe<Array<Maybe<ILessonInput>>>;
};

export type IClaimBookingInput = {
  id: Scalars['ID'];
};

export type IConfirmInput = {
  token: Scalars['String'];
};

export type IContact = INode & {
  __typename?: 'Contact';
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  datetime: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  subject: Maybe<Scalars['String']>;
  content: Maybe<Scalars['String']>;
  updatedTime: Maybe<Scalars['DateTime']>;
  createdTime: Maybe<Scalars['DateTime']>;
};

export type ICourse = INode & {
  __typename?: 'Course';
  id: Scalars['ID'];
  priceId: Maybe<Scalars['String']>;
  lang: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
  duration: Maybe<Scalars['Int']>;
  price: Maybe<Scalars['Int']>;
  isFree: Maybe<Scalars['Boolean']>;
  isPublic: Maybe<Scalars['Boolean']>;
  thumbnail: Maybe<Scalars['String']>;
  seoName: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  content: Maybe<Scalars['String']>;
  message: Maybe<Scalars['String']>;
  lessons: Maybe<Array<Maybe<ICourseLesson>>>;
  skills: Maybe<Array<Maybe<Scalars['String']>>>;
  status: Maybe<Scalars['String']>;
  owner: Maybe<ICourseOwner>;
  createdBy: Scalars['ID'];
  updatedBy: Scalars['ID'];
  updatedTime: Maybe<Scalars['DateTime']>;
  createdTime: Maybe<Scalars['DateTime']>;
};

export type ICourseCountInput = {
  userId: Maybe<Scalars['ID']>;
};

export type ICourseData = {
  __typename?: 'CourseData';
  courses: Maybe<Array<Maybe<ICourse>>>;
  page: Scalars['Int'];
};

export type ICourseHeader = {
  __typename?: 'CourseHeader';
  title: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  banner: Maybe<Scalars['String']>;
  bannerBlur: Maybe<Scalars['Int']>;
};

export type ICourseLesson = {
  __typename?: 'CourseLesson';
  title: Maybe<Scalars['String']>;
  video: Maybe<Scalars['String']>;
  content: Maybe<Scalars['String']>;
};

export type ICourseListModel = {
  __typename?: 'CourseListModel';
  id: Scalars['ID'];
  priceId: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  isPublic: Maybe<Scalars['Boolean']>;
  type: Maybe<Scalars['String']>;
  status: Maybe<Scalars['String']>;
  thumbnail: Maybe<Scalars['String']>;
  seoName: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  owner: Maybe<ICourseOwner>;
  updatedTime: Scalars['DateTime'];
  createdTime: Scalars['DateTime'];
};

export type ICourseOwner = {
  __typename?: 'CourseOwner';
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  profilePath: Maybe<Scalars['String']>;
  totalCourses: Maybe<Scalars['Int']>;
  totalStudents: Maybe<Scalars['Int']>;
  totalReviews: Maybe<Scalars['Int']>;
  avgRate: Maybe<Scalars['Float']>;
  rates: Maybe<Array<Maybe<IRate>>>;
};

export type ICourseSetting = {
  __typename?: 'CourseSetting';
  isPublic: Maybe<Scalars['Boolean']>;
  banner: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  limit: Maybe<Scalars['Int']>;
  bannerBlur: Maybe<Scalars['Int']>;
  featuredPosts: Maybe<Array<Maybe<IFeaturedPost>>>;
};

export type ICourseSettingInput = {
  isPublic: Maybe<Scalars['Boolean']>;
  title: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  banner: Maybe<Scalars['String']>;
  limit: Maybe<Scalars['Int']>;
  bannerBlur: Maybe<Scalars['Int']>;
  featuredPosts: Maybe<Array<Maybe<IFeaturedPostInput>>>;
};

export type ICreateAboutInput = {
  type: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
};

export type ICreateBookInput = {
  title: Maybe<Scalars['String']>;
};

export type ICreateBookingInput = {
  scheduleId: Scalars['ID'];
  userId: Maybe<Scalars['ID']>;
  userName: Maybe<Scalars['String']>;
  authorId: Scalars['ID'];
  authorName: Maybe<Scalars['String']>;
  lessonLevel: Maybe<Scalars['String']>;
  lessonTopic: Maybe<Scalars['String']>;
  lessonFlows: Maybe<Array<Maybe<Scalars['String']>>>;
  lessonStrictness: Maybe<Array<Maybe<Scalars['String']>>>;
  lessonOthers: Maybe<Scalars['String']>;
  datetime: Scalars['String'];
};

export type ICreateCourseBookInput = {
  courseId: Scalars['ID'];
};

export type ICreateCourseInput = {
  title: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
};

export type ICreateEventInput = {
  isPublic: Maybe<Scalars['Boolean']>;
  thumbnail: Maybe<Scalars['String']>;
  banner: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  content: Maybe<Scalars['String']>;
  target: Maybe<Scalars['String']>;
  from: Maybe<Scalars['DateTime']>;
  to: Maybe<Scalars['DateTime']>;
  isActive: Maybe<Scalars['Boolean']>;
};

export type ICreateExamInput = {
  courseId: Scalars['String'];
};

export type ICreateIdentityByCourseInput = {
  userId: Scalars['ID'];
  courseId: Scalars['String'];
};

export type ICreateIdentityInput = {
  userId: Scalars['ID'];
  access: Maybe<IAccessType>;
  courseId: Maybe<Scalars['String']>;
  table: Maybe<Scalars['String']>;
  permission: Maybe<Scalars['Int']>;
};

export type ICreateMarketingInput = {
  type: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  address: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
};

export type ICreateNotificationInput = {
  type: Maybe<Scalars['String']>;
  message: Maybe<Scalars['String']>;
  read: Maybe<Scalars['Boolean']>;
  userId: Maybe<Scalars['String']>;
  sourceId: Maybe<Scalars['String']>;
};

export type ICreateOrderInput = {
  courseIds: Maybe<Array<Scalars['ID']>>;
  priceIds: Maybe<Array<Scalars['ID']>>;
  userId: Scalars['ID'];
};

export type ICreatePostInput = {
  category: Maybe<Scalars['String']>;
  thumbnail: Maybe<Scalars['String']>;
  imageCover: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  seoName: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  content: Maybe<Scalars['String']>;
};

export type ICreateQuestionInput = {
  type: Scalars['String'];
  bookId: Scalars['String'];
  lessonId: Scalars['String'];
  question: Maybe<IQaInput>;
  groupQuestion: Maybe<IGroupQaInput>;
};

export type ICreateReportInput = {
  type: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  reason: Maybe<Scalars['String']>;
  authorId: Maybe<Scalars['String']>;
  bookingId: Maybe<Scalars['String']>;
  courseId: Maybe<Scalars['String']>;
};

export type ICreateReviewInput = {
  courseId: Maybe<Scalars['String']>;
  rate: Maybe<Scalars['Int']>;
  content: Maybe<Scalars['String']>;
};

export type ICreateRuleInput = {
  allow: IAllowRule;
  operations: Array<Maybe<IOperationRule>>;
};

export type ICreateScheduleInput = {
  userId: Maybe<Scalars['ID']>;
  userName: Maybe<Scalars['ID']>;
  datetime: Scalars['String'];
};

export type ICreateServiceInput = {
  category: Maybe<Scalars['String']>;
  thumbnail: Maybe<Scalars['String']>;
  imageCover: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  seoName: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  content: Maybe<Scalars['String']>;
};

export type ICreateTicketInput = {
  userId: Scalars['ID'];
  invoiceId: Maybe<Scalars['ID']>;
  bookingId: Maybe<Scalars['ID']>;
  productId: Maybe<Scalars['ID']>;
  ticket: Maybe<Scalars['Int']>;
};

export type ICreateTransactionInput = {
  events: Array<Maybe<Scalars['String']>>;
};

export type ICreateUserExamInput = {
  examId: Scalars['ID'];
};

export type ICreateUserInput = {
  role: Maybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
  confirmPassword: Scalars['String'];
};

export type IDeleteAboutInput = {
  id: Scalars['ID'];
};

export type IDeleteBookInput = {
  id: Scalars['ID'];
};

export type IDeleteContactInput = {
  id: Scalars['ID'];
};

export type IDeleteCourseInput = {
  id: Scalars['ID'];
};

export type IDeleteEventInput = {
  id: Scalars['ID'];
};

export type IDeleteExamInput = {
  id: Scalars['ID'];
};

export type IDeleteHelpInput = {
  id: Scalars['ID'];
};

export type IDeleteIdentityInput = {
  id: Scalars['ID'];
};

export type IDeleteMarketingInput = {
  id: Scalars['ID'];
};

export type IDeleteMediaInput = {
  id: Scalars['String'];
};

export type IDeleteNotificationInput = {
  id: Scalars['ID'];
};

export type IDeleteOrderInput = {
  id: Scalars['ID'];
};

export type IDeletePostInput = {
  id: Scalars['ID'];
};

export type IDeleteQuestionInput = {
  id: Scalars['ID'];
};

export type IDeleteReportInput = {
  id: Scalars['ID'];
};

export type IDeleteReviewInput = {
  id: Scalars['ID'];
};

export type IDeleteScheduleInput = {
  id: Scalars['ID'];
};

export type IDeleteServiceInput = {
  id: Scalars['ID'];
};

export type IDeleteSessionInput = {
  id: Scalars['String'];
};

export type IDeleteTransactionInput = {
  id: Scalars['ID'];
};

export type IDeleteUserExamInput = {
  id: Scalars['ID'];
};

export type IDeleteUserInput = {
  id: Scalars['ID'];
};

export type IEvent = INode & {
  __typename?: 'Event';
  id: Scalars['ID'];
  isPublic: Maybe<Scalars['Boolean']>;
  type: Maybe<Scalars['String']>;
  thumbnail: Maybe<Scalars['String']>;
  banner: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  ticket: Maybe<Scalars['Int']>;
  courseIds: Maybe<Array<Maybe<Scalars['String']>>>;
  description: Maybe<Scalars['String']>;
  content: Maybe<Scalars['String']>;
  target: Maybe<Scalars['String']>;
  from: Maybe<Scalars['DateTime']>;
  to: Maybe<Scalars['DateTime']>;
  isActive: Maybe<Scalars['Boolean']>;
  createdBy: Scalars['ID'];
  updatedTime: Maybe<Scalars['DateTime']>;
  createdTime: Maybe<Scalars['DateTime']>;
};

export type IExam = INode & {
  __typename?: 'Exam';
  id: Scalars['ID'];
  courseId: Maybe<Scalars['String']>;
  courseName: Maybe<Scalars['String']>;
  lessonId: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  time: Maybe<Scalars['Int']>;
  type: Maybe<Scalars['String']>;
  isPublic: Maybe<Scalars['Boolean']>;
  isFree: Maybe<Scalars['Boolean']>;
  sections: Maybe<Array<Maybe<ISection>>>;
  createdBy: Scalars['ID'];
  student: Maybe<IUser>;
  testDate: Maybe<Scalars['DateTime']>;
  updatedTime: Maybe<Scalars['DateTime']>;
  createdTime: Maybe<Scalars['DateTime']>;
};

export type IExamCountInput = {
  userId: Maybe<Scalars['ID']>;
};

export type IExamQuestionInput = {
  id: Scalars['String'];
};

export type IFeaturedPost = {
  __typename?: 'FeaturedPost';
  id: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  thumbnail: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
  createdTime: Maybe<Scalars['DateTime']>;
};

export type IFeaturedPostInput = {
  id: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  thumbnail: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
  createdTime: Maybe<Scalars['DateTime']>;
};

export type IFilter = {
  authorId: Maybe<Scalars['ID']>;
  from: Maybe<Scalars['String']>;
  to: Maybe<Scalars['String']>;
};

export type IFinishBookingInput = {
  id: Scalars['ID'];
};

export type IFooterApp = {
  __typename?: 'FooterApp';
  ios: Maybe<Scalars['String']>;
  android: Maybe<Scalars['String']>;
};

export type IFooterAppInput = {
  ios: Maybe<Scalars['String']>;
  android: Maybe<Scalars['String']>;
};

export type IFooterInput = {
  profile: Maybe<IFooterProfileInput>;
  apps: Maybe<IFooterAppInput>;
  copyright: Maybe<Scalars['String']>;
};

export type IFooterProfile = {
  __typename?: 'FooterProfile';
  name: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
};

export type IFooterProfileInput = {
  name: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
};

export type IFooterSetting = {
  __typename?: 'FooterSetting';
  profile: Maybe<IFooterProfile>;
  apps: Maybe<IFooterApp>;
  copyright: Maybe<Scalars['String']>;
};

export type IGetAboutInput = {
  id: Scalars['ID'];
};

export type IGetAuthorProfileInput = {
  id: Scalars['ID'];
};

export type IGetAuthorRevenueInput = {
  filter: Maybe<IFilter>;
};

export type IGetBookInput = {
  id: Scalars['ID'];
};

export type IGetContactInput = {
  id: Scalars['ID'];
};

export type IGetCourseBookInput = {
  courseId: Scalars['ID'];
};

export type IGetCourseInput = {
  id: Scalars['ID'];
};

export type IGetCourseListInput = {
  filter: Maybe<IModelCourseFilterInput>;
  sortDirection: ISortDirection;
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type IGetCourseSyllabusInput = {
  id: Scalars['ID'];
};

export type IGetEventInput = {
  id: Scalars['ID'];
};

export type IGetExamInput = {
  id: Scalars['ID'];
};

export type IGetHelpInput = {
  id: Scalars['ID'];
};

export type IGetIdentityInput = {
  id: Scalars['ID'];
};

export type IGetMarketingInput = {
  id: Scalars['ID'];
};

export type IGetNotificationInput = {
  id: Scalars['ID'];
};

export type IGetOrderInput = {
  id: Scalars['ID'];
};

export type IGetPostInput = {
  id: Scalars['ID'];
};

export type IGetQuestionInput = {
  id: Scalars['ID'];
};

export type IGetReportInput = {
  id: Scalars['ID'];
};

export type IGetReviewInput = {
  id: Scalars['ID'];
};

export type IGetRuleInput = {
  id: Scalars['ID'];
};

export type IGetScheduleInput = {
  from: Maybe<Scalars['String']>;
  to: Maybe<Scalars['String']>;
  authorId: Maybe<Scalars['String']>;
};

export type IGetServiceInput = {
  id: Scalars['ID'];
};

export type IGetSettingInput = {
  id: Maybe<Scalars['String']>;
  lang: Maybe<Scalars['String']>;
};

export type IGetStatisticInput = {
  userId: Maybe<Scalars['ID']>;
};

export type IGetTransactionInput = {
  id: Scalars['ID'];
};

export type IGetUserCourseInput = {
  userId: Scalars['String'];
  courseId: Scalars['String'];
};

export type IGetUserExamInput = {
  id: Maybe<Scalars['ID']>;
  examId: Maybe<Scalars['ID']>;
  userId: Maybe<Scalars['ID']>;
};

export type IGetUserIdentity = {
  userId: Scalars['ID'];
};

export type IGetUserInput = {
  id: Scalars['ID'];
};

export type IGroupHelp = {
  __typename?: 'GroupHelp';
  role: Maybe<Scalars['String']>;
  helps: Maybe<Array<Maybe<IHelp>>>;
};

export type IGroupQa = {
  __typename?: 'GroupQA';
  question: Maybe<Scalars['String']>;
  subQuestions: Maybe<Array<Maybe<IQa>>>;
};

export type IGroupQaInput = {
  question: Maybe<Scalars['String']>;
  subQuestions: Maybe<Array<Maybe<IQaInput>>>;
};

export type IHelp = INode & {
  __typename?: 'Help';
  id: Scalars['ID'];
  forRole: Maybe<Scalars['String']>;
  seoName: Maybe<Scalars['String']>;
  isPublic: Maybe<Scalars['Boolean']>;
  category: Maybe<Scalars['String']>;
  translations: Maybe<Array<Maybe<IHelpTranslation>>>;
  createdBy: Scalars['ID'];
  updatedTime: Maybe<Scalars['DateTime']>;
  createdTime: Maybe<Scalars['DateTime']>;
};

export type IHelpTranslation = {
  __typename?: 'HelpTranslation';
  lang: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  content: Maybe<Scalars['String']>;
};

export type IHelpTranslationInput = {
  lang: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  content: Maybe<Scalars['String']>;
};

export type IHistory = {
  __typename?: 'History';
  courseId: Maybe<Scalars['String']>;
  price: Maybe<Scalars['Int']>;
  createdTime: Maybe<Scalars['DateTime']>;
};

export type IHomeInput = {
  promotion: Maybe<IHomeSettingInput>;
  examFree: Maybe<IHomeSettingInput>;
  categories: Maybe<Array<Maybe<ICategoryInput>>>;
  course: Maybe<IHomeSettingInput>;
  news: Maybe<IHomeSettingInput>;
  blog: Maybe<IHomeSettingInput>;
};

export type IHomePage = {
  __typename?: 'HomePage';
  promotion: Maybe<IHomeSetting>;
  examFree: Maybe<IHomeSetting>;
  categories: Maybe<Array<Maybe<ICategory>>>;
  course: Maybe<IHomeSetting>;
  news: Maybe<IHomeSetting>;
  blog: Maybe<IHomeSetting>;
};

export type IHomeSetting = {
  __typename?: 'HomeSetting';
  isPublic: Maybe<Scalars['Boolean']>;
  banner: Maybe<Scalars['String']>;
  bannerBlur: Maybe<Scalars['Int']>;
  title: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  limit: Maybe<Scalars['Int']>;
  featuredPosts: Maybe<Array<Maybe<IFeaturedPost>>>;
};

export type IHomeSettingInput = {
  isPublic: Maybe<Scalars['Boolean']>;
  title: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  banner: Maybe<Scalars['String']>;
  limit: Maybe<Scalars['Int']>;
  bannerBlur: Maybe<Scalars['Int']>;
  featuredPosts: Maybe<Array<Maybe<IFeaturedPostInput>>>;
};

export type IIdentity = INode & {
  __typename?: 'Identity';
  id: Scalars['ID'];
  userId: Scalars['ID'];
  access: Maybe<IAccessType>;
  courseId: Maybe<Scalars['String']>;
  table: Maybe<Scalars['String']>;
  permission: Maybe<Scalars['Int']>;
  expiredDate: Maybe<Scalars['DateTime']>;
  createdBy: Scalars['ID'];
  updatedBy: Scalars['ID'];
  updatedTime: Maybe<Scalars['DateTime']>;
  createdTime: Maybe<Scalars['DateTime']>;
};

export type ILesson = {
  __typename?: 'Lesson';
  id: Scalars['ID'];
  chapterId: Scalars['ID'];
  title: Maybe<Scalars['String']>;
  video: Maybe<Scalars['String']>;
  content: Maybe<Scalars['String']>;
};

export type ILessonInput = {
  id: Scalars['ID'];
  chapterId: Scalars['ID'];
  title: Maybe<Scalars['String']>;
  video: Maybe<Scalars['String']>;
  content: Maybe<Scalars['String']>;
};

export type IListAboutInput = {
  sortDirection: ISortDirection;
  limit: Scalars['Int'];
  nextToken: Maybe<Scalars['String']>;
};

export type IListAuthorCoursesInput = {
  filter: Maybe<IModelCourseFilterInput>;
  sortDirection: ISortDirection;
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type IListBlogData = {
  __typename?: 'ListBlogData';
  page: Scalars['Int'];
  totalPages: Scalars['Int'];
  results: Maybe<Array<Maybe<IPost>>>;
};

export type IListBlogsPublicInput = {
  filter: Maybe<IModelPostFilterInput>;
  sortDirection: ISortDirection;
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type IListBookInput = {
  filter: Maybe<IModelBookFilterInput>;
  sortDirection: ISortDirection;
  limit: Scalars['Int'];
  nextToken: Maybe<Scalars['String']>;
};

export type IListBookingInput = {
  filter: IModelBookingFilterInput;
};

export type IListContactData = {
  __typename?: 'ListContactData';
  page: Scalars['Int'];
  totalPages: Scalars['Int'];
  results: Maybe<Array<Maybe<IContact>>>;
};

export type IListContactsInput = {
  filter: Maybe<IModelContactFilterInput>;
  sortDirection: ISortDirection;
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type IListCourseData = {
  __typename?: 'ListCourseData';
  page: Scalars['Int'];
  header: Maybe<ICourseHeader>;
  totalPages: Scalars['Int'];
  results: Maybe<Array<Maybe<ICourse>>>;
  featureCourses: Maybe<Array<Maybe<ICourse>>>;
};

export type IListCourseInput = {
  filter: Maybe<IModelCourseFilterInput>;
  sortDirection: ISortDirection;
  limit: Scalars['Int'];
  nextToken: Maybe<Scalars['String']>;
};

export type IListCoursesPublicInput = {
  filter: Maybe<IModelCourseFilterInput>;
  sortDirection: ISortDirection;
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type IListEventData = {
  __typename?: 'ListEventData';
  page: Scalars['Int'];
  totalPages: Scalars['Int'];
  results: Maybe<Array<Maybe<IEvent>>>;
};

export type IListEventsInput = {
  filter: Maybe<IModelEventFilterInput>;
  sortDirection: ISortDirection;
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type IListExamInput = {
  filter: Maybe<IModelExamFilterInput>;
  sortDirection: ISortDirection;
  limit: Scalars['Int'];
  nextToken: Maybe<Scalars['String']>;
};

export type IListHelpsInput = {
  filter: Maybe<IModelHelpFilterInput>;
  sortDirection: ISortDirection;
  limit: Scalars['Int'];
  nextToken: Maybe<Scalars['String']>;
};

export type IListIdentityInput = {
  filter: Maybe<IModelIdentityFilterInput>;
  sortDirection: ISortDirection;
  limit: Scalars['Int'];
  nextToken: Maybe<Scalars['String']>;
};

export type IListMediaInput = {
  sortDirection: ISortDirection;
  limit: Scalars['Int'];
  nextToken: Maybe<Scalars['String']>;
};

export type IListOrdersInput = {
  limit: Maybe<Scalars['Int']>;
  sortDirection: ISortDirection;
  filter: Maybe<IModelOrderFilterInput>;
};

export type IListPostData = {
  __typename?: 'ListPostData';
  page: Scalars['Int'];
  totalPages: Scalars['Int'];
  totalResults: Scalars['Int'];
  results: Maybe<Array<Maybe<IPost>>>;
  blogs: Maybe<Array<Maybe<IPost>>>;
  business: Maybe<Array<Maybe<IPost>>>;
  exams: Maybe<Array<Maybe<IPost>>>;
  featurePosts: Maybe<Array<Maybe<IPost>>>;
  header: Maybe<IPostHeader>;
};

export type IListPostsInput = {
  filter: Maybe<IModelPostFilterInput>;
  sortDirection: ISortDirection;
  limit: Scalars['Int'];
  nextToken: Maybe<Scalars['String']>;
};

export type IListPostsPublicInput = {
  filter: Maybe<IModelPostFilterInput>;
  sortDirection: ISortDirection;
  limit: Scalars['Int'];
  page: Scalars['Int'];
  platform: Maybe<Scalars['String']>;
};

export type IListQuestionInput = {
  filter: Maybe<IModelQuestionFilterInput>;
  sortDirection: ISortDirection;
  limit: Scalars['Int'];
  nextToken: Maybe<Scalars['String']>;
};

export type IListRecentCoursesInput = {
  userId: Scalars['String'];
  sortDirection: ISortDirection;
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type IListRuleInput = {
  sortDirection: ISortDirection;
  limit: Scalars['Int'];
  nextToken: Maybe<Scalars['String']>;
};

export type IListServiceData = {
  __typename?: 'ListServiceData';
  page: Scalars['Int'];
  totalPages: Scalars['Int'];
  total: Scalars['Int'];
  results: Maybe<Array<Maybe<IService>>>;
};

export type IListServiceInput = {
  filter: Maybe<IModelServiceFilterInput>;
  sortDirection: ISortDirection;
  limit: Scalars['Int'];
  nextToken: Maybe<Scalars['String']>;
};

export type IListSessionInput = {
  filter: Maybe<IModelServiceFilterInput>;
  sortDirection: ISortDirection;
  limit: Scalars['Int'];
  nextToken: Maybe<Scalars['String']>;
};

export type IListTransactionInput = {
  filter: Maybe<IModelTransactionFilterInput>;
  sortDirection: ISortDirection;
  limit: Scalars['Int'];
  nextToken: Maybe<Scalars['String']>;
};

export type IListUserCourseData = {
  __typename?: 'ListUserCourseData';
  page: Scalars['Int'];
  totalPages: Scalars['Int'];
  totalResults: Scalars['Int'];
  results: Maybe<Array<Maybe<IUserCourse>>>;
};

export type IListUserCoursesInput = {
  userId: Maybe<Scalars['String']>;
};

export type IListUserExamInput = {
  sortDirection: ISortDirection;
  limit: Scalars['Int'];
  filter: Maybe<IModelUserExamFilterInput>;
};

export type IListUserInput = {
  filter: Maybe<IModelUserFilterInput>;
  sortDirection: ISortDirection;
  limit: Scalars['Int'];
  nextToken: Maybe<Scalars['String']>;
};

export type ILocale = {
  __typename?: 'Locale';
  errors: Maybe<Scalars['String']>;
  typeList: Maybe<Scalars['String']>;
  global: Maybe<Scalars['String']>;
};

export type ILocaleInput = {
  errors: Maybe<Scalars['String']>;
  typeList: Maybe<Scalars['String']>;
  global: Maybe<Scalars['String']>;
};

export type ILocaleSetting = {
  __typename?: 'LocaleSetting';
  ja: Maybe<ILocale>;
  vi: Maybe<ILocale>;
  en: Maybe<ILocale>;
};

export type ILoginUserInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  provider: ISocialProvider;
};

export type IMarketing = INode & {
  __typename?: 'Marketing';
  id: Scalars['ID'];
  type: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  address: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  userId: Maybe<Scalars['String']>;
  accesss: Maybe<Array<Maybe<IAccess>>>;
  createdBy: Scalars['ID'];
  updatedTime: Maybe<Scalars['DateTime']>;
  createdTime: Maybe<Scalars['DateTime']>;
};

export type IMedia = INode & {
  __typename?: 'Media';
  id: Scalars['ID'];
  originalName: Scalars['String'];
  mimetype: Scalars['String'];
  storagePath: Scalars['String'];
  thumbnail: Maybe<Scalars['String']>;
  videoStreamUrl: Maybe<Scalars['String']>;
  src: Scalars['String'];
  ratio: Maybe<Scalars['String']>;
  size: Maybe<Scalars['String']>;
  createdBy: Scalars['ID'];
  updatedTime: Scalars['DateTime'];
  createdTime: Scalars['DateTime'];
};

export type IMediaShort = {
  __typename?: 'MediaShort';
  id: Scalars['String'];
  originalName: Scalars['String'];
  mimetype: Scalars['String'];
  storagePath: Scalars['String'];
  thumbnail: Maybe<Scalars['String']>;
  videoStreamUrl: Maybe<Scalars['String']>;
  src: Scalars['String'];
};

export type IMediaUploadOutput = {
  __typename?: 'MediaUploadOutput';
  paths: Maybe<Array<Maybe<IMediaShort>>>;
};

export type IModelBookFilterInput = {
  keyword: Maybe<Scalars['String']>;
  id: Maybe<Scalars['ID']>;
  ids: Maybe<Array<Maybe<Scalars['ID']>>>;
  and: Maybe<Array<Maybe<IModelBookFilterInput>>>;
  or: Maybe<Array<Maybe<IModelBookFilterInput>>>;
  not: Maybe<IModelBookFilterInput>;
};

export type IModelBookingFilterInput = {
  scheduleId: Maybe<Scalars['ID']>;
  userId: Maybe<Scalars['String']>;
  authorId: Maybe<Scalars['String']>;
  from: Scalars['String'];
  to: Scalars['String'];
};

export type IModelContactFilterInput = {
  keyword: Maybe<Scalars['String']>;
};

export type IModelCourseFilterInput = {
  keyword: Maybe<Scalars['String']>;
  id: Maybe<Scalars['ID']>;
  ids: Maybe<Array<Maybe<Scalars['ID']>>>;
  status: Maybe<Array<Maybe<Scalars['String']>>>;
  userIds: Maybe<Array<Maybe<Scalars['String']>>>;
  type: Maybe<Array<Maybe<Scalars['String']>>>;
  title: Maybe<Scalars['String']>;
  price: Maybe<Scalars['Int']>;
  isFree: Maybe<Scalars['Boolean']>;
};

export type IModelEventFilterInput = {
  keyword: Maybe<Scalars['String']>;
  id: Maybe<Scalars['ID']>;
  category: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type IModelExamFilterInput = {
  keyword: Maybe<Scalars['String']>;
  id: Maybe<Scalars['ID']>;
  courseId: Maybe<Scalars['String']>;
  category: Maybe<Scalars['String']>;
  isFree: Maybe<Scalars['Boolean']>;
  and: Maybe<Array<Maybe<IModelExamFilterInput>>>;
  or: Maybe<Array<Maybe<IModelExamFilterInput>>>;
  not: Maybe<IModelExamFilterInput>;
};

export type IModelHelpFilterInput = {
  keyword: Maybe<Scalars['String']>;
  id: Maybe<Scalars['ID']>;
  category: Maybe<Array<Maybe<Scalars['String']>>>;
  and: Maybe<Array<Maybe<IModelHelpFilterInput>>>;
  or: Maybe<Array<Maybe<IModelHelpFilterInput>>>;
  not: Maybe<IModelHelpFilterInput>;
};

export type IModelIdentityFilterInput = {
  keyword: Maybe<Scalars['String']>;
  id: Maybe<Scalars['ID']>;
  userId: Maybe<Scalars['ID']>;
  access: Maybe<IAccessType>;
  and: Maybe<Array<Maybe<IModelIdentityFilterInput>>>;
  or: Maybe<Array<Maybe<IModelIdentityFilterInput>>>;
  not: Maybe<IModelIdentityFilterInput>;
};

export type IModelOrderFilterInput = {
  keyword: Maybe<Scalars['String']>;
  id: Maybe<Scalars['ID']>;
  courseId: Maybe<Scalars['String']>;
  userId: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
  status: Maybe<IOrderStatus>;
  and: Maybe<Array<Maybe<IModelQuestionFilterInput>>>;
  or: Maybe<Array<Maybe<IModelQuestionFilterInput>>>;
  not: Maybe<IModelQuestionFilterInput>;
};

export type IModelPostFilterInput = {
  keyword: Maybe<Scalars['String']>;
  id: Maybe<Scalars['ID']>;
  status: Maybe<Array<Maybe<Scalars['String']>>>;
  category: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type IModelQuestionFilterInput = {
  keyword: Maybe<Scalars['String']>;
  id: Maybe<Scalars['ID']>;
  bookId: Maybe<Scalars['String']>;
  lessonId: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
  and: Maybe<Array<Maybe<IModelQuestionFilterInput>>>;
  or: Maybe<Array<Maybe<IModelQuestionFilterInput>>>;
  not: Maybe<IModelQuestionFilterInput>;
};

export type IModelReviewFilterInput = {
  keyword: Maybe<Scalars['String']>;
  courseId: Maybe<Scalars['String']>;
};

export type IModelSearchScheduleFilterInput = {
  from: Maybe<Scalars['String']>;
  to: Maybe<Scalars['String']>;
  authorId: Maybe<Scalars['String']>;
  lessonLevel: Maybe<Scalars['String']>;
  lessonTopic: Maybe<Scalars['String']>;
};

export type IModelServiceFilterInput = {
  keyword: Maybe<Scalars['String']>;
  id: Maybe<Scalars['ID']>;
  category: Maybe<Scalars['String']>;
};

export type IModelTransactionFilterInput = {
  keyword: Maybe<Scalars['String']>;
  id: Maybe<Scalars['ID']>;
  and: Maybe<Array<Maybe<IModelTransactionFilterInput>>>;
  or: Maybe<Array<Maybe<IModelTransactionFilterInput>>>;
  not: Maybe<IModelTransactionFilterInput>;
};

export type IModelUserExamFilterInput = {
  keyword: Maybe<Scalars['String']>;
  id: Maybe<Scalars['ID']>;
  courseId: Maybe<Scalars['String']>;
  userId: Maybe<Scalars['String']>;
  examId: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
  status: Maybe<IUserExamStatus>;
};

export type IModelUserFilterInput = {
  and: Maybe<Array<Maybe<IModelUserFilterInput>>>;
  id: Maybe<Scalars['ID']>;
  keyword: Maybe<Scalars['String']>;
  not: Maybe<IModelUserFilterInput>;
  or: Maybe<Array<Maybe<IModelUserFilterInput>>>;
  roles: Maybe<Array<Maybe<Scalars['String']>>>;
  status: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type IMutation = {
  __typename?: 'Mutation';
  _: Maybe<Scalars['String']>;
  approveCourse: Maybe<Scalars['Boolean']>;
  cancelBooking: Maybe<Scalars['Boolean']>;
  changePassword: Maybe<Scalars['Boolean']>;
  claimBooking: Maybe<Scalars['Boolean']>;
  confirm: Maybe<Scalars['Boolean']>;
  createAbout: Maybe<Scalars['Boolean']>;
  createBook: Maybe<Scalars['String']>;
  createBooking: Maybe<Scalars['Boolean']>;
  createCourse: Maybe<Scalars['String']>;
  createCourseBook: Maybe<Scalars['String']>;
  createEvent: Maybe<Scalars['String']>;
  createExam: Maybe<Scalars['String']>;
  createHelp: Maybe<Scalars['String']>;
  createIdentity: Maybe<Scalars['String']>;
  createIdentityByCourse: Maybe<Scalars['String']>;
  createMarketing: Maybe<Scalars['String']>;
  createNotification: Maybe<Scalars['Boolean']>;
  createOrder: Maybe<Scalars['String']>;
  createPost: Maybe<Scalars['String']>;
  createQuestion: Maybe<Scalars['String']>;
  createReport: Maybe<Scalars['Boolean']>;
  createReview: Maybe<Scalars['String']>;
  createRule: Maybe<IIdentity>;
  createSchedule: Maybe<Scalars['Boolean']>;
  createService: Maybe<Scalars['String']>;
  createTicket: Maybe<Scalars['Boolean']>;
  createTransaction: Maybe<Scalars['String']>;
  createUser: Maybe<Scalars['String']>;
  createUserExam: Maybe<IUserExamDetails>;
  deleteAbout: Maybe<Scalars['Boolean']>;
  deleteBook: Maybe<Scalars['Boolean']>;
  deleteContact: Maybe<Scalars['Boolean']>;
  deleteCourse: Maybe<Scalars['Boolean']>;
  deleteEvent: Maybe<Scalars['Boolean']>;
  deleteExam: Maybe<Scalars['Boolean']>;
  deleteHelp: Maybe<Scalars['Boolean']>;
  deleteIdentity: Maybe<Scalars['Boolean']>;
  deleteMarketing: Maybe<Scalars['Boolean']>;
  deleteMedia: Maybe<Scalars['Boolean']>;
  deleteNotification: Maybe<Scalars['Boolean']>;
  deleteOrder: Maybe<Scalars['Boolean']>;
  deletePost: Maybe<Scalars['Boolean']>;
  deleteQuestion: Maybe<Scalars['Boolean']>;
  deleteReport: Maybe<Scalars['Boolean']>;
  deleteReview: Maybe<Scalars['Boolean']>;
  deleteRule: Maybe<IIdentity>;
  deleteSchedule: Maybe<Scalars['Boolean']>;
  deleteService: Maybe<Scalars['Boolean']>;
  deleteSession: Maybe<Scalars['Boolean']>;
  deleteTransaction: Maybe<Scalars['Boolean']>;
  deleteUser: Maybe<Scalars['Boolean']>;
  deleteUserExam: Maybe<Scalars['Boolean']>;
  finishBooking: Maybe<Scalars['Boolean']>;
  login: Maybe<IUser>;
  logout: Maybe<Scalars['Boolean']>;
  parseCSV: Maybe<Array<Maybe<ICsvUploadOutput>>>;
  profileUpload: Maybe<IProfileUploadOutput>;
  readNotification: Maybe<Scalars['Boolean']>;
  refundTicket: Maybe<Scalars['Boolean']>;
  register: Maybe<Scalars['Boolean']>;
  rejectCourse: Maybe<Scalars['Boolean']>;
  resetConfirm: Maybe<Scalars['Boolean']>;
  resetPassword: Maybe<Scalars['Boolean']>;
  sendContact: Maybe<Scalars['String']>;
  submitForReviewCourse: Maybe<Scalars['Boolean']>;
  unpublishCourse: Maybe<Scalars['Boolean']>;
  upDateTimeRule: Maybe<IIdentity>;
  updateAbout: Maybe<Scalars['Boolean']>;
  updateBook: Maybe<Scalars['Boolean']>;
  updateBooking: Maybe<Scalars['Boolean']>;
  updateCourse: Maybe<Scalars['Boolean']>;
  updateCoursePrice: Maybe<Scalars['Boolean']>;
  updateEvent: Maybe<Scalars['Boolean']>;
  updateExam: Maybe<Scalars['Boolean']>;
  updateHelp: Maybe<Scalars['Boolean']>;
  updateIdentity: Maybe<Scalars['Boolean']>;
  updateLocaleSetting: Maybe<Scalars['Boolean']>;
  updateMarketing: Maybe<Scalars['Boolean']>;
  updateOrder: Maybe<Scalars['Boolean']>;
  updatePost: Maybe<Scalars['Boolean']>;
  updateQuestion: Maybe<Scalars['Boolean']>;
  updateReport: Maybe<Scalars['Boolean']>;
  updateReview: Maybe<Scalars['Boolean']>;
  updateService: Maybe<Scalars['Boolean']>;
  updateSetting: Maybe<Scalars['Boolean']>;
  updateTransaction: Maybe<Scalars['Boolean']>;
  updateUser: Maybe<Scalars['Boolean']>;
  updateUserExam: Maybe<Scalars['Boolean']>;
  uploadMedia: Maybe<IMediaUploadOutput>;
  userAccess: Maybe<Scalars['Boolean']>;
  userCreateReview: Maybe<Scalars['String']>;
};

export type IMutationApproveCourseArgs = {
  input: IApproveCourseInput;
};

export type IMutationCancelBookingArgs = {
  input: ICancelBookingInput;
};

export type IMutationChangePasswordArgs = {
  input: IChangePasswordUserInput;
};

export type IMutationClaimBookingArgs = {
  input: IClaimBookingInput;
};

export type IMutationConfirmArgs = {
  input: IConfirmInput;
};

export type IMutationCreateAboutArgs = {
  input: ICreateAboutInput;
};

export type IMutationCreateBookArgs = {
  input: ICreateBookInput;
};

export type IMutationCreateBookingArgs = {
  input: ICreateBookingInput;
};

export type IMutationCreateCourseArgs = {
  input: ICreateCourseInput;
};

export type IMutationCreateCourseBookArgs = {
  input: ICreateCourseBookInput;
};

export type IMutationCreateEventArgs = {
  input: ICreateEventInput;
};

export type IMutationCreateExamArgs = {
  input: ICreateExamInput;
};

export type IMutationCreateIdentityArgs = {
  input: ICreateIdentityInput;
};

export type IMutationCreateIdentityByCourseArgs = {
  input: ICreateIdentityByCourseInput;
};

export type IMutationCreateMarketingArgs = {
  input: ICreateMarketingInput;
};

export type IMutationCreateNotificationArgs = {
  input: ICreateNotificationInput;
};

export type IMutationCreateOrderArgs = {
  input: ICreateOrderInput;
};

export type IMutationCreatePostArgs = {
  input: ICreatePostInput;
};

export type IMutationCreateQuestionArgs = {
  input: ICreateQuestionInput;
};

export type IMutationCreateReportArgs = {
  input: ICreateReportInput;
};

export type IMutationCreateReviewArgs = {
  input: ICreateReviewInput;
};

export type IMutationCreateRuleArgs = {
  input: Maybe<ICreateRuleInput>;
};

export type IMutationCreateScheduleArgs = {
  input: ICreateScheduleInput;
};

export type IMutationCreateServiceArgs = {
  input: ICreateServiceInput;
};

export type IMutationCreateTicketArgs = {
  input: ICreateTicketInput;
};

export type IMutationCreateTransactionArgs = {
  input: ICreateTransactionInput;
};

export type IMutationCreateUserArgs = {
  input: ICreateUserInput;
};

export type IMutationCreateUserExamArgs = {
  input: ICreateUserExamInput;
};

export type IMutationDeleteAboutArgs = {
  input: IDeleteAboutInput;
};

export type IMutationDeleteBookArgs = {
  input: IDeleteBookInput;
};

export type IMutationDeleteContactArgs = {
  input: IDeleteContactInput;
};

export type IMutationDeleteCourseArgs = {
  input: IDeleteCourseInput;
};

export type IMutationDeleteEventArgs = {
  input: IDeleteEventInput;
};

export type IMutationDeleteExamArgs = {
  input: IDeleteExamInput;
};

export type IMutationDeleteHelpArgs = {
  input: IDeleteHelpInput;
};

export type IMutationDeleteIdentityArgs = {
  input: IDeleteIdentityInput;
};

export type IMutationDeleteMarketingArgs = {
  input: IDeleteMarketingInput;
};

export type IMutationDeleteMediaArgs = {
  input: IDeleteMediaInput;
};

export type IMutationDeleteNotificationArgs = {
  input: IDeleteNotificationInput;
};

export type IMutationDeleteOrderArgs = {
  input: IDeleteOrderInput;
};

export type IMutationDeletePostArgs = {
  input: IDeletePostInput;
};

export type IMutationDeleteQuestionArgs = {
  input: IDeleteQuestionInput;
};

export type IMutationDeleteReportArgs = {
  input: IDeleteReportInput;
};

export type IMutationDeleteReviewArgs = {
  input: IDeleteReviewInput;
};

export type IMutationDeleteRuleArgs = {
  input: Maybe<IDeleteIdentityInput>;
};

export type IMutationDeleteScheduleArgs = {
  input: IDeleteScheduleInput;
};

export type IMutationDeleteServiceArgs = {
  input: IDeleteServiceInput;
};

export type IMutationDeleteSessionArgs = {
  input: IDeleteSessionInput;
};

export type IMutationDeleteTransactionArgs = {
  input: IDeleteTransactionInput;
};

export type IMutationDeleteUserArgs = {
  input: IDeleteUserInput;
};

export type IMutationDeleteUserExamArgs = {
  input: IDeleteUserExamInput;
};

export type IMutationFinishBookingArgs = {
  input: IFinishBookingInput;
};

export type IMutationLoginArgs = {
  input: ILoginUserInput;
};

export type IMutationParseCsvArgs = {
  files: Array<Scalars['Upload']>;
  table: Scalars['String'];
};

export type IMutationProfileUploadArgs = {
  file: Scalars['Upload'];
};

export type IMutationReadNotificationArgs = {
  input: IReadNotificationInput;
};

export type IMutationRefundTicketArgs = {
  input: IRefundTicketInput;
};

export type IMutationRegisterArgs = {
  input: IRegisterUserInput;
};

export type IMutationRejectCourseArgs = {
  input: IRejectCourseInput;
};

export type IMutationResetConfirmArgs = {
  input: IResetConfirmInput;
};

export type IMutationResetPasswordArgs = {
  input: IResetPasswordInput;
};

export type IMutationSendContactArgs = {
  input: ISendContactInput;
};

export type IMutationSubmitForReviewCourseArgs = {
  input: ISubmitForReviewCourseInput;
};

export type IMutationUnpublishCourseArgs = {
  input: IUnpublishCourseInput;
};

export type IMutationUpDateTimeRuleArgs = {
  input: Maybe<IUpDateTimeRuleInput>;
};

export type IMutationUpdateAboutArgs = {
  input: IUpdateAboutInput;
};

export type IMutationUpdateBookArgs = {
  input: IUpdateBookInput;
};

export type IMutationUpdateBookingArgs = {
  input: IUpdateBookingInput;
};

export type IMutationUpdateCourseArgs = {
  input: IUpdateCourseInput;
};

export type IMutationUpdateCoursePriceArgs = {
  input: IUpdateCoursePriceInput;
};

export type IMutationUpdateEventArgs = {
  input: IUpdateEventInput;
};

export type IMutationUpdateExamArgs = {
  input: IUpdateExamInput;
};

export type IMutationUpdateHelpArgs = {
  input: IUpdateHelpInput;
};

export type IMutationUpdateIdentityArgs = {
  input: IUpdateIdentityInput;
};

export type IMutationUpdateLocaleSettingArgs = {
  input: IUpdateLocaleSettingInput;
};

export type IMutationUpdateMarketingArgs = {
  input: IUpdateMarketingInput;
};

export type IMutationUpdateOrderArgs = {
  input: IUpdateOrderInput;
};

export type IMutationUpdatePostArgs = {
  input: IUpdatePostInput;
};

export type IMutationUpdateQuestionArgs = {
  input: IUpdateQuestionInput;
};

export type IMutationUpdateReportArgs = {
  input: IUpdateReportInput;
};

export type IMutationUpdateReviewArgs = {
  input: IUpdateReviewInput;
};

export type IMutationUpdateServiceArgs = {
  input: IUpdateServiceInput;
};

export type IMutationUpdateSettingArgs = {
  input: IUpdateSettingInput;
};

export type IMutationUpdateTransactionArgs = {
  input: IUpdateTransactionInput;
};

export type IMutationUpdateUserArgs = {
  input: IUpdateUserInput;
};

export type IMutationUpdateUserExamArgs = {
  input: IUpdateUserExamInput;
};

export type IMutationUploadMediaArgs = {
  files: Array<Scalars['Upload']>;
};

export type IMutationUserAccessArgs = {
  input: IUserAccessInput;
};

export type IMutationUserCreateReviewArgs = {
  input: IUserCreateReviewInput;
};

export type INewsSetting = {
  __typename?: 'NewsSetting';
  isPublic: Maybe<Scalars['Boolean']>;
  banner: Maybe<Scalars['String']>;
  bannerBlur: Maybe<Scalars['Int']>;
  title: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  limit: Maybe<Scalars['Int']>;
  featuredPosts: Maybe<Array<Maybe<IFeaturedPost>>>;
};

export type INewsSettingInput = {
  isPublic: Maybe<Scalars['Boolean']>;
  title: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  banner: Maybe<Scalars['String']>;
  bannerBlur: Maybe<Scalars['Int']>;
  limit: Maybe<Scalars['Int']>;
  featuredPosts: Maybe<Array<Maybe<IFeaturedPostInput>>>;
};

export type INode = {
  id: Scalars['ID'];
};

export type INotification = INode & {
  __typename?: 'Notification';
  id: Scalars['ID'];
  type: Maybe<Scalars['String']>;
  message: Maybe<Scalars['String']>;
  param: Maybe<Scalars['String']>;
  read: Maybe<Scalars['Boolean']>;
  userId: Maybe<Scalars['String']>;
  sourceId: Maybe<Scalars['String']>;
  createdBy: Scalars['ID'];
  updatedTime: Maybe<Scalars['DateTime']>;
  createdTime: Maybe<Scalars['DateTime']>;
};

export type IOperationGetUserInput = {
  id: Scalars['ID'];
};

export enum IOperationRule {
  Read = 'READ',
  Create = 'CREATE',
  Update = 'UPDATE',
  Delete = 'DELETE',
}

export type IOperationSearchArticlesInput = {
  keyword: Maybe<Scalars['String']>;
  status: Maybe<Array<Maybe<Scalars['String']>>>;
  categories: Maybe<Array<Maybe<Scalars['String']>>>;
  sortDirection: Maybe<ISortDirection>;
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type IOperationSearchUserExamsInput = {
  keyword: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  courseId: Maybe<Scalars['String']>;
  userId: Maybe<Scalars['String']>;
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type IOperationSearchUsersInput = {
  keyword: Maybe<Scalars['String']>;
  roles: Maybe<Array<Maybe<Scalars['String']>>>;
  status: Maybe<Array<Maybe<Scalars['String']>>>;
  sortDirection: Maybe<ISortDirection>;
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type IOrder = INode & {
  __typename?: 'Order';
  id: Scalars['ID'];
  courseIds: Maybe<Array<Maybe<Scalars['String']>>>;
  userId: Maybe<Scalars['ID']>;
  courses: Maybe<Array<Maybe<ICourse>>>;
  user: Maybe<IUser>;
  type: Maybe<Scalars['String']>;
  status: Maybe<IOrderStatus>;
  price: Maybe<Scalars['Int']>;
  createdBy: Scalars['ID'];
  updatedTime: Maybe<Scalars['DateTime']>;
  createdTime: Maybe<Scalars['DateTime']>;
};

export type IOrderCourseInput = {
  id: Maybe<Scalars['String']>;
  price: Maybe<Scalars['Int']>;
};

export type IOrderOutput = {
  __typename?: 'OrderOutput';
  id: Scalars['ID'];
  courseIds: Maybe<Array<Scalars['String']>>;
  userId: Scalars['String'];
  courses: Maybe<Array<Maybe<ICourse>>>;
  user: Maybe<IUser>;
  type: Maybe<Scalars['String']>;
  status: Maybe<IOrderStatus>;
  price: Maybe<Scalars['Int']>;
  createdBy: Scalars['ID'];
  updatedTime: Maybe<Scalars['DateTime']>;
  createdTime: Maybe<Scalars['DateTime']>;
};

export enum IOrderStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Reject = 'REJECT',
  Delete = 'DELETE',
}

export enum IPermission {
  RoleCreateCourse = 'ROLE_CREATE_COURSE',
  RoleUpdateCourse = 'ROLE_UPDATE_COURSE',
  RoleListCourse = 'ROLE_LIST_COURSE',
  RoleApproveCourse = 'ROLE_APPROVE_COURSE',
  RoleRejectCourse = 'ROLE_REJECT_COURSE',
  RoleUnpublishCourse = 'ROLE_UNPUBLISH_COURSE',
  RoleDeleteCourse = 'ROLE_DELETE_COURSE',
  RoleSubmitReviewCourse = 'ROLE_SUBMIT_REVIEW_COURSE',
  RoleCreateNews = 'ROLE_CREATE_NEWS',
  RoleListNews = 'ROLE_LIST_NEWS',
  RoleDeleteNews = 'ROLE_DELETE_NEWS',
  RoleCreateUser = 'ROLE_CREATE_USER',
  RoleUpdateUser = 'ROLE_UPDATE_USER',
  RoleChangePassUser = 'ROLE_CHANGE_PASS_USER',
  RoleResetPassUser = 'ROLE_RESET_PASS_USER',
  RoleDeleteUser = 'ROLE_DELETE_USER',
}

export type IPost = INode & {
  __typename?: 'Post';
  id: Scalars['ID'];
  lang: Maybe<Scalars['String']>;
  isPublic: Maybe<Scalars['Boolean']>;
  category: Maybe<Scalars['String']>;
  thumbnail: Maybe<Scalars['String']>;
  imageCover: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  seoName: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  content: Maybe<Scalars['String']>;
  translations: Maybe<Array<Maybe<IPostTranslation>>>;
  createdBy: Scalars['ID'];
  updatedTime: Maybe<Scalars['DateTime']>;
  createdTime: Maybe<Scalars['DateTime']>;
};

export type IPostHeader = {
  __typename?: 'PostHeader';
  title: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  banner: Maybe<Scalars['String']>;
  bannerBlur: Maybe<Scalars['Int']>;
};

export type IPostTranslation = {
  __typename?: 'PostTranslation';
  lang: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  content: Maybe<Scalars['String']>;
};

export type IPostTranslationInput = {
  lang: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  content: Maybe<Scalars['String']>;
};

export type IProfileUploadOutput = {
  __typename?: 'ProfileUploadOutput';
  profilePath: Scalars['String'];
};

export type IQa = {
  __typename?: 'QA';
  id: Maybe<Scalars['String']>;
  audio: Maybe<Scalars['String']>;
  question: Maybe<Scalars['String']>;
  answers: Maybe<Array<Maybe<IAnswer>>>;
};

export type IQaInput = {
  id: Maybe<Scalars['String']>;
  audio: Maybe<Scalars['String']>;
  question: Maybe<Scalars['String']>;
  answers: Maybe<Array<Maybe<IAnswerInput>>>;
};

export type IQuery = {
  __typename?: 'Query';
  _: Maybe<Scalars['String']>;
  averageReview: Maybe<IAverageReview>;
  bookCount: Maybe<Scalars['Int']>;
  checkAuth: Maybe<IUser>;
  courseCount: Maybe<Scalars['Int']>;
  examCount: Maybe<Scalars['Int']>;
  getAbout: Maybe<IAbout>;
  getAuthorProfile: Maybe<IUser>;
  getAuthorRevenue: Maybe<IAuthorRevenueOuput>;
  getAuthorSchedule: Maybe<Array<Maybe<IScheduleOutput>>>;
  getBook: Maybe<IBook>;
  getContact: Maybe<IContact>;
  getCourse: Maybe<ICourse>;
  getCourseBook: Maybe<IBook>;
  getCourseList: Maybe<IResponseCourseList>;
  getEvent: Maybe<IEvent>;
  getExam: Maybe<IExam>;
  getGroupHelps: Maybe<Array<Maybe<IGroupHelp>>>;
  getHelp: Maybe<IHelp>;
  getIdentity: Maybe<IIdentity>;
  getLocaleSetting: Maybe<ILocaleSetting>;
  getMarketing: Maybe<IMarketing>;
  getNotification: Maybe<INotification>;
  getOrder: Maybe<IOrder>;
  getPost: Maybe<IPost>;
  getQuestion: Maybe<IQuestion>;
  getReport: Maybe<IReport>;
  getReview: Maybe<IReview>;
  getReviewList: Maybe<IResponseReviewList>;
  getRule: Maybe<IRule>;
  getScheduleList: Maybe<Array<Maybe<IContact>>>;
  getService: Maybe<IService>;
  getSetting: Maybe<ISetting>;
  getStatistic: Maybe<IStatistic>;
  getTransaction: Maybe<ITransaction>;
  getUnreadNotificationCount: Maybe<Scalars['Int']>;
  getUser: Maybe<IUser>;
  getUserCourse: Maybe<IUserCourse>;
  getUserExam: Maybe<IUserExamDetails>;
  getUserIdentity: Maybe<IUserIdentity>;
  getUserProfile: Maybe<IUser>;
  listAbout: Maybe<Array<Maybe<IAbout>>>;
  listAuthorCourses: Maybe<IListCourseData>;
  listBlogsPublic: Maybe<IListBlogData>;
  listBookings: Maybe<Array<Maybe<IBookingOutput>>>;
  listBooks: Maybe<Array<Maybe<IBook>>>;
  listContacts: Maybe<IListContactData>;
  listCourse: Maybe<Array<Maybe<ICourse>>>;
  listCoursesPublic: Maybe<IListCourseData>;
  listEvents: Maybe<IListEventData>;
  listExam: Maybe<Array<Maybe<IExam>>>;
  listExamPublic: Maybe<Array<Maybe<IExam>>>;
  listHelps: Maybe<Array<Maybe<IHelp>>>;
  listIdentites: Maybe<Array<Maybe<IIdentity>>>;
  listMedia: Maybe<Array<Maybe<IMedia>>>;
  listOrders: Maybe<Array<Maybe<IOrder>>>;
  listPosts: Maybe<Array<Maybe<IPost>>>;
  listPostsPublic: Maybe<IListPostData>;
  listQuestions: Maybe<Array<Maybe<IQuestion>>>;
  listRecentCourses: Maybe<ICourseData>;
  listRules: Maybe<Array<Maybe<IRule>>>;
  listService: Maybe<Array<Maybe<IService>>>;
  listServicePublic: Maybe<Array<Maybe<IService>>>;
  listSession: Maybe<Array<Maybe<ISession>>>;
  listTransaction: Maybe<Array<Maybe<ITransaction>>>;
  listUserCourses: Maybe<IListUserCourseData>;
  listUserExam: Maybe<Array<Maybe<IUserExam>>>;
  listUsers: Maybe<Array<Maybe<IUser>>>;
  operationGetUser: Maybe<IUser>;
  operationSearchArticles: Maybe<ISearchArticleResult>;
  operationSearchUserExams: Maybe<ISearchUserExamResult>;
  operationSearchUsers: Maybe<ISearchUserResult>;
  searchAccesses: Maybe<ISearchAccessesResult>;
  searchAuthors: Maybe<ISearchAuthorsResult>;
  searchCourses: Maybe<IListCourseData>;
  searchHelps: Maybe<Array<Maybe<IHelp>>>;
  searchMarketing: Maybe<ISearchMarketingResult>;
  searchMedia: ISearchMediaOutput;
  searchNotifications: Maybe<ISearchNotificationResult>;
  searchPosts: Maybe<IListPostData>;
  searchPostsPublic: Maybe<IListPostData>;
  searchQuestions: Maybe<ISearchQuestionResult>;
  searchReports: Maybe<ISearchReportResult>;
  searchReviews: Maybe<ISearchReviewsResult>;
  searchSchedule: Maybe<Array<Maybe<ISearchScheduleOutput>>>;
  searchServices: Maybe<IListServiceData>;
  searchTickets: Maybe<ISearchTicketsResult>;
  sendMultipleEmail: Maybe<ISendMultipleEmailOutput>;
};

export type IQueryAverageReviewArgs = {
  input: IAverageReviewInput;
};

export type IQueryBookCountArgs = {
  input: IBookCountInput;
};

export type IQueryCourseCountArgs = {
  input: ICourseCountInput;
};

export type IQueryExamCountArgs = {
  input: IExamCountInput;
};

export type IQueryGetAboutArgs = {
  input: IGetAboutInput;
};

export type IQueryGetAuthorProfileArgs = {
  input: IGetAuthorProfileInput;
};

export type IQueryGetAuthorRevenueArgs = {
  input: IGetAuthorRevenueInput;
};

export type IQueryGetAuthorScheduleArgs = {
  input: IGetScheduleInput;
};

export type IQueryGetBookArgs = {
  input: IGetBookInput;
};

export type IQueryGetContactArgs = {
  input: IGetContactInput;
};

export type IQueryGetCourseArgs = {
  input: IGetCourseInput;
};

export type IQueryGetCourseBookArgs = {
  input: IGetCourseBookInput;
};

export type IQueryGetCourseListArgs = {
  input: IGetCourseListInput;
};

export type IQueryGetEventArgs = {
  input: IGetEventInput;
};

export type IQueryGetExamArgs = {
  input: IGetExamInput;
};

export type IQueryGetHelpArgs = {
  input: IGetHelpInput;
};

export type IQueryGetIdentityArgs = {
  input: IGetIdentityInput;
};

export type IQueryGetMarketingArgs = {
  input: IGetMarketingInput;
};

export type IQueryGetNotificationArgs = {
  input: IGetNotificationInput;
};

export type IQueryGetOrderArgs = {
  input: IGetOrderInput;
};

export type IQueryGetPostArgs = {
  input: IGetPostInput;
};

export type IQueryGetQuestionArgs = {
  input: IGetQuestionInput;
};

export type IQueryGetReportArgs = {
  input: IGetReportInput;
};

export type IQueryGetReviewArgs = {
  input: IGetReviewInput;
};

export type IQueryGetReviewListArgs = {
  input: IReviewListInput;
};

export type IQueryGetRuleArgs = {
  input: Maybe<IGetRuleInput>;
};

export type IQueryGetServiceArgs = {
  input: IGetServiceInput;
};

export type IQueryGetSettingArgs = {
  input: IGetSettingInput;
};

export type IQueryGetStatisticArgs = {
  input: IGetStatisticInput;
};

export type IQueryGetTransactionArgs = {
  input: IGetTransactionInput;
};

export type IQueryGetUserArgs = {
  input: IGetUserInput;
};

export type IQueryGetUserCourseArgs = {
  input: IGetUserCourseInput;
};

export type IQueryGetUserExamArgs = {
  input: IGetUserExamInput;
};

export type IQueryGetUserIdentityArgs = {
  input: IGetUserIdentity;
};

export type IQueryGetUserProfileArgs = {
  input: IGetUserInput;
};

export type IQueryListAboutArgs = {
  input: IListAboutInput;
};

export type IQueryListAuthorCoursesArgs = {
  input: IListAuthorCoursesInput;
};

export type IQueryListBlogsPublicArgs = {
  input: IListBlogsPublicInput;
};

export type IQueryListBookingsArgs = {
  input: IListBookingInput;
};

export type IQueryListBooksArgs = {
  input: IListBookInput;
};

export type IQueryListContactsArgs = {
  input: IListContactsInput;
};

export type IQueryListCourseArgs = {
  input: IListCourseInput;
};

export type IQueryListCoursesPublicArgs = {
  input: IListCoursesPublicInput;
};

export type IQueryListEventsArgs = {
  input: IListEventsInput;
};

export type IQueryListExamArgs = {
  input: IListExamInput;
};

export type IQueryListExamPublicArgs = {
  input: IListExamInput;
};

export type IQueryListHelpsArgs = {
  input: IListHelpsInput;
};

export type IQueryListIdentitesArgs = {
  input: IListIdentityInput;
};

export type IQueryListMediaArgs = {
  input: Maybe<IListMediaInput>;
};

export type IQueryListOrdersArgs = {
  input: IListOrdersInput;
};

export type IQueryListPostsArgs = {
  input: IListPostsInput;
};

export type IQueryListPostsPublicArgs = {
  input: IListPostsPublicInput;
};

export type IQueryListQuestionsArgs = {
  input: IListQuestionInput;
};

export type IQueryListRecentCoursesArgs = {
  input: IListRecentCoursesInput;
};

export type IQueryListRulesArgs = {
  input: Maybe<IListRuleInput>;
};

export type IQueryListServiceArgs = {
  input: IListServiceInput;
};

export type IQueryListServicePublicArgs = {
  input: IListServiceInput;
};

export type IQueryListSessionArgs = {
  input: IListSessionInput;
};

export type IQueryListTransactionArgs = {
  input: IListTransactionInput;
};

export type IQueryListUserCoursesArgs = {
  input: IListUserCoursesInput;
};

export type IQueryListUserExamArgs = {
  input: IListUserExamInput;
};

export type IQueryListUsersArgs = {
  input: IListUserInput;
};

export type IQueryOperationGetUserArgs = {
  input: IOperationGetUserInput;
};

export type IQueryOperationSearchArticlesArgs = {
  input: IOperationSearchArticlesInput;
};

export type IQueryOperationSearchUserExamsArgs = {
  input: IOperationSearchUserExamsInput;
};

export type IQueryOperationSearchUsersArgs = {
  input: IOperationSearchUsersInput;
};

export type IQuerySearchAccessesArgs = {
  input: ISearchAccessesInput;
};

export type IQuerySearchAuthorsArgs = {
  input: ISearchAuthorsInput;
};

export type IQuerySearchCoursesArgs = {
  input: ISearchCoursesInput;
};

export type IQuerySearchHelpsArgs = {
  input: ISearchHelpsInput;
};

export type IQuerySearchMarketingArgs = {
  input: ISearchMarketingInput;
};

export type IQuerySearchMediaArgs = {
  input: ISearchMediaInput;
};

export type IQuerySearchNotificationsArgs = {
  input: ISearchNotificationsInput;
};

export type IQuerySearchPostsArgs = {
  input: ISearchPostsInput;
};

export type IQuerySearchPostsPublicArgs = {
  input: ISearchPostsPublicInput;
};

export type IQuerySearchQuestionsArgs = {
  input: ISearchQuestionsInput;
};

export type IQuerySearchReportsArgs = {
  input: ISearchReportsInput;
};

export type IQuerySearchReviewsArgs = {
  input: ISearchReviewsInput;
};

export type IQuerySearchScheduleArgs = {
  input: ISearchScheduleInput;
};

export type IQuerySearchServicesArgs = {
  input: ISearchServicesInput;
};

export type IQuerySearchTicketsArgs = {
  input: ISearchTicketsInput;
};

export type IQuerySendMultipleEmailArgs = {
  input: ISendMultipleEmailInput;
};

export type IQuestion = INode & {
  __typename?: 'Question';
  id: Scalars['ID'];
  type: Maybe<Scalars['String']>;
  category: Maybe<Scalars['String']>;
  bookId: Maybe<Scalars['String']>;
  lessonId: Maybe<Scalars['String']>;
  isPublic: Maybe<Scalars['Boolean']>;
  question: Maybe<IQa>;
  groupQuestion: Maybe<IGroupQa>;
  createdBy: Maybe<Scalars['ID']>;
  updatedBy: Maybe<Scalars['ID']>;
  updatedTime: Maybe<Scalars['DateTime']>;
  createdTime: Maybe<Scalars['DateTime']>;
};

export type IQuestionInput = {
  id: Scalars['ID'];
  type: Maybe<Scalars['String']>;
  category: Maybe<Scalars['String']>;
  bookId: Maybe<Scalars['String']>;
  lessonId: Maybe<Scalars['String']>;
  isPublic: Maybe<Scalars['Boolean']>;
  question: Maybe<IQaInput>;
  groupQuestion: Maybe<IGroupQaInput>;
  createdBy: Maybe<Scalars['ID']>;
  updatedBy: Maybe<Scalars['ID']>;
  updatedTime: Maybe<Scalars['DateTime']>;
  createdTime: Maybe<Scalars['DateTime']>;
};

export type IRate = {
  __typename?: 'Rate';
  percent: Maybe<Scalars['Float']>;
  total: Maybe<Scalars['Int']>;
  value: Maybe<Scalars['Int']>;
};

export type IReadNotificationInput = {
  id: Scalars['ID'];
};

export type IRefundTicketInput = {
  id: Scalars['ID'];
  reportId: Maybe<Scalars['ID']>;
};

export type IRegisterUserInput = {
  provider: ISocialProvider;
  email: Scalars['String'];
  role: Maybe<Scalars['String']>;
  sessionId: Maybe<Scalars['String']>;
  examChallengeId: Maybe<Scalars['String']>;
  password: Scalars['String'];
  confirmPassword: Maybe<Scalars['String']>;
};

export type IRejectCourseInput = {
  message: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type IReplyReview = INode & {
  __typename?: 'ReplyReview';
  id: Scalars['ID'];
  userId: Scalars['String'];
  content: Maybe<Scalars['String']>;
  reviewer: Maybe<IUser>;
  createdBy: Scalars['ID'];
  updatedTime: Maybe<Scalars['DateTime']>;
  createdTime: Maybe<Scalars['DateTime']>;
};

export type IReport = INode & {
  __typename?: 'Report';
  id: Scalars['ID'];
  status: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  reason: Maybe<Scalars['String']>;
  userId: Maybe<Scalars['String']>;
  reporter: Maybe<IReporter>;
  authorId: Maybe<Scalars['String']>;
  bookingId: Maybe<Scalars['String']>;
  courseId: Maybe<Scalars['String']>;
  ticketId: Maybe<Scalars['String']>;
  createdBy: Scalars['ID'];
  updatedTime: Maybe<Scalars['DateTime']>;
  createdTime: Maybe<Scalars['DateTime']>;
};

export type IReporter = {
  __typename?: 'Reporter';
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  profilePath: Maybe<Scalars['String']>;
};

export type IResetConfirmInput = {
  token: Scalars['String'];
  password: Scalars['String'];
  confirmPassword: Scalars['String'];
};

export type IResetPasswordInput = {
  email: Scalars['String'];
};

export type IResponseCourseList = {
  __typename?: 'ResponseCourseList';
  page: Scalars['Int'];
  totalPages: Scalars['Int'];
  results: Maybe<Array<Maybe<ICourseListModel>>>;
};

export type IResponseReviewList = {
  __typename?: 'ResponseReviewList';
  page: Scalars['Int'];
  totalPages: Scalars['Int'];
  total: Scalars['Int'];
  results: Maybe<Array<Maybe<IReview>>>;
};

export type IReview = INode & {
  __typename?: 'Review';
  id: Scalars['ID'];
  courseId: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  authorId: Maybe<Scalars['String']>;
  rate: Scalars['Int'];
  content: Maybe<Scalars['String']>;
  replies: Maybe<Array<Maybe<IReplyReview>>>;
  reviewer: Maybe<IUser>;
  isVerifiedPurchase: Maybe<Scalars['Boolean']>;
  createdBy: Scalars['ID'];
  updatedTime: Maybe<Scalars['DateTime']>;
  createdTime: Maybe<Scalars['DateTime']>;
};

export type IReviewListInput = {
  filter: Maybe<IModelReviewFilterInput>;
  sortDirection: ISortDirection;
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export enum IRole {
  Admin = 'ADMIN',
  User = 'USER',
}

export type IRule = INode & {
  __typename?: 'Rule';
  id: Scalars['ID'];
  allow: Maybe<IAllowRule>;
  operations: Maybe<Array<Maybe<IOperationRule>>>;
  createdBy: Scalars['ID'];
  updatedTime: Maybe<Scalars['DateTime']>;
  createdTime: Maybe<Scalars['DateTime']>;
};

export type ISchedule = {
  __typename?: 'Schedule';
  id: Scalars['ID'];
  userId: Scalars['ID'];
  userName: Scalars['String'];
  datetime: Scalars['String'];
  createdBy: Scalars['ID'];
  updatedBy: Scalars['ID'];
  updatedTime: Scalars['DateTime'];
  createdTime: Scalars['DateTime'];
};

export type IScheduleOutput = {
  __typename?: 'ScheduleOutput';
  id: Scalars['ID'];
  authorId: Scalars['ID'];
  authorName: Scalars['ID'];
  datetime: Scalars['String'];
  bookedId: Maybe<Scalars['String']>;
  bookedUserId: Maybe<Scalars['String']>;
  bookedUserName: Maybe<Scalars['String']>;
  bookedLessonLevel: Maybe<Scalars['String']>;
  bookedLessonContent: Maybe<Scalars['String']>;
  bookedLessonFlows: Maybe<Array<Maybe<Scalars['String']>>>;
  bookedLessonStrictness: Maybe<Array<Maybe<Scalars['String']>>>;
  bookedLessonOthers: Maybe<Scalars['String']>;
  bookedUrl: Maybe<Scalars['String']>;
  bookedStatus: Maybe<Scalars['String']>;
  bookedPassword: Maybe<Scalars['String']>;
  isCanBooking: Maybe<Scalars['Boolean']>;
  createdBy: Maybe<Scalars['ID']>;
  updatedTime: Maybe<Scalars['DateTime']>;
  createdTime: Maybe<Scalars['DateTime']>;
};

export type ISearchAccessesInput = {
  from: Maybe<Scalars['String']>;
  to: Maybe<Scalars['String']>;
  mkid: Maybe<Scalars['String']>;
  keyword: Maybe<Scalars['String']>;
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type ISearchAccessesResult = {
  __typename?: 'SearchAccessesResult';
  totalItems: Scalars['Int'];
  items: Maybe<Array<Maybe<IAccess>>>;
};

export type ISearchArticleResult = {
  __typename?: 'SearchArticleResult';
  totalItems: Scalars['Int'];
  items: Array<Maybe<IPost>>;
};

export type ISearchAuthorItem = {
  __typename?: 'SearchAuthorItem';
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  profilePath: Maybe<Scalars['String']>;
  avgRate: Maybe<Scalars['Float']>;
};

export type ISearchAuthorsInput = {
  keyword: Maybe<Scalars['String']>;
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type ISearchAuthorsResult = {
  __typename?: 'SearchAuthorsResult';
  totalItems: Scalars['Int'];
  items: Maybe<Array<Maybe<ISearchAuthorItem>>>;
};

export type ISearchCoursesInput = {
  filter: Maybe<IModelCourseFilterInput>;
  sortDirection: ISortDirection;
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type ISearchHelpsInput = {
  filter: Maybe<IModelHelpFilterInput>;
  sortDirection: ISortDirection;
  limit: Scalars['Int'];
  nextToken: Maybe<Scalars['String']>;
};

export type ISearchMarketingInput = {
  keyword: Maybe<Scalars['String']>;
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type ISearchMarketingResult = {
  __typename?: 'SearchMarketingResult';
  totalItems: Scalars['Int'];
  items: Maybe<Array<Maybe<IMarketing>>>;
};

export type ISearchMediaInput = {
  from: Scalars['Int'];
  size: Scalars['Int'];
  query: Maybe<Scalars['String']>;
  sort: Maybe<Scalars['String']>;
};

export type ISearchMediaOutput = {
  __typename?: 'SearchMediaOutput';
  total: Scalars['Int'];
  hits: Array<Maybe<IMedia>>;
};

export type ISearchNotificationResult = {
  __typename?: 'SearchNotificationResult';
  totalItems: Scalars['Int'];
  items: Maybe<Array<Maybe<INotification>>>;
};

export type ISearchNotificationsInput = {
  keyword: Maybe<Scalars['String']>;
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type ISearchPostsInput = {
  filter: Maybe<IModelPostFilterInput>;
  sortDirection: ISortDirection;
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type ISearchPostsPublicInput = {
  filter: Maybe<IModelPostFilterInput>;
  sortDirection: ISortDirection;
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type ISearchQuestionResult = {
  __typename?: 'SearchQuestionResult';
  totalItems: Scalars['Int'];
  items: Maybe<Array<Maybe<IQuestion>>>;
};

export type ISearchQuestionsInput = {
  keyword: Maybe<Scalars['String']>;
  userId: Maybe<Scalars['String']>;
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type ISearchReportResult = {
  __typename?: 'SearchReportResult';
  totalItems: Scalars['Int'];
  items: Maybe<Array<Maybe<IReport>>>;
};

export type ISearchReportsInput = {
  keyword: Maybe<Scalars['String']>;
  status: Maybe<Array<Maybe<Scalars['String']>>>;
  type: Maybe<Array<Maybe<Scalars['String']>>>;
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type ISearchReviewsInput = {
  keyword: Maybe<Scalars['String']>;
  courseId: Maybe<Scalars['String']>;
  authorId: Maybe<Scalars['String']>;
  sortDirection: Maybe<ISortDirection>;
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type ISearchReviewsResult = {
  __typename?: 'SearchReviewsResult';
  totalItems: Scalars['Int'];
  items: Maybe<Array<Maybe<IReview>>>;
};

export type ISearchSchedule = {
  __typename?: 'SearchSchedule';
  id: Scalars['String'];
  datetime: Scalars['String'];
};

export type ISearchScheduleInput = {
  filter: IModelSearchScheduleFilterInput;
};

export type ISearchScheduleOutput = {
  __typename?: 'SearchScheduleOutput';
  authorId: Scalars['ID'];
  authorName: Maybe<Scalars['ID']>;
  profilePath: Maybe<Scalars['String']>;
  birthday: Maybe<Scalars['String']>;
  teachingExperience: Maybe<Scalars['String']>;
  currentCountry: Maybe<Scalars['String']>;
  avgRate: Maybe<Scalars['Float']>;
  lessonLevels: Maybe<Array<Maybe<Scalars['String']>>>;
  lessonTopics: Maybe<Array<Maybe<Scalars['String']>>>;
  availabilities: Maybe<Array<Maybe<ISearchSchedule>>>;
};

export type ISearchServicesInput = {
  filter: Maybe<IModelServiceFilterInput>;
  sortDirection: ISortDirection;
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type ISearchTicketsInput = {
  userId: Maybe<Scalars['ID']>;
  keyword: Maybe<Scalars['String']>;
  status: Maybe<Array<Maybe<Scalars['String']>>>;
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type ISearchTicketsResult = {
  __typename?: 'SearchTicketsResult';
  totalItems: Scalars['Int'];
  items: Maybe<Array<Maybe<ITicket>>>;
};

export type ISearchUserExamResult = {
  __typename?: 'SearchUserExamResult';
  totalItems: Scalars['Int'];
  items: Maybe<Array<Maybe<IUserExamItem>>>;
};

export type ISearchUserResult = {
  __typename?: 'SearchUserResult';
  totalItems: Scalars['Int'];
  items: Array<Maybe<IUser>>;
};

export type ISection = {
  __typename?: 'Section';
  id: Scalars['String'];
  order: Maybe<Scalars['Int']>;
  title: Maybe<Scalars['String']>;
  questions: Maybe<Array<Maybe<IQuestion>>>;
};

export type ISectionInput = {
  id: Scalars['String'];
  order: Maybe<Scalars['Int']>;
  title: Maybe<Scalars['String']>;
  questions: Array<Maybe<IExamQuestionInput>>;
};

export type ISendContactInput = {
  name: Maybe<Scalars['String']>;
  datetime: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  subject: Maybe<Scalars['String']>;
  content: Maybe<Scalars['String']>;
};

export type ISendMultipleEmailInput = {
  to: Maybe<Array<Scalars['String']>>;
  isAll: Maybe<Scalars['Boolean']>;
  subject: Scalars['String'];
  body: Scalars['String'];
};

export type ISendMultipleEmailOutput = {
  __typename?: 'SendMultipleEmailOutput';
  processed: Maybe<Scalars['Int']>;
  failedEmail: Maybe<Array<Scalars['String']>>;
};

export type IService = INode & {
  __typename?: 'Service';
  id: Scalars['ID'];
  isPublic: Maybe<Scalars['Boolean']>;
  category: Maybe<Scalars['String']>;
  thumbnail: Maybe<Scalars['String']>;
  imageCover: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
  seoName: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  content: Maybe<Scalars['String']>;
  createdBy: Scalars['ID'];
  updatedTime: Maybe<Scalars['DateTime']>;
  createdTime: Maybe<Scalars['DateTime']>;
};

export type IServiceTranslationInput = {
  lang: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  content: Maybe<Scalars['String']>;
};

export type ISession = INode & {
  __typename?: 'Session';
  id: Scalars['ID'];
  userAgent: Scalars['String'];
  role: Maybe<Scalars['String']>;
  ip: Scalars['String'];
  isUser: Maybe<Scalars['Boolean']>;
  updatedTime: Scalars['DateTime'];
  createdTime: Scalars['DateTime'];
};

export type ISetting = INode & {
  __typename?: 'Setting';
  id: Scalars['ID'];
  home: Maybe<IHomePage>;
  privacy: Maybe<Scalars['String']>;
  terms: Maybe<Scalars['String']>;
  commercialLaw: Maybe<Scalars['String']>;
  companyProfile: Maybe<Scalars['String']>;
  locale: Maybe<ILocaleSetting>;
  course: Maybe<ICourseSetting>;
  news: Maybe<INewsSetting>;
  footer: Maybe<IFooterSetting>;
  updatedTime: Maybe<Scalars['DateTime']>;
  createdTime: Maybe<Scalars['DateTime']>;
};

export enum ISocialProvider {
  Google = 'GOOGLE',
  Facebook = 'FACEBOOK',
  Twitter = 'TWITTER',
  Line = 'LINE',
  Mail = 'MAIL',
}

export enum ISortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type IStatistic = {
  __typename?: 'Statistic';
  sales: Maybe<Array<Maybe<IHistory>>>;
  totalCourse: Maybe<Scalars['Int']>;
  totalBook: Maybe<Scalars['Int']>;
  totalExam: Maybe<Scalars['Int']>;
};

export type ISubmitForReviewCourseInput = {
  id: Scalars['ID'];
};

export type ISubscription = {
  __typename?: 'Subscription';
  _: Maybe<Scalars['String']>;
};

export type ISyllabusInput = {
  bookId: Maybe<Scalars['String']>;
  time: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  details: Maybe<Scalars['String']>;
  lessons: Maybe<Array<Maybe<Scalars['String']>>>;
  skills: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum ITable {
  User = 'user',
  About = 'about',
  News = 'news',
  Media = 'media',
}

export type ITicket = {
  __typename?: 'Ticket';
  id: Scalars['ID'];
  status: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
  invoiceId: Maybe<Scalars['ID']>;
  bookingId: Maybe<Scalars['ID']>;
  productId: Maybe<Scalars['ID']>;
  ticket: Maybe<Scalars['Int']>;
  refundTicket: Maybe<Scalars['Int']>;
  createdBy: Scalars['ID'];
  updatedBy: Scalars['ID'];
  updatedTime: Scalars['DateTime'];
  createdTime: Scalars['DateTime'];
};

export type ITransaction = INode & {
  __typename?: 'Transaction';
  id: Scalars['ID'];
  events: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy: Scalars['ID'];
  updatedBy: Scalars['ID'];
  updatedTime: Maybe<Scalars['DateTime']>;
  createdTime: Maybe<Scalars['DateTime']>;
};

export type IUnpublishCourseInput = {
  id: Scalars['ID'];
};

export type IUpDateTimeRuleInput = {
  id: Scalars['ID'];
  allow: Maybe<IAllowRule>;
  operations: Maybe<Array<Maybe<IOperationRule>>>;
};

export type IUpdateAboutInput = {
  id: Scalars['ID'];
  type: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
};

export type IUpdateBookInput = {
  id: Scalars['ID'];
  isPublic: Maybe<Scalars['Boolean']>;
  title: Maybe<Scalars['String']>;
  thumbnail: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  chapters: Maybe<Array<Maybe<IChapterInput>>>;
};

export type IUpdateBookingInput = {
  id: Scalars['String'];
  url: Scalars['String'];
  password: Scalars['String'];
};

export type IUpdateCourseInput = {
  id: Scalars['ID'];
  thumbnail: Maybe<Scalars['String']>;
  seoName: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  duration: Maybe<Scalars['Int']>;
  description: Maybe<Scalars['String']>;
  content: Maybe<Scalars['String']>;
  skills: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type IUpdateCoursePriceInput = {
  id: Scalars['ID'];
  price: Maybe<Scalars['Int']>;
  isFree: Maybe<Scalars['Boolean']>;
  priceId: Maybe<Scalars['String']>;
};

export type IUpdateEventInput = {
  id: Scalars['ID'];
  type: Maybe<Scalars['String']>;
  isPublic: Maybe<Scalars['Boolean']>;
  thumbnail: Maybe<Scalars['String']>;
  banner: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  ticket: Maybe<Scalars['Int']>;
  description: Maybe<Scalars['String']>;
  content: Maybe<Scalars['String']>;
  target: Maybe<Scalars['String']>;
  courseIds: Maybe<Array<Maybe<Scalars['String']>>>;
  from: Maybe<Scalars['DateTime']>;
  to: Maybe<Scalars['DateTime']>;
  isActive: Maybe<Scalars['Boolean']>;
};

export type IUpdateExamInput = {
  id: Scalars['ID'];
  type: Maybe<Scalars['String']>;
  courseId: Maybe<Scalars['String']>;
  lessonId: Maybe<Scalars['String']>;
  isPublic: Maybe<Scalars['Boolean']>;
  isFree: Maybe<Scalars['Boolean']>;
  title: Maybe<Scalars['String']>;
  time: Maybe<Scalars['Int']>;
  testDate: Maybe<Scalars['DateTime']>;
  sections: Array<Maybe<ISectionInput>>;
};

export type IUpdateHelpInput = {
  id: Scalars['ID'];
  forRole: Maybe<Scalars['String']>;
  isPublic: Maybe<Scalars['Boolean']>;
  category: Maybe<Scalars['String']>;
  seoName: Maybe<Scalars['String']>;
  translations: Maybe<Array<Maybe<IHelpTranslationInput>>>;
};

export type IUpdateIdentityInput = {
  id: Scalars['ID'];
  courseId: Maybe<Scalars['String']>;
  table: Maybe<Scalars['String']>;
  permission: Maybe<Scalars['Int']>;
};

export type IUpdateLocaleSettingInput = {
  ja: Maybe<ILocaleInput>;
  en: Maybe<ILocaleInput>;
  vi: Maybe<ILocaleInput>;
};

export type IUpdateMarketingInput = {
  id: Scalars['String'];
  type: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  address: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
};

export type IUpdateOrderInput = {
  id: Scalars['ID'];
  status: Maybe<IOrderStatus>;
};

export type IUpdatePostInput = {
  id: Scalars['ID'];
  isPublic: Maybe<Scalars['Boolean']>;
  category: Scalars['String'];
  thumbnail: Maybe<Scalars['String']>;
  imageCover: Maybe<Scalars['String']>;
  title: Scalars['String'];
  seoName: Scalars['String'];
  description: Maybe<Scalars['String']>;
  content: Maybe<Scalars['String']>;
  translations: Maybe<Array<Maybe<IPostTranslationInput>>>;
};

export type IUpdateQuestionInput = {
  id: Scalars['ID'];
  type: Maybe<Scalars['String']>;
  category: Maybe<Scalars['String']>;
  bookId: Maybe<Scalars['String']>;
  lessonId: Maybe<Scalars['String']>;
  isPublic: Maybe<Scalars['Boolean']>;
  question: Maybe<IQaInput>;
  groupQuestion: Maybe<IGroupQaInput>;
};

export type IUpdateReportInput = {
  id: Scalars['ID'];
  status: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  reason: Maybe<Scalars['String']>;
  userId: Maybe<Scalars['String']>;
  authorId: Maybe<Scalars['String']>;
  bookingId: Maybe<Scalars['String']>;
  courseId: Maybe<Scalars['String']>;
};

export type IUpdateReviewInput = {
  id: Scalars['ID'];
  rate: Maybe<Scalars['Int']>;
  content: Maybe<Scalars['String']>;
};

export type IUpdateServiceInput = {
  id: Scalars['ID'];
  isPublic: Maybe<Scalars['Boolean']>;
  category: Scalars['String'];
  thumbnail: Maybe<Scalars['String']>;
  imageCover: Maybe<Scalars['String']>;
  title: Scalars['String'];
  url: Scalars['String'];
  seoName: Scalars['String'];
  description: Maybe<Scalars['String']>;
  content: Maybe<Scalars['String']>;
};

export type IUpdateSettingInput = {
  id: Scalars['ID'];
  home: Maybe<IHomeInput>;
  privacy: Maybe<Scalars['String']>;
  terms: Maybe<Scalars['String']>;
  commercialLaw: Maybe<Scalars['String']>;
  companyProfile: Maybe<Scalars['String']>;
  course: Maybe<ICourseSettingInput>;
  news: Maybe<INewsSettingInput>;
  footer: Maybe<IFooterInput>;
};

export type IUpdateTransactionInput = {
  id: Scalars['ID'];
  events: Array<Maybe<Scalars['String']>>;
};

export type IUpdateUserExamInput = {
  id: Scalars['ID'];
  status: Maybe<IUserExamStatus>;
  answerSheet: Maybe<Scalars['String']>;
};

export type IUpdateUserInput = {
  id: Maybe<Scalars['ID']>;
  name: Maybe<Scalars['String']>;
  gender: Maybe<Scalars['String']>;
  address: Maybe<Scalars['String']>;
  role: Maybe<Scalars['String']>;
  isVerified: Maybe<Scalars['Boolean']>;
  isLocked: Maybe<Scalars['Boolean']>;
  isAuthorVerified: Maybe<Scalars['Boolean']>;
  profilePath: Maybe<Scalars['String']>;
  birthday: Maybe<Scalars['DateTime']>;
  provider: Maybe<ISocialProvider>;
  certificates: Maybe<Array<Maybe<ICertificateInput>>>;
  description: Maybe<Scalars['String']>;
  promoVideo: Maybe<Scalars['String']>;
  cover: Maybe<Scalars['String']>;
  lessonLevels: Maybe<Array<Maybe<Scalars['String']>>>;
  lessonTopics: Maybe<Array<Maybe<Scalars['String']>>>;
  teachingExperience: Maybe<Scalars['String']>;
  currentCountry: Maybe<Scalars['String']>;
};

export type IUser = INode & {
  __typename?: 'User';
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  customerId: Maybe<Scalars['String']>;
  token: Maybe<Scalars['String']>;
  avgRate: Maybe<Scalars['Float']>;
  totalReviews: Maybe<Scalars['Int']>;
  rates: Maybe<Array<Maybe<IRate>>>;
  profilePath: Maybe<Scalars['String']>;
  role: Scalars['String'];
  email: Scalars['String'];
  gender: Maybe<Scalars['String']>;
  birthday: Maybe<Scalars['DateTime']>;
  address: Maybe<Scalars['String']>;
  provider: Maybe<ISocialProvider>;
  isVerified: Maybe<Scalars['Boolean']>;
  isLocked: Maybe<Scalars['Boolean']>;
  isAuthorVerified: Maybe<Scalars['Boolean']>;
  oldPassword: Maybe<Scalars['String']>;
  password: Maybe<Scalars['String']>;
  certificates: Maybe<Array<Maybe<ICertificate>>>;
  description: Maybe<Scalars['String']>;
  promoVideo: Maybe<Scalars['String']>;
  cover: Maybe<Scalars['String']>;
  lessonLevels: Maybe<Array<Maybe<Scalars['String']>>>;
  lessonTopics: Maybe<Array<Maybe<Scalars['String']>>>;
  sessionId: Maybe<Scalars['String']>;
  examChallengeId: Maybe<Scalars['String']>;
  tickets: Maybe<Scalars['Int']>;
  teachingExperience: Maybe<Scalars['String']>;
  currentCountry: Maybe<Scalars['String']>;
  createdBy: Scalars['ID'];
  passwordAttemptTimes: Maybe<Scalars['Int']>;
  updatedTime: Scalars['DateTime'];
  createdTime: Scalars['DateTime'];
};

export type IUserAccessInput = {
  dataSource: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
  mkid: Maybe<Scalars['String']>;
};

export type IUserBook = {
  __typename?: 'UserBook';
  id: Maybe<Scalars['ID']>;
  title: Maybe<Scalars['String']>;
  thumbnail: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  chapterCount: Maybe<Scalars['Int']>;
};

export type IUserCourse = {
  __typename?: 'UserCourse';
  id: Maybe<Scalars['ID']>;
  isFree: Maybe<Scalars['Boolean']>;
  remainTime: Maybe<Scalars['Int']>;
  identityId: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  thumbnail: Maybe<Scalars['String']>;
  duration: Maybe<Scalars['Int']>;
  price: Maybe<Scalars['Int']>;
  type: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  books: Maybe<Array<Maybe<IUserBook>>>;
  exams: Maybe<Array<Maybe<IUserExam>>>;
};

export type IUserCreateReviewInput = {
  courseId: Maybe<Scalars['String']>;
  authorId: Maybe<Scalars['String']>;
  rate: Maybe<Scalars['Int']>;
  content: Maybe<Scalars['String']>;
};

export type IUserExam = INode & {
  __typename?: 'UserExam';
  answerSheet: Maybe<Scalars['String']>;
  avgPoint: Maybe<Scalars['Float']>;
  courseId: Maybe<Scalars['String']>;
  createdBy: Scalars['ID'];
  createdTime: Maybe<Scalars['DateTime']>;
  examId: Scalars['ID'];
  id: Scalars['ID'];
  pass: Maybe<Scalars['Int']>;
  remainTime: Maybe<Scalars['Int']>;
  status: Maybe<IUserExamStatus>;
  time: Maybe<Scalars['Int']>;
  times: Maybe<Scalars['Int']>;
  title: Maybe<Scalars['String']>;
  total: Maybe<Scalars['Int']>;
  type: Maybe<Scalars['String']>;
  updatedTime: Maybe<Scalars['DateTime']>;
  userId: Scalars['ID'];
};

export type IUserExamDetails = {
  __typename?: 'UserExamDetails';
  result: Maybe<IUserExam>;
  exam: Maybe<IExam>;
};

export type IUserExamItem = {
  __typename?: 'UserExamItem';
  no: Scalars['Int'];
  exam: IExam;
  userExamResult: IUserExam;
  user: IUser;
  course: ICourse;
};

export enum IUserExamStatus {
  Created = 'CREATED',
  Testing = 'TESTING',
  Finish = 'FINISH',
}

export type IUserIdentity = {
  __typename?: 'UserIdentity';
  courses: Maybe<Scalars['String']>;
  accesses: Maybe<Scalars['String']>;
};
