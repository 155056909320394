import React, { Suspense } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { History } from 'history';
import * as Pages from './Loadables';
import { IUser } from 'types/types';
import { Loading } from 'vendor-deprecated/Loading';
import Header from 'components/common/components/Header/Header';
import Footer from 'components/common/components/PageFooter/Footer';

type AuthorLayoutProps = {
  auth: IUser;
  history: History;
};

export const AuthorLayout = (props: AuthorLayoutProps) => {
  const location = useLocation();
  const isAuthorVerified = props.auth.isAuthorVerified;
  if (props.auth.role === 'AUTHOR') {
    return (
      <div>
        <Header isCMS={false} history={props.history} />
        <Switch>
          <Route path="/author/lecture/:bookId/:lessonId" component={Pages.Lecture} />
          <Route path="/author/lecture/:bookId" component={Pages.Lecture} />
          <Route path="/author/:courseId/syllabus-preview" component={Pages.BookPreview} />
          <Route path="/author/exam/:examId/preview" component={Pages.CMSExamPreview} />

          <Route
            path="/author/courses/:courseId/:tabId"
            history={props.history}
            component={Pages.AuthorCourseDetails}
          />
          <Route
            path="/author/"
            history={props.history}
            component={() => (
              <Pages.Author auth={props.auth} history={props.history}>
                <Suspense fallback={<Loading />}>
                  <Route
                    exact
                    path="/author/profile"
                    history={props.history}
                    component={() => <Pages.AuthorProfile auth={props.auth} history={props.history} />}
                  />
                  <Route exact path="/author/notification" component={Pages.Notification} />

                  {isAuthorVerified && (
                    <Switch>
                      <Route
                        exact
                        path="/author/statistics"
                        history={props.history}
                        component={() => <Pages.AuthorStatistic auth={props.auth} />}
                      />

                      <Route
                        exact
                        path="/author/schedule"
                        history={props.history}
                        component={() => <Pages.AuthorSchedule />}
                      />
                      <Route
                        exact
                        path="/author/courses"
                        history={props.history}
                        component={() => <Pages.AuthorCoursesList auth={props.auth} history={props.history} />}
                      />

                      <Route exact path="/author/questions" history={props.history} component={Pages.QuestionList} />
                    </Switch>
                  )}

                  <Route exact path="/author/change-password" history={props.history} component={Pages.ChangePass} />

                  <Route exact path="/author/medias" history={props.history} component={Pages.MediaList} />
                </Suspense>
              </Pages.Author>
            )}
          />

          <Route component={Pages.NotFoundPage} />
        </Switch>
        {!location.pathname.includes('/author/') && <Footer />}
      </div>
    );
  } else {
    return <Redirect to={{ pathname: '/login' }} />;
  }
};
