import gql from 'graphql-tag';

export const CHECK_AUTH = gql`
  query checkAuth {
    checkAuth {
      id
      email
      tickets
      isAuthorVerified
      role
      customerId
      __typename
    }
  }
`;

export const GET = gql`
  query getUser($input: GetUserInput!) {
    getUser(input: $input) {
      id
      email
      name
      description
      gender
      address
      isVerified
      isLocked
      isAuthorVerified
      role
      cover
      certificates {
        name
        image
      }
      lessonLevels
      lessonTopics
      promoVideo
      profilePath
      updatedTime
      createdTime
      __typename
    }
  }
`;

export const LIST_USER_COURSE = gql`
  query listUserCourses($input: ListUserCoursesInput!) {
    listUserCourses(input: $input) {
      page
      totalPages
      totalResults
      results {
        id
        identityId
        title
        price
        type
        duration
        isFree
        description
        thumbnail
        remainTime
        books {
          id
          title
          thumbnail
          description
          chapterCount
        }
        exams {
          id
          time
          title
          type
        }
      }
    }
  }
`;

export const GET_USER_COURSE = gql`
  query getUserCourse($input: GetUserCourseInput!) {
    getUserCourse(input: $input) {
      id
      identityId
      title
      price
      duration
      isFree
      description
      thumbnail
      type
      remainTime
      books {
        id
        title
        thumbnail
        description
        chapterCount
      }
      exams {
        id
        time
        times
        avgPoint
        title
        type
      }
    }
  }
`;

export const GET_PROFILE = gql`
  query getUserProfile($input: GetUserInput!) {
    getUserProfile(input: $input) {
      id
      email
      name
      description
      gender
      address
      isVerified
      isLocked
      role
      cover
      tickets
      certificates {
        name
        image
      }
      promoVideo
      isAuthorVerified
      updatedTime
      createdTime
      profilePath
      __typename
      lessonLevels
      lessonTopics
    }
  }
`;

export const GET_AUTHOR_PROFILE = gql`
  query getAuthorProfile($input: GetAuthorProfileInput!) {
    getAuthorProfile(input: $input) {
      id
      name
      cover
      avgRate
      totalReviews
      rates {
        total
        value
        percent
      }
      description
      profilePath
      lessonLevels
      lessonTopics
    }
  }
`;

export const LIST = gql`
  query listUsers($input: ListUserInput!) {
    listUsers(input: $input) {
      id
      email
      name
      isVerified
      isLocked
      profilePath
      isAuthorVerified
      role
      updatedTime
      createdTime
      __typename
    }
  }
`;

export const SEARCH_USER = gql`
  query searchUser($input: SearchUserInput) {
    searchUser(input: $input) {
      total
      users {
        id
        email
        isVerified
        isLocked
        role
        updatedTime
        createdTime
        __typename
      }
    }
  }
`;

export const OPERATION_SEARCH_USER = gql`
  query operationSearchUsers($input: OperationSearchUsersInput!) {
    operationSearchUsers(input: $input) {
      totalItems
      items {
        id
        name
        email
        profilePath
        isVerified
        isLocked
        isAuthorVerified
        role
        updatedTime
        createdTime
      }
    }
  }
`;

export const USERS = gql`
  query users($role: String, $keyword: String) {
    users(role: $role, keyword: $keyword) {
      id
      loginId
      name
      role
      createdAt
      __typename
    }
  }
`;

export const QueryKeys = gql`
  query queryKeys {
    queryKeys {
      type
      table
      query
    }
  }
`;
