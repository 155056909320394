import gql from 'graphql-tag';

export const GET = gql`
  query getSetting($input: GetSettingInput!) {
    getSetting(input: $input) {
      id
      home {
        promotion {
          isPublic
          title
          banner
          description
          limit
          featuredPosts {
            id
            title
            thumbnail
            description
            url
          }
        }
        categories {
          icon
          title
          description
        }
        course {
          isPublic
          title
          banner
          description
          limit
          featuredPosts {
            id
            title
            thumbnail
            description
          }
        }
        examFree {
          isPublic
          title
          banner
          description
          limit
          featuredPosts {
            id
            title
            thumbnail
            description
          }
        }
        news {
          isPublic
          title
          banner
          description
          limit
          featuredPosts {
            id
            title
            thumbnail
            description
          }
        }
        blog {
          isPublic
          title
          banner
          description
          limit
          featuredPosts {
            id
            title
            thumbnail
            description
          }
        }
      }
      news {
        isPublic
        title
        banner
        bannerBlur
        description
        limit
        featuredPosts {
          id
          title
          thumbnail
          description
        }
      }
      course {
        isPublic
        title
        banner
        bannerBlur
        description
        limit
        featuredPosts {
          id
          title
          thumbnail
          description
        }
      }
      footer {
        profile {
          name
          description
          phone
          email
        }
        apps {
          ios
          android
        }
        copyright
      }
      privacy
      terms
      commercialLaw
      companyProfile
    }
  }
`;

export const GET_FEATURE_COURSE = gql`
  query getSetting($input: GetSettingInput!) {
    getSetting(input: $input) {
      home {
        course {
          featuredPosts {
            id
            title
            thumbnail
            description
          }
        }
      }
    }
  }
`;

export const GET_LOCALE = gql`
  query getLocaleSetting {
    getLocaleSetting {
      ja {
        global
        errors
        typeList
      }
      vi {
        global
        errors
        typeList
      }
      en {
        global
        errors
        typeList
      }
    }
  }
`;
