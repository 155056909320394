import React from 'react';
import styled from 'styled-components';
import { History } from 'history';
import { useUserApi } from 'api/user/useUserApi';
import { AppConst } from 'helpers';
import { useLocation } from 'react-router-dom';
import { MobileHeaderView } from './MobileHeaderView';
import { WebHeaderView } from './WebHeaderView';
import { Div, HorizontalView } from 'vendor-deprecated/Wrapper';
import { useAppSetting } from 'helpers/useAppSetting';
import { useSession } from 'session/useSession';

const TopHeaderWrapper = styled.div<{ borderColor: string; background: string }>`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.background};
  border-bottom: 1px solid ${(props) => props.borderColor};
`;

type HeaderProps = {
  isCMS: boolean;
  history: History;
};

export const Header = (props: HeaderProps) => {
  const location = useLocation();
  const { session } = useSession();
  const { appTheme, appLayout } = useAppSetting();

  const {
    getProfile: { user, getUserProfile },
  } = useUserApi();

  React.useEffect(() => {
    if (session.user?.id) {
      getUserProfile({ id: session.user.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  const isMobile = appLayout.type === 'mobile' || appLayout.type === 'tablet';

  const userInfor = session.isAuthenticated ? user || session.user : undefined;

  let height = '60px';

  if (
    location.pathname.includes('/author/') ||
    location.pathname.includes('/cms') ||
    location.pathname.includes('/user')
  ) {
    if (!location.pathname.includes('/user/exam/')) {
      height = '40px';
    }
  }

  return (
    <HorizontalView
      id={'header'}
      width={appLayout.header.width}
      height={appLayout.isMobile ? '60px' : height}
      position={'fixed'}
      left={'0px'}
      top={'0px'}
      zIndex={AppConst.zIndex.header + 1}
    >
      <TopHeaderWrapper background={appTheme.header.bg} borderColor={appTheme.header.border}>
        <Div fullWidthHeight>
          <MobileHeaderView
            isMobile={isMobile}
            isCMS={props.isCMS}
            history={props.history}
            path={location.pathname}
            auth={userInfor}
            isSessionLoaded={session.isLoaded}
          />

          <WebHeaderView
            isMobile={isMobile}
            isCMS={props.isCMS}
            history={props.history}
            path={location.pathname}
            auth={userInfor}
            isSessionLoaded={session.isLoaded}
          />
        </Div>
      </TopHeaderWrapper>
    </HorizontalView>
  );
};

export default Header;
