import gql from 'graphql-tag';

export const LOG_IN = gql`
  mutation login($input: LoginUserInput!) {
    login(input: $input) {
      id
      token
      email
      examChallengeId
      isVerified
      isLocked
      role
      __typename
    }
  }
`;

export const REGISTER = gql`
  mutation register($input: RegisterUserInput!) {
    register(input: $input)
  }
`;

export const CREATE = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input)
  }
`;

export const LOG_OUT = gql`
  mutation logout {
    logout
  }
`;

export const UPDATE = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input)
  }
`;

export const DELETE = gql`
  mutation deleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input)
  }
`;

export const CONFIRM = gql`
  mutation confirm($input: ConfirmInput!) {
    confirm(input: $input)
  }
`;

export const RESET_PASS = gql`
  mutation resetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input)
  }
`;

export const RESET_PASS_CONFIRM = gql`
  mutation resetConfirm($input: ResetConfirmInput!) {
    resetConfirm(input: $input)
  }
`;

export const CHANGE_PASS = gql`
  mutation changePassword($input: ChangePasswordUserInput!) {
    changePassword(input: $input)
  }
`;

export const RESET_CONFRIM = gql`
  mutation resetConfirm($input: ResetConfirmInput) {
    resetConfirm(input: $input)
  }
`;
