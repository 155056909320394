import gql from 'graphql-tag';

export const SEARCH = gql`
  query searchAccesses($input: SearchAccessesInput!) {
    searchAccesses(input: $input) {
      totalItems
      items {
        id
        ip
        url
        dataSource
        userAgent
        user {
          id
          name
        }
        permission
        createdTime
        updatedTime
      }
    }
  }
`;
