import axios from 'axios';
import { useAccess } from 'hooks/api/access';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSession } from 'session/useSession';

export const useUserAccess = () => {
  const location = useLocation();
  const { session } = useSession();
  const { onAccess } = useAccess();
  const getMyIp = async (url: string, mkid?: string | null) => {
    try {
      const res = await axios.get('https://geolocation-db.com/json/');
      onAccess({
        mkid: mkid || null,
        url,
        dataSource: res.data ? JSON.stringify(res.data) : JSON.stringify(res),
      });
    } catch (error) {
      onAccess({
        mkid: mkid || null,
        url,
        dataSource: null,
      });
    }
  };
  useEffect(() => {
    const mkid = new URLSearchParams(location.search).get('mkid');
    if (
      session.isLoaded &&
      (mkid || location.pathname.includes('/articles/') || location.pathname.includes('/courses/'))
    ) {
      getMyIp(location.pathname, mkid);
    }
  }, [location.pathname, session.isLoaded]);
};
