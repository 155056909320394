import React from 'react';
import { Routes } from './routes';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { initI18next } from 'locale';

initI18next();

function App() {
  return <Routes />;
}

export default App;
