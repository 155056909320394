import React from 'react';
import { TextDisplay } from 'vendor-deprecated/Text';
import { HorizontalView } from 'vendor-deprecated/Wrapper';
import { Button } from 'vendor-deprecated/Button';
import { useTranslationText } from 'locale';
import { ChangeLanguageModal } from '../Modals/ChangeLanguageModal';
export const ChangeLanguage = (props: { showLabel: boolean; color?: string }) => {
  const { translation } = useTranslationText();
  const [open, setOpen] = React.useState(false);
  return (
    <HorizontalView>
      {props.showLabel && (
        <TextDisplay size={'15px'} marginRight={'15px'} color={props.color}>
          {translation('mobileSideBar.language')}
        </TextDisplay>
      )}
      <Button
        icon={'global'}
        size={'15px'}
        height={'30px'}
        borderRadius={'4px'}
        border
        fontWeight={300}
        color={props.color}
        text={translation(localStorage.getItem('QHC_LANG') || 'ja')}
        onClick={() => setOpen(true)}
      />
      {open && <ChangeLanguageModal open onClose={() => setOpen(false)} />}
    </HorizontalView>
  );
};
