export function delay(delay: number) {
  return new Promise((res) => setTimeout(res, delay));
}

function getTime(duration: number) {
  let seconds = Math.floor(duration % 60);
  let minutes = Math.floor((duration / 60) % 60);
  let hours = Math.floor((duration / (60 * 60)) % 24);

  hours = hours < 10 ? 0 + hours : hours;
  minutes = minutes < 10 ? 0 + minutes : minutes;
  seconds = seconds < 10 ? 0 + seconds : seconds;
  return { hours: hours, minutes: minutes, seconds: seconds };
}

export default class Timer {
  examTime?: number;
  remaining?: number;
  isStop?: boolean = true;
  onFinish?: () => void;
  onStart?: () => void;
  onChange?: (hours: number, minus: number, seconds: number, remaining: number) => void;

  static instance?: Timer = undefined;
  static createInstance() {
    const object = new Timer();
    return object;
  }

  static getInstance() {
    if (!Timer.instance) {
      Timer.instance = Timer.createInstance();
    }
    return Timer.instance;
  }

  setTime(time: number) {
    this.examTime = time;
  }

  stop() {
    this.examTime = 0;
    this.remaining = 0;
    this.isStop = true;
  }

  async startCountdown(time: number, finish: boolean) {
    if (!finish && this.isStop) {
      this.isStop = false;
      this.examTime = time;
      this.remaining = time;
      const timeRemaining = getTime(this.remaining);
      this.onChange && this.onChange(timeRemaining.hours, timeRemaining.minutes, timeRemaining.seconds, this.remaining);
      while (this.remaining > 0) {
        await delay(1000);
        this.remaining -= 1;
        const timeRemaining = getTime(this.remaining);
        this.onChange &&
          this.onChange(timeRemaining.hours, timeRemaining.minutes, timeRemaining.seconds, this.remaining);
      }
      if (!this.isStop) {
        this.onFinish && this.onFinish();
      }
    }
  }
  getCountdown() {
    return '';
  }
}
