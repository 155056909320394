import styled from 'styled-components';
import { CssProps } from '../../types/CommonTypes';
import * as colors from 'helpers/colors';
import * as wrapper from '../Wrapper';

export const HorizontalView = wrapper.HorizontalView;
export const VerticalView = wrapper.VerticalView;

export const CommonWrapper = styled.div`
  width: 100vw;
`;

export const TitleWrapper = styled.div<{
  size?: string;
  marginTop?: string;
  maxLine?: number;
  align?: 'center' | 'left' | 'right';
}>`
  margin-top: ${(props) => props.marginTop || '0px'};
  -webkit-line-clamp: ${(props) => props.maxLine || '2'};
  font-weight: 600;
  font-size: ${(props) => props.size || '24px'};
  text-align: ${(props) => props.align || 'left'};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  ${(props) => (props.color ? ` color: ${props.color};` : '')};
  text-overflow: ellipsis;
`;

export const DescriptionWrapper = styled.div<{ size?: string; marginTop?: string; maxLine?: number }>`
  margin-top: ${(props) => props.marginTop || '10px'};
  -webkit-line-clamp: ${(props) => props.maxLine || '3'};
  font-size: ${(props) => props.size || '16px'};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  text-align: left;
  ${(props) => (props.color ? ` color: ${props.color};` : '')};
`;

export const BodyContentWrapper = styled.div`
  min-height: 80vh;
  padding-top: 60px;
  width: 100vw;
  overflow: hidden;
`;
export const BackgroundWrapper = styled.div`
  background-color: #f4f4f9;
  width: 100%;
  min-height: calc(100vh - 60px);
`;

export const BodyWrapper = styled.div<{ maxWidth?: string; width?: string }>`
  ${(props) => (props.maxWidth ? `max-width:${props.maxWidth};` : ' max-width: 1000px;')}
  ${(props) => (props.width ? `width:${props.width};` : '')}
  margin: auto;
`;

export const H2 = styled.h2``;
export const H3 = styled.h3``;

export const CardWrapper = styled.div<CssProps>`
  transition: 0.3s;
  padding-top: 20px;
  padding-bottom: 20px;
  padding: ${(props: CssProps) => (props.padding ? props.padding : '20px')};
  border-radius: ${(props: CssProps) => props.borderRadius || '5px'};
  width: ${(props: CssProps) => props.width || '100%'};
  height: ${(props: CssProps) => props.height || '100%'};
  background: ${(props: CssProps) => props.background || 'white'};
  ${(props: CssProps) => (props.paddingTop ? `padding-top: ${props.paddingTop}` : '')};
  ${(props: CssProps) => (props.paddingBottom ? `padding-bottom: ${props.paddingBottom}` : '')};
  margin: ${(props: CssProps) => (props.margin ? props.margin : '0px')};
  margin-top: ${(props: CssProps) => (props.marginTop ? props.marginTop : '0px')};
  margin-bottom: ${(props: CssProps) => (props.marginBottom ? props.marginBottom : '0px')};

  ${(props: CssProps) =>
    props.borderTop ? `border-top:${props.borderWidth || 5}px solid ${props.borderColor || colors.BORDER};` : ''};
  ${(props: CssProps) =>
    !props.noShadow
      ? ` 
            box-shadow: 0 2px 8px 2px rgb(20 23 28 / 15%);
            -webkit-box-shadow: 0 2px 8px 2px rgb(20 23 28 / 15%);
            -moz-box-shadow: 0 2px 8px 2px rgb(20 23 28 / 15%);
            box-shadow: 0 2px 8px 2px rgb(20 23 28 / 15%);`
      : ''};
`;

export const PageBodyPanel = styled.div`
  webkit-box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3), 0 1px 5px -2px rgba(152, 162, 179, 0.3);
  box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3), 0 1px 5px -2px rgba(152, 162, 179, 0.3);
  background-color: #fff;
  border: 1px solid #d3dae6;
  border-radius: 4px;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 20px;
  max-width: 960px;
`;

export const PageTitle = styled.div`
  color: #1a1c21;
  font-size: 36px;
  font-size: 2.25rem;
  line-height: 1.25;
  font-weight: 300;
  letter-spacing: -0.03em;
  line-height: 3rem;
  margin-bottom: 2rem;
`;

export const PageBody = styled.div``;

export const ErrorMessage = styled.div`
  margin-top: 5px;
  color: red;
  font-size: 10px;
`;

export const MustLabel = styled.span`
  display: inline-block;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1;
  border-radius: 3px;
  color: red;
  margin-left: 2px;
`;
