export function shortenedString(props: { content?: string; shortened?: { lenght: number } }): string {
  if (!props.content) {
    return '';
  }

  if (props.shortened && props.content.length > props.shortened.lenght) {
    return props.content.substring(0, props.shortened.lenght) + '...';
  }
  return props.content;
}

export function shortenedHTMLString(props: { content: string; shortened?: { lenght: number } }): string {
  if (props.content === '') {
    return '';
  }
  const div = document.createElement('div');
  div.innerHTML = props.content;
  const textContent = div.textContent || div.innerText || '';
  return shortenedString({ content: textContent, shortened: props.shortened });
}
