import gql from 'graphql-tag';

export const CREATE_NOTIFICATION = gql`
  mutation createNotification($input: CreateNotificationInput!) {
    createNotification(input: $input)
  }
`;

export const READ_NOTIFICATION = gql`
  mutation readNotification($input: ReadNotificationInput!) {
    readNotification(input: $input)
  }
`;

export const DELETE_NOTIFICATION = gql`
  mutation deleteNotification($input: DeleteNotificationInput!) {
    deleteNotification(input: $input)
  }
`;
