import React from 'react';
import { AppColors } from 'helpers';
import { useHistory } from 'react-router';
import { Button } from 'vendor-deprecated/Button';
import { Div, HorizontalView } from 'vendor-deprecated/Wrapper';
import { TextDisplay } from 'vendor-deprecated/Text';
import { appEvent } from 'vendor-deprecated/Event';

type CartButtonProps = {
  size?: string;
  color?: string;
};

export const CartButton = (props: CartButtonProps) => {
  const history = useHistory();
  const [count, setCount] = React.useState(0);

  const refresh = () => {
    const cartIds = localStorage.getItem('cartIds');
    if (cartIds) {
      try {
        const ids = Object.keys(JSON.parse(cartIds));
        setCount(ids.length);
      } catch (_) {
        setCount(0);
      }
    }
  };

  React.useEffect(() => {
    refresh();
  }, []);

  React.useEffect(() => {
    appEvent.addListener('update-cart', refresh);
    return () => {
      appEvent.off('update-cart', refresh);
    };
  }, []);

  return (
    <HorizontalView
      cursor={'pointer'}
      position={'relative'}
      onClick={() => {
        history.push('/my-cart');
      }}
    >
      <HorizontalView
        height={'30px'}
        width={'1px'}
        background={props.color || 'white'}
        marginLeft={'5px'}
        marginRight={'5px'}
      />
      <Button
        icon={'cart'}
        size={props.size || '25px'}
        color={props.color || 'black'}
        background={AppColors.TRANSPARENT}
      />
      {Boolean(count) && (
        <Div position={'absolute'} top={'5px'} right={'5px'}>
          <Div background={'red'} width={'16px'} height={'16px'} borderRadius={'8px'}>
            <TextDisplay color={'white'} size={'10px'}>
              {count}
            </TextDisplay>
          </Div>
        </Div>
      )}
    </HorizontalView>
  );
};
