import { DocumentNode, useApolloClient } from '@apollo/client';
import { IDeleteMediaInput, IMediaUploadOutput, ISearchMediaInput, ISearchMediaOutput, IMedia } from 'types/types';

export type MutationDataType = 'deleteMedia' | 'uploadMedia';

type QueryInputType = ISearchMediaInput;

type QueryType = 'searchMedia';

type QueryDataType = ISearchMediaOutput;

type MutationData =
  | {
      type: 'deleteMedia';
      input: IDeleteMediaInput;
    }
  | {
      type: 'uploadMedia';
      input: File[];
      response: IMediaUploadOutput;
    };

export type UpdateCacheQuery = {
  type: QueryType;
  query: DocumentNode;
  input: QueryInputType;
  currentData: QueryDataType;
};

export const useApolloCache = () => {
  const client = useApolloClient();

  const writeCache = (type: QueryType, query: DocumentNode, input: QueryInputType, dataUpdate: QueryDataType) => {
    try {
      client.writeQuery({
        query,
        variables: { input: input },
        data: {
          [type]: dataUpdate,
        },
      });
    } catch (e) {}
  };

  const updateApolloCache = (cacheQuery: UpdateCacheQuery, mutationData: MutationData) => {
    let data: ISearchMediaOutput;
    switch (mutationData.type) {
      case 'deleteMedia':
        data = JSON.parse(JSON.stringify(cacheQuery.currentData));
        data.hits = data.hits ? data.hits.filter((media) => media?.id !== mutationData.input.id) : [];
        writeCache('searchMedia', cacheQuery.query, cacheQuery.input, data);
        break;
      case 'uploadMedia':
        data = JSON.parse(JSON.stringify(cacheQuery.currentData));
        if (mutationData.response.paths && mutationData.response.paths.length > 0) {
          if (cacheQuery.currentData.hits.length > 0) {
            const medias =
              mutationData.response.paths.filter((media) => {
                return Boolean(cacheQuery.currentData.hits.filter((m) => m?.id !== media?.id));
              }) || [];

            data.hits = (medias ? [...medias, ...data.hits] : data.hits) as IMedia[];
          } else {
            data.hits = mutationData.response.paths as IMedia[];
          }
          writeCache('searchMedia', cacheQuery.query, cacheQuery.input, data);
        }

        break;
    }
  };

  return {
    updateApolloCache,
  };
};
