import * as mutations from './mutations';
import * as queries from './queries';

export const accessMutations = {
  ...mutations,
};

export const accessQueries = {
  ...queries,
};
