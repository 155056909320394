import gql from 'graphql-tag';

export const SEARCH_NOTIFICATONS = gql`
  query searchNotifications($input: SearchNotificationsInput!) {
    searchNotifications(input: $input) {
      totalItems
      items {
        id
        type
        message
        read
        userId
        sourceId
        param
        createdBy
        updatedTime
        createdTime
      }
    }
  }
`;

export const GET_NOTIFICATION = gql`
  query getNotification($input: GetNotificationInput!) {
    getNotification(input: $input) {
      id
      type
      message
      read
      userId
      param
      sourceId
      createdBy
      updatedTime
      createdTime
    }
  }
`;

export const GET_UNREAD = gql`
  query getUnreadNotificationCount {
    getUnreadNotificationCount
  }
`;
