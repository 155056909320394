import defaultConfig from './default-config';

type Config = {
  graphql: {
    uri: string;
  };
  UI_HOST_URL: string;
  HOST_URL: string;
  API_HOST_URL: string;
  STRIPE_PUBLISH: string;
  FB_TOKEN: string;
  tickets: {
    ticket_1: string;
    ticket_3: string;
    ticket_5: string;
    ticket_10: string;
  };
};
const envConfig = require(`./${process.env.NODE_ENV}-config`).default;

export default Object.assign({ env: process.env.NODE_ENV }, defaultConfig, envConfig) as Config;
