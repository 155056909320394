import React from 'react';
import { useLocation } from 'react-router';
import { IUser } from 'types/types';
import { useWindowDimensions } from 'vendor-deprecated/Hooks/useWindow/useWindowDimensions';

type BaseColor = {
  base: string;
  text: string;
  blueLight: string;
  red: string;
  green: string;
  blue: string;
  yellow: string;
  orange: string;
  white: string;
  black: string;
  purple: string;
  border: string;
};

type HeaderColor = {
  bg: string;
  text: string;
  border: string;
};
type SidebarColor = {
  bg: string;
  bgHover: string;
  text: string;
  border: string;
  selected: string;
};

type BodyColor = {
  bg: string;
  bgHover: string;
  text: string;
  border: string;
  selected: string;
  add: string;
  edit: string;
  delete: string;
};

type NewsColor = {
  bg: string;
  title: string;
  category: string;
  description: string;
  time: string;
  selected: string;
  add: string;
  edit: string;
  delete: string;
};

export type AppTheme = {
  style: 'cms' | 'public' | 'author' | 'member';
  header: HeaderColor;
  news: NewsColor;
  sidebar: SidebarColor;
  body: BodyColor;
  base: BaseColor;
};

type Layout = {
  width: string;
  minWidth: string;
  maxWidth: string;
  height: string;
};

type AppLayout = {
  type: 'mobile' | 'tablet' | 'larger';
  width: number;
  height?: number;
  isMobile: boolean;
  isSmallMobile: boolean;
  sidebar: Layout;
  header: Layout;
  body: Layout;
};

export type AppSize = {
  h1: string;
  h2: string;
  h3: string;
  h4: string;
  h5: string;
  xs: string;
  sm: string;
  md: string;
  lg: string;
  xl: string;
  xxl: string;
};

type Lang = 'vi' | 'en' | 'ja';

type AppSetting = {
  lang: Lang;
  appLayout: AppLayout;
  appTheme: AppTheme;
  appSize: AppSize;
};

const mobileSize = {
  h1: '32px',
  h2: '22px',
  h3: '16px',
  h4: '14px',
  h5: '12px',
  xs: '10px',
  sm: '12px',
  md: '13px',
  lg: '14px',
  xl: '16px',
  xxl: '18px',
};

const webSize = {
  h1: '32px',
  h2: '24px',
  h3: '18px',
  h4: '16px',
  h5: '14px',
  xs: '12px',
  sm: '13px',
  md: '14px',
  lg: '16px',
  xl: '17px',
  xxl: '18px',
};

export const useAppSetting = (props?: { auth?: IUser }): AppSetting => {
  const [auth, setAuth] = React.useState(props?.auth);

  const location = useLocation();
  const windowDimensions = useWindowDimensions();

  const isTablet = windowDimensions.isTablet;
  const isMobile = windowDimensions.isMobile || windowDimensions.isTablet;

  React.useEffect(() => {
    setAuth(props?.auth);
  }, [props?.auth]);

  function getSetting(): AppSetting {
    const setting: AppSetting = {
      appTheme: {
        header: { bg: '', text: '' },
        sidebar: { bg: '', text: '' },
        body: { bg: '', text: '' },
        style: 'public',
      },
    } as AppSetting;

    if ((auth && auth.role === 'AUTHOR') || location.pathname.includes('/author/')) {
      /********************
       *****Theme color/*****
       **********************/
      /* Header */
      setting.appTheme.header.bg = isMobile ? '#37474F' : '#EDEDED';
      setting.appTheme.header.text = isMobile ? 'white' : 'black';
      setting.appTheme.header.border = isMobile ? 'rgb(255,255,255,0.1)' : 'rgb(0,0,0,0.1)';

      if (location.pathname === '/author/profile') {
        setting.appTheme.header.bg = 'rgb(0,0,0,0.2)';
      } else if (location.pathname.includes('/author/courses/') && location.pathname.length > 17) {
        setting.appTheme.header.bg = '#37474F';
        setting.appTheme.header.text = 'white';
        setting.appTheme.header.border = 'rgb(255,255,255,0.1)';
      }

      /* Sidebar */
      setting.appTheme.sidebar.bg = '#37474F';
      setting.appTheme.sidebar.bgHover = 'rgb(255,255,255,0.1)';
      setting.appTheme.sidebar.text = 'white';
      setting.appTheme.sidebar.selected = '#e1b382';
      setting.appTheme.sidebar.border = 'rgb(255,255,255,0.1)';

      /* Body */
      setting.appTheme.body.bg = 'white';
      setting.appTheme.body.text = 'black';
      setting.appTheme.body.add = 'white';
      setting.appTheme.body.edit = '#9bc400';
      setting.appTheme.body.delete = '#ea352d';

      setting.appTheme.style = 'author';
    } else if ((auth && auth.role === 'ADMIN') || location.pathname.includes('/cms/')) {
      /*Theme color*/
      /* Header */
      setting.appTheme.header.bg = isMobile ? '#051E34' : '#EDEDED';
      setting.appTheme.header.text = isMobile ? 'white' : 'black';
      setting.appTheme.header.border = isMobile ? 'rgb(255,255,255,0.1)' : 'rgb(0,0,0,0.1)';

      if (location.pathname.includes('/cms/courses/') && location.pathname.length > 14) {
        setting.appTheme.header.bg = '#051E34';
        setting.appTheme.header.text = 'white';
        setting.appTheme.header.border = 'rgb(255,255,255,0.1)';
      }

      /* Sidebar */
      setting.appTheme.sidebar.bg = '#37474F';
      setting.appTheme.sidebar.bgHover = 'rgb(255,255,255,0.1)';
      setting.appTheme.sidebar.text = 'white';
      setting.appTheme.sidebar.selected = '#e1b382';
      setting.appTheme.sidebar.border = 'rgb(255,255,255,0.1)';
      setting.appTheme.style = 'cms';
    } else if ((auth && auth.role === 'USER') || location.pathname.includes('/user/')) {
      /* Header */
      setting.appTheme.header.bg = isMobile ? '#7398a5' : '#EDEDED';
      setting.appTheme.header.text = isMobile ? 'white' : 'black';
      setting.appTheme.header.border = isMobile ? 'rgb(255,255,255,0.1)' : 'rgb(0,0,0,0.1)';

      if (location.pathname.includes('/user/exam')) {
        setting.appTheme.header.bg = '#7398a5';
        setting.appTheme.header.text = 'white';
        setting.appTheme.header.border = 'rgb(255,255,255,0.1)';
      }

      /* Sidebar */
      setting.appTheme.sidebar.bg = '#7398a5';
      setting.appTheme.sidebar.bgHover = 'rgb(255,255,255,0.1)';
      setting.appTheme.sidebar.text = 'white';
      setting.appTheme.sidebar.selected = '#e1b382';
      setting.appTheme.sidebar.border = 'rgb(255,255,255,0.1)';

      setting.appTheme.style = 'member';
    } else {
      /* Header */
      setting.appTheme.header.bg = 'white';
      setting.appTheme.header.text = '#262626';
      setting.appTheme.header.border = 'rgb(0,0,0,0.1)';

      if (location.pathname.includes('/services')) {
        setting.appTheme.header.bg = '#05D3E4';
        setting.appTheme.header.border = '#05D3E4';
      }

      /* Sidebar */
      setting.appTheme.sidebar.bg = 'white';
      setting.appTheme.sidebar.bgHover = 'white';
      setting.appTheme.sidebar.text = 'black';
      setting.appTheme.sidebar.selected = 'black';
      setting.appTheme.sidebar.border = 'gray';

      /*Layout*/
      setting.appLayout = {
        width: windowDimensions.windowDimensions.width,
        height: windowDimensions.windowDimensions.height,
        isMobile: isMobile,
        isSmallMobile: windowDimensions.windowDimensions.width < 340,
        type: isMobile ? 'mobile' : isTablet ? 'tablet' : 'larger',
        header: {} as Layout,
        sidebar: {} as Layout,
        body: {} as Layout,
      };

      setting.appLayout.sidebar.width = '0px';
      setting.appLayout.sidebar.height = '0px';
      setting.appLayout.header.width = '100vw';
      setting.appLayout.header.height = 'auto';
      setting.appLayout.body.width = '100vw';
      setting.appLayout.body.height = 'auto';

      setting.appTheme.style = 'public';
    }

    if (setting.appTheme.style !== 'public') {
      /*Layout*/
      setting.appLayout = {
        width: windowDimensions.windowDimensions.width,
        height: windowDimensions.windowDimensions.height,
        isMobile: isMobile,
        isSmallMobile: windowDimensions.windowDimensions.width <= 320,
        type: isMobile ? 'mobile' : isTablet ? 'tablet' : 'larger',
        header: {} as Layout,
        sidebar: {} as Layout,
        body: {} as Layout,
      };

      setting.appLayout.header.width = '100vw';
      setting.appLayout.header.height = isMobile ? '60px' : '40px';

      setting.appLayout.sidebar.width = '100%';
      setting.appLayout.sidebar.minWidth = location.pathname.includes('/cms/') ? '50px' : '85px';
      setting.appLayout.sidebar.maxWidth = '300px';
      setting.appLayout.sidebar.height = '100vh';

      setting.appLayout.body.width = '100%';
      setting.appLayout.body.minWidth = isMobile ? '100vw' : `calc(100vw - ${setting.appLayout.sidebar.maxWidth})`;
      setting.appLayout.body.maxWidth = isMobile ? '100vw' : `calc(100vw - ${setting.appLayout.sidebar.minWidth})`;
      setting.appLayout.body.height = `calc(100vw - ${setting.appLayout.header.height})`;
    }

    //NEWS CARD
    setting.appTheme.news = {} as NewsColor;
    setting.appTheme.news.bg = '#094A82';
    setting.appTheme.news.category = '#7F7F7F';
    setting.appTheme.news.title = '#292929';
    setting.appTheme.news.time = '#7F7F7F';
    setting.appTheme.news.description = '#757575 !important';

    /* Body */
    setting.appTheme.body.bg = 'white';
    setting.appTheme.body.text = 'black';
    setting.appTheme.body.add = 'white';
    setting.appTheme.body.edit = '#9bc400';
    setting.appTheme.body.delete = '#ea352d';

    //Base color
    setting.appTheme.base = {
      base: '#051E34',
      text: '#707070',
      blueLight: '#C4F3F7',
      red: 'red',
      green: 'green',
      blue: 'blue',
      yellow: 'yellow',
      orange: '#FF8502',
      white: 'white',
      black: 'black',
      purple: 'purple',
      border: '#707070',
    };

    setting.appSize = isMobile ? mobileSize : webSize;

    setting.lang = (localStorage.getItem('QHC_LANG') || 'ja') as Lang;

    return setting;
  }

  const st = React.useMemo(() => {
    return getSetting();
  }, [location.pathname, auth?.role, isMobile]);

  return st;
};
