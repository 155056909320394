import pMinDelay from 'p-min-delay';
import React from 'react';

export const NotFoundPage = React.lazy(() => import('containers/user-interface/NotFound'));
export const EmptyPage = React.lazy(() => import('containers/user-interface/Empty'));
export const Home = React.lazy(() => import('containers/user-interface/Home'));
export const HelpCenter = React.lazy(() => pMinDelay(import('containers/user-interface/HelpCenter/List'), 0));
export const HelpDetails = React.lazy(() => pMinDelay(import('containers/user-interface/HelpCenter/Details'), 0));
export const Exam = React.lazy(() => pMinDelay(import('../containers/student-interface/Exam'), 0));
export const CourseDetails = React.lazy(() => pMinDelay(import('containers/user-interface/CourseDetails'), 0));
export const Course = React.lazy(() => pMinDelay(import('containers/user-interface/CourseList'), 0));
export const About = React.lazy(() => pMinDelay(import('../containers/user-interface/About/containers/index'), 0));
export const Privacy = React.lazy(() => pMinDelay(import('containers/user-interface/Privacy'), 0));
export const Terms = React.lazy(() => pMinDelay(import('containers/user-interface/Terms'), 0));
export const Service = React.lazy(() => pMinDelay(import('containers/user-interface/Service/List'), 0));
export const ServiceDetails = React.lazy(() => pMinDelay(import('containers/user-interface/Service/Details'), 0));
export const News = React.lazy(() => pMinDelay(import('containers/user-interface/News/List'), 0));
export const NewsDetails = React.lazy(() => pMinDelay(import('containers/user-interface/News/Details'), 0));
export const BlogList = React.lazy(() => pMinDelay(import('containers/user-interface/Blog'), 0));
export const SeacrchNews = React.lazy(() => pMinDelay(import('containers/user-interface/News/Search'), 0));

export const CommercialLaw = React.lazy(() => pMinDelay(import('containers/user-interface/CommercialLaw'), 0));

export const Lecture = React.lazy(() => pMinDelay(import('containers/student-interface/Lecture'), 0));
// User Profile
export const User = React.lazy(() => pMinDelay(import('containers/student-interface/Index'), 0));
export const UserInformation = React.lazy(() => pMinDelay(import('containers/student-interface/Information/index'), 0));
export const AuthorProfile = React.lazy(() => pMinDelay(import('containers/author-interface/profile/index'), 0));
export const AuthorSchedule = React.lazy(() => pMinDelay(import('containers/author-interface/schedule'), 0));
//MyCourse
export const MyCourse = React.lazy(() => pMinDelay(import('containers/student-interface/MyCourse/index'), 0));
export const TeachApply = React.lazy(() => pMinDelay(import('containers/user-interface/TeachApply/index'), 0));

//MyExam
export const MyExam = React.lazy(() => pMinDelay(import('containers/student-interface/MyExam/index'), 0));

//ChangePass
export const ChangePass = React.lazy(() =>
  pMinDelay(import('../containers/student-interface/ChangePassword/index'), 0),
);

//ResetPassConfirm
export const ResetPassConfirm = React.lazy(() =>
  pMinDelay(import('containers/user-interface/ResetPasswordConfirm/index'), 0),
);

//ResetPassConfirm
export const Contact = React.lazy(() => pMinDelay(import('containers/user-interface/Contact/index'), 0));

//VerifyAccount
export const VerifyAccount = React.lazy(() => pMinDelay(import('containers/user-interface/VerifyAccount/index'), 0));

//OrderContainer
export const OrderContainer = React.lazy(() =>
  pMinDelay(import('containers/student-interface/PurchaseHistory/index'), 0),
);

//OrderContainer
export const CartContainer = React.lazy(() => pMinDelay(import('containers/student-interface/MyCart'), 0));

//Authorf
export const Author = React.lazy(() => pMinDelay(import('containers/author-interface/Index/containers/index'), 0));

//Course Price
export const CoursePrice = React.lazy(() =>
  pMinDelay(import('containers/cms-interface/CourseDetails/containers/CoursePrice'), 0),
);

export const AuthorCoursesList = React.lazy(() =>
  pMinDelay(import('../containers/author-interface/course-list/index'), 0),
);
export const AuthorCoursesLanding = React.lazy(() =>
  pMinDelay(import('containers/cms-interface/CourseDetails/containers/CourseLanding'), 0),
);
export const AuthorCourseDetails = React.lazy(() =>
  pMinDelay(import('containers/cms-interface/CourseDetails/containers/index'), 0),
);

//Exam
export const CourseExamsList = React.lazy(() =>
  pMinDelay(import('containers/cms-interface/CourseDetails/containers/CourseExamList'), 0),
);
export const CourseExamsDetails = React.lazy(() =>
  pMinDelay(import('containers/cms-interface/CourseDetails/containers/CourseExamDetails'), 0),
);

export const AuthorStatistic = React.lazy(() =>
  pMinDelay(import('containers/author-interface/Statistic/containers/index'), 0),
);

//HOME
export const CMSHomeSetting = React.lazy(() => pMinDelay(import('containers/cms-interface/Setting/HomeSetting'), 0));

//Privacy
export const CMSPrivacy = React.lazy(() => pMinDelay(import('containers/cms-interface/Setting/PrivacySetting'), 0));

//Privacy
export const CMSTerms = React.lazy(() => pMinDelay(import('containers/cms-interface/Setting/TermsSetting'), 0));

//Profile
export const CMSCompanyProfile = React.lazy(() =>
  pMinDelay(import('containers/cms-interface/Setting/CompanyProfile'), 0),
);

export const LocaleTranslation = React.lazy(() =>
  pMinDelay(import('containers/cms-interface/Setting/LocaleTranslation'), 0),
);
export const CourseBookDetails = React.lazy(() =>
  pMinDelay(import('containers/cms-interface/CourseDetails/containers/CourseBookDetails'), 0),
);

export const CMSLawSetting = React.lazy(() => pMinDelay(import('containers/cms-interface/Setting/CommercialLaw'), 0));

export const CMSNewsSetting = React.lazy(() => pMinDelay(import('containers/cms-interface/Setting/NewsSetting'), 0));

export const CMSCourseSetting = React.lazy(() =>
  pMinDelay(import('containers/cms-interface/Setting/CourseSetting'), 0),
);

//News
export const CMSNewsList = React.lazy(() =>
  pMinDelay(import('../containers/cms-interface/Article/containers/ArticlesSearch'), 0),
);
export const CMSNewsDetails = React.lazy(() =>
  pMinDelay(import('../containers/cms-interface/Article/containers/Articledetails'), 0),
);

//Help
export const CMSHelpsList = React.lazy(() => pMinDelay(import('containers/cms-interface/Help/List/index'), 0));
export const CMSHelpDetails = React.lazy(() => pMinDelay(import('containers/cms-interface/Help/Details/index'), 0));

//Service
export const CMSServiceList = React.lazy(() => pMinDelay(import('containers/cms-interface/Service/List/index'), 0));
export const CMSServiceDetails = React.lazy(() =>
  pMinDelay(import('containers/cms-interface/Service/Details/index'), 0),
);

//Exam
export const CMSExamList = React.lazy(() => pMinDelay(import('containers/cms-interface/Exam/List/index'), 0));
export const CMSExamDetails = React.lazy(() => pMinDelay(import('containers/cms-interface/Exam/Details/index'), 0));
export const CMSExamPreview = React.lazy(() => pMinDelay(import('containers/cms-interface/Exam/Preview/index'), 0));

//Order
export const CMSOrdersList = React.lazy(() => pMinDelay(import('containers/cms-interface/Order/List/index'), 0));
export const CMSOrderDetails = React.lazy(() => pMinDelay(import('containers/cms-interface/Order/Details/index'), 0));

// Admin
export const CMSIndex = React.lazy(() => pMinDelay(import('containers/cms-interface/Index'), 0));

export const CMSFooterSetting = React.lazy(() =>
  pMinDelay(import('containers/cms-interface/Setting/FooterSetting'), 0),
);

export const UserList = React.lazy(() =>
  pMinDelay(import('containers/cms-interface/User/containers/UsersSearchPage'), 0),
);
export const UserDetails = React.lazy(() => pMinDelay(import('containers/cms-interface/User/Details/index'), 0));
export const UserExamResults = React.lazy(() =>
  pMinDelay(import('containers/cms-interface/User/UserExamResults/index'), 0),
);

export const UploadCourse = React.lazy(() =>
  pMinDelay(import('containers/cms-interface/Course/containers/CourseUpload'), 0),
);
export const CourseList = React.lazy(() =>
  pMinDelay(import('containers/cms-interface/Course/containers/CoursesSearch'), 0),
);

export const UploadBook = React.lazy(() => pMinDelay(import('containers/cms-interface/Book/containers/BookUpload'), 0));
export const BookList = React.lazy(() => pMinDelay(import('containers/cms-interface/Book/containers/BooksSearch'), 0));
export const CreateBook = React.lazy(() =>
  pMinDelay(import('containers/cms-interface/Book/containers/BookDetails'), 0),
);
export const BookPreview = React.lazy(() =>
  pMinDelay(import('containers/cms-interface/Book/containers/BookPreview'), 0),
);

export const MediaList = React.lazy(() => pMinDelay(import('containers/cms-interface/Media/List'), 0));

//Contact
export const CMSContactList = React.lazy(() =>
  pMinDelay(import('containers/cms-interface/Contact/containers/ContactsSearch'), 0),
);
export const CMSContactDetails = React.lazy(() =>
  pMinDelay(import('containers/cms-interface/Contact/containers/ContactDetails'), 0),
);

//Report
export const CMSReportList = React.lazy(() => pMinDelay(import('containers/cms-interface/Report/ReportSearch'), 0));
export const CMSReportDetail = React.lazy(() => pMinDelay(import('containers/cms-interface/Report/ReportDetail'), 0));
//Notification
export const Notification = React.lazy(() =>
  pMinDelay(import('containers/student-interface/Notification/Notification'), 0),
);
//Promotion
export const CMSEventsList = React.lazy(() => pMinDelay(import('containers/cms-interface/event/EventListPage'), 0));
export const CMSEventDetails = React.lazy(() =>
  pMinDelay(import('containers/cms-interface/event/EventDetailsPage'), 0),
);
export const CMSMarketing = React.lazy(() => pMinDelay(import('containers/cms-interface/marketing/marketing-list'), 0));
export const CMSMarketingDetail = React.lazy(() =>
  pMinDelay(import('containers/cms-interface/marketing/marketing-detail'), 0),
);
export const CMSAccessList = React.lazy(() => pMinDelay(import('containers/cms-interface/marketing/access-list'), 0));
export const CMSAccessLog = React.lazy(() => pMinDelay(import('containers/cms-interface/access'), 0));
//Question
export const QuestionList = React.lazy(() => pMinDelay(import('containers/cms-interface/Question/List/index'), 0));
export const UploadQuestion = React.lazy(() => pMinDelay(import('containers/cms-interface/Question/Upload/index'), 0));
export const QuestionDetails = React.lazy(() =>
  pMinDelay(import('containers/cms-interface/Question/Details/index'), 0),
);

export const TeacherPage = React.lazy(() => pMinDelay(import('containers/user-interface/TeacherPage'), 0));

// Exam FRee
export const ExamFree = React.lazy(() => pMinDelay(import('containers/user-interface/ExamFree'), 0));

export const MobileArticleDetails = React.lazy(() =>
  pMinDelay(import('containers/user-interface/MobileArticleDetails'), 0),
);

export const CMSSchedule = React.lazy(() => pMinDelay(import('containers/cms-interface/Schedule/CMSSchedulePage'), 0));
