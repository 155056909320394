import React from 'react';
import { HorizontalView, Div, VerticalView } from 'vendor-deprecated/Wrapper';
import { AppColors } from 'helpers/index';
import { AnimationView } from 'vendor-deprecated/Animation';
import { ContentLoading } from 'vendor-deprecated/Loading';
import { TitleWrapper } from 'vendor-deprecated/CommonWrappers';

export const PlashScreen = () => {
  const [visible, setVisible] = React.useState(true);

  const [showPlash, setShowPlash] = React.useState(true);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setShowPlash(false);
    }, 1300);
    return () => clearTimeout(timeout);
  }, []);

  React.useEffect(() => {
    const time = setTimeout(() => {
      setVisible(false);
    }, 600);
    return () => clearTimeout(time);
  }, []);

  if (!visible) {
    return null;
  }

  return (
    <Div position={'fixed'} left={'0'} top={'0'} bottom={'0'} right={'0'} zIndex={10000}>
      <AnimationView
        duration={showPlash ? 5 : 200}
        animation={showPlash ? undefined : 'fadeOut'}
        width={'100%'}
        height={'100%'}
      >
        <HorizontalView position={'fixed'} fullWidthHeight zIndex={10000} background={AppColors.PRIMARY}>
          <VerticalView>
            <TitleWrapper size={'40px'} color={'white'}>
              Vietcademy
            </TitleWrapper>
            <Div marginTop={'60px'}>
              <ContentLoading loading color={'white'} />
            </Div>
          </VerticalView>
        </HorizontalView>
      </AnimationView>
    </Div>
  );
};
