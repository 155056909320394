import { useApolloClient } from '@apollo/client';

import { IListContactData, IUser } from 'types/types';
import { cacheQueries } from 'api/cache';
import { ICacheKey } from 'types/Models';

export type DataType = IUser;
export type TableType =
  | 'user'
  | 'book'
  | 'course'
  | 'media'
  | 'question'
  | 'post'
  | 'exam'
  | 'service'
  | 'setting'
  | 'identity'
  | 'order'
  | 'userExam'
  | 'statistic'
  | 'help'
  | 'contact'
  | 'event'
  | 'review';

export type Action = 'update' | 'delete' | 'add' | 'login' | 'logout';

interface Callback {
  updateCacheKeys: (cacheKeys: ICacheKey) => void;
  syncDataCache: (data: { value: DataType; table: TableType; action: Action }) => void;
}

export const useCache = (): Callback => {
  const client = useApolloClient();

  /**
   * Update get query when detect mutation data
   * Exam updateUser -> update in cache query getUser;
   * @param cache
   * @param update input data from mutation update or delete
   * @returns
   */

  function updateGetQuery(cache: ICacheKey, update: any) {
    let read: any | null = null;
    const tData = cache.tData;
    const input = cache.input;
    const query = cache.query;

    if (!query || !input || !tData) return;

    try {
      read = client.readQuery({ query: query, variables: { input: input } });

      switch (tData) {
        case 'getUserProfile':
        case 'getUser':
          /* eslint-disable no-case-declarations */
          const cache = read && read[tData] ? read[tData] : {};
          client.writeQuery({
            query: query,
            variables: { input: input },
            data: { [tData]: { ...cache, ...update } },
          });
          break;
      }
    } catch (err) {}
  }

  /**
   *
   * @param cache
   * @param update input data from mutation update or delete
   * @param action 'update' | 'delete' | 'add';
   * @returns
   */

  function updateListQuery(cache: ICacheKey, update: any, action: Action) {
    let read: any | null = null;
    const tData = cache.tData;
    const input = cache.input;
    const query = cache.query;

    if (!query || !input || !tData) return;

    try {
      read = client.readQuery({ query: query, variables: { input: input } });
    } catch (_) {}

    try {
      let updateList: any = [];

      if (action === 'add') {
        switch (tData) {
          /**
           * Get list with pagination
           * struct 
           *  
            [tData]:{
              page:number
              totalPages:number
              results:Array
            }
           */
          case 'getReviewList':
            /* eslint-disable no-case-declarations */
            const oldData = JSON.parse(JSON.stringify(read && read[tData] ? read[tData] : []));
            oldData.results = [{ ...update }, ...oldData.results];
            updateList = oldData;
            break;
          default:
            /**
             * Old get list
             * struct
             * [tData]:Array
             */
            const caches = read && read[tData] ? read[tData] : [];
            updateList = caches.map((u: any) => (u.id === update.id ? { ...u, ...update } : u));
            break;
        }
      } else if (action === 'update') {
        switch (tData) {
          /**
             * Get list with pagination
             * struct 
             *  
              [tData]:{
                page:number
                totalPages:number
                results:Array
              }
             */
          case 'listContacts':
          case 'listEvents':
          case 'listPostsPublic':
          case 'getCourseList':
          case 'getReviewList':
            /* eslint-disable no-case-declarations */
            const oldData = JSON.parse(JSON.stringify(read && read[tData] ? read[tData] : []));
            oldData.results = oldData.results.map((u: any) => (u.id === update.id ? { ...u, ...update } : u));
            updateList = oldData;
            break;
          default:
            /**
             * Old get list
             * struct
             * [tData]:Array
             */
            const caches = read && read[tData] ? read[tData] : [];
            updateList = caches.map((u: any) => (u.id === update.id ? { ...u, ...update } : u));
            break;
        }
      } else if (action === 'delete') {
        switch (tData) {
          /**
             * Get list with pagination
             * struct 
             *  
              [tData]:{
                page:number
                totalPages:number
                results:Array
              }
            */
          case 'listContacts':
          case 'listEvents':
          case 'listPostsPublic':
          case 'getCourseList':
          case 'getReviewList':
          case 'searchPosts':
            /* eslint-disable no-case-declarations */
            const caches1 = read && read[tData] ? read[tData] : {};
            const oldData: IListContactData = JSON.parse(JSON.stringify(caches1));
            oldData.results = oldData.results!.filter((u: any) => u.id !== update.id);
            updateList = oldData;
            break;
          default:
            /**
             * Old get list
             * struct
             * [tData]:Array
             */
            const caches2 = read && read[tData] ? read[tData] : [];
            updateList = caches2.filter((u: any) => u.id !== update.id);
            break;
        }
      }

      client.writeQuery({
        query: query,
        variables: { input: input },
        data: { [tData]: updateList },
      });
    } catch {}
  }

  function updateData(cache: ICacheKey, data: any, action: Action) {
    if (cache.type === 'get' && action === 'update') {
      if (cache.input?.id === data.id) {
        updateGetQuery(cache, data);
      }
    } else if (cache.type === 'list') {
      updateListQuery(cache, data, action);
    }
  }

  function syncDataCache(data: { value: DataType; table: TableType; action: Action }) {
    try {
      const read = client.readQuery<{ cacheKeys: ICacheKey[] }>({ query: cacheQueries.CACHE });
      if (read && read.cacheKeys) {
        read.cacheKeys.forEach((cache) => {
          if (cache.table === data.table) {
            updateData(cache, data.value, data.action);
          }
        });
      }
    } catch {}
  }

  //Update cache key query
  function updateCacheKeys(cacheKey: ICacheKey) {
    let caches: { cacheKeys: ICacheKey[] } | null = { cacheKeys: [] };

    try {
      caches = client.readQuery<{ cacheKeys: ICacheKey[] }>({ query: cacheQueries.CACHE });
    } catch {}

    try {
      let cacheKeys: ICacheKey[] = caches ? caches.cacheKeys : [];
      if (cacheKeys.length > 0) {
        const filters =
          cacheKeys.filter(
            (q) =>
              cacheKey.type === q.type &&
              cacheKey.table === q.table &&
              JSON.stringify(cacheKey.query) === JSON.stringify(q.query) &&
              JSON.stringify(cacheKey.input) === JSON.stringify(q.input),
          ) || [];

        if (filters.length === 0) {
          cacheKeys = [...cacheKeys, cacheKey];
        }
      } else {
        cacheKeys = [cacheKey];
      }
      client.writeQuery({ query: cacheQueries.CACHE, data: { cacheKeys } });
    } catch {}
  }

  return {
    updateCacheKeys: updateCacheKeys,
    syncDataCache: syncDataCache,
  };
};
