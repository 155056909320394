import { useTranslationText } from 'locale';
import React from 'react';
import styled from 'styled-components';
import { Div, HorizontalView, VerticalView } from 'vendor-deprecated/Wrapper';
import { TextDisplay } from 'vendor-deprecated/Text';
import { AppColors } from 'helpers';
import { SVGIcon } from 'vendor-deprecated/Icon';
import { useSettingApi } from 'api/setting/useSettingApi';
import { IFooterSetting, IGetSettingInput } from 'types/types';
import { useAppSetting } from 'helpers/useAppSetting';
import { useHistory } from 'react-router';
import { Image } from 'components/common/vendors/Image';

const CopyrightWrapper = styled.div`
  width: 100%;
  background-color: #333;
  color: #fff;
  text-align: center;
  font-size: 13px;
  padding: 10px 0;
`;
const LinkWrapper = styled.div`
  &:hover {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }
`;

type FooterItem = {
  type: 'text' | 'header' | 'link' | 'email' | 'phone' | 'ios' | 'android';
  title: string;
  url?: string;
};

const copyrightText = '© 2021 VietAcademy, Inc. All rights reserved.';

export const FooterMenu = (props: { item: FooterItem; footer?: IFooterSetting | null }) => {
  const history = useHistory();
  const item = props.item;
  const appsIos = props.footer?.apps?.ios;
  const appsAndroid = props.footer?.apps?.android;
  const { appLayout } = useAppSetting();

  return (
    <HorizontalView alignContent={'centerLeft'} flexWrap={'nowrap'}>
      {item.type === 'header' && (
        <TextDisplay fontWeight={600} size={'20px'} color={AppColors.DARK}>
          {item.title}
        </TextDisplay>
      )}
      {item.type === 'link' && (
        <TextDisplay size={'15px'} color={AppColors.DARK} marginTop={'15px'}>
          <LinkWrapper
            onClick={() => {
              if (item.url?.includes('http')) {
                window.open(item.url);
              } else {
                item.url && history.push(item.url);
              }
            }}
          >
            {item.title}
          </LinkWrapper>
        </TextDisplay>
      )}
      {item.type === 'text' && (
        <TextDisplay size={'15px'} color={AppColors.DARK} marginTop={'15px'}>
          {item.title}
        </TextDisplay>
      )}
      {item.type === 'phone' && (
        <HorizontalView marginTop={'15px'} flexWrap={'nowrap'}>
          <SVGIcon name={'phone'} size={'15px'} color={AppColors.DARK} />
          <TextDisplay size={'15px'} color={AppColors.DARK} marginLeft={'10px'}>
            {item.title}
          </TextDisplay>
        </HorizontalView>
      )}
      {item.type === 'email' && (
        <HorizontalView marginTop={'15px'} flexWrap={'nowrap'}>
          <SVGIcon name={'email'} size={'15px'} color={AppColors.DARK} />
          <TextDisplay size={'15px'} color={AppColors.DARK} marginLeft={'10px'}>
            {item.title}
          </TextDisplay>
        </HorizontalView>
      )}
      {item.type === 'ios' && (
        <HorizontalView
          marginTop={'25px'}
          flexWrap={'nowrap'}
          cursor={appsIos?.includes('http') ? 'pointer' : 'auto'}
          onClick={() => {
            if (appsIos?.includes('http')) {
              window.open(appsIos);
            }
          }}
        >
          <Image
            width={'180px'}
            src={appLayout.isMobile ? '/apple-store-mobile.png' : '/apple-store.png'}
            objectFit={'contain'}
          />
        </HorizontalView>
      )}
      {item.type === 'android' && (
        <HorizontalView
          marginTop={'15px'}
          flexWrap={'nowrap'}
          cursor={appsAndroid?.includes('http') ? 'pointer' : 'auto'}
          onClick={() => {
            if (appsAndroid?.includes('http')) {
              window.open(appsAndroid);
            }
          }}
        >
          <Image
            width={'180px'}
            src={appLayout.isMobile ? '/google-play-mobile.png' : '/google-play.png'}
            objectFit={'contain'}
          />
        </HorizontalView>
      )}
    </HorizontalView>
  );
};

export const Footer = () => {
  const { get: getSettingApi } = useSettingApi();
  const { appLayout } = useAppSetting();
  const { getTypes } = useTranslationText();
  const [copyright, setCopyright] = React.useState(copyrightText);
  const [isShow, setShow] = React.useState(false);
  const footerMenu: FooterItem[][] = getTypes('footerMenu');

  React.useEffect(() => {
    setCopyright(localStorage.getItem('copyright') || copyrightText);
    getSettingApi.getSetting({ lang: 'ja' } as IGetSettingInput);
  }, []);

  React.useEffect(() => {
    if (!getSettingApi.setting) {
      return;
    }

    if (getSettingApi.setting) {
      localStorage.setItem('copyright', getSettingApi.setting?.footer?.copyright || copyrightText);
      setCopyright(getSettingApi.setting?.footer?.copyright || copyrightText);
    }

    const t = setTimeout(() => {
      setShow(true);
    }, 1000);
    return () => clearTimeout(t);
  }, [getSettingApi.setting]);

  if (!isShow) {
    return null;
  }

  return (
    <VerticalView fullWidth>
      <Div fullWidth zIndex={1}>
        <Image width={'100%'} src={'/images/footerTop.png'} objectFit={'contain'} />
      </Div>

      <VerticalView fullWidth alignContent={'center'} background={'#05D1E4'} id={'footer'}>
        <VerticalView fullWidth maxWidth={'1200px'} alignContent={'center'}>
          {footerMenu && (
            <HorizontalView
              fullWidth
              alignContent={'topAround'}
              paddingTop={appLayout.isMobile ? '20px' : '0px'}
              paddingBottom={'40px'}
            >
              {footerMenu.map((items, index) => {
                return (
                  <VerticalView
                    key={index}
                    alignContent={appLayout.isMobile ? 'center' : 'topLeft'}
                    padding={'15px'}
                    paddingRight={appLayout.isMobile ? '15px' : '50px'}
                    paddingTop={appLayout.isMobile ? '0px' : '15px'}
                    minWidth={appLayout.isMobile ? '100%' : '200px'}
                    width={`${index === 0 ? 40 : 60 / (footerMenu.length - 1)}%`}
                  >
                    <VerticalView
                      fullWidth
                      maxWidth={appLayout.isMobile ? '550px' : 'auto'}
                      alignContent={'centerLeft'}
                    >
                      {getSettingApi.setting?.footer && index === 0 && (
                        <VerticalView alignContent={'centerLeft'} flexWrap={'nowrap'}>
                          <TextDisplay fontWeight={600} size={'20px'} color={AppColors.DARK}>
                            {getSettingApi.setting?.footer.profile?.name}
                          </TextDisplay>
                          <TextDisplay size={'15px'} color={AppColors.DARK} marginTop={'15px'}>
                            {getSettingApi.setting?.footer.profile?.description}
                          </TextDisplay>
                          <HorizontalView marginTop={'15px'} flexWrap={'nowrap'}>
                            <SVGIcon name={'phone'} size={'15px'} color={AppColors.DARK} />
                            <TextDisplay size={'15px'} color={AppColors.DARK} marginLeft={'10px'}>
                              {getSettingApi.setting?.footer.profile?.phone}
                            </TextDisplay>
                          </HorizontalView>
                          <HorizontalView marginTop={'15px'} flexWrap={'nowrap'}>
                            <SVGIcon name={'email'} size={'15px'} color={AppColors.DARK} />
                            <TextDisplay size={'15px'} color={AppColors.DARK} marginLeft={'10px'}>
                              {getSettingApi.setting?.footer.profile?.email}
                            </TextDisplay>
                          </HorizontalView>
                        </VerticalView>
                      )}
                      {!(getSettingApi.setting?.footer && index === 0) &&
                        items.map((menu, i) => {
                          return (
                            <HorizontalView key={i} alignContent={'centerLeft'} flexWrap={'nowrap'}>
                              <FooterMenu item={menu} footer={getSettingApi.setting?.footer} />
                            </HorizontalView>
                          );
                        })}
                      {appLayout.isMobile && index + 1 < footerMenu.length && (
                        <HorizontalView
                          marginTop={'15px'}
                          fullWidth
                          height={'1px'}
                          background={AppColors.DARK}
                          opacity={0.3}
                        />
                      )}
                    </VerticalView>
                  </VerticalView>
                );
              })}
            </HorizontalView>
          )}
        </VerticalView>

        <CopyrightWrapper>{copyright}</CopyrightWrapper>
      </VerticalView>
    </VerticalView>
  );
};

export default Footer;
