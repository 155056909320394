export const userRoleList = [
  {
    label: 'ユーザー',
    value: 'USER',
  },
  {
    label: '管理者',
    value: 'ADMIN',
  },
];

export const yesNoType = [
  {
    label: 'いいえ',
    value: false,
  },
  {
    label: 'はい',
    value: true,
  },
];

export const role = [
  {
    label: 'ADMIN',
    value: 'ADMIN',
  },
  {
    label: 'USER',
    value: 'USER',
  },
];
export const zIndex = {
  header: 100,
  confirm: 50,
  modal: 150,
  body: 30,
};

export const application = {
  ios: 'https://apps.apple.com/us/app/id1586754667',
  android: 'https://play.google.com/store/apps/details?id=jp.qhconsulting.vietcademy',
};

export enum ReportType {
  Course = 'course',
  Author = 'author',
  Booking = 'booking',
  RefundTicket = 'refund-ticket',
}

export enum TicketStatus {
  Add = 'add',
  Sub = 'sub',
  Refund = 'refund',
}

export enum ReviewType {
  Author = 'author',
  Course = 'course',
}

export enum NotificationType {
  RefundTicket = 'refund_ticket',
  Booking = 'booking',
  BookingCancel = 'booking_cancel',
  ScheduleCompleted = 'schedule_completed',
}
