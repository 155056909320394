import * as mutations from './mutations';
import * as queries from './queries';

export const notificationMutations = {
  ...mutations,
};

export const notificationQueries = {
  ...queries,
};
