import gql from 'graphql-tag';

export const UPDATE = gql`
  mutation updateSetting($input: UpdateSettingInput!) {
    updateSetting(input: $input)
  }
`;

export const UPDATE_LOCALE = gql`
  mutation updateLocaleSetting($input: UpdateLocaleSettingInput!) {
    updateLocaleSetting(input: $input)
  }
`;
