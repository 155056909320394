import { useTranslationText } from 'locale';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { INavigation } from 'types/CommonTypes';

export const useTitle = () => {
  const [title, setTitle] = useState('');
  const { getTypes, translation } = useTranslationText();
  const location = useLocation();
  const { tabId } = useParams<{ tabId: string }>();

  const getPageTitle = () => {
    /**
     * Author
     */
    if (tabId) {
      switch (tabId) {
        case 'skill':
          setTitle(translation('pageTitle.courseSkill'));
          break;
        case 'course-landing':
          setTitle(translation('pageTitle.courseLanding'));
          break;
        case 'syllabus':
          setTitle(translation('pageTitle.courseSyllabus'));
          break;
        case 'exams':
          setTitle(translation('pageTitle.courseExam'));
          break;
        case 'questions':
          setTitle(translation('pageTitle.courseQuestion'));
          break;
        case 'edit-exam':
          setTitle(translation('pageTitle.courseEditExam'));
          break;
        case 'price':
          setTitle(translation('pageTitle.coursePrice'));
          break;
        default:
          setTitle('Vietcademy');
          break;
      }
    } else {
      const userMenuBars: INavigation[] = getTypes(
        location.pathname.includes('/author/') ? 'authorMenu' : 'userProfileMenu',
      );
      const adminMenuBars: { subMenus: INavigation[] }[] = getTypes('cmsSidebarMenus');
      let nagigationTitle = '';

      if (location.pathname.includes('/news')) {
        nagigationTitle = translation('public.newsList.header');
      } else if (location.pathname.includes('/services')) {
        nagigationTitle = translation('public.serviceList.header');
      } else if (location.pathname.includes('/privacy')) {
        nagigationTitle = translation('public.privacy.header');
      } else if (location.pathname.includes('/terms')) {
        nagigationTitle = translation('public.terms.header');
      } else if (location.pathname.includes('/about')) {
        nagigationTitle = translation('public.about.header');
      } else if (location.pathname === '/courses') {
        nagigationTitle = translation('public.courseList.header');
      } else if (location.pathname.includes('/courses/')) {
        nagigationTitle = translation('courseDetailsTitle');
      } else if (location.pathname.includes('/help-center') || location.pathname.includes('/helps/')) {
        nagigationTitle = translation('helpCenter');
      } else if (location.pathname.includes('/cms/users/')) {
        nagigationTitle = translation('userDetailsTitle');
      } else if (location.pathname === '/contact') {
        nagigationTitle = translation('contactHeader');
      } else if (location.pathname === '/') {
        nagigationTitle = translation('homeTitle');
      } else if (location.pathname.includes('/user/lecture/')) {
        nagigationTitle = translation('lectureTitle');
      }

      if (userMenuBars.length > 0) {
        const currentTab = userMenuBars.find((menu) => menu.url !== '/' && location.pathname.includes(menu.url));
        if (currentTab) {
          nagigationTitle = currentTab.label;
        }
      }

      if (location.pathname.includes('/cms') && adminMenuBars.length > 0) {
        adminMenuBars.forEach((menus) => {
          const currentTab = menus.subMenus.find((menu) => menu.url !== '/' && location.pathname.includes(menu.url));
          if (currentTab) {
            nagigationTitle = currentTab.label;
          }
        });
      }

      setTitle(nagigationTitle);
    }
  };

  useEffect(() => {
    getPageTitle();
  }, [location.pathname, tabId]);

  return {
    pageTitle: title,
  };
};
