import React, { Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { History } from 'history';
import { useLocation } from 'react-router-dom';
import { IUser } from 'types/types';
import { BodyContentWrapper } from '../vendor-deprecated/CommonWrappers';
import * as Pages from './Loadables';
import Header from 'components/common/components/Header/Header';
import Footer from 'components/common/components/PageFooter/Footer';
const ResetPassword = React.lazy(() => import('containers/user-interface/ResetPassword'));
const Login = React.lazy(() => import('containers/user-interface/Login'));
const AuthorRegister = React.lazy(() => import('containers/user-interface/AuthorRegister'));
const Register = React.lazy(() => import('containers/user-interface/Register'));
const BookingSearchPage = React.lazy(() => import('containers/user-interface/booking/BookingSearchPage'));
const StudyWithTeacherPage = React.lazy(() => import('containers/user-interface/StudyWithTeacher'));
const SelfStudyPage = React.lazy(() => import('containers/user-interface/SelfStudy'));

type PublicLayoutProps = {
  auth?: IUser;
  history: History;
};

export const PublicLayout = (props: PublicLayoutProps) => {
  const location = useLocation();

  if (
    location.pathname.includes('/cms/') ||
    location.pathname.includes('/user/') ||
    location.pathname.includes('/author/')
  ) {
    return <Redirect to={{ pathname: '/login' }} />;
  }

  if (
    props.auth?.id &&
    (location.pathname.includes('/login') ||
      location.pathname.includes('/reset-password') ||
      location.pathname.includes('/register-author') ||
      location.pathname.includes('/register'))
  ) {
    return <Redirect to={{ pathname: '/' }} />;
  }

  if (
    !props.auth?.id &&
    (location.pathname.includes('/login') ||
      location.pathname.includes('/reset-password') ||
      location.pathname.includes('/register-author') ||
      location.pathname.includes('/register'))
  ) {
    return (
      <Switch>
        <Route path="/login" exact history={props.history} component={Login} />
        <Route path="/reset-password" exact component={ResetPassword} />
        <Route path="/register-author" exact history={props.history} component={AuthorRegister} />
        <Route path="/register" exact history={props.history} component={Register} />
        <Route
          component={() => (
            <>
              <Header isCMS={false} history={props.history} /> <Route component={Pages.NotFoundPage} /> <Footer />
            </>
          )}
        />
      </Switch>
    );
  }

  return (
    <div>
      <Header isCMS={false} history={props.history} />

      <BodyContentWrapper id={'body-content'}>
        <Suspense fallback={<></>}>
          <Switch>
            <Route path="/challenge-exam/:seoname" component={Pages.ExamFree} />
            <Route path="/teacher/:seoname" component={Pages.TeacherPage} />
            <Route path="/help-center" component={Pages.HelpCenter} />
            <Route path="/helps/:seoname" component={Pages.HelpDetails} />
            <Route exact path="/courses" component={Pages.Course} />
            <Route path="/courses/:seoname/:token" component={() => <Pages.CourseDetails history={props.history} />} />
            <Route path="/courses/:seoname" component={() => <Pages.CourseDetails history={props.history} />} />
            <Route path="/about" component={Pages.About} />
            <Route path="/privacy" component={Pages.Privacy} />
            <Route path="/how-to-get-a-teaching-job" component={Pages.TeachApply} />
            <Route path="/terms-of-service" component={Pages.Terms} />
            <Route exact path="/services" component={Pages.Service} />
            <Route path="/services/:seoname" component={Pages.ServiceDetails} />

            <Route exact path="/articles/search/:seoname?" component={Pages.SeacrchNews} />
            <Route path="/articles/:seoname" component={Pages.NewsDetails} />
            <Route exact path="/articles" component={Pages.News} />
            <Route path="/events/:seoname" component={Pages.NewsDetails} />
            <Route path="/blogs/:seoname" component={Pages.NewsDetails} />
            <Route path="/blogs" component={Pages.BlogList} />
            <Route path="/verify-account/:token" component={Pages.VerifyAccount} />
            <Route path="/change-password/:token" component={Pages.ResetPassConfirm} />
            <Route path="/contact" component={Pages.Contact} />
            <Route path="/specified-commercial-law" component={Pages.CommercialLaw} />
            <Route
              path="/my-cart"
              component={() => <Pages.CartContainer history={props.history} auth={props.auth} />}
            />
            <Route exact path="/booking" component={BookingSearchPage} />
            <Route path="/study-with-teacher" component={StudyWithTeacherPage} />
            <Route path="/self-study" component={SelfStudyPage} />
            <Route path="/" exact history={props.history} component={Pages.Home} />
            <Route component={Pages.NotFoundPage} />
          </Switch>
        </Suspense>
      </BodyContentWrapper>
      <Footer />
    </div>
  );
};
