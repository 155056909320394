import { settingMutations, settingQueries } from './index';
import { useQueryData } from '../apollo/query';
import { useMutationData } from '../apollo/mutation';
import {
  IUpdateSettingInput,
  IGetSettingInput,
  ISetting,
  IMutationUpdateSettingArgs,
  IUpdateLocaleSettingInput,
  ILocaleSetting,
  IMutationUpdateLocaleSettingArgs,
} from 'types/types';

import { IError } from 'types/Models';
import { useTranslationText } from 'locale';
import { useBaseGetQuery } from 'api/base/useBaseGetQuery';

interface Callback {
  update: {
    loading: boolean;
    updateSetting: (input: IUpdateSettingInput) => Promise<{ error?: IError }>;
  };
  updateLocale: {
    loading: boolean;
    updateLocaleSetting: (input: IUpdateLocaleSettingInput) => Promise<{ error?: IError }>;
  };
  get: {
    loading: boolean;
    setting?: ISetting;
    getSetting: (input: IGetSettingInput) => void;
  };
  getLocale: {
    loading: boolean;
    locale?: ILocaleSetting;
    getLocaleSetting: () => void;
  };
}

export const useSettingApi = (): Callback => {
  const { getMessage } = useTranslationText();

  //MARK: GET QURERY
  const [getSetting, { data: dataGet, loading: getLoading }] = useQueryData<{ getSetting: ISetting }>({
    table: 'setting',
    action: 'get',
    tData: 'getSetting',
    fetchPolicy: 'cache-and-network',
    query: settingQueries.GET,
  });

  const [getLocaleSetting, { data, loading }] = useQueryData<{ getLocaleSetting: ILocaleSetting }>({
    table: 'setting',
    action: 'get',
    tData: 'getLocaleSetting',
    fetchPolicy: 'cache-and-network',
    query: settingQueries.GET_LOCALE,
  });

  //MARK: UPDATE MUTATION
  const [updateSetting, { loading: updateLoading }] = useMutationData<
    { updateSetting: boolean },
    IMutationUpdateSettingArgs
  >({
    mutation: settingMutations.UPDATE,
    table: 'setting',
    action: 'update',
  });

  const [updateLocaleSetting, { loading: updateLocaleLoading }] = useMutationData<
    { updateLocaleSetting: boolean },
    IMutationUpdateLocaleSettingArgs
  >({
    mutation: settingMutations.UPDATE_LOCALE,
    table: 'setting',
    action: 'update',
  });

  /********************** CUSTOM FUNCTION ************************* */

  //MARK:UPDATE FUNCTION
  async function mutationupdateSetting(Service: IUpdateSettingInput): Promise<{ error?: IError }> {
    try {
      await updateSetting({ variables: { input: Service } });
      return {};
    } catch (error) {
      return { error: getMessage(error.message, 'error') };
    }
  }

  async function mutationUpdateLocaleSetting(input: IUpdateLocaleSettingInput): Promise<{ error?: IError }> {
    try {
      await updateLocaleSetting({ variables: { input } });
      return {};
    } catch (error) {
      return { error: getMessage(error.message, 'error') };
    }
  }

  //MARK:GET FUNCTION
  function querySetting(input: IGetSettingInput) {
    getSetting({ variables: { input } });
  }

  return {
    update: {
      loading: updateLoading,
      updateSetting: mutationupdateSetting,
    },
    updateLocale: {
      loading: updateLocaleLoading,
      updateLocaleSetting: mutationUpdateLocaleSetting,
    },

    get: {
      setting: dataGet?.getSetting,
      loading: dataGet?.getSetting ? false : getLoading,
      getSetting: querySetting,
    },
    getLocale: {
      locale: data?.getLocaleSetting,
      loading: data?.getLocaleSetting ? false : loading,
      getLocaleSetting: getLocaleSetting,
    },
  };
};

export const useGetFeatureCourse = () => {
  const { item, loading, getQuery } = useBaseGetQuery<IGetSettingInput, ISetting>({
    fetchPolicy: 'cache-and-network',
    query: settingQueries.GET_FEATURE_COURSE,
  });
  return {
    setting: item,
    loading,
    getSetting: getQuery,
  };
};
