import React from 'react';
import styled from 'styled-components';
import Icons from './svg/icons.svg';

const Wrapper = styled.div<IconProps>`
  display: inline-flex;
  margin-top: ${(props) => (props.withText ? '-0.2em' : '0')};
  overflow: hidden;
  fill: ${(props) => (props.color ? props.color : 'currentColor')};
`;

export interface IconProps {
  name: string;
  size?: string;
  style?: React.CSSProperties;
  withText?: boolean;
}

export const Icon = (props: IconProps) => {
  let style = {};
  if (props.style) {
    style = { ...props.style };
  }

  if (props.size) {
    style = { ...props.style, fontSize: props.size };
  }

  return (
    <Wrapper {...props} aria-hidden="true">
      <i className={`fa fa-${props.name}`} style={{ ...style }} />
    </Wrapper>
  );
};

export const iconList = [
  'arrow-left',
  'arrow-right',
  'arrow-up',
  'arrow-down',
  'plus',
  'home',
  'download',
  'list',
  'trash',
  'search',
  'file',
  'media',
  'archive',
  'order',
  'log-out',
  'key',
  'user',
  'clock',
  'check',
  'check-circle',
  'question',
  'close',
  'menu',
  'case',
  'lock',
  'save',
  'user-plus',
  'sign-in-alt',
  'headphones',
  'edit',
  'camera',
  'phone',
  'email',
  'service',
  '404',
  'plus-square',
  'statistic',
  'email-verify',
  'gift',
  'global',
  'google-play',
  'apple-store',
  'credit-card',
  'send',
  'dollar',
  'play',
  'eye',
];

export type IConType =
  | 'arrow-left'
  | 'arrow-right'
  | 'arrow-up'
  | 'arrow-down'
  | 'plus'
  | 'home'
  | 'download'
  | 'list'
  | 'trash'
  | 'search'
  | 'file'
  | 'media'
  | 'archive'
  | 'order'
  | 'log-out'
  | 'key'
  | 'user'
  | 'clock'
  | 'check'
  | 'check-circle'
  | 'question'
  | 'close'
  | 'menu'
  | 'case'
  | 'lock'
  | 'save'
  | 'user-plus'
  | 'sign-in-alt'
  | 'headphones'
  | 'edit'
  | 'phone'
  | 'email'
  | 'camera'
  | 'service'
  | '404'
  | 'book'
  | 'login'
  | 'logout'
  | 'cart'
  | 'plus-square'
  | 'filter'
  | 'star'
  | 'fb'
  | 'instagram'
  | 'twitter'
  | 'star-fill'
  | 'message'
  | 'statistic'
  | 'copy'
  | 'email-verify'
  | 'gift'
  | 'global'
  | 'google-play'
  | 'apple-store'
  | 'credit-card'
  | 'send'
  | 'dollar'
  | 'play'
  | 'eye'
  | 'footer';

export interface SVGIconProps {
  name?: IConType;
  color?: string;
  stroke?: string;
  size?: string;
  height?: string;
  width?: string;
  style?: React.CSSProperties;
  onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}

const IconWrapper = styled.svg`
  display: inline-flex;
  overflow: hidden;
  width: ${(props: SVGIconProps) => (props.width || props.size ? props.size : '1em')};
  height: ${(props: SVGIconProps) => (props.height || props.size ? props.size : '1em')};
  fill: ${(props: SVGIconProps) => (props.color ? props.color : 'currentColor')};
`;

export const SVGIcon = (props: SVGIconProps) => {
  return (
    <IconWrapper {...props} stroke={props.stroke} viewBox="0 0 24 24" aria-hidden="true">
      <use xlinkHref={`${Icons}#icon-${props.name}`} />
    </IconWrapper>
  );
};
