import React from 'react';
import { useLocation, useHistory, Redirect } from 'react-router-dom';
import { AdminLayout } from './AdminLayout';
import { PublicLayout } from './PublicLayout';
import { StudentLayout } from './StudentLayout';
import { AuthorLayout } from './AuthorLayout';
import { useSession } from 'session/useSession';
import { PlashScreen } from 'components/common/components/Plash';
import { useUserAccess } from 'hooks/useUserAccess';

export const CommonLayout = () => {
  const history = useHistory();
  const location = useLocation();
  const { session } = useSession();

  useUserAccess();

  return React.useMemo(() => {
    if (
      location.pathname.includes('/cms/') ||
      location.pathname.includes('/user/') ||
      location.pathname.includes('/author/')
    ) {
      if (!session.isLoaded) {
        return <PlashScreen />;
      }

      if (!session.user) {
        return <PublicLayout auth={session.user} history={history} />;
      }

      if (session.isAdmin) {
        return <AdminLayout history={history} />;
      } else if (session.isStudent) {
        return <StudentLayout history={history} />;
      } else if (session.isAuthor) {
        return <AuthorLayout auth={session.user!} history={history} />;
      } else {
        return <Redirect to={{ pathname: '/' }} />;
      }
    } else {
      return <PublicLayout auth={session.user} history={history} />;
    }
  }, [session.isAdmin, session.isStudent, session.isAuthor, location.pathname]);
};

export default CommonLayout;
