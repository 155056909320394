import React from 'react';
import styled from 'styled-components';
import { Image } from 'vendor-deprecated/Image';
import { ContentLoading } from 'vendor-deprecated/Loading';
import { Div, HorizontalView } from 'vendor-deprecated/Wrapper';
import * as colors from 'helpers/colors';
import { CssProps } from '../../types/CommonTypes';
import { IConType, SVGIcon } from '../Icon';

const Wrapper = styled.div<ButtonProps>`
  ${(props) => (props.backgroundColor ? `background:${props.backgroundColor};` : '')};
  ${(props) => (props.background ? `background:${props.background};` : '')};
  color: ${(props) => (props.color ? props.color : colors.TEXT)};
  height: ${(props) => (props.height ? props.height : '40px')};
  min-width: ${(props) => (props.minWidth ? props.minWidth : 'auto')};
  width: ${(props) => (props.width ? props.width : 'auto')};
  cursor: pointer;
  border: ${(props) => (props.border ? `1px solid ${props.borderColor ? props.borderColor : colors.BORDER}` : 'none')};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '0px')};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '0px')};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0px')};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '0px')};
  transition: all ease-out 0.3s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '0px')};
  text-align: center;
  :hover {
    opacity: 0.6;
  }
  overflow: hidden;
`;

const SocialWrapper = styled.div<ButtonProps>`
  text-align: center;
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : 'white')};
  color: ${(props) => (props.color ? props.color : colors.TEXT)};
  height: ${(props) => (props.height ? props.height : '40px')};
  cursor: pointer;
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '0px')};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '0px')};
  border: 1px solid ${(props) => (props.borderColor ? props.borderColor : colors.BORDER)};

  :hover {
    opacity: 0.7;
  }
`;

const TextSocialDisplay = styled.div<ButtonProps>`
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 600)};
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
`;

const TextDisplay = styled.div<ButtonProps>`
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 600)};
`;

const ButtonDefault = styled.button`
  &:focus {
    outline: none;
  }
  border: 0px;
  border-width: 0px;
  width: 100%;
  height: 100%;
  background: transparent;
  cursor: pointer;
  transition: width 0.1s;
  &:hover {
    opacity: 0.7;
  }
  &:active {
    background: rgb(1, 1, 1, 0.2);
    opacity: 0.4;
  }
  overflow: hidden;
`;

export type ButtonProps = CssProps & {
  id?: string;
  ariaLabel?: string;
  shape?: 'circle';
  type?: string;
  text?: string;
  icon?: IConType;
  suffixIcon?: IConType;
  border?: boolean;
  social?: 'google' | 'facebook' | 'mail';
  fontWeight?:
    | number
    | 'normal'
    | 'bold'
    | '-moz-initial'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'unset'
    | (number & {})
    | 'bolder'
    | 'lighter'
    | undefined;
  isLoading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
};

const SocialButton = (props: ButtonProps) => {
  const typeText = props.type && props.type === 'register' ? '登録する' : 'ログイン';
  const facebook = '/images/facebook_white.svg';
  const google = '/images/google.svg';
  const mail = '/images/mail_color.svg';
  let icon = facebook;
  let text = `Facebookで${typeText}`;
  let color = colors.WHITE;
  let backgroundColor = colors.WHITE;
  let borderColor = colors.WHITE;
  if (props.social === 'facebook') {
    icon = facebook;
    text = `Facebookで${typeText}`;
    backgroundColor = colors.FACEBOOK;
    borderColor = colors.FACEBOOK;
  } else if (props.social === 'google') {
    icon = google;
    text = `Googleで${typeText}`;
    color = colors.TEXT;
    borderColor = colors.BORDER;
  } else if (props.social === 'mail') {
    icon = mail;
    text = `メールアドレスで${typeText}`;
    color = colors.WHITE;
    borderColor = colors.RED;
    backgroundColor = colors.RED;
  }

  return (
    <SocialWrapper {...props} color={color} backgroundColor={backgroundColor} borderColor={borderColor}>
      <Image width={'20px'} height={'20px'} src={icon} objectFit={'contain'} />
      <TextSocialDisplay>{text}</TextSocialDisplay>
    </SocialWrapper>
  );
};

export const Button = (props: ButtonProps) => {
  let buttonContent: React.ReactNode;

  if (!props.social) {
    buttonContent = (
      <>
        {props.icon && !props.isLoading && (
          <SVGIcon
            style={{ marginRight: props.text || props.children ? '5px' : '0px', fontSize: props.fontSize || '20px' }}
            size={props.fontSize || props.size || '18px'}
            name={props.icon}
          />
        )}
        <TextDisplay id={props.id} fontSize={props.fontSize || '18px'} fontWeight={props.fontWeight}>
          {props.text}
        </TextDisplay>
        {props.suffixIcon && (
          <SVGIcon
            style={{ marginLeft: props.text || props.children ? '5px' : '0px', fontSize: props.fontSize || '20px' }}
            size={props.fontSize || props.size || '18px'}
            name={props.suffixIcon}
          />
        )}
        {props.children}
      </>
    );

    let style = {};
    if (props.shape && props.shape === 'circle') {
      style = { padding: '0', width: '30px', height: '30px', borderRadius: '50%', backgroundColor: '#EEEAE3' };
    }

    if (props.disabled) {
      style = { cursor: 'not-allowed', backgroundColor: colors.BCKGROUND_DISABLED, color: colors.DISABLED };
    }

    const propButton = { ...props };
    propButton.onClick && delete propButton.onClick;

    return (
      <Wrapper {...propButton} id={props.id} style={{ ...style, padding: '1px' }}>
        <ButtonDefault
          aria-label={props.ariaLabel}
          id={props.id}
          type={'button'}
          onClick={(e) => {
            e.preventDefault();
            if (props.onClick && !props.disabled && !props.isLoading) {
              props.onClick();
            }
          }}
        >
          <HorizontalView
            flexWrap={'nowrap'}
            width={'100%'}
            height={'100%'}
            position={'relative'}
            paddingLeft={props.paddingLeft || '5px'}
            paddingRight={props.paddingRight || '5px'}
            paddingTop={props.paddingTop || '5px'}
            paddingBottom={props.paddingBottom || '5px'}
          >
            {props.isLoading && (
              <Div width={'15px'} marginRight={'10px'}>
                <ContentLoading opacity={1} size={'15px'} loading color={props.color || 'white'} />
              </Div>
            )}
            {buttonContent}
          </HorizontalView>
        </ButtonDefault>
      </Wrapper>
    );
  } else {
    return <SocialButton {...props} />;
  }
};
