import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import config from './index';
import { setContext } from '@apollo/client/link/context';

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('QHC_LOGIN_TOKEN') || 'GUEST';
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const cache = new InMemoryCache({
  addTypename: false,
  typePolicies: {
    Query: {
      fields: {
        cacheKeys: {
          merge(_, incoming) {
            return incoming;
          },
        },
        listUsers: {
          merge(_, incoming) {
            return incoming;
          },
        },
        searchMedia: {
          merge(_, incoming) {
            return incoming;
          },
        },
        getCourse: {
          merge(_, incoming) {
            return { ...incoming };
          },
        },
        getCourseBook: {
          merge(_, incoming) {
            return { ...incoming };
          },
        },
      },
    },
  },
});

const httpLink = createUploadLink({
  uri: config.API_HOST_URL,
  credentials: 'same-origin',
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: cache,
});

export default client;
