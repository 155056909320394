import { accessMutations } from 'api/access';
import { useBaseMutation } from 'api/base/useBaseMutation';
import { IUserAccessInput } from 'types/types';

export const useAccess = () => {
  const { mutation, loading } = useBaseMutation<IUserAccessInput, { userAccess: boolean }>({
    mutation: accessMutations.ACCESS,
  });

  async function onAccess(input: IUserAccessInput) {
    try {
      await mutation(input);
    } catch (error) {}
  }

  return {
    loading,
    onAccess,
  };
};
