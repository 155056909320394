import React from 'react';
import styled from 'styled-components';
import { History } from 'history';
import { HorizontalView, VerticalView } from 'vendor-deprecated/Wrapper';
import { AppColors } from 'helpers';
import { delay } from 'vendor-deprecated/Timer';
import { IConType, SVGIcon } from 'vendor-deprecated/Icon';
import { TextDisplay } from 'vendor-deprecated/Text';
import { useTranslationText } from 'locale';
import { IUser } from 'types/types';
import { Image } from 'components/common/vendors/Image';
import { Hr } from 'vendor-deprecated/CommonParts';
import { shortenedString } from 'helpers/documentUtils';
import { INavigation } from 'types/CommonTypes';
import { useUserApi } from 'api/user/useUserApi';
import { useAppSetting } from 'helpers/useAppSetting';
import { ConfirmModal } from '../Modals/ConfirmModal';
import { ChangeLanguage } from '../ChangeLanguage';

const SideBarWrapper = styled.div<{ open: boolean; left: number; width: number }>`
  position: absolute;
  left: ${(props) => -props.left}px;
  width: ${(props) => props.width}px;
  height: 100vh;
  transition: 0.3s;
  background: white;
  z-index: 25;
  overlow-y: scroll;
`;

const AvatarWrapper = styled.div`
  overflow: hidden;
  background: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

type HeaderSearchBarProps = {
  auth?: IUser;
  open: boolean;
  history: History;
  onClose: () => void;
};

export const MobileSideBar = (props: HeaderSearchBarProps) => {
  const { translation, getTypes } = useTranslationText();
  const { appTheme, appSize } = useAppSetting({ auth: props.auth });
  const [active, setActive] = React.useState(false);
  let menuBars: INavigation[] = React.useMemo(() => {
    if (props.auth?.role === 'AUTHOR') {
      return getTypes('authorMenu');
    } else if (props.auth?.role === 'ADMIN') {
      return getTypes('cmsSidebarMenus');
    }

    return getTypes('userProfileMenu');
  }, [props.auth?.role]);

  React.useEffect(() => {
    setActive(props.open);
  }, [props.open]);

  async function onClose() {
    setActive(false);
    await delay(400);
    props.onClose();
  }

  const isAuthorVerified = props.auth?.isAuthorVerified;

  if (!isAuthorVerified && props.auth?.role === 'AUTHOR') {
    menuBars = menuBars.filter(
      (menu) => menu.url === '/author/profile' || menu.url === '/author/logout' || menu.url === '/',
    );
  }

  return (
    <VerticalView
      width={'100vw'}
      height={'100vh'}
      zIndex={20}
      left={'0px'}
      top={'0px'}
      position={'fixed'}
      display={props.open ? 'flex' : 'none'}
    >
      <VerticalView fullWidthHeight position={'relative'}>
        <VerticalView
          width={'100vw'}
          height={'100vh'}
          zIndex={20}
          left={'0px'}
          top={'0px'}
          background={AppColors.BLACK}
          opacity={0.4}
          position={'absolute'}
          onClick={async () => {
            onClose();
          }}
          display={active ? 'flex' : 'none'}
          transition={'ease-in'}
        />
        <SideBarWrapper open={props.open} width={250} left={active ? 0 : 250}>
          <VerticalView
            fullWidthHeight
            alignContent={'topLeft'}
            padding={'10px'}
            overflowY={'scroll'}
            background={appTheme.sidebar.bg}
          >
            <VerticalView fullWidth alignContent={'centerLeft'}>
              {props.auth?.id && (
                <VerticalView fullWidth alignContent={'centerLeft'} paddingTop={'10px'} paddingLeft={'20px'}>
                  <HorizontalView paddingBottom={'15px'} alignContent={'topBetween'}>
                    <AvatarWrapper>
                      <Image
                        width={'100%'}
                        height={'100%'}
                        src={props.auth?.profilePath || '/images/avatar.png'}
                        objectFit={'cover'}
                      />
                    </AvatarWrapper>
                    <VerticalView alignContent={'topLeft'} paddingLeft={'10px'} paddingTop={'5px'}>
                      <TextDisplay size={'13px'} fontWeight={600} color={appTheme.sidebar.text}>
                        {shortenedString({ content: props.auth.name || 'My account', shortened: { lenght: 16 } })}
                      </TextDisplay>
                      <TextDisplay size={'10px'} marginTop={'5px'} fontWeight={600} color={appTheme.sidebar.text}>
                        {shortenedString({ content: props.auth.email, shortened: { lenght: 16 } })}
                      </TextDisplay>
                    </VerticalView>
                  </HorizontalView>
                </VerticalView>
              )}
              {props.auth?.id &&
                menuBars.map((menu, index) => {
                  return (
                    <HorizontalView fullWidth alignContent={'centerLeft'} key={index}>
                      <MenuRow
                        auth={props.auth}
                        icon={menu.prefixIcon! as IConType}
                        title={menu.label}
                        history={props.history}
                        url={menu.url}
                        onTap={() => {
                          onClose();
                        }}
                      />
                    </HorizontalView>
                  );
                })}
              {props.auth?.id && (
                <VerticalView fullWidth paddingTop={'20px'}>
                  <Hr />
                </VerticalView>
              )}
              <HorizontalView fullWidth paddingTop={'10px'} alignContent={'centerLeft'}>
                <TextDisplay fontWeight={600} marginLeft={'10px'} size={appSize.xl} color={appTheme.sidebar.text}>
                  {translation('vietcademyService')}
                </TextDisplay>
              </HorizontalView>

              <MenuRow
                icon={'home'}
                auth={props.auth}
                title={translation('mobileSideBar.home')}
                history={props.history}
                url={'/'}
                onTap={() => {
                  onClose();
                }}
              />

              <MenuRow
                icon={'case'}
                auth={props.auth}
                title={translation('mobileSideBar.course')}
                history={props.history}
                url={'/courses'}
                onTap={() => {
                  onClose();
                }}
              />

              <MenuRow
                icon={'file'}
                auth={props.auth}
                title={translation('mobileSideBar.news')}
                history={props.history}
                url={'/articles'}
                onTap={() => {
                  onClose();
                }}
              />

              <MenuRow
                icon={'service'}
                auth={props.auth}
                title={translation('mobileSideBar.service')}
                history={props.history}
                url={'/services'}
                onTap={() => {
                  onClose();
                }}
              />

              <MenuRow
                icon={'case'}
                auth={props.auth}
                title={translation('mobileSideBar.help')}
                history={props.history}
                url={'/help-center'}
                onTap={() => {
                  onClose();
                }}
              />

              {!props.auth?.id && (
                <MenuRow
                  auth={props.auth}
                  icon={'user'}
                  title={translation('mobileSideBar.register')}
                  history={props.history}
                  url={'/register'}
                  onTap={() => {
                    onClose();
                  }}
                />
              )}

              {!props.auth?.id && (
                <MenuRow
                  auth={props.auth}
                  icon={'user'}
                  title={translation('mobileSideBar.forAuthor')}
                  history={props.history}
                  url={'/how-to-get-a-teaching-job'}
                  onTap={() => {
                    onClose();
                  }}
                />
              )}

              {!props.auth?.id && (
                <MenuRow
                  auth={props.auth}
                  icon={'login'}
                  title={translation('mobileSideBar.login')}
                  history={props.history}
                  url={'/login'}
                  onTap={() => {
                    onClose();
                  }}
                />
              )}
              <VerticalView fullWidth alignContent={'centerLeft'} paddingTop={'20px'}>
                <Hr />
                <HorizontalView paddingTop={'20px'}>
                  <ChangeLanguage showLabel color={appTheme.sidebar.text} />
                </HorizontalView>
              </VerticalView>
            </VerticalView>
          </VerticalView>
        </SideBarWrapper>
      </VerticalView>
    </VerticalView>
  );
};

type MenuRowProps = {
  auth?: IUser;
  icon: IConType;
  title: string;
  history: History;
  url: string;
  onTap: () => void;
};

export const MenuRow = (props: MenuRowProps) => {
  const {
    logout: { logoutUser },
  } = useUserApi();
  const { translation } = useTranslationText();
  const [confirmLogout, setConfirmLogout] = React.useState(false);
  const { appTheme, appSize } = useAppSetting({ auth: props.auth });

  return (
    <>
      <HorizontalView
        fullWidth
        alignContent={'centerLeft'}
        paddingTop={'20px'}
        paddingLeft={'10px'}
        onClick={async () => {
          if (props.url.includes('logout')) {
            setConfirmLogout(true);
          } else {
            props.onTap();
            props.history.push(props.url);
          }
        }}
      >
        <SVGIcon name={props.icon} size={appSize.lg} color={appTheme.sidebar.text} />
        <TextDisplay marginLeft={'10px'} size={appSize.lg} color={appTheme.sidebar.text}>
          {props.title}
        </TextDisplay>
      </HorizontalView>
      <ConfirmModal
        open={confirmLogout}
        title={translation('modals.confirmLogout.title')}
        cancelTitle={translation('modals.confirmLogout.close')}
        confirmTitle={translation('modals.confirmLogout.confirm')}
        onClose={() => setConfirmLogout(false)}
        onConfirm={async () => {
          setConfirmLogout(false);
          await logoutUser();
          props.onTap();
          props.history.push('/');
        }}
      />
    </>
  );
};
