import * as React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div``;

export interface BaseViewProps {
  id?: string;
  width?: string;
  minWidth?: string;
  minHeight?: string;
  maxWidth?: string;
  maxHeight?: string;
  height?: string;
  color?: string;
  fullWidth?: boolean;
  fullHeight?: boolean;
  fullWidthHeight?: boolean;
  background?: string;
  backgroundImage?: string;
  padding?: string;
  paddingLeft?: string;
  paddingRight?: string;
  paddingTop?: string;
  paddingBottom?: string;
  borderRadius?: string;
  border?: string;
  top?: string;
  opacity?: number;
  left?: string;
  right?: string;
  bottom?: string;
  margin?: string;
  flex?: string;
  zIndex?: number;
  marginTop?: string;
  marginLeft?: string;
  marginRight?: string;
  marginBottom?: string;
  boxSizing?: 'border-box';
  flexBasis?: string;
  whiteSpace?: React.CSSProperties['whiteSpace'];
  transition?: React.CSSProperties['transition'];
  display?: React.CSSProperties['display'];
  children?: React.ReactNode;
  cursor?: React.CSSProperties['cursor'];
  flexWrap?: React.CSSProperties['flexWrap'];
  overflowY?: React.CSSProperties['overflowY'];
  overflowX?: React.CSSProperties['overflowX'];
  overflow?: React.CSSProperties['overflow'];
  position?: React.CSSProperties['position'];
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  style?: React.CSSProperties;
}

export type AlignContent =
  | 'topLeft'
  | 'topCenter'
  | 'topRight'
  | 'centerLeft'
  | 'center'
  | 'centerRight'
  | 'bottomLeft'
  | 'bottomCenter'
  | 'bottomRight'
  | 'topBetween'
  | 'centerBetween'
  | 'bottomBetween'
  | 'topAround'
  | 'centerAround'
  | 'bottomAround'
  | 'topEvenly'
  | 'centerEvenly'
  | 'bottomEvenly'
  | 'stretch';

interface AlignProps extends BaseViewProps {
  alignContent?: AlignContent;
}
export function alignContentRow(props: AlignProps) {
  const baseStyle: React.CSSProperties = {};
  baseStyle.display = 'flex';
  baseStyle.flexDirection = 'row';
  baseStyle.justifyContent = 'center';
  baseStyle.alignItems = 'center';

  switch (props.alignContent || 'left') {
    case 'topLeft':
      baseStyle.justifyContent = 'flex-start';
      baseStyle.alignItems = 'flex-start';
      break;
    case 'topCenter':
      baseStyle.justifyContent = 'center';
      baseStyle.alignItems = 'flex-start';
      break;
    case 'topRight':
      baseStyle.justifyContent = 'flex-end';
      baseStyle.alignItems = 'flex-start';
      break;
    case 'centerLeft':
      baseStyle.justifyContent = 'flex-start';
      baseStyle.alignItems = 'center';
      break;
    case 'center':
      baseStyle.justifyContent = 'center';
      baseStyle.alignItems = 'center';
      break;
    case 'centerRight':
      baseStyle.justifyContent = 'flex-end';
      baseStyle.alignItems = 'center';
      break;
    case 'bottomLeft':
      baseStyle.justifyContent = 'flex-start';
      baseStyle.alignItems = 'flex-end';
      break;
    case 'bottomCenter':
      baseStyle.justifyContent = 'center';
      baseStyle.alignItems = 'flex-end';
      break;
    case 'bottomRight':
      baseStyle.justifyContent = 'flex-end';
      baseStyle.alignItems = 'flex-end';
      break;
    case 'topBetween':
      baseStyle.justifyContent = 'space-between';
      baseStyle.alignItems = 'flex-start';
      break;
    case 'centerBetween':
      baseStyle.justifyContent = 'space-between';
      baseStyle.alignItems = 'center';
      break;
    case 'bottomBetween':
      baseStyle.justifyContent = 'space-between';
      baseStyle.alignItems = 'flex-end';
      break;
    case 'topAround':
      baseStyle.justifyContent = 'space-around';
      baseStyle.alignItems = 'flex-start';
      break;
    case 'centerAround':
      baseStyle.justifyContent = 'space-around';
      baseStyle.alignItems = 'center';
      break;
    case 'bottomAround':
      baseStyle.justifyContent = 'space-around';
      baseStyle.alignItems = 'flex-end';
      break;
    case 'topEvenly':
      baseStyle.justifyContent = 'space-evenly';
      baseStyle.alignItems = 'flex-start';
      break;
    case 'centerEvenly':
      baseStyle.justifyContent = 'space-evenly';
      baseStyle.alignItems = 'center';
      break;
    case 'bottomEvenly':
      baseStyle.justifyContent = 'space-evenly';
      baseStyle.alignItems = 'flex-end';
      break;
    case 'stretch':
      baseStyle.justifyContent = 'stretch';
      baseStyle.alignItems = 'stretch';
      break;
  }

  return baseStyle;
}

export function alignContentColumn(props: AlignProps) {
  const baseStyle: React.CSSProperties = {};
  baseStyle.display = 'flex';
  baseStyle.flexDirection = 'column';
  baseStyle.justifyContent = 'center';
  baseStyle.alignItems = 'center';

  switch (props.alignContent || 'left') {
    case 'topLeft':
      baseStyle.justifyContent = 'flex-start';
      baseStyle.alignItems = 'flex-start';
      break;
    case 'topCenter':
      baseStyle.justifyContent = 'flex-start';
      baseStyle.alignItems = 'center';
      break;
    case 'topRight':
      baseStyle.justifyContent = 'flex-start';
      baseStyle.alignItems = 'flex-end';
      break;
    case 'centerLeft':
      baseStyle.justifyContent = 'center';
      baseStyle.alignItems = 'flex-start';
      break;
    case 'center':
      baseStyle.justifyContent = 'center';
      baseStyle.alignItems = 'center';
      break;
    case 'centerRight':
      baseStyle.justifyContent = 'center';
      baseStyle.alignItems = 'flex-end';
      break;
    case 'bottomLeft':
      baseStyle.justifyContent = 'flex-end';
      baseStyle.alignItems = 'flex-start';
      break;
    case 'bottomCenter':
      baseStyle.justifyContent = 'flex-end';
      baseStyle.alignItems = 'center';
      break;
    case 'bottomRight':
      baseStyle.justifyContent = 'flex-end';
      baseStyle.alignItems = 'flex-end';
      break;
    case 'topBetween':
      baseStyle.justifyContent = 'space-between';
      baseStyle.alignItems = 'flex-start';
      break;
    case 'centerBetween':
      baseStyle.justifyContent = 'space-between';
      baseStyle.alignItems = 'center';
      break;
    case 'bottomBetween':
      baseStyle.justifyContent = 'space-between';
      baseStyle.alignItems = 'flex-end';
      break;
    case 'topAround':
      baseStyle.justifyContent = 'space-around';
      baseStyle.alignItems = 'flex-start';
      break;
    case 'centerAround':
      baseStyle.justifyContent = 'space-around';
      baseStyle.alignItems = 'center';
      break;
    case 'bottomAround':
      baseStyle.justifyContent = 'space-around';
      baseStyle.alignItems = 'flex-end';
      break;
    case 'topEvenly':
      baseStyle.justifyContent = 'space-evenly';
      baseStyle.alignItems = 'flex-start';
      break;
    case 'centerEvenly':
      baseStyle.justifyContent = 'space-evenly';
      baseStyle.alignItems = 'center';
      break;
    case 'bottomEvenly':
      baseStyle.justifyContent = 'space-evenly';
      baseStyle.alignItems = 'flex-end';
      break;
    case 'stretch':
      baseStyle.justifyContent = 'stretch';
      baseStyle.alignItems = 'stretch';
      break;
  }

  return baseStyle;
}

export const getViewStyle = (props: BaseViewProps) => {
  const baseStyle: React.CSSProperties = {};
  baseStyle.top = props.top;
  baseStyle.left = props.left;
  baseStyle.right = props.right;
  baseStyle.bottom = props.bottom;
  baseStyle.opacity = props.opacity;
  baseStyle.zIndex = props.zIndex;
  baseStyle.flex = props.flex;
  baseStyle.cursor = props.cursor;
  baseStyle.transition = props.transition;
  baseStyle.boxSizing = props.boxSizing;
  baseStyle.flexBasis = props.flexBasis;
  props.whiteSpace && (baseStyle.whiteSpace = props.whiteSpace);
  props.margin && (baseStyle.margin = props.margin);
  props.background && (baseStyle.backgroundColor = props.background);
  props.padding && (baseStyle.padding = props.padding);
  props.paddingTop && (baseStyle.paddingTop = props.paddingTop);
  props.paddingBottom && (baseStyle.paddingBottom = props.paddingBottom);
  props.paddingLeft && (baseStyle.paddingLeft = props.paddingLeft);
  props.paddingRight && (baseStyle.paddingRight = props.paddingRight);
  props.marginTop && (baseStyle.marginTop = props.marginTop);
  props.marginLeft && (baseStyle.marginLeft = props.marginLeft);
  props.marginRight && (baseStyle.marginRight = props.marginRight);
  props.marginBottom && (baseStyle.marginBottom = props.marginBottom);
  props.flexWrap && (baseStyle.flexWrap = props.flexWrap);
  props.height && (baseStyle.height = props.height);
  props.width && (baseStyle.width = props.width);
  props.minWidth && (baseStyle.minWidth = props.minWidth);
  props.overflowY && (baseStyle.overflowY = props.overflowY);
  props.overflowX && (baseStyle.overflowX = props.overflowX);
  props.overflow && (baseStyle.overflow = props.overflow);
  props.position && (baseStyle.position = props.position);
  props.maxWidth && (baseStyle.maxWidth = props.maxWidth);
  props.maxHeight && (baseStyle.maxHeight = props.maxHeight);
  props.minHeight && (baseStyle.minHeight = props.minHeight);
  props.borderRadius && (baseStyle.borderRadius = props.borderRadius);
  props.backgroundImage && (baseStyle.backgroundImage = props.backgroundImage);
  props.border && (baseStyle.border = props.border);
  props.fullWidth && (baseStyle.width = '100%');
  props.fullHeight && (baseStyle.height = '100%');
  if (props.fullWidthHeight) {
    baseStyle.width = '100%';
    baseStyle.height = '100%';
  }

  /* eslint-disable react/prop-types */
  props.display && (baseStyle.display = props.display);

  return { ...baseStyle, ...(props.style ? props.style : {}) };
};

export const ReactView = (props: BaseViewProps) => {
  const style = getViewStyle(props);
  return (
    <Wrapper id={props.id} style={style}>
      {props.children ? props.children : ''}
    </Wrapper>
  );
};

/* eslint-disable react/display-name */
export const VerticalView = React.forwardRef<
  HTMLDivElement | null,
  AlignProps & { flexDirection?: 'column' | 'column-reverse' }
>((props, ref) => {
  const baseStyle = getViewStyle(props);
  /* eslint-disable react/prop-types */
  props.flexDirection && (baseStyle.flexDirection = props.flexDirection);
  const alignContentStyle = alignContentColumn(props);
  if (!props.flexWrap) {
    alignContentStyle.flexWrap = 'wrap';
  }

  /* eslint-disable react/prop-types */
  props.display && (alignContentStyle.display = props.display);

  const mixStyle = { ...baseStyle, ...alignContentStyle };

  const onClick = props.onClick ? props.onClick : undefined;
  return (
    <Wrapper
      id={props.id}
      ref={ref}
      style={mixStyle}
      onClick={onClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      {props.children ? props.children : ''}
    </Wrapper>
  );
});

/* eslint-disable react/display-name */
export const HorizontalView = React.forwardRef<HTMLDivElement | null, AlignProps>((props, ref) => {
  const baseStyle = getViewStyle(props);
  const alignContentStyle = alignContentRow(props);
  if (!props.flexWrap) {
    alignContentStyle.flexWrap = 'wrap';
  }

  /* eslint-disable react/prop-types */
  props.display && (alignContentStyle.display = props.display);
  const mixStyle = { ...baseStyle, ...alignContentStyle };
  const onClick = props.onClick ? props.onClick : undefined;

  return (
    <Wrapper
      id={props.id}
      ref={ref}
      style={mixStyle}
      onClick={onClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      {props.children ? props.children : ''}
    </Wrapper>
  );
});

/* eslint-disable react/display-name */
export const Div = React.forwardRef<HTMLDivElement | null, AlignProps>((props, ref) => {
  const baseStyle = getViewStyle(props);
  const alignContentStyle = alignContentRow(props);
  if (!props.flexWrap) {
    alignContentStyle.flexWrap = 'wrap';
  }

  /* eslint-disable react/prop-types */
  props.display && (alignContentStyle.display = props.display);
  const mixStyle = { ...alignContentStyle, ...baseStyle };
  const onClick = props.onClick ? props.onClick : undefined;
  return (
    <Wrapper
      id={props.id}
      ref={ref}
      style={mixStyle}
      onClick={onClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      {props.children ? props.children : ''}
    </Wrapper>
  );
});

export const Body = (props: { maxWidth?: string; padding?: string; children: React.ReactNode }) => {
  return (
    <HorizontalView fullWidthHeight alignContent={'topCenter'}>
      <VerticalView fullWidth maxWidth={props.maxWidth || '1000px'} padding={props.padding || '0px'}>
        <VerticalView fullWidthHeight alignContent="center">
          {props.children}
        </VerticalView>
      </VerticalView>
    </HorizontalView>
  );
};
