import gql from 'graphql-tag';
export const CACHE = gql`
  query cacheKeys {
    cacheKeys {
      type
      table
      tData
      input
      query
    }
  }
`;
