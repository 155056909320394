export default {
  graphql: {
    uri: '/graphql',
  },
  UI_HOST_URL: 'https://vietcademy.jp',
  HOST_URL: 'https://vietcademy.jp',
  API_HOST_URL: 'https://api.vietcademy.jp',
  STRIPE_PUBLISH:
    'pk_live_51IpWGPHZr2kCozyWtGGdjH3kPBZw0gCR0X4wCjnxsf0lSiEEr8wpwLy0fbxsBmqNmXU3GN9Tp7i1KBMNiGM9ImBU00Mztt7vxe',
  FB_TOKEN:
    '&access_token=EAAFnSdequosBAE6q7Wgs8lDizHQFodTjm5ZCNnwbAITZCfUG0SlzrZA5P25e0ZAk9XOtkb7tuSmrAWFAiK1aCS8EiZCk7RL7dLXjFIkx2R3YKFJke2ZAOF4ZCSmcOy0pjVGl6WQzmd9OvV0gn83N9hbsoxhGS2WKmOwsBINPne3LddZBrZBEtTZAwypmocK8kBcOsgbaXxVT94FSGGabdHCPjo1S8A4ZA0xYqZB6uZCRpvF5YzaCIPcfoyKG0',
  FACEBOOK_APP_ID: '1777256802456199',
  tickets: {
    ticket_1: 'price_1K6a5cHZr2kCozyWpeMOvBH9',
    ticket_3: 'price_1K6aACHZr2kCozyWsOEy92f7',
    ticket_5: 'price_1K6ap2HZr2kCozyWGGhcdiCy',
    ticket_10: 'price_1K6aprHZr2kCozyWBG61B4B6',
  },
};
