import config from 'config';
import React from 'react';
import styled from 'styled-components';
import { CssProps } from '../../types/CommonTypes';
import { AnimationView } from 'vendor-deprecated/Animation';

const ImageWrapper = styled.img<ImageProps>`
  overflow: hidden;
  object-fit: ${(props) => props.objectFit};
  border-radius: ${(props) => props.borderRadius || '0px'};
  width: ${(props) => props.width || 'auto'};
  height: ${(props) => props.height || 'auto'};
`;

export type ImageProps = CssProps & {
  src?: string;
  skipAnimation?: boolean;
  borderRadius?: string;
  objectFit: React.CSSProperties['objectFit'];
};

export const Image = React.memo((props: ImageProps) => {
  const [animation, setAnimation] = React.useState(false);

  const API_HOST_URL = config.API_HOST_URL;
  let src = props.src;

  if (src && !src.includes(API_HOST_URL) && src.includes('/static/files/') && !src?.includes('http')) {
    src = API_HOST_URL + props.src;
  }

  React.useEffect(() => {
    const t = setTimeout(() => {
      setAnimation(true);
    }, 200);
    return () => clearTimeout(t);
  }, [props.src]);

  if (props.skipAnimation) {
    return (
      <ImageWrapper
        {...props}
        src={src}
        width={props.width ? props.width : 'auto'}
        height={props.height ? props.height : 'auto'}
      />
    );
  }

  if (!props.src || (props.src && props.src.length === 0)) {
    return null;
  }

  return (
    <AnimationView
      width={props.width ? props.width : 'auto'}
      height={props.height ? props.height : 'auto'}
      duration={animation ? 200 : 0}
      animation={animation ? 'fadeIn' : 'fadeOut'}
    >
      <ImageWrapper
        {...props}
        src={src}
        width={props.width ? props.width : 'auto'}
        height={props.height ? props.height : 'auto'}
      />
    </AnimationView>
  );
});

Image.displayName = 'Image';
