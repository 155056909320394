export const typeList = {
  languages: [
    {
      label: '日本語',
      value: 'ja',
    },
    // {
    //   label: '英語',
    //   value: 'en',
    // },
    {
      label: 'ベトナム語',
      value: 'vi',
    },
  ],
  test: {
    name: 'asd',
  },
  questionTypes: [
    {
      label: 'グループ',
      value: 'GROUP',
    },
    {
      label: '単一',
      value: 'SINGLE',
    },
  ],
  articleCategories: [
    {
      label: 'ニュース',
      value: 'news',
    },
    {
      label: 'ブログ',
      value: 'blog',
    },
    {
      label: 'イベント',
      value: 'event',
    },
    {
      label: 'ビジネス',
      value: 'business',
    },
    {
      label: '教育',
      value: 'education',
    },
    {
      label: '試験',
      value: 'exam',
    },
    {
      label: 'コース',
      value: 'course',
    },
  ],
  serviceTypes: [
    {
      label: 'ニュース',
      value: 'NEWS',
    },
    {
      label: 'サービス',
      value: 'SERVICE',
    },
  ],
  questionCategories: [
    {
      label: '読解',
      value: 'READING',
    },
    {
      label: '聴解',
      value: 'LISTENING',
    },
  ],
  userProfileMenu: [
    {
      prefixIcon: 'user',
      label: '個人情報',
      url: '/user/my-profile',
    },
    {
      prefixIcon: 'message',
      label: 'お知らせ',
      url: '/user/notification',
    },
    {
      prefixIcon: 'clock',
      label: '予約',
      url: '/user/booking',
    },
    {
      prefixIcon: 'clock',
      label: 'マイスケジュール',
      url: '/user/my-booking',
    },
    {
      prefixIcon: 'cart',
      label: 'オーダー',
      url: '/user/orders',
    },
    {
      prefixIcon: 'archive',
      label: '私のコース',
      url: '/user/courses',
    },
    // {
    //   prefixIcon: 'check-circle',
    //   label: '試験',
    //   url: '/user/my-exam',
    // },
    // {
    //   prefixIcon: 'book',
    //   label: '教科書',
    //   url: '/user/my-book',
    // },
    {
      prefixIcon: 'key',
      label: 'パスワード変更',
      url: '/user/change-password',
    },
    {
      prefixIcon: 'logout',
      label: 'ログアウト',
      url: '/user/logout',
    },
  ],
  authorMenu: [
    {
      prefixIcon: 'statistic',
      label: '統計',
      url: '/author/statistics',
    },

    {
      prefixIcon: 'clock',
      label: 'スケジュール',
      url: '/author/schedule',
    },
    {
      prefixIcon: 'user',
      label: '個人情報',
      url: '/author/profile',
    },
    {
      prefixIcon: 'message',
      label: 'お知らせ',
      url: '/author/notification',
    },
    {
      prefixIcon: 'case',
      label: 'コース管理',
      url: '/author/courses',
    },
    {
      prefixIcon: 'question',
      label: '質問管理',
      url: 'questions',
    },
    {
      prefixIcon: 'key',
      label: 'パスワード変更',
      url: '/author/change-password',
    },
    {
      prefixIcon: 'media',
      label: 'メディアセンター',
      url: '/author/medias',
    },
    {
      prefixIcon: 'logout',
      label: 'ログアウト',
      url: '/author/logout',
    },
  ],
  courseDetailsMenu: [
    {
      prefixIcon: 'book',
      label: '教材',
      url: 'syllabus',
    },
    {
      prefixIcon: 'check-circle',
      label: 'テスト管理',
      url: 'exam',
    },

    {
      prefixIcon: 'case',
      label: '概要',
      url: 'course-landing',
    },
    {
      prefixIcon: 'dollar',
      label: '価格設定',
      url: 'price',
    },
  ],
  footerMenu: [
    [
      {
        type: 'header',
        title: 'Vietcademy',
      },
      {
        type: 'text',
        title:
          'QHコンサルティング（QHC）は豊かな経験・業界s知識を駆使して、 お客様の直面する変asd化や課題を解決しています。 デジタル化時代における、 IT活用はお客様の経営戦略を支援します。',
      },
      {
        type: 'phone',
        title: '03-6873-1076',
      },
      {
        type: 'email',
        title: 'contact@qh-consulting.com',
      },
    ],
    [
      {
        type: 'header',
        title: '運営会社',
      },
      // {
      //   type: 'link',
      //   title: '企業情報',
      //   url: 'https://qh-consulting.com/about',
      // },
      {
        type: 'link',
        title: '個人情報保護方針',
        url: '/terms-of-service',
      },
      {
        type: 'link',
        title: '使用条件',
        url: '/privacy',
      },
      {
        type: 'link',
        title: '特商法に基づく表記',
        url: '/specified-commercial-law',
      },

      {
        type: 'link',
        title: 'お問い合わせ',
        url: '/contact',
      },
    ],
    [
      {
        type: 'header',
        title: 'メニュー',
      },
      {
        type: 'link',
        title: 'コース',
        url: '/courses',
      },
      {
        type: 'link',
        title: 'サービス',
        url: '/services',
      },
      {
        type: 'link',
        title: 'ニュース',
        url: '/articles',
      },
      {
        type: 'link',
        title: 'ヘルプ',
        url: '/help-center',
      },
    ],
    [
      {
        type: 'header',
        title: 'APP',
      },
      {
        type: 'android',
        title: 'android',
      },

      {
        type: 'ios',
        title: 'ios',
      },
    ],
  ],
  genders: [
    {
      label: '女性',
      value: 'FEMALE',
    },
    {
      label: '男性',
      value: 'MALE',
    },
  ],
  userDetailsTabs: [
    {
      label: '情報',
      value: 'UserInformation',
    },
    {
      label: 'コース',
      value: 'UserCourse',
    },
    {
      label: '結果テスト',
      value: 'UserExam',
    },
  ],
  adminDetailsTabs: [
    {
      label: '情報',
      value: 'UserInformation',
    },
    {
      label: 'コース',
      value: 'UserCourse',
    },
    {
      label: '結果テスト',
      value: 'UserExam',
    },
  ],
  yesNoTypes: [
    {
      label: 'いいえ',
      value: false,
    },
    {
      label: 'はい',
      value: true,
    },
  ],
  examSegmentItems: [
    {
      label: 'シングル',
      value: 'single',
    },
    {
      label: 'すべて',
      value: 'all',
    },
  ],
  cmsSidebarMenus: [
    {
      label: 'ユーザー管理',
      prefixIcon: 'user',
      url: '/cms/users',
      subMenus: [
        {
          label: 'ユーザー一覧',
          url: '/cms/users',
        },
        {
          label: 'ユーザー試験一覧',
          url: '/cms/user-exams',
        },
      ],
    },
    {
      prefixIcon: 'menu',
      label: 'スケジュール管理',
      url: '/cms/schedules',
      subMenus: [
        {
          label: 'スケジュール管理',
          url: '/cms/schedules',
        },
      ],
    },
    {
      prefixIcon: 'clock',
      label: '予約管理',
      url: '/cms/bookings',
      subMenus: [
        {
          label: '予約管理',
          url: '/cms/bookings',
        },
      ],
    },
    // {
    //   label: '注文管理',
    //   prefixIcon: 'cart',
    //   url: '/cms/orders',
    //   subMenus: [
    //     {
    //       label: '注文一覧',
    //       url: '/cms/orders',
    //     },
    //   ],
    // },
    // {
    //   prefixIcon: 'book',
    //   label: '教科書管理',
    //   url: '/cms/books',
    //   subMenus: [
    //     {
    //       label: '教科書一覧',
    //       url: '/cms/books',
    //     },
    //     {
    //       prefixIcon: 'check',
    //       label: '教科書アップロード',
    //       url: '/cms/book/upload',
    //     },
    //   ],
    // },
    {
      prefixIcon: 'case',
      label: 'コース管理',
      url: '/cms/courses',
      subMenus: [
        {
          label: 'コース一覧',
          url: '/cms/courses',
        },
        {
          prefixIcon: 'check',
          label: 'コースアップロード',
          url: '/cms/courses/upload',
        },
      ],
    },
    // {
    //   prefixIcon: 'question',
    //   label: '質問管理',
    //   url: '/cms/questions',
    //   subMenus: [
    //     {
    //       label: '質問一覧',
    //       url: '/cms/questions',
    //     },
    //     {
    //       prefixIcon: 'check',
    //       label: '質問アップロード',
    //       url: '/cms/questions/upload',
    //     },
    //   ],
    // },
    // {
    //   prefixIcon: 'clock',
    //   label: '試験管理',
    //   url: '/cms/exam/list',
    //   subMenus: [
    //     {
    //       label: '試験一覧',
    //       url: '/cms/exam/list',
    //     },
    //     {
    //       prefixIcon: 'check',
    //       label: '試験結果',
    //       url: '/cms/exam-result/list',
    //     },
    //   ],
    // },

    {
      prefixIcon: 'file',
      label: '投稿',
      url: '/cms/articles',
      subMenus: [
        {
          label: '投稿一覧',
          url: '/cms/articles',
        },
      ],
    },
    {
      prefixIcon: 'case',
      label: 'サービス',
      url: '/cms/services',
      subMenus: [
        {
          label: 'サービス一覧',
          url: '/cms/services',
        },
      ],
    },
    {
      prefixIcon: 'media',
      label: 'メディアセンター',
      url: '/cms/medias',
      subMenus: [
        {
          label: 'メディア一覧',
          url: '/cms/medias',
        },
      ],
    },

    {
      prefixIcon: 'question',
      label: 'ヘルプ',
      url: '/cms/helps',
      subMenus: [
        {
          label: 'ヘルプマネージャー',
          url: '/cms/helps',
        },
      ],
    },
    {
      prefixIcon: 'email',
      label: 'コンタクト',
      url: '/cms/contacts',
      subMenus: [
        {
          label: 'コンタクトマネージャー',
          url: '/cms/contacts',
        },
      ],
    },
    {
      prefixIcon: 'list',
      label: 'クレーム',
      url: '/cms/reports',
      subMenus: [
        {
          label: 'クレームマネージャー',
          url: '/cms/reports',
        },
      ],
    },

    {
      prefixIcon: 'gift',
      label: 'イベント',
      url: '/cms/events',
      subMenus: [
        {
          label: 'イベントマネージャー',
          url: '/cms/events',
        },
        {
          label: 'マーケティング',
          url: '/cms/marketing',
        },
      ],
    },

    {
      prefixIcon: 'case',
      label: 'ACCESS LOG',
      url: '/cms/access-log',
      subMenus: [
        {
          label: 'ACCESS LOG',
          url: '/cms/access-log',
        },
      ],
    },
    {
      prefixIcon: 'setting',
      label: '設定',
      url: '/cms/setting',
      subMenus: [
        {
          label: 'トップページ',
          url: '/cms/settings/home',
        },
        {
          label: '使用条件',
          url: '/cms/settings/privacy',
        },
        {
          label: '利用規約',
          url: '/cms/settings/terms-of-service',
        },
        {
          label: '運営会社',
          url: '/cms/settings/company-profile',
        },
        {
          label: '特商法に基づく表記',
          url: '/cms/settings/specified-commercial-law',
        },
        {
          label: 'ニュース',
          url: '/cms/settings/articles',
        },
        {
          label: 'コース',
          url: '/cms/settings/course',
        },
        {
          label: 'フッター',
          url: '/cms/settings/footer',
        },
        {
          label: '言語翻訳',
          url: '/cms/settings/locale-translation',
        },
      ],
    },
    {
      prefixIcon: 'logout',
      label: 'ログアウト',
      url: 'logout',
      subMenus: [],
    },
  ],
  orderStatus: [
    {
      label: '承認待ち',
      value: 'PENDING',
    },
    {
      label: '承認済み',
      value: 'APPROVED',
    },
  ],
  orderSegment: [
    {
      label: 'カート',
      value: 'cart',
    },
    {
      label: '注文',
      value: 'order',
    },
  ],
  examTypes: [
    {
      label: 'ミニテスト',
      value: 'MiniTest',
    },
    {
      label: 'フルテスト',
      value: 'FullTest',
    },
  ],
  examTabs: [
    {
      label: 'すべて',
      value: 'All',
    },
    {
      label: 'ミニテスト',
      value: 'MiniTest',
    },
    {
      label: 'フルテスト',
      value: 'FullTest',
    },
  ],
  userRoles: [
    {
      label: '管理者',
      value: 'ADMIN',
    },
    {
      label: 'メンバー',
      value: 'USER',
    },
    {
      label: '著者',
      value: 'AUTHOR',
    },
  ],
  segmentPublic: [
    {
      label: '公開',
      value: true,
    },
    {
      label: '非公開',
      value: false,
    },
  ],
  examSteps: [
    '(Yêu cầu: Khoá học đã được tạo và đã công khai).',
    '',
    '1. Liên kết đề thi với khoá học. ( Để biết đề thi dành cho khoá học nào ).',
    '2. Thêm các mục.',
    '3. Thêm câu hỏi cho các mục.',
    '',
    'Để chỉnh sửa câu hỏi nhấp vào nút chỉnh sửa.',
  ],
  courseSteps: [
    'Yêu cầu: Giáo trình đã được tạo và đã công khai.',
    'Mỗi khoá học có thể có nhiều giáo trình khác nhau.',
    '',
    '1. Thêm giáo trình.',
    'Tên, mô tả và các tiêu đề của bài học được lấy tự động.',
    '',
    '2. Chỉnh sửa nội dung tiêu đề - mô tả - bài học nếu cần.',
    '',
    '3. Thêm Các kỹ năng sẽ học được trong mỗi giáo trình.',
  ],
  questionSteps: [
    'Yêu cầu: Giáo trình đã được tạo và đã công khai.',
    'Mỗi Giáo trình đều có nhiều bài học khác nhau. Vì thế mỗi câu hỏi sẽ tạo ra từ nội dung bài học',
    '',
    '1. Chọn bài học cần tạo câu hỏi [Tên bài học]. nếu không có bài học vui lòng thêm bài học vào giáo trình trước',
    '',
    '2. Chọn loại câu hỏi đơn hay nhóm.',
    '',
    '3. Chọn loại câu hỏi về nghe hay đọc hiểu.',
    '',
    '4. Nhập nội dung câu hỏi.',
  ],
  helpCategories: [
    {
      label: 'アカウント',
      value: 'account',
    },
    {
      label: '本',
      value: 'book',
    },
    {
      label: 'コース',
      value: 'course',
    },
    {
      label: '質問',
      value: 'question',
    },
    {
      label: '試験',
      value: 'exam',
    },
    {
      label: '支払い',
      value: 'payment',
    },
    {
      label: 'ポリシー',
      value: 'policy',
    },
    {
      label: 'その他',
      value: 'other',
    },
  ],
  daysOfWeek: [
    {
      label: '月',
      value: 'monday',
    },
    {
      label: '火',
      value: 'tuesday',
    },
    {
      label: '水',
      value: 'wednesday',
    },
    {
      label: '木',
      value: 'thursday',
    },
    {
      label: '金',
      value: 'friday',
    },
    {
      label: '土',
      value: 'saturday',
    },
    {
      label: '日',
      value: 'sunday',
    },
  ],
  targetGroup: [
    {
      label: '新会員',
      value: 'NEW_MEMBER',
    },
    {
      label: '学生',
      value: 'MEMBER',
    },
    {
      label: '有料ユーザー',
      value: 'PAID_MEMBER',
    },
    {
      label: '著者',
      value: 'AUTHOR',
    },
  ],
  eventType: [
    {
      label: 'ボーナスコース',
      value: 'BONUS_COURSE',
    },
    {
      label: 'ボーナスチケット',
      value: 'BONUS_TICKET',
    },
  ],
  offerGroup: [
    {
      label: 'ゲスト',
      value: 'GUEST',
    },
    {
      label: '学生',
      value: 'USER',
    },
    {
      label: '有料ユーザー',
      value: 'PAID_USER',
    },
    {
      label: 'Tác giả / Giảng viên',
      value: '著者',
    },
  ],
  priceGroup: [
    {
      label: '有料',
      value: 'FEE',
    },
    {
      label: '無料',
      value: 'FREE',
    },
  ],
  mediaGroup: [
    {
      label: '画像',
      value: 'image',
    },
    {
      label: 'ビデオ',
      value: 'video',
    },
    {
      label: 'オーディオ',
      value: 'audio',
    },
  ],
  limitOptions: [
    {
      label: '10件',
      value: 10,
    },
    {
      label: '30件',
      value: 30,
    },
    {
      label: '50件',
      value: 50,
    },
    {
      label: '100件',
      value: 100,
    },
    {
      label: '500件',
      value: 500,
    },
  ],
  objectReport: [
    {
      label: '教師',
      value: 'author',
    },
    {
      label: '予約',
      value: 'booking',
    },
    {
      label: '払い戻しチケット',
      value: 'refund-ticket',
    },
    {
      label: 'コース',
      value: 'course',
    },
  ],
  lessonLevels: [
    {
      label: '初級者',
      value: 'lesson-level-beginner',
    },
    {
      label: '中級者',
      value: 'lesson-level-intermediate',
    },
    {
      label: 'ビジネスレベル',
      value: 'lesson-level-business',
    },
    {
      label: 'まだレベルか分からない方',
      value: 'lesson-level-unkown',
    },
    {
      label: '読み書きはできるけど会話が苦手な人',
      value: 'lesson-level-only-red-and-write',
    },
  ],
  lessonTopics: [
    {
      label: '日常会話',
      value: 'lesson-topic-daily-communication',
    },
    {
      label: 'ビジネス会話',
      value: 'lesson-topic-business-communication',
    },
    {
      label: '初級者向けレッスン',
      value: 'lesson-topic-beginners',
    },
    {
      label: 'フリートーク',
      value: 'lesson-topic-free-talk',
    },
    {
      label: '映画や音楽など',
      value: 'lesson-topic-movies-music-etc',
    },
    {
      label: '宿題の手伝い',
      value: 'lesson-topic-homework',
    },
    {
      label: 'キッズ向けレッスン',
      value: 'lesson-topic-kids',
    },
    {
      label: 'ベトナム語技能検定対策',
      value: 'lesson-topic-vietnamese-test',
    },
    {
      label: 'その他（生徒の希望次第）',
      value: 'other',
    },
  ],
  lessonFlows: [
    {
      label: '自己紹介の時間を短くして欲しい（名前の確認程度）',
      value: 'self-introduce-shorten',
    },
    {
      label: '初心者なのでゆっくり話してほしい',
      value: 'talk-slowly',
    },
    {
      label: '生徒が話す時間を多くとってほしい',
      value: 'student-talk-more',
    },
  ],
  lessonStrictness: [
    {
      label: '講師に任せる',
      value: 'teacher-depend',
    },
    {
      label: '積極的に間違いの指摘をしてほしい',
      value: 'point-out',
    },
    {
      label: '会話を楽しみたいので、あまり指摘はしなくて良い',
      value: 'not-point-out',
    },
  ],
  marketingTypes: [
    {
      label: 'レストラン',
      value: 'restaurant',
    },
    {
      label: 'ショップ',
      value: 'shop',
    },
    {
      label: 'コース',
      value: 'course',
    },
    {
      label: 'イベント',
      value: 'event',
    },
  ],
};
